import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useMemo } from 'react';

interface INodeExtended extends Omit<INode, 'content'> {
  content: { btnText: string };
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { btnText },
  },
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="Content" initialOpen>
        <InputControl
          name="btnText"
          defaultValue={btnText}
          fullWidth
          variant="outlined"
          label="Button text"
        />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = (props) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
