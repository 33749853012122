import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { IMenuItem } from '@/types/menu';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useContext } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';

interface IProps {
  data: IMenuItem;
  conferenceId: string;
  leftMenu?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: '6px 0px',
    fontWeight: theme.typography.fontWeightBold,
    margin: '3px 8px',
    // '&:last-child': {
    //   marginRight: 0,
    // },
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  leftMenu: {
    padding: '2px 0',
    margin: 0,
  },
  elevated: {
    backgroundColor: theme.palette.background.shadedown_20,
    borderRadius: 20,
    padding: '6px 17px',
  },
  img: {
    maxHeight: 70,
    maxWidth: '100%',
  },
}));

/**
 *
 * Renders an item for the top menu
 *
 * @params data required any
 * @returns JSX.Element
 * @example
 * <TopMenuItem data={menuData} />
 */
export const TopMenuItem: FC<IProps> = ({
  data: { title, type, url, target, picture, icon, design },
  conferenceId,
  leftMenu = false,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={clsx(classes.root, {
        [classes.elevated]: design === 'elevated',
        [classes.leftMenu]: leftMenu,
        // [classes.elevated]: design === 'picture',
      })}
      href={url}
      target={target || null}
    >
      {picture ? (
        <img
          className={classes.img}
          src={getImage(picture, ImageSize.Small, conferenceId)}
          alt={title}
        />
      ) : (
        title
      )}
    </ButtonBase>
  );
};
