import { RenderComponent } from '@/node-editor';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { SiteContainer } from './container';
import { fetchPageBySlugService } from '@/page-editor/utilities';
import { IPage, IPageTemplate } from '@/types/page';

interface IProps extends RouteComponentProps {
  authState: {
    stateLoaded: boolean;
    userLoggedIn: boolean;
    [key: string]: unknown;
  };
}

export const SitePage = ({ authState, match: { path } }: IProps) => {
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState<IPage | IPageTemplate | null>(null);

  useEffect(() => {
    let mounted = true;

    fetchPageBySlugService(window.CONFERENCE_ID, path)
      .then((response) => mounted && setPage(response.data))
      .finally(() => mounted && setLoaded(true));

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <SiteContainer {...authState} path={path}>
      {loaded && <RenderComponent entity={page} />}
    </SiteContainer>
  );
};
