import { FormLabel, Icon, IconButton, makeStyles } from '@material-ui/core';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { MediaPicker } from '../media-picker';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  conferenceId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '164px',
    height: '96px',
    padding: theme.spacing(1),
    borderColor: theme.palette.grey[200],
    border: '1px solid',
    marginBottom: theme.spacing(2),
  },
  image: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}));

export const MediaPlaceholder: FC<IProps> = ({
  value,
  onChange,
  label,
  conferenceId,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(value);

  const openDialog = () => {
    setIsOpen(true);
  };

  const onSelectImage = (source_id: string) => {
    onChange(source_id);
    setImage(source_id);
    setIsOpen(false);
  };

  const onRemoveImage = () => {
    onChange(undefined);
    setImage(undefined);
  };

  useEffect(() => {
    setImage(value);
  }, [value]);

  return (
    <Fragment>
      <FormLabel>{label}</FormLabel>
      <div className={classes.root}>
        <div
          className={classes.image}
          style={{
            ...(image && {
              backgroundImage: `url('${getImage(
                image,
                ImageSize.Medium,
                conferenceId
              )}')`,
            }),
          }}
        >
          {image ? (
            <IconButton onClick={onRemoveImage}>
              <Icon>delete</Icon>
            </IconButton>
          ) : (
            <IconButton onClick={openDialog}>
              <Icon>add_a_photo</Icon>
            </IconButton>
          )}
        </div>
      </div>
      <MediaPicker
        open={isOpen}
        onClose={onSelectImage}
        conferenceId={conferenceId}
      />
    </Fragment>
  );
};
