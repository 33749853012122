import { Typography } from '@material-ui/core';
import { CompositeDecorator, DefaultDraftBlockRenderMap } from 'draft-js';
import { Map } from 'immutable';
import React from 'react';
import { RichTextLink } from './components/link';

export const BLOCK_TYPES = {
  unstyled: 'unstyled',
  header_one: 'header-one',
  header_two: 'header-two',
  header_three: 'header-three',
};

export const isPlatformMac = (): boolean =>
  navigator.platform.indexOf('Mac') > -1;

const customBlockRenderMap = Map({
  [BLOCK_TYPES.unstyled]: {
    element: 'span',
    aliasedElements: ['p'],
    wrapper: <Typography component="span" paragraph />,
  },
  [BLOCK_TYPES.header_one]: {
    element: 'span',
    wrapper: <Typography variant="h1" />,
  },
  [BLOCK_TYPES.header_two]: {
    element: 'span',
    wrapper: <Typography variant="h2" />,
  },
  [BLOCK_TYPES.header_three]: {
    element: 'span',
    wrapper: <Typography variant="h3" />,
  },
});

export const blockRenderMap = DefaultDraftBlockRenderMap.merge(
  customBlockRenderMap
);

export const blockStyleFn = (block: Draft.ContentBlock) => {
  let alignment = 'left';
  (block as any).findStyleRanges((e: any) => {
    if (e.hasStyle('center')) {
      alignment = 'center';
    }
    if (e.hasStyle('right')) {
      alignment = 'right';
    }
  });
  return `editor-alignment-${alignment}`;
};

export const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const decorators = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: RichTextLink,
  },
]);
