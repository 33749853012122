import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  FormHelperText,
  Icon,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { AuthContext } from '../../shared/context/AuthContext';

const schema = yup
  .object()
  .shape({
    password: yup.string().min(7).max(25).required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

const BackToStart = ({ isManager }) => (
  <Link href={isManager ? '/manager' : '/'}>Back to start</Link>
);

const PasswordReset = withRouter((props) => {
  const { authState, actions } = useContext(AuthContext); // Hämta Context
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const isManager = props.match.params.type === 'm';

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
  });

  register('id', {
    type: 'text',
    value: props.match.params.id,
  });

  const onSubmit = (data) => {
    updatePassword(props.match.params.type, data);
  };

  const updatePassword = (type, data) => {
    setLoading(true);
    setErrorMsg('');
    axios
      .post(`/api/recoverycode/${type}`, data)
      .then(() => {
        setForgotSuccess(true);
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const loadByRecoveryCode = (type, recoveryCode) => {
    setLoading(true);
    axios
      .get(`/api/recoverycode/${type}/${recoveryCode}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        setErrorMsg(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadByRecoveryCode(
      props.match.params.type === 'p' ? 'visitor' : 'manager',
      props.match.params.id
    );
  }, []);

  return authState.stateLoaded ? (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" pt={4}>
          {!user && errorMsg ? (
            <Paper>
              <Typography color="error" gutterBottom>
                {errorMsg}
              </Typography>
              <BackToStart isManager={isManager} />
            </Paper>
          ) : (
            <Paper>
              {authState.userLoggedIn && (
                <Box p={2}>
                  You are already logged in!
                  <br />
                  <BackToStart isManager={isManager} />
                </Box>
              )}
              <Box p={2}>
                {user && (
                  <Typography variant="body1" gutterBottom>
                    {user.full_name}
                  </Typography>
                )}
                <Typography variant="h5" gutterBottom>
                  Create new password
                </Typography>
                {forgotSuccess ? (
                  <Box p={2}>
                    <Typography variant="body2" gutterBottom>
                      Password changed!
                      <br />
                      <br />
                      <BackToStart isManager={isManager} />
                    </Typography>
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box pb={2}>
                      <Box>
                        <FormControl>
                          <TextField
                            disabled={loading}
                            error={errors.hasOwnProperty('password')}
                            {...register('password')}
                            type="password"
                            label="New password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icon>lock</Icon>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        {errors.password && (
                          <FormHelperText error>
                            {errors.password.message}
                          </FormHelperText>
                        )}
                      </Box>
                      <Box>
                        <FormControl>
                          <TextField
                            disabled={loading}
                            error={errors.hasOwnProperty(
                              'passwordConfirmation'
                            )}
                            {...register('passwordConfirmation')}
                            type="password"
                            label="Repeat password"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icon>lock</Icon>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        {errors.passwordConfirmation && (
                          <FormHelperText error>
                            {errors.passwordConfirmation.message}
                          </FormHelperText>
                        )}
                      </Box>
                      {errorMsg && (
                        <FormHelperText error>{errorMsg}</FormHelperText>
                      )}
                    </Box>
                    <Box align="right">
                      <Button
                        disabled={loading}
                        variant="contained"
                        type="submit"
                        color="primary"
                      >
                        Save {loading && <CircularProgress size={24} />}
                      </Button>
                    </Box>
                  </form>
                )}
              </Box>
            </Paper>
          )}
        </Box>
      </Container>
    </React.Fragment>
  ) : (
    <Box align="center">
      <CssBaseline />
      <CircularProgress disableShrink />
    </Box>
  );
});

export default PasswordReset;
