import { ButtonGroup } from '@/components/buttongroup-control';
import { Icon } from '@material-ui/core';
import { EditorState, Modifier } from 'draft-js';
import React from 'react';

interface IProps {
  editorState: Draft.EditorState;
  dispatch: (state: Draft.EditorState) => void;
}

export const AlignmentControls = ({ editorState, dispatch }: IProps) => {
  
  const alignmentStyles = ['left', 'right', 'center'];

  const applyAlignment = (newStyle: string) => {

    let styleForRemove = alignmentStyles.filter((style) => style !== newStyle);
    let currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey());
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey());
    let isBackward = selection.getIsBackward();

    let selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    };

    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength();
    }

    let finalSelection = selection.merge(selectionMerge);

    let finalContent = styleForRemove.reduce(
      (content, style) =>
        Modifier.removeInlineStyle(content, finalSelection, style),
      currentContent
    );

    let modifiedContent = Modifier.applyInlineStyle(
      finalContent,
      finalSelection,
      newStyle
    );

    const nextEditorState = EditorState.push(
      editorState,
      modifiedContent,
      'change-inline-style'
    );

    dispatch(nextEditorState);
  };

  const currentStyle = editorState.getCurrentInlineStyle();

  const currentValue = currentStyle.has('center') ? 'center' : currentStyle.has('right') ? 'right' : 'left';

  return (
    <ButtonGroup
      defaultValue={currentValue}
      options={[
        {
          label: 'Align Left',
          value: 'left',
          component: <Icon>format_align_left</Icon>,
        },
        {
          label: 'Align Center',
          value: 'center',
          component: <Icon>format_align_justify</Icon>,
        },
        {
          label: 'Align Right',
          value: 'right',
          component: <Icon>format_align_right</Icon>,
        },
      ]}
      onChange={applyAlignment}
    />
  );
};