import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Add to Calendar',
  icon: 'insert_invitation',
  type: 'addtocalendar',
  tag: 'components',
  content: {
    label: 'Add to Calendar',
    title: '',
    description: '',
    // location: '', // Most often the Conference domain
    start: '', // Date
    end: '', // Date
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
