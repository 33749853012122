import { getConferenceId } from '@/js/shared/utils/DataFormat';
import { IPage } from '@/types/page';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { fetchPagesMapService } from './utilities';

type PartialPage = Pick<IPage, 'id' | 'slug' | 'locked'>;
type PageMap = { [slug: string]: PartialPage };

interface IProvided {
  getPage: (slug: string) => PartialPage;
  getPagesArray: () => PartialPage[];
}

export const ConferencePagesContext = createContext<IProvided | undefined>(
  undefined
);

/**
 * Context for site bundle, assumes every conference atleast has default pages.
 *
 * @param children - React.ReactNode
 * @returns
 */
export const ConferencePagesProvider: React.FC<object> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  const [pageMap, setPageMap] = useState<PageMap>();

  const conferenceId = useRef<string>(getConferenceId());

  const loadPageMap = async (subscribed: boolean) => {
    if (!subscribed) return;

    try {
      const { data } = await fetchPagesMapService(conferenceId.current);

      setPageMap(data);
    } catch (error) {
      // handle error
    } finally {
      setLoaded(true);
    }
  };

  const getPage: IProvided['getPage'] = (slug) => pageMap[slug];

  const getPagesArray = () => Object.values(pageMap);

  useEffect(() => {
    let subscribed = true;

    loadPageMap(subscribed);

    return () => {
      subscribed = false;
    };
  }, []);

  return (
    <ConferencePagesContext.Provider value={{ getPage, getPagesArray }}>
      {loaded && children}
    </ConferencePagesContext.Provider>
  );
};
