import { Box, Button, Fade, Icon, Link, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import { UserContext } from '../../context/UserContext';
import { VideoChatContext } from '../../context/VideoChatContext';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles(() => ({
  miniEcard: {
    maxWidth: 230,
    borderRadius: 4,
    backgroundColor: '#77777744',
    textAlign: 'left',
  },
  realEcard: {
    overflowWrap: 'anywhere',
  },
}));

const FollowToggle = (props) => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { actions } = useContext(UserContext);
  const { handleSendInvitation } = useContext(VideoChatContext);
  const { conferenceState, openNewThreadPopup, canIStartChat } = useContext(
    ConferenceContext
  ); // Hämta Context
  const [messageText, setMessageText] = useState('');
  const [updated, setUpdated] = useState(true);
  const [addMessage, setAddMessage] = useState(false);

  const toggleFollow = () => {
    setUpdated(false);
    actions.updateFollowing(
      props.user.id,
      !props.user.youAreFollowing,
      messageText,
      (updatedUser) => {
        setTimeout(() => {
          setUpdated(true);
          setMessageText('');
          setAddMessage(false);
          props.updatedCallback && props.updatedCallback(updatedUser);
        }, 200);
      }
    );
  };

  return (
    <Box>
      <Fade in={updated} timeout={{ appear: 300, exit: 300 }}>
        {props.user.youAreFollowing ? (
          <Box pb={1}>
            {props.user.followsYou ? (
              <>
                <em>Hi! You have exchanged E-Cards</em>
                {props.user.following[0].visitorFollowings.text && (
                  <Box p={1}>
                    <em>"{props.user.following[0].visitorFollowings.text}"</em>
                  </Box>
                )}
                <Box className={classes.miniEcard} display="flex">
                  <Box p={1}>
                    <UserAvatar size="medium" user={props.user} />
                  </Box>
                  <Box p={1} flexGrow={1} className={classes.realEcard}>
                    <Link href={`mailto:${props.user.email}`}>
                      {props.user.email}
                    </Link>
                    <br />
                    <em>{props.user.phone || 'No phone added'}</em>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <em>
                  E-Card requested! <br />
                  If granted, you will share contact details.
                </em>
              </>
            )}
            <br />
            <Button
              onClick={toggleFollow}
              size="small"
              color="secondary"
              variant="contained"
              startIcon={<Icon>remove_circle</Icon>}
            >
              {props.user.followsYou ? 'Take back' : 'Regret asking'}
            </Button>
          </Box>
        ) : (
          <Box pb={1}>
            {props.user.followsYou ? (
              <>
                <em>
                  Has asked for your E-Card! <br />
                  Exchange to share contact details.
                </em>
                <br />
                {props.user.following[0].visitorFollowings.text && (
                  <Box p={1}>
                    <em>"{props.user.following[0].visitorFollowings.text}"</em>
                  </Box>
                )}
                <br />
              </>
            ) : (
              <Box className={classes.miniEcard} display="flex">
                <Box p={1}>
                  <UserAvatar size="medium" user={props.user} />
                </Box>
                <Box p={1} flexGrow={1}>
                  <em>Email: **@***.com</em>
                  <br />
                  <em>Phone: ***-****</em>
                </Box>
              </Box>
            )}
            {addMessage ? (
              <Box pt={1}>
                <Box pb={1}>
                  <TextField
                    autoFocus
                    placeholder="Add a message (if you want)"
                    multiline
                    rows={3}
                    InputLabelProps={{ shrink: true }}
                    label="Message"
                    variant="outlined"
                    value={messageText}
                    onChange={(e) => setMessageText(e.target.value)}
                  />
                </Box>
                <Button
                  size="small"
                  color="default"
                  onClick={() => setAddMessage(false)}
                  variant="contained"
                >
                  cancel
                </Button>
                &nbsp;
                <Button
                  size="small"
                  color="primary"
                  onClick={toggleFollow}
                  variant="contained"
                  startIcon={<Icon>contact_mail</Icon>}
                >
                  {props.user.followsYou ? 'Exchange' : 'Now ask'}
                </Button>
              </Box>
            ) : (
              <Box pb={1}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setAddMessage(true)}
                  variant="contained"
                  startIcon={<Icon>contact_mail</Icon>}
                >
                  {props.user.followsYou ? 'Exchange E-Card' : 'Ask for E-Card'}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Fade>

      {canIStartChat(
        conferenceState.conferenceDetail.chat_level,
        authState.user,
        props.user.followsYou
      ) && (
        <Box pb={1}>
          <Button
            startIcon={<Icon>forum</Icon>}
            color="primary"
            size="small"
            variant="contained"
            onClick={() => openNewThreadPopup([props.user])}
          >
            Start chat
          </Button>
        </Box>
      )}
      {/* {window.SETTINGS.USE_VIDEOCHAT &&
        canIStartChat(
          conferenceState.conferenceDetail.chat_level,
          authState.user,
          props.user.followsYou
        ) && (
          <Box pb={1}>
            <Button
              startIcon={<Icon>videocam</Icon>}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleSendInvitation(props.user)}
            >
              Video call
            </Button>
          </Box>
        )} */}
    </Box>
  );
};

export default FollowToggle;
