import { Edit } from './edit';
import { Render } from './render';
import { INodeTemplate } from '../../types/node';

const template: INodeTemplate = {
  version: 1,
  name: 'Eventinfo template',
  icon: 'table_chart',
  type: 'static-eventinfo',
  tag: 'components',
  content: {
    design: 'dateimage',
    addtocalendar: 'Add to calendar',
  },
  override: {
    root: {},
    addtocalendar: {},
  },
  supports: {
    contexts: ['page'],
    appender: false,
  },
};

export { template, Render, Edit };
