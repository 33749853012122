import { INode, INodeProps } from '@/types/node';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import React from 'react';
import {
  blockRenderMap, blockStyleFn, decorators,
} from '@/rich-text/utilities';
import { IContent } from './contract';
import { makeStyles, Theme } from '@material-ui/core';
import { useNodeProps } from '@/node-editor/hooks';
import { getSpacerValue } from '@/js/shared/utils/format';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IStyleProps {
    override: Record<string, any>;
    verticalSpacing: IContent['verticalSpacing'];
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  root: ({ override, verticalSpacing }) => ({
    width: '100%',
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    '& .editor-alignment-left': { textAlign: 'left' },
    '& .editor-alignment-center': { textAlign: 'center' },
    '& .editor-alignment-right': { textAlign: 'right' },
    ...override.root,
  }),
}));

export const Render = ({
  node: {
    id,
    override,
    content: { richContent, verticalSpacing },
  },
}: IProps) => {
  const classes = useStyles({ override, verticalSpacing });

  const editorState =
    richContent.blocks.length > 0
      ? EditorState.createWithContent(convertFromRaw(richContent), decorators)
      : EditorState.createEmpty(decorators);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      <Editor
        onChange={() => null}
        blockRenderMap={blockRenderMap}
        blockStyleFn={blockStyleFn}
        editorState={editorState}
        readOnly
      />
    </div>
  );
};