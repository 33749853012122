import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Poll',
  icon: 'poll',
  type: 'poll',
  tag: 'forum-inputs',
  content: {
    answered: 'Thanks! You have answered',
    pollIds: [],
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
