import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import Webcam from 'react-webcam';
import { b64toBlob } from '@/js/shared/utils/b64toBlob';
import { withS3Bucket } from '@/js/shared/components/file-upload/services/s3bucket';

interface UploadedFile {
  generated: string;
  original: string;
  result?: number;
}

export interface IProps {
  upload: (file: File, callback: (fileInfo: UploadedFile) => unknown) => void;
  onChange: (fileInfo: UploadedFile) => unknown;
  label?: string;
  mirrored?: boolean;
  maxWidth: any;
}

export interface IStyleProps {
  maxWidth?: any;
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  container: ({ maxWidth }) => ({
    display: 'inline-block',
    maxWidth: maxWidth,
    textAlign: 'center',
  }),
  webcam: {
    borderRadius: 5,
    overflow: 'hidden',
    '& video': {
      display: 'block',
    },
    position: 'relative',
    backgroundColor: '#333',
  },
  buttonHolder: {
    position: 'relative',
    display: 'inline-block',
    margin: '-6px auto 0 auto',
    backgroundColor: theme.palette.background.default,
    borderRadius: 5,
    padding: '0 8px',
  },
  waiting: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchCam: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: '#000000AA',
    borderRadius: 20,
    padding: 2,
    '& span': {
      fontSize: '1.2rem',
      color: '#ffffff',
    },
    '&:hover': {
      backgroundColor: '#000000',
    },
  },
}));

export const WebcamUploader = withS3Bucket()(
  ({
    upload,
    onChange,
    label = 'Snap!',
    mirrored = false,
    maxWidth = 300,
  }: IProps) => {
    const classes = useStyles({ maxWidth });
    const [ready, setReady] = useState(false);
    const [uploading, setUploading] = useState(false);
    const webcamRef = React.useRef(null);
    const [deviceId, setDeviceId] = useState('');
    const [devices, setDevices] = useState([]);

    const handleDevices = useCallback(
      (mediaDevices) =>
        setDevices(
          mediaDevices.filter(
            ({ kind }: { kind: string }) => kind === 'videoinput'
          )
        ),
      [setDevices]
    );

    const rotateCamera = () => {
      console.log('rotate', deviceId, devices);

      const currentCamIndex = devices.findIndex(
        (cam) => cam.deviceId === deviceId
      );
      setDeviceId(devices[(currentCamIndex + 1) % devices.length].deviceId);
    };

    useEffect(() => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }, [handleDevices]);

    useEffect(() => {
      if (devices.length > 1) {
        setDeviceId(devices[0].deviceId);
      }
    }, [devices.length]);

    const capture = React.useCallback(async () => {
      setUploading(true);
      const imageSrc = webcamRef.current.getScreenshot({
        width: videoConstraints.width,
        height: videoConstraints.height,
      });
      const fileInfo = await upload(
        new File(
          [
            b64toBlob(
              imageSrc.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''),
              'image/jpeg'
            ),
          ],
          `webcam.jpg`
        ),
        (fileInfo: UploadedFile) => {
          onChange(fileInfo);
          //   setTransfered((processed: any[]) => [...processed, process]);
          setUploading(false);
        }
      );
    }, [webcamRef]);
    const mediaError = () => {
      console.log('Could not connect to camera');
    };
    const cameraReady = () => {
      setReady(true);
    };
    // /visitor/conferences/:id/signmediaupload
    return (
      <div className={classes.container}>
        <div className={classes.webcam}>
          <Webcam
            audio={false}
            height={'auto'}
            screenshotFormat="image/jpeg"
            screenshotQuality={0.7}
            width={'100%'}
            ref={webcamRef}
            mirrored={mirrored}
            videoConstraints={{
              ...videoConstraints,
              ...(deviceId ? { deviceId } : {}),
            }}
            onUserMedia={cameraReady}
            onUserMediaError={mediaError}
          />
          {(!ready || uploading) && (
            <div className={classes.waiting}>
              <CircularProgress size={20} />
            </div>
          )}
          {devices.length > 1 && (
            <div className={classes.switchCam}>
              <Tooltip title="Switch camera" aria-label="Switch camera">
                <IconButton size="small" onClick={rotateCamera}>
                  <Icon>flip_camera_ios</Icon>
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={classes.buttonHolder}>
          <Button
            disabled={uploading}
            startIcon={<Icon>camera_alt</Icon>}
            onClick={capture}
            color="primary"
            size="small"
          >
            {label}
          </Button>
        </div>
      </div>
    );
  }
);
// WebcamUploader.displayName = 'WebcamUploader';
