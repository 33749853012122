import { Box, ButtonBase, Icon, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { infoList } from '../../../shared/utils/DataFormat';
import UserAvatar from '../ui/UserAvatar';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';

const holderSmall = {
  flex: 'auto',
  flexDirection: 'row-reverse',
  padding: 0,
  textAlign: 'right',
  alignSelf: 'end',
};
const avatarSmall = {
  paddingRight: 8,
  paddingLeft: 8,
  '&>*': {
    width: 30,
    height: 30,
  },
};

const itemStyles = makeStyles((theme) => ({
  userHolder: ({ alwaysSmall }) => ({
    flex: '120px 0 0',
    overflow: 'hidden',
    padding: '0 8px 8px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: holderSmall,
    ...(alwaysSmall ? holderSmall : null),
    '&:hover': {
      backgroundColor: theme.palette.background.shadedown_20,
    },
  }),
  avatar: ({ alwaysSmall }) => ({
    paddingBottom: 5,
    [theme.breakpoints.down('sm')]: avatarSmall,
    ...(alwaysSmall ? avatarSmall : null),
  }),
  userName: {
    //textDecoration: 'underline',
  },
  infoList: {
    opacity: 0.6,
  },
}));

export const DiscussionVisitor = (props) => {
  const classes = itemStyles({ alwaysSmall: props.alwaysSmall });
  const { openUserPopup } = useContext(ConferenceContext);

  return props.anonymous ? (
    <Box align="center" className={classes.userHolder}>
      <Icon fontSize="large">help</Icon>
    </Box>
  ) : props.visitor ? (
    <ButtonBase
      onClick={() => openUserPopup(props.visitor)}
      className={classes.userHolder}
    >
      <Box className={classes.avatar}>
        <UserAvatar user={props.visitor} />
      </Box>
      <Box>
        <Box className={classes.userName}>{props.visitor.full_name}</Box>
        <Box className={classes.infoList}>
          {infoList([props.visitor.company, props.visitor.location_city])}
        </Box>
      </Box>
    </ButtonBase>
  ) : (
    <Box align="center" className={classes.userHolder}>
      Participant no longer exist
    </Box>
  );
};

DiscussionVisitor.displayName = 'DiscussionVisitor';
