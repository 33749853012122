import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
    version: 1,
    name: 'Grid',
    icon: 'grid_view',
    type: 'grid',
    tag: 'layout',
    content: {
        columns: 2,
        colSpacing: 2,
        verticalSpacing: {
            top: '0',
            bottom: 'md',
        },
    },
    override: {
        root: {}
    },
    supports: {
        contexts: ['page'],
        children: [],
        appender: false,
    }
}

export {
    template,
    Render,
    Edit,
};

