import {
  Box,
  Button,
  Icon,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { infoList } from '../../utils/DataFormat';
import RedButton from '../ui/RedButton';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles((theme) => ({
  chatHolder: {
    zIndex: 1400,
    position: 'fixed',
    bottom: 180,
    left: '50%',
  },
  dialogBg: {
    border: theme.palette.background.default,
    backgroundColor: theme.palette.background.shadedown_20,
    position: 'relative',
    left: -155,
    width: 310,
    borderRadius: 5,
  },
  closeButton: {},
  answerButtons: {
    width: '100%',
    '& > *': {
      margin: 5,
    },
  },
  answerAccept: {
    flexGrow: 1,
  },
}));

const VideoChatCall = (props) => {
  const classes = useStyles();

  const title =
    props.data.direction === 'outgoing'
      ? 'Outgoing'
      : props.data.participants.length > 1
      ? 'Group'
      : 'Incoming';

  return (
    <Box className={classes.chatHolder}>
      <Box className={classes.dialogBg} p={1}>
        <Box align="center" pb={1}>
          <Typography variant="h6">{title} call</Typography>
        </Box>

        {props.data.participants.map((p, index) => (
          <Box display="flex" alignItems="center" key={index}>
            <Box p={1}>
              <UserAvatar user={p} />
            </Box>
            <Box>
              {p.full_name}
              <br />
              <em>{infoList([p.company, p.location_city])}</em>
            </Box>
          </Box>
        ))}
        {props.data.direction === 'outgoing' ? (
          <Box
            display="flex"
            className={classes.answerButtons}
            justifyContent="center"
          >
            <RedButton
              className={classes.answerAccept}
              onClick={() => props.cancelHandler()}
              variant="contained"
              size="small"
              startIcon={<Icon>close</Icon>}
            >
              Cancel
            </RedButton>
          </Box>
        ) : (
          <Box display="flex" className={classes.answerButtons}>
            <Button
              className={classes.answerAccept}
              onClick={() => props.answerHandler(true)}
              variant="contained"
              size="small"
              startIcon={<Icon>videocam</Icon>}
              color="primary"
            >
              {props.data.addToCurrent ? 'Add to group' : 'Talk'}
            </Button>
            <RedButton
              onClick={() => props.answerHandler(false)}
              variant="contained"
              size="small"
              startIcon={<Icon>close</Icon>}
            >
              Later
            </RedButton>
          </Box>
        )}

        <LinearProgress />
      </Box>
    </Box>
  );
};

export default VideoChatCall;
