import { WebcamUploader } from '@/components/webcam-uploader';
import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import DropZone from '@/js/shared/components/ui/DropZone';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import C from '../../../shared/constants/general';
import { Discussion } from '@/js/shared/components/discussion/Discussion';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { uploadText, webcamText, message, received, discussionId },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [discussion, setDiscussion] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [viewDiscussion, setViewDiscussion] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [image, setImage] = useState<string>('');

  const uploadHandler = ({ generated }: { generated: string }) => {
    setUploading(false);
    setShowWebcam(false);

    if (message) {
      setImage(generated);
    } else {
      // If not adding a message, send image
      post('', generated);
    }
  };

  const post = (postText = '', postImage = '') => {
    let postData = ``;
    setSuccess(false);
    if (postImage) {
      postData += `![](${postImage})`;
    }
    if (postImage && postText) {
      postData += `\n`;
    }
    if (postText) {
      postData += postText;
    }

    setSending(true);
    axios
      .post(`/api/visitor/discussions/${discussionId}/messages`, {
        text: postData,
        skipNotifications: true,
      })
      .then(() => {
        setText('');
        setError('');
        setImage('');
        setSuccess(true);
      })
      .catch((e) => {
        setError(e.response.data.message);
      })
      .then(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (discussionId) {
      axios
        .get(`/api/visitor/discussions/${discussionId}`)
        .then((response) => {
          setDiscussion(response.data);
        })
        .catch((e) => {
          setError(e.response.data.message);
        });
    }
  }, []);
  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <>
          <Box textAlign="center">
            {!discussionId && (
              <Typography color="error">Please select post</Typography>
            )}
            {discussion && discussion.title && (
              <Typography variant="h6">{discussion.title}</Typography>
            )}
            {discussion && discussion.text && (
              <MarkdownRender text={discussion.text} />
            )}
          </Box>
          <Box textAlign="center" p={1}>
            {image && (
              <Box p={1}>
                <img src={getImage(image, ImageSize.Icon, conferenceId)} />
              </Box>
            )}
            {uploading && (
              <Box p={1}>
                <CircularProgress size={14} />
              </Box>
            )}
            {showWebcam && (
              <Box>
                <WebcamUploader
                  signUrl={`/api/visitor/conferences/${conferenceId}/signmediaupload`}
                  onChange={(fileInfo: any) => {
                    uploadHandler(fileInfo);
                  }}
                />
              </Box>
            )}
            {showWebcam && (
              <Button
                color="secondary"
                startIcon={<Icon>close</Icon>}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            )}
            {!showWebcam && uploadText && (
              <DropZone
                acceptedfiletypes="image/jpeg, image/png, image/webp"
                uploadStart={() => setUploading(true)}
                uploadHandler={uploadHandler}
                buttonText={uploadText}
                uploadurl={`/api/visitor/conferences/${conferenceId}/signmediaupload`}
              />
            )}
            {!showWebcam && webcamText && (
              <Button
                startIcon={<Icon>camera_alt</Icon>}
                size="small"
                onClick={() => setShowWebcam(!showWebcam)}
              >
                {webcamText}
              </Button>
            )}
          </Box>

          {(success || error) && (
            <Box p={1} textAlign="center">
              {success && received}
              {error && <Typography color="error">{error}</Typography>}
            </Box>
          )}
          {message && (
            <>
              <TextField
                label={message}
                name="text"
                value={text}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setText(e.target.value)}
              />
              <Box display="flex">
                <Box flexGrow={1} p={1}></Box>
                <Box pt={1}>
                  <UserAvatar user={authState.user} size="small" />
                </Box>
                <Button
                  disabled={sending || !image || !discussion}
                  endIcon={<Icon>send</Icon>}
                  color="primary"
                  onClick={() => post(text, image)}
                >
                  post
                </Button>
              </Box>
            </>
          )}
          {(authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR ||
            authState.user.participation_level ===
              C.VISITOR.PARTICIPATION_LEVEL.CONTRIBUTE) && (
            <Box>
              <Button
                color="secondary"
                startIcon={<Icon>forum</Icon>}
                onClick={() => setViewDiscussion(!viewDiscussion)}
              >
                {viewDiscussion ? 'hide list' : 'view list'}
              </Button>
              {viewDiscussion && (
                <Discussion
                  discussionId={discussionId}
                  isEmbedded={true}
                  alwaysSmall={true}
                />
              )}
            </Box>
          )}
        </>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
