export const DateUtils = {
  getNowDateTimeByTimezone(timezonestring: string = 'Europe/Stockholm') {
    return new Date().toLocaleString('sv-SE', { timeZone: timezonestring });
  },
  getDateTimeByDateAndTimezone(
    date: Date,
    timezonestring: string = 'Europe/Stockholm'
  ) {
    return new Date(date).toLocaleString('sv-SE', { timeZone: timezonestring });
  },
  getUserTimezoneReportedByBrowser() {
    const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return tzid;
  },
  getUserLocaleReportedByBrowser() {
    const localid = Intl.DateTimeFormat().resolvedOptions().locale;
    return localid;
  },
  //Only supported by new browser
  getOffsetByTimezone(timeZone: string, date: any = Date.now()) {
    try {
      const timeZoneName = Intl.DateTimeFormat('sv', {
        timeZoneName: 'shortOffset',
        timeZone,
      })
        .formatToParts(date)
        .find((i) => i.type === 'timeZoneName').value;

      const offset = timeZoneName.slice(3);
      if (!offset) return 0;

      const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
      if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;

      const [, sign, hour, minute] = matchData;
      let result = parseInt(hour) * 60;
      if (sign === '+') result *= -1;
      if (minute) result += parseInt(minute);
      return result;
    } catch (error) {
      console.log(error);
    }
  },

  //Note: Safari does not support shortOffset in getOffsetByTimezone, this is a bugg fix
  getOffsetByTimezone2(
    timeZone: string = 'Europe/Stockholm',
    date: any = Date.now()
  ) {
    // Try English to get offset. If get abbreviation, use French
    let offset;

    ['en', 'fr'].some((lang) => {
      // Get parts - can't get just timeZoneName, must get one other part at least
      let parts = new Intl.DateTimeFormat(lang, {
        minute: 'numeric',
        timeZone: timeZone,
        timeZoneName: 'short',
      }).formatToParts(date);

      // Get offset from parts
      let tzName = parts.filter(
        (part) => part.type == 'timeZoneName' && part.value
      );
      // timeZoneName starting with GMT or UTC is offset - keep and stop looping
      // Otherwise it's an abbreviation, keep looping
      if (/^(GMT|UTC)/.test(tzName[0].value)) {
        offset = tzName[0].value.replace(/GMT|UTC/, '') || '+0';
        return true;
      }
    });
    if (offset) {
      const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
      if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;
      const [full, sign, hour, minute] = matchData;
      let result = parseInt(hour) * 60;
      if (sign === '+') result *= -1;
      if (minute) result += parseInt(minute);
      return result;
    } else {
      console.error('error in getTimezoneOffset2');
    }
  },
};
