import { RadioGroupProps, RadioProps } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export const defaultClasses = {
  formLabel: {},
  radioGroup: {},
  formControlLabel: {},
};

export interface IOption {
  label: string | React.ReactNode;
  value: string | number;
  radioProps?: RadioProps;
}

export interface IProps
  extends Omit<RadioGroupProps, 'onChange' | 'value' | 'children' | 'classes'> {
  label?: string;
  options: IOption[];
  withTooltip?: boolean;
  classes?: Record<
    'formLabel' | 'radioGroup' | 'formControlLabel',
    Partial<ClassNameMap<string>>
  >;
  value: string;
  onChange: (value: string) => void;
}
