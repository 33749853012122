import { useState, useEffect, useCallback, useRef } from 'react';

type PostData = {
  type: string;
  payload?: unknown;
};

export const postMessage = <T extends PostData>(
  data: T,
  target: Window,
  origin = '*'
) => target.postMessage(data, origin);

export const useMessage = <T extends PostData>(
  watch?: string,
  callback?: (returnMessage: (data: T) => void, data: T, origin: string) => void
) => {
  const [origin, setOrigin] = useState();

  const [source, setSource] = useState();

  const originRef = useRef<string>();

  const sourceRef = useRef<Window>();

  originRef.current = origin;

  sourceRef.current = source;

  const returnMessage = <T extends PostData>(data: T) =>
    postMessage(data, sourceRef.current, originRef.current);

  const sendMessage = <T extends PostData>(
    target: Window,
    data: T,
    origin?: string
  ) => postMessage<T>(data, target, origin);

  const handleEvent = useCallback(
    ({ origin, source, data }) => {
      if (data.type === watch) {
        setOrigin(origin);
        setSource(source);
        if (typeof callback === 'function') {
          //   console.log('handleEvent', watch, data, callback);
          callback(returnMessage, data, origin);
        }
      }
    },
    [watch, callback, setOrigin, setSource]
  );

  useEffect(() => {
    window.addEventListener('message', handleEvent);

    return () => window.removeEventListener('message', handleEvent);
  }, [watch, source, origin, handleEvent]);

  return { sendMessage };
};
