import { INodeTemplate } from '../../types/node';
import { WidgetSettings } from '@/widget-editor/components';
import { Settings } from './edit';
import { Render as WidgetRender } from './render';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';

const template: INodeTemplate = {
  version: 1,
  icon: 'forum',
  name: 'Room Discussion',
  type: 'widget-discussion',
  tag: 'forum',
  content: {
    widgetName: 'Room forum',
    forumHeadline: 'Forum',
    topicSlug: '',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(WidgetRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Edit, Render };
