import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useMemo, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import C from '../../../../../shared/constants/general';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';
import { AuthContext } from '../../context/AuthContext';
import { markdownMini, addExtraInfoToEvent } from '../../utils/DataFormat';
import { timeStringToNum } from '../../utils/Sorters';
import UserAvatar from '../ui/UserAvatar';

const hourStyles = makeStyles((theme) => ({
  list: {
    width: (props) => props.width,
    // backgroundColor: theme.palette.background.default,
    fontSize: 13,
    position: 'absolute',
    left: 0,
    top: (props) => props.roomTitleHeight - 8,
  },
  item: {
    height: (props) => props.height,
  },
  full: {
    height: (props) => props.height / 2,
    fontWeight: 600,
  },
  half: {
    height: (props) => props.height / 2,
    opacity: 0.5,
  },
}));

const range = (start, end) =>
  Array.from({ length: end - start }, (v, k) => k + start);

const HourGrid = React.memo(
  (props) => {
    const classes = hourStyles({
      gridcolor: props.gridcolor,
      width: props.width,
      height: props.height,
      roomTitleHeight: props.roomTitleHeight,
    });
    const hourRange = range(props.start, props.end);
    return (
      <Box className={classes.list}>
        {hourRange.map((h) => (
          <Box key={h} className={classes.item}>
            <Box className={classes.full}>
              {h.toString().padStart(2, '0')}:00
            </Box>
            <Box className={classes.half}>
              {h.toString().padStart(2, '0')}:30
            </Box>
          </Box>
        ))}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.start === nextProps.start &&
    prevProps.end === nextProps.end &&
    prevProps.updateStepper === nextProps.updateStepper
);

const gridBGStyles = makeStyles((theme) => ({
  gridBg: {},
  room: {
    width: (props) => props.roomWidth,
    minWidth: 200,
    textAlign: 'center',
    height: (props) => props.roomTitleHeight,
    // display: 'inline-block',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 0.9,
  },
  eventList: {
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 1,
      backgroundColor: (props) => props.bgColor,
      height: (props) => props.listHeight,
      opacity: 0.5,
    },
    '&:last-child::after': {
      display: 'none',
    },
    position: 'relative',
    width: (props) => props.roomWidth,
    minWidth: 200,
    height: (props) => props.listHeight,
    display: 'inline-block',
    background: (props) =>
      `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" height="${
        props.height / 2
      }" width="100px"><rect width="100px" height="1px" fill="${
        props.bgColorEscaped
      }" fill-opacity="0.3" /></svg>')`,
  },
  roomLink: {
    color: theme.palette.primary.main,
  },
  eventItem: {
    fontSize: 13,
    color: '#ffffff',
    backgroundColor: '#333333BB',
    borderRadius: 3,
    fontWeight: 'bold',
    position: 'absolute',
    left: 4,
    right: 5,
    padding: 5,
    minHeight: 20,
    overflow: 'hidden',
    '&:hover': {
      textDecoration: 'none',
      zIndex: 800,
      backgroundColor: '#333333',
    },
  },
  eventPause: {
    opacity: 0.5,
  },
  eventTimeInfo: {
    fontWeight: 'normal',
    fontSize: 12,
    opacity: 0.7,
    whiteSpace: 'pre-wrap',
  },
  eventTitleLive: {
    paddingRight: 5,
    lineHeight: '14px',
    fontSize: 14,
    color: '#f44336',
  },
  eventVideo: {
    color: theme.palette.primary.main,
  },
  eventVideoIcon: {
    width: 12,
    height: 14,
    fontSize: 15,
    paddingTop: 2,
  },
}));

const EventGrid = React.memo(
  (props) => {
    const {
      conferenceState: { conferenceDetail },
    } = useContext(ConferenceContext);
    const allRooms = props.totalFavorites.length
      ? [
          {
            id: 'xxx',
            title: 'Favorites',
            events: props.totalFavorites,
            video_state: C.PROGRAM_ROOM.VIDEO_STATE.NONE,
            programroomvideoassets: [],
          },
          ...props.programDay.programRooms,
        ]
      : props.programDay.programRooms;

    const classes = gridBGStyles({
      roomWidth: `${100 / allRooms.length}%`,
      height: props.height,
      listHeight: (props.end - props.start) * props.height,
      bgColorEscaped: escape(props.gridcolor),
      bgColor: props.gridcolor,
      roomTitleHeight: props.roomTitleHeight,
    });

    const handleEventClick = (e, ev) => {
      e.preventDefault();
      props.setViewEvent(ev);
    };

    const calcTop = (timeStart, timeEnd, overlapsPreviousEvent) => {
      const calcTime = timeStringToNum(timeStart) / 3600 - props.start;
      const calcHeight =
        timeEnd === null
          ? 1
          : timeStringToNum(timeEnd) / 3600 - calcTime - props.start;
      return {
        top: calcTime * props.height,
        height: calcHeight * props.height - 2,
        left: overlapsPreviousEvent ? 15 : 0,
      };
    };

    return (
      <Box className={classes.gridBg}>
        <Box display="flex">
          {allRooms.map((room) => (
            <Box key={room.id} className={classes.room}>
              {room.id === 'xxx' && (
                <span>
                  <Icon>star</Icon>
                </span>
              )}
              {window.SETTINGS.HIDE_PROGRAM_ROOMS_IN_MENU ||
              room.id === 'xxx' ? (
                <strong>{room.title}</strong>
              ) : (
                <Link
                  className={classes.roomLink}
                  href={`/live/room/${room.id}`}
                >
                  <strong>{room.title}</strong>
                </Link>
              )}
              {/* {room.id === 'xxx' && (
                <Button
                  size="small"
                  color="primary"
                  endIcon={<Icon>event</Icon>}
                  target="_blank"
                  href={`/api/visitor/conferences/${conferenceDetail.id}/downloadfavorites`}
                >
                  Download
                </Button>
              )} */}
            </Box>
          ))}
        </Box>
        <Box display="flex">
          {allRooms.map((room) => (
            <Box key={room.id} className={classes.eventList}>
              {room.events.map((e, index) => {
                const roomData = e && (e.roomData || room);

                const canViewEvent =
                  e.type !== C.EVENT.TYPE.ACTIVITY &&
                  roomData.video_state === C.PROGRAM_ROOM.VIDEO_STATE.FILE &&
                  roomData.programroomvideoassets &&
                  roomData.programroomvideoassets.length > 0;

                const endTime =
                  e.end_time ||
                  (index + 1 < room.events.length &&
                    room.events[index + 1].start_time) ||
                  null;
                // Find if there's an overlap from previous event
                const prevEvent = index > 0 ? room.events[index - 1] : null;
                const prevEndTime = prevEvent?.end_time || null;
                const prevOverlap =
                  prevEndTime &&
                  timeStringToNum(prevEndTime) > timeStringToNum(e.start_time);

                // const prevEvent = index > 0 ? room.events[index - 1] : null;
                // const prevEndTime =
                //   prevEvent && prevEvent.end_time ? prevEvent.end_time : null;
                // const prevOverlap =
                //   prevEndTime &&
                //   timeStringToNum(prevEndTime) > timeStringToNum(e.start_time);
                // const overlap = prevOverlap ? 'overlap' : '';

                const styleCalc = calcTop(e.start_time, endTime, prevOverlap);
                const timeStr = `${e.start_time.substring(0, 5)}-${
                  endTime ? endTime.substring(0, 5) : ''
                }`;

                return e.type !== C.EVENT.TYPE.ACTIVITY ? (
                  <Link
                    href="#"
                    onClick={(event) =>
                      handleEventClick(event, {
                        ...e,
                        programRoom: {
                          id: roomData.id,
                          title: roomData.title,
                          programroomvideoassets:
                            roomData.programroomvideoassets,
                        },
                        timeStr,
                      })
                    }
                    key={e.id}
                    className={classes.eventItem}
                    style={styleCalc}
                  >
                    {roomData.currentEventId === e.id && (
                      <span
                        className={classes.eventTitleLive}
                        component="span"
                        color="error"
                        variant="subtitle1"
                      >
                        LIVE
                      </span>
                    )}
                    {e.title.length > 100
                      ? `${e.title.substring(0, 100)}..`
                      : e.title}
                    <br />
                    <span className={classes.eventTimeInfo}>{timeStr}</span>
                    {canViewEvent && (
                      <span className={classes.eventVideo}>
                        <Icon className={classes.eventVideoIcon}>
                          play_arrow
                        </Icon>{' '}
                        video
                      </span>
                    )}
                    <div
                      className={classes.eventTimeInfo}
                      dangerouslySetInnerHTML={markdownMini(
                        e.description.length > 200
                          ? `${e.description.substring(0, 200)}..`
                          : e.description
                      )}
                    />
                  </Link>
                ) : (
                  <Box
                    key={e.id}
                    className={clsx(classes.eventItem, classes.eventTimeInfo)}
                    style={styleCalc}
                  >
                    {e.title.length > 100
                      ? `${e.title.substring(0, 100)}..`
                      : e.title}
                    <div
                      dangerouslySetInnerHTML={markdownMini(
                        e.description.length > 200
                          ? `${e.description.substring(0, 200)}..`
                          : e.description
                      )}
                    />
                  </Box>
                );
              })}
            </Box>
          ))}
        </Box>
        {props.children}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.programDayId === nextProps.programDayId &&
    prevProps.totalFavorites.length === nextProps.totalFavorites.length &&
    prevProps.updateStepper === nextProps.updateStepper
);
EventGrid.displayName = 'EventGrid';

const MIN_EVENT_LENGTH = 3600; // 1 hour
const getFirstAndLastEventTime = (programDay) => {
  let firstStartTime = null;
  let lastEndTime = null;
  programDay.programRooms.forEach((room) => {
    room.events.forEach((ev) => {
      const calcStartTime = timeStringToNum(ev.start_time);
      const calcEndTime = ev.end_time
        ? timeStringToNum(ev.end_time)
        : calcStartTime + MIN_EVENT_LENGTH;
      if (firstStartTime === null || calcStartTime < firstStartTime) {
        firstStartTime = calcStartTime;
      }
      if (lastEndTime === null || calcEndTime > lastEndTime) {
        lastEndTime = calcEndTime;
      }
    });
  });
  return {
    firstHour: firstStartTime === null ? 8 : Math.floor(firstStartTime / 3600),
    lastHour: lastEndTime === null ? 16 : Math.ceil(lastEndTime / 3600),
  };
};

const useStyles = makeStyles((theme) => ({
  holder: {
    position: 'relative',
  },
  calendar: {
    paddingLeft: (props) => props.hourWidth,
    position: 'relative',
    overflowX: 'auto',
  },
  content: {
    width: 1400,
    // height: 700,
  },
  dialogBg: {
    minWidth: 300,
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
    backgroundColor: theme.palette.background.shadedown_20,
  },
  roomLink: {
    // color: theme.palette.primary.main,
    color: 'inherit',
  },
  eventDescription: {
    whiteSpace: 'pre-wrap',
  },
  videoFile: {
    // Keep
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    '&::after': {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      display: 'block',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&:hover': {
      '&::after': {
        display: 'none',
      },
    },
    '& > *': {
      zIndex: 2,
      display: 'relative',
    },
  },
  videoFileHolder: {
    // Keep
    cursor: 'pointer',
    marginTop: 5,
    color: '#fff',
    backgroundColor: '#000',
    width: '100%',
    height: 0,
    position: 'relative',
    backgroundPosition: 'size',
    backgroundSize: 'cover',
    borderRadius: 2,
    paddingBottom: '56.25%',
  },
  forumLink: {
    paddingTop: 8,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    opacity: 0.4,
    '&:hover': {
      opacity: 1,
    },
  },
  favoriteButton: {},
}));

export const ProgramViewGrid = React.memo(
  (props) => {
    const history = useHistory();
    const HOUR_WIDTH = 50;
    const HOUR_HEIGHT = 160;
    const ROOM_HEIGHT = 400;
    const ROOM_TITLE_HEIGHT = 40;
    const GRID_COLOR = '#999999';
    const classes = useStyles({
      hourWidth: HOUR_WIDTH,
      roomHeight: ROOM_HEIGHT,
      roomTitleHeight: ROOM_TITLE_HEIGHT,
    });
    const { authState } = useContext(AuthContext);
    const [viewEvent, setViewEvent] = useState(null);
    const timeRange = useMemo(
      () => getFirstAndLastEventTime(props.programDay),
      [props.programDay]
    );

    const { openUserPopup, toggleFavorite, conferenceState } = useContext(
      ConferenceContext
    ); // Hämta Context

    const roomData = viewEvent && (viewEvent.roomData || viewEvent.programRoom);

    let canViewEvent = false;
    canViewEvent =
      viewEvent !== null &&
      viewEvent.type !== C.EVENT.TYPE.ACTIVITY &&
      roomData.programroomvideoassets !== null &&
      roomData.programroomvideoassets.length > 0;

    const handleModalClose = () => {
      setViewEvent(null);
    };
    const gotoEvent = (eventId) => {
      //history.push(`/live/session/${eventId}`);
      location.replace(`/live/session/${eventId}`); //TODO: Ugly need to fix states in EventDashBoard.
    };
    if (viewEvent) {
      //Add a props to event in order to calculate offest and startasset etc
      let extra_props = {
        programDay: {
          date: props.programDay.date,
          conference: { timezone: conferenceState.conferenceDetail.timezone },
        },
      };

      viewEvent['programDay'] = extra_props.programDay;
      //change by reference
      addExtraInfoToEvent(viewEvent);
    }
    const triggerToggleFavorite = () => {
      toggleFavorite(viewEvent.id, viewEvent.favorites.length === 0).then(
        () => {
          setViewEvent((curr) => ({
            ...curr,
            favorites: viewEvent.favorites.length
              ? []
              : [{ id: authState.user.id }],
          }));
        }
      );
    };

    return (
      <Box className={classes.holder}>
        <Dialog
          open={viewEvent !== null}
          onClose={handleModalClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{ className: classes.dialogBg }}
        >
          {viewEvent && (
            <>
              <DialogTitle id="scroll-dialog-title">
                <Box display="flex" alignItems="flex-start">
                  <Box flexGrow={1}>{viewEvent.title}</Box>
                  {viewEvent.favorites && (
                    <Box>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={triggerToggleFavorite}
                        className={classes.favoriteButton}
                      >
                        <Icon>
                          {viewEvent.favorites.length > 0
                            ? 'star'
                            : 'star_border'}
                        </Icon>
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </DialogTitle>
              <DialogContent dividers={true}>
                {canViewEvent && (
                  <Box
                    className={classes.videoFileHolder}
                    onClick={() => gotoEvent(viewEvent.id)}
                    style={{
                      backgroundImage: `url(/api/redirect_mux_image/${viewEvent.start_asset.mux_asset_playback_id}/thumb/${viewEvent.start_asset.start_offest_seconds})`,
                    }}

                    /*style={{
                      backgroundImage: `url(https://image.mux.com/${viewEvent.EventAssets[0].mux_asset_playback_id}/thumbnail.jpg?token=${viewEvent.EventAssets[0].thumb_jwt})`,
                    }}
                    */
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className={classes.videoFile}
                    >
                      <Icon>play_arrow</Icon>
                      <Box>
                        <strong>Replay</strong>
                      </Box>
                    </Box>
                  </Box>
                )}

                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                  <span>
                    {viewEvent.timeStr}{' '}
                    {window.SETTINGS.HIDE_PROGRAM_ROOMS_IN_MENU ? (
                      <strong>{roomData.title}</strong>
                    ) : (
                      <RouterLink
                        className={classes.roomLink}
                        to={`/live/room/${roomData.id}`}
                      >
                        <strong>{roomData.title}</strong>
                      </RouterLink>
                    )}
                  </span>
                  <br />
                  <Box
                    className={classes.eventDescription}
                    dangerouslySetInnerHTML={markdownMini(
                      viewEvent.description
                    )}
                  />

                  {!window.SETTINGS.NEW_DISCUSSION_MODERATE && !canViewEvent && (
                    <>
                      <br />
                      <Link
                        href={`/live/session/${viewEvent.id}`}
                        className={classes.forumLink}
                      >
                        Forum
                      </Link>
                    </>
                  )}
                </DialogContentText>
                {viewEvent.visitors.length > 0 && (
                  <Box display="flex" py={1}>
                    {viewEvent.visitors.map((v) => (
                      <Box key={v.id} pr={1}>
                        <ButtonBase
                          onClick={() => openUserPopup(v)}
                          disabled={!authState.userLoggedIn}
                        >
                          <UserAvatar size="medium" user={v} />
                        </ButtonBase>
                      </Box>
                    ))}
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
        <Box className={classes.calendar}>
          <EventGrid
            start={timeRange.firstHour}
            end={timeRange.lastHour}
            setViewEvent={setViewEvent}
            gridcolor={GRID_COLOR}
            roomTitleHeight={ROOM_TITLE_HEIGHT}
            height={HOUR_HEIGHT}
            programDay={props.programDay}
            programDayId={props.programDayId}
            totalFavorites={props.totalFavorites}
            updateStepper={props.updateStepper}
          />
        </Box>
        <HourGrid
          start={timeRange.firstHour}
          end={timeRange.lastHour}
          roomTitleHeight={ROOM_TITLE_HEIGHT}
          gridcolor={GRID_COLOR}
          height={HOUR_HEIGHT}
          width={HOUR_WIDTH}
          updateStepper={props.updateStepper}
        />
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.programDayId === nextProps.programDayId &&
    prevProps.totalFavorites.length === nextProps.totalFavorites.length &&
    prevProps.updateStepper === nextProps.updateStepper
);
