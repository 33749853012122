import {
  EventEmitterContext,
  useEventEmitterInstance,
} from '@byteclaw/use-event-emitter';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { AnnouncementProvider } from '../shared/components/ui/AnnouncementProvider';
import AuthContext from '../shared/context/AuthContext';
import ConferenceContext from '../shared/context/ConferenceContext';
import DiscussionContext from '../shared/context/DiscussionContext';
import { MenuContextProvider } from '../shared/context/MenuContext';
import PusherContext from '../shared/context/PusherContext';
import ThreadContext from '../shared/context/ThreadContext';
import UserContext from '../shared/context/UserContext';
import VideoChatContext from '../shared/context/VideoChatContext';
import themeCoeo from '../themes/coeo_live';
import SiteRoutes from './SiteRoutes';
import { Coeolytics } from '../shared/utils/coeolytics';
import CoeolyticsProvider from '../shared/context/CoeolyticsProvider';
import { ConferencePagesProvider } from '@/page-editor/context';

const App = () => {
  const eventEmitter = useEventEmitterInstance();
  const coeoLytics = Coeolytics();
  coeoLytics.page();
  return (
    <MuiThemeProvider theme={themeCoeo}>
      <AnnouncementProvider>
        <CoeolyticsProvider instance={coeoLytics}>
          <EventEmitterContext.Provider value={eventEmitter}>
            <AuthContext>
              <UserContext>
                <ThreadContext>
                  <DiscussionContext>
                    <PusherContext>
                      <VideoChatContext
                        videoChatEnabled={window.SETTINGS.USE_VIDEOCHAT}
                      >
                        <ConferenceContext
                          useSnackbar
                          conferencename={window.CONFERENCE_NAME}
                        >
                          <MenuContextProvider>
                            <CssBaseline />
                            <ConferencePagesProvider>
                                <SiteRoutes />
                            </ConferencePagesProvider>
                          </MenuContextProvider>
                        </ConferenceContext>
                      </VideoChatContext>
                    </PusherContext>
                  </DiscussionContext>
                </ThreadContext>
              </UserContext>
            </AuthContext>
          </EventEmitterContext.Provider>
        </CoeolyticsProvider>
      </AnnouncementProvider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));
