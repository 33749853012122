import { IEvent, IEventVisitor, TEventStepper } from '@/types/event';
import { IEventAsset } from '../types/event';
import C from '../../shared/constants/general';
import { IProgramRoom } from '@/types/room';

export interface IProvided {
  eventUrl: string;
  eventAssets: IEventAsset;
  eventRoomTitle: string;
  eventStatus: 'live' | 'passed' | 'upcoming';
  eventAccess: boolean;
  eventVisitors: IEventVisitor[];
}

const getEventAccess = (
  { EventAssets, programRoomId, type}: IEvent,
  stepper: TEventStepper, rooms: IProgramRoom[]
): IProvided['eventAccess'] => {
  const room = rooms.find((room) => room.id === programRoomId);
  let view:boolean = type !== C.EVENT.TYPE.ACTIVITY && stepper.finished.includes(programRoomId) &&
  room.programroomvideoassets.length > 0;
  return view;
};

const getEventAssets = (EventAssets: IEventAsset[] = []) =>
  EventAssets.length > 0 && EventAssets[0];

const getEventRoomTitle = (event: IEvent, rooms: IProgramRoom[]): string => {
  const room = rooms.find((room) => room.id === event.programRoomId);
  return room ? room.title : '';
};

const getEventStatus = (
  { id, programRoomId }: Partial<IEvent>,
  stepper: TEventStepper
): IProvided['eventStatus'] => {
  return stepper.current.includes(id)
    ? 'live'
    : stepper.finished.includes(programRoomId)
    ? 'passed'
    : 'upcoming';
};

const getEventUrl = (
  { id, programRoomId }: Partial<IEvent>,
  stepper: TEventStepper
) => {
  switch (getEventStatus({ id, programRoomId }, stepper)) {
    case 'live': {
      return `/live/room/${programRoomId}`;
    }
    case 'passed': {
      return `/live/session/${id}`;
    }
    default: {
      return `/live/room/${programRoomId}`;
    }
  }
};

const getEventVisitors = (visitors: IEventVisitor[]): IEventVisitor[] =>
  visitors.filter(
    ({ eventVisitors }: any) => eventVisitors !== C.EVENT_VISITOR_TYPE.AUDIENCE
  );

export const useEventGetters = (
  event: IEvent,
  rooms: IProgramRoom[],
  stepper: TEventStepper
): IProvided => {
  return {
    eventAccess: getEventAccess(event, stepper,rooms),
    eventAssets: getEventAssets(event.EventAssets || []),
    eventRoomTitle: getEventRoomTitle(event, rooms),
    eventStatus: getEventStatus(event, stepper),
    eventUrl: getEventUrl(event, stepper),
    eventVisitors: getEventVisitors(event.visitors),
  };
};
