import {
  EditNodeTemplate,
  IEditNodeTemplateOptions,
} from '@/components/edit-node-template';
import { SettingsPanel } from '@/components/settings-panel';
import { addNode, addTemplate, useActions } from '@/node-editor/store/actions';
import { getNodes, useSelectors } from '@/node-editor/store/selectors';
import { mergeSavedTemplateData } from '@/node-editor/utilities';
import {
  deleteNodeTemplateService,
  fetchNodeTemplatesService,
  IStoredNodeTemplate,
  updateNodeTemplateService,
} from '@/page-editor/utilities';
import { INode, INodeTemplate, TNodes } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { relative } from 'path';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FieldValues } from 'react-hook-form';
import { getImage } from '../../../../shared/utils/imageFormat';

interface IProps {
  templates: { [key: string]: INodeTemplate };
}

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.6,
    },
  },
  label: {
    marginTop: theme.spacing(2),
  },
  dialog: {
    width: '600px',
    height: '800px',
  },
  disabled: {
    cursor: 'initial',
    color: theme.palette.text.primary,
    opacity: '0.4 !important',
  },
  templateHolder: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  img: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 5,
    width: 80,
    height: 80,
    backgroundColor: '#666',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    borderRadius: 5,
    '&:hover': {
      // backgroundSize: 'cover',
      width: 140,
      height: 140,
      margin: -30,
      zIndex: 1,
    },
  },
  clickImg: {
    cursor: 'pointer',
  },
  stInfo: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    backgroundColor: '#00000055',
    color: '#fff',
    width: '100%',
    fontSize: '0.8rem',
    '& span': {
      fontSize: '1rem',
      marginRight: 5,
      verticalAlign: 'text-bottom',
    },
  },
  edit: {
    opacity: 0.4,
    fontSize: 13,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export const AppenderDialog = forwardRef<any, IProps>(({ templates }, ref) => {
  const [node, setNode] = useState<INode | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [savedTemplates, setSavedTemplates] = useState<IStoredNodeTemplate[]>(
    []
  );
  const [allowedNodes, setAllowedNodes] = useState<string[]>([]);
  const classes = useStyles();
  const templateRef = useRef<{
    open: (initOptions: IEditNodeTemplateOptions) => void;
  } | null>(null);

  const privateSavedTemplates: IStoredNodeTemplate[] = savedTemplates.filter(
    (st) => !st.public
  );
  const publicSavedTemplates: IStoredNodeTemplate[] = savedTemplates.filter(
    (st) => st.public
  );
  const selectors = useSelectors(() => ({
    getNodes,
  }));

  const actions = useActions(() => ({
    addNode,
    addTemplate,
  }));

  const nodes = selectors.getNodes();

  const loadTemplates = async () => {
    const response = await fetchNodeTemplatesService();
    setSavedTemplates(response.data);
  };
  useImperativeHandle(ref, () => ({
    open: (id: string) => {
      setIsOpen(true);
      setNode(nodes[id]);
      loadTemplates();
    },
    close: () => setIsOpen(false),
  }));

  const updateTemplate = async (values: FieldValues) => {
    await updateNodeTemplateService(values as IStoredNodeTemplate);
    loadTemplates();
  };
  const deleteTemplate = async (id: string) => {
    await deleteNodeTemplateService(id);
    loadTemplates();
  };
  const editTemplate = (data: IStoredNodeTemplate) => {
    templateRef.current.open({
      saveAsTemplate: updateTemplate,
      deleteTemplate: deleteTemplate,
      data,
    });
  };

  const handleSelection = (template: INodeTemplate, id: string): void => {
    setIsOpen(false);
    actions.addNode({ ...template, justAdded: true }, id);
  };

  const handleTemplate = (template: IStoredNodeTemplate): void => {
    setIsOpen(false);

    let order =
      node.type === 'root'
        ? Object.values(nodes).filter((n) => n.nodeId === node.id).length // Count all nodes at "root"
        : node.children.length; // All nodes at this level

    const merged = mergeSavedTemplateData(
      template.nodes?.nodes,
      node.id,
      order
    );
    actions.addTemplate((merged as unknown) as TNodes, node.id);
  };

  const tagMap = useCallback(() => {
    return Object.values(templates)
      .filter((n) => !n.disabled)
      .reduce((map, current) => {
        if (
          current.supports.appender &&
          (map.length === 0 || !map.includes(current.tag))
        ) {
          map.push(current.tag);
        }
        return map; // Draw in order of first appearance
        // return map.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
      }, [] as string[]);
  }, []);

  const enabled = useCallback(
    (type: string): boolean => {
      return (
        !allowedNodes ||
        allowedNodes.length === 0 ||
        allowedNodes.includes(type)
      );
    },
    [allowedNodes]
  );

  useEffect(() => {
    if (node != undefined) {
      setAllowedNodes(templates[node.type].supports.children);
    }

    return () => setAllowedNodes([]);
  }, [node]);

  const TemplateList = ({
    savedList = [],
  }: {
    savedList: IStoredNodeTemplate[];
  }) => {
    return (
      <Grid container spacing={1}>
        {savedList.map((st) => (
          <Grid
            key={st.id}
            item
            xs={12}
            sm={6}
            className={classes.templateHolder}
          >
            <ButtonBase
              className={clsx(classes.img, {
                [classes.clickImg]: enabled(st.type),
              })}
              disabled={!enabled(st.type)}
              onClick={() => handleTemplate(st)}
              style={{
                backgroundImage: st.picture
                  ? `url(${getImage(st.picture, 'small', null, 'managers')})`
                  : '',
              }}
            >
              <div className={classes.addButton}>
                <Icon>{templates[st.type] ? templates[st.type].icon : ''}</Icon>
                ADD
              </div>
            </ButtonBase>
            <div className={classes.stInfo}>
              <Typography color="textPrimary">{st.title}</Typography>
              {st.description && (
                <Typography color="textSecondary">{st.description}</Typography>
              )}
            </div>
            <div>
              <IconButton
                onClick={() => editTemplate(st)}
                size="small"
                classes={{ root: classes.edit }}
              >
                <Icon>edit</Icon>
              </IconButton>
            </div>
          </Grid>
        ))}
      </Grid>
    );
  };

  const NodeByTag = (tag: string) => (
    <ImageList rowHeight="auto" gap={4} cols={4}>
      {Object.values(templates)
        .filter(
          (template) => template.tag === tag && template.supports.appender
        )
        .map((template) => (
          <ImageListItem
            key={template.type}
            title={template.name}
            onClick={() =>
              enabled(template.type) && handleSelection(template, node.id)
            }
          >
            <div
              className={clsx(classes.item, {
                [classes.disabled]: !enabled(template.type),
              })}
            >
              <Icon color="inherit" fontSize="large">
                {template.icon}
              </Icon>
              <Typography color="textPrimary">{template.name}</Typography>
            </div>
          </ImageListItem>
        ))}
    </ImageList>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      classes={{ paper: classes.dialog }}
      title="Appender dialog"
    >
      <DialogTitle>Add</DialogTitle>
      <DialogContent>
        {tagMap().map((tag, index) => (
          <SettingsPanel
            key={`${tag}-${index}`}
            label={tag.substring(0, 1).toUpperCase() + tag.substring(1)}
            initialOpen
          >
            {NodeByTag(tag)}
          </SettingsPanel>
        ))}
        <SettingsPanel label="Your saved templates" initialOpen>
          {privateSavedTemplates.length === 0 ? (
            <Box textAlign={'center'}>
              You have not saved any templates yet.
            </Box>
          ) : (
            <TemplateList savedList={privateSavedTemplates} />
          )}
        </SettingsPanel>
        <SettingsPanel label="Public templates" initialOpen>
          <TemplateList savedList={publicSavedTemplates} />
        </SettingsPanel>
      </DialogContent>
      <EditNodeTemplate ref={templateRef} />
    </Dialog>
  );
});

AppenderDialog.displayName = 'AppenderDialog';
