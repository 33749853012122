import { DateUtils } from './dateUtils';

export const mapParticipantToList = (user) => {
  return {
    id: user.id,
    picture: user.picture,
    status: user.status,
    initials: user.initials,
    inviation_code: user.inviation_code,
    interaction_type: user.interaction_type,
    ticket_type: user.ticket_type,
    access_level: user.access_level,
    participation_level: user.participation_level,
    invitation_sent: user.invitation_sent,
    logins: user.logins,
    email: user.email,
    full_name: user.full_name,
    first_name: user.first_name,
    last_name: user.last_name,
    info_description: user.info_description,
    location_city: user.location_city,
    location_country: user.location_country || '',
    location_countrycode: user.location_countrycode || '',
    regDate: user.createdAt,
    tags: user.tags,
    registration_data: user.registration_data,
    integration_type: user.integration_type,
    integration_id: user.integration_id,
    entities: user.entities,
    company: user.company || '',
    title: user.title || '',
    conferenceId: user.conferenceId,
  };
};

/**
 * Get the conferenceid from widow or in manager from location pathname.
 *
 * @returns string UUId conferenceId
 */
export const getConferenceId = () => {
  if ('CONFERENCE_ID' in window) {
    return window.CONFERENCE_ID;
  }

  const paths = window.location.pathname.split('/');

  return paths[paths.indexOf('conference') + 1];
};

export const isLocalUrl = (url) => {
  const lowerUrl = url ? url.toLowerCase() : '';
  if (
    lowerUrl.substring(0, 4) === 'http' &&
    lowerUrl.indexOf(window.location.hostname) === -1
  ) {
    return false;
  }
  return true;
};

export const infoList = (infos) => {
  const filtered = infos.filter((info) => !!info);
  return filtered.join(', ');
};

export const sortAsTree = (
  list,
  treeList,
  parentDiscussionMessageId,
  depth
) => {
  const messagesOnThesDepth = list.filter(
    (m) => m.parentDiscussionMessageId === parentDiscussionMessageId
  );
  messagesOnThesDepth.forEach((m) => {
    const messageData = {
      ...m,
      depth: depth,
    };
    treeList.push(messageData);
    sortAsTree(list, treeList, m.id, depth + 1);
  });
  return treeList;
};

const hashtagRegex = /#[a-zA-Z0-9_-]+/gim;
export const getHashtags = (str = '') => {
  return str.match(hashtagRegex) || [];
};
export const getHashtagData = (tag = '') => {
  const tagData = tag.split('-'); // Split {title}-{color}
  return {
    tag: tag,
    title: tagData[0].replace('_', ' ').substring(1),
    count: 1,
    color: tagData[1] || null,
  };
};
export const removeHashtags = (str = '') => {
  return str.replace(hashtagRegex, '');
};

export const getHashtagList = (list = []) => {
  const tagMap = {};
  list.forEach((str) => {
    getHashtags(str).forEach((tag) => {
      if (tagMap[tag]) {
        tagMap[tag].count++;
      } else {
        tagMap[tag] = getHashtagData(tag);
      }
    });
  });
  return Object.values(tagMap).sort((a, b) => a.title.localeCompare(b.title));
};

export const arrayToCSV = (data, fields, setFileName = null) => {
  const fileName = setFileName || 'export.csv';
  const list = [fields];
  data.forEach((item) => {
    const row = [];
    fields.forEach((field) => {
      row.push((item[field] || '').toString().replace(/(")/gm, "'"));
    });
    list.push(row);
  });
  //Convert data to CSV format
  let csvContent = list
    .map(
      (row) => row.map((field) => `"${field.replace(/"/g, '""')}"`).join(',') // Quote fields and escape internal quotes
    )
    .join('\n');

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv' });

  // Create a link to download the CSV file
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;

  // Automatically trigger the download
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // // var encodedUri = encodeURI(csvContent);
  // // window.open(encodedUri);
};

export const downloadVisitorsCSV = (ecards) => {
  const modified = ecards.map((ecard) => {
    return {
      ...ecard,
      tags: ecard.tags.map((tag) => tag.title).join(', '),
    };
  });
  return arrayToCSV(modified, [
    'first_name',
    'last_name',
    'title',
    'company',
    'location_city',
    'email',
    'message',
    'tags',
  ]);
};

// const ONE_DAY = 1000 * 60 * 60 * 24;
// export const daysLeft = (dateParameter) => {
//   let today = new Date().setHours(0, 0, 0, 0);
//   let compareDate = new Date(dateParameter).setHours(0, 0, 0, 0);
//   return (compareDate - today) / ONE_DAY;
// };

// export const getTimeLeft = (room) => {
//   if (room.programDay.date) {
//     const days = daysLeft(room.programDay.date);
//     if (days === 0) {
//       const eventStart =
//         room.events[0].start_time && room.events[0].start_time.substring(0, 5);
//       return `Starts today at ${eventStart}`;
//     } else if (days > 0) {
//       return `Starts in ${days} day${days > 1 ? 's' : ''}`;
//     } else {
//       return `${Math.abs(days)} day${
//         Math.abs(days) > 1 ? 's' : ''
//       } ago, seems there was no live stream`;
//     }
//   }
//   return '';
// };

export const addMinutes = (time, minutes) => {
  try {
    const [h, m] = time.split(':');
    const newDate = new Date(
      new Date(2020, 1, 1, parseInt(h), parseInt(m), 0).getTime() +
        minutes * 60000
    );
    console.log('newDate', newDate);
    // Return as time string
    return `${String(newDate.getHours()).padStart(2, '0')}:${String(
      newDate.getMinutes()
    ).padStart(2, '0')}:00`;
  } catch (e) {
    return '00:00:00';
  }
};

export const getConferencePreviewUrl = (conferenceData) => {
  const port = window.location.port ? `:${window.location.port}` : '';
  return `//${conferenceData.name}${window.location.hostname.substring(
    window.location.hostname.indexOf('.')
  )}${port}`;
};

export const getConferenceUrl = (conferenceData) => {
  const port = window.location.port ? `:${window.location.port}` : '';
  if (conferenceData.url_cname) {
    return `//${conferenceData.url_cname}`;
  } else {
    return `//${conferenceData.name}${window.location.hostname.substring(
      window.location.hostname.indexOf('.')
    )}${port}`;
  }
};

export const markdownMini = (text) => {
  let marked = !text
    ? ''
    : text
        // .replace(/^### (.*$)/gim, '<h3>$1</h3>')
        // .replace(/^## (.*$)/gim, '<h2>$1</h2>')
        // .replace(/^# (.*$)/gim, '<h1>$1</h1>')
        // .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
        // .replace(/(<([^>]+)>)/gi, '')
        .replace(/\</g, '&lt;') //for <
        .replace(/\>/g, '&gt;')
        .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
        .replace(/_(.*)_/gim, '<i>$1</i>');
  // .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
  // .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
  // .replace(/\n$/gim, '<br />')

  return { __html: marked };
};

export const findCurrentDayId = (conferenceData) => {
  if (conferenceData.programDays.length === 0) {
    return null;
  }
  // See if there is a active and selected current day
  const activeCurrentDay = conferenceData.programDays.find(
    (day) => day.id === conferenceData.currentDayId && day.state === 'active'
  );
  if (activeCurrentDay) {
    return activeCurrentDay.id;
  } else {
    // If none is selected, use first active
    const activeDays = conferenceData.programDays.filter(
      (day) => day.state === 'active'
    );
    if (activeDays.length > 0) {
      return activeDays[0].id;
    } else {
      // If no active, use first anything
      return conferenceData.programDays[0].id;
    }
  }
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
//Add by reference
export const addExtraInfoToEvent = (event) => {
  const eventdate = event.programDay.date;
  //const offset = event.programDay.conference.utc_offset; //Sweden
  const timezone = event.programDay.conference.timezone;
  const ev = event;
  const videoassets = event.programRoom.programroomvideoassets;

  if (ev && videoassets) {
    let possible_assets = []; //If assets exist
    let utcdatestring = eventdate + 'T' + ev.start_time + '.000Z';
    let unixtimestamp_milliseconds = new Date(utcdatestring).getTime();

    //Milliseconds to seconds
    let unixtimestamp = parseInt(
      (unixtimestamp_milliseconds / 1000).toFixed(0)
    );
    const offset = DateUtils.getOffsetByTimezone2(
      timezone,
      unixtimestamp_milliseconds
    ); //get offset by conference timezone and date of event
    //console.log('offset timezone', offset);

    let utc_offest_hours = offset / 60; //Sweden for example, utc+2,
    let event_start_unixtimestamp_offset_compensated =
      unixtimestamp + 3600 * utc_offest_hours;
    let user_event_start_adjustment_sec = ev.adjusted_start_offset;
    let event_start_with_compensation =
      event_start_unixtimestamp_offset_compensated +
      user_event_start_adjustment_sec;

    //Starttimedate_utc will now have offset compensation added to it.
    ev.starttimedate_utc = event_start_with_compensation;
    for (let i = 0; i < videoassets.length; i++) {
      //deep copy
      const tmp_videoasset = videoassets.map((item) => ({ ...item }));
      //Select from assetst that have end time larger start of event
      if (tmp_videoasset[i].recording_end >= event_start_with_compensation) {
        let start_offest_seconds = parseInt(
          event_start_with_compensation - tmp_videoasset[i].recording_start
        );
        tmp_videoasset[i].start_offest_seconds = start_offest_seconds;
        possible_assets.push(tmp_videoasset[i]);
      }
    }
    ev.possible_assets = possible_assets;
    if (possible_assets.length > 0) {
      let earliest_event_of_possible = possible_assets.reduce(function (
        prev,
        curr
      ) {
        return prev.recording_start < curr.recording_start ? prev : curr;
      });
      ev.start_asset = earliest_event_of_possible;
    } else {
      console.log('No possible asset for this event', ev);
      ev.start_asset = null;
    }
  }
};

export default {
  mapParticipantToList,
  // daysLeft,
  // getTimeLeft,
  monthNames,
};
