import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { TopicSelector } from '@/components/topic-selector';
import { ManagerMarkdownEditor } from '@/js/shared/components/markdown/ManagerMarkdownEditor';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';

import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { description, received, topicSlug = '' },
  },
  conferenceId,
}: IProps) => {
  const { control, setValue } = useFormContext();
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <Controller
          control={control}
          name="description"
          defaultValue={description}
          render={({ field: { onChange, value } }) => (
            <ManagerMarkdownEditor
              value={value}
              setValue={onChange}
              conferenceId={conferenceId}
              height={'150px'}
            />
          )}
        />
        <br />
        <InputControl
          fullWidth
          name="received"
          label="Text when sent"
          defaultValue={received}
          variant="outlined"
        />
        <br />
        Select a discussion topic first, can be hidden but not secret
        <TopicSelector
          onChange={(newVal) => setValue('topicSlug', newVal)}
          conferenceId={conferenceId}
          value={topicSlug}
          newTopicTitle={''} // Dont allow new topic
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
