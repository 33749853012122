import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import C from '../../../../../shared/constants/general';
import UserAvatar from '../../../shared/components/ui/UserAvatar';
import { AuthContext } from '../../context/AuthContext';
import { MarkdownEditor } from '../markdown/MarkdownEditor';

const useStyles = makeStyles((theme) => ({
  paperBg: {
    backgroundColor: theme.palette.background.shadedown_10,
  },
  parentVisitor: {
    flex: '45px 0 0',
    maxHeight: 60,
    overflow: 'hidden',
  },
  parentText: {
    maxHeight: 60,
    overflow: 'hidden',
  },
  pinField: {
    width: 80,
  },
}));

export const DiscussionMessageEdit = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [postError, setPostError] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);
  const isModerator =
    authState.user &&
    authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;

  useImperativeHandle(ref, () => ({
    open: ({ discussion, parentMessage, message, callback }) => {
      setData({ discussion, parentMessage, message, callback });
      setOpen(true);
    },
  }));

  const cancelHandler = () => {
    setPostError(null);
    setData(null);
    setOpen(false);
    setLoading(false);
  };

  const deleteHandler = () => {
    axios
      .delete(
        `/api/visitor/discussionmessages/${data.message.id}${
          isModerator ? '/moderator' : ''
        }`,
        data.message
      )
      .then(() => {
        data.callback && data.callback('delete');
        cancelHandler();
      })
      .catch((error) => setPostError(error.response.data.message));
  };
  const save = () => {
    setLoading(true);
    if (data.message.id) {
      axios
        .put(`/api/visitor/discussionmessages/${data.message.id}`, data.message)
        .then(() => {
          cancelHandler();
        })
        .catch((error) => {
          setLoading(false);
          setPostError(error.response.data.message);
        });
    } else {
      axios
        .post(
          `/api/visitor/discussions/${data.message.discussionId}/messages`,
          data.message
        )
        .then((response) => {
          cancelHandler();
        })
        .catch((error) => {
          setLoading(false);
          setPostError(error.response.data.message);
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((currentData) => {
      currentData.message[name] = value;
      return { ...currentData };
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={cancelHandler}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="Reply"
      PaperProps={{ className: classes.paperBg }}
    >
      {data && (
        <>
          <DialogContent>
            <Box display="flex">
              <Box display="flex">
                {data.discussion.visitor && (
                  <Box align="center" className={classes.parentVisitor} pr={1}>
                    <UserAvatar user={data.discussion.visitor} />
                    {data.discussion.visitor.initials}
                  </Box>
                )}
                <Box flexGrow={1} className={classes.parentText}>
                  {data.discussion.title.length > 20
                    ? `${data.discussion.title.substring(0, 20)}...`
                    : data.discussion.title}
                </Box>
              </Box>
              {data.parentMessage && (
                <Box display="flex">
                  <Box p={1}>
                    <Icon>reply</Icon>
                  </Box>
                  {data.parentMessage.visitor && (
                    <Box align="center" className={classes.parentVisitor}>
                      <UserAvatar user={data.parentMessage.visitor} />
                      {data.parentMessage.visitor.initials}
                    </Box>
                  )}
                  <Box flexGrow={1} className={classes.parentText}>
                    {data.parentMessage.text.length > 20
                      ? `${data.parentMessage.text.substring(0, 20)}...`
                      : data.parentMessage.text}
                  </Box>
                </Box>
              )}
              <Box p={1}>
                <Icon>reply</Icon>
              </Box>
            </Box>
          </DialogContent>
          <DialogContent>
            <Box>
              <MarkdownEditor
                value={data.message.text}
                setValue={(txt) =>
                  handleInputChange({
                    target: { name: 'text', value: txt },
                  })
                }
                height={'200px'}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Box display="flex" className={classes.buttonList} flexGrow={1}>
              {isModerator && (
                <Box flexGrow={1}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    className={classes.pinField}
                    size="small"
                    name="pinned"
                    label="Pinned?"
                    type="number"
                    variant="outlined"
                    onChange={handleInputChange}
                    value={data.message.pinned}
                  />
                </Box>
              )}
              {postError && (
                <Box align="right" color="error.main">
                  {postError}
                </Box>
              )}
              <Box>
                {((data.message &&
                  data.message.id &&
                  data.message.replies === 0) ||
                  (isModerator && data.message.id)) && (
                  <Button
                    onClick={deleteHandler}
                    startIcon={<Icon>delete</Icon>}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Box flexGrow={1} align="right">
                <Button onClick={cancelHandler}>Cancel</Button>

                <Button disabled={loading} color="primary" onClick={save}>
                  Post
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
});

DiscussionMessageEdit.displayName = 'DiscussionMessageEdit';
