import React from 'react';
import Link from '@material-ui/core/Link';

interface IProps {
    [key: string]: any;
}

export const RichTextLink = ({ contentState, entityKey, children }: IProps) => {
  const { url } = contentState.getEntity(entityKey).getData();

  return <Link href={url}>{children}</Link>;
};