import { AuthContext } from '@/js/shared/context/AuthContext';
import { Models } from '@/node-editor/store/models';
import {
  IMigration,
  INode,
  INodeElement,
  INodeProps,
  INodeSavedTemplate,
  INodeTemplate,
  TMergedNodeTamplates,
  TNodes,
  TNodeTemplates,
  TRenderContext,
  TSavedNodeTamplates,
} from '@/types/node';
import { GridSize } from '@material-ui/core';
import { id } from 'date-fns/locale';
import { type } from 'os';
import { ComponentType, lazy, LazyExoticComponent, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isNodeValid } from './validation';

/**
 * Registers INodeTemplate to Models instance
 *
 * @param type string - node name
 * @param schema INodeTemplate
 */
export const registerTemplate = (type: string, schema: INodeTemplate): void => {
  Models.getInstance().register(type, schema);
};

/**
 *
 * @param type string - node name
 * @returns INodeTemplate
 */
export const getTemplate = (type: string): INodeTemplate => {
  const { depricated, ...template } = Models.getInstance().get(type);

  return template;
};

export const getTemplateDepricated = (
  type: string
): undefined | IMigration[] => {
  return Models.getInstance().get(type).depricated;
};

/**
 *
 * @returns INoodeTemplate[]
 */
export const getTemplates = (): INodeTemplate[] => {
  return Models.getInstance()
    .all()
    .map((template) => (({ depricated, ...rest }) => rest)(template));
};

/**
 * Merges an INodeTemplate with a page defined node template
 *
 * @param template
 * @returns INodeTemplate
 */
export const mergeTemplates = (
  template: INodeTemplate,
  partial: Partial<INodeTemplate>
): INodeTemplate => {
  if (partial.content) {
    template.content = {
      ...template.content,
      ...partial.content,
    };
  }
  if (partial.override) {
    template.override = {
      ...template.override,
      ...partial.override,
    };
  }

  if (partial.children) {
    template.children = [...partial.children];
  }

  return template;
};

/**
 * Runs migrations and returns new node content.
 *
 * @param migrations
 * @param invalidContent
 * @returns Record<string, any>
 */
export const migrateContent = (
  migrations: IMigration[],
  invalidContent: Record<string, any>
): Record<string, any> => {
  let newContent = { ...invalidContent };

  for (let i = 0; i < migrations.length; i++) {
    newContent = { ...migrations[i].migrate(newContent) };
  }

  return newContent;
};

/**
 * Parses Node JSON template tree to TNodes format
 * @param template Partial<INodeTemplate[]>
 * @returns TNodes
 */
export const fromJsonTemplate = (
  template: Partial<INodeTemplate[]>,
  parentId: string = null
): TNodes => {
  return template.reduce((acc, curr, index) => {
    const template =
      typeof curr === 'string'
        ? getTemplate(curr)
        : mergeTemplates(getTemplate(curr.type.toLowerCase()), curr);

    const node = { ...template, id: uuidv4(), nodeId: parentId, order: index };

    if (node.children && node.children.length >= 1) {
      acc = { ...acc, ...fromJsonTemplate(node.children, node.id) };

      node.children = [];
    }

    return {
      ...acc,
      [node.id]: node,
    };
  }, {});
};

const isPlainObject = (value: any) => {
  return (
    value instanceof Object && Object.getPrototypeOf(value) == Object.prototype
  );
};
const IMAGES = ['jpg', 'jpeg', 'png', 'gif'];
// Check that not starting with / and ends with IMAGES ending
const isLocalImage = (path: string) =>
  path.at(0) !== '/' && IMAGES.includes(path.split('.').at(-1).toLowerCase());
interface IContent {
  [key: string]: any;
}
const convertImagePathsToExact = (
  content: IContent = {},
  conferenceId: string
) => {
  return Object.entries(content).reduce<{ [key: string]: any }>(
    (acc, [key, val]) => {
      if (typeof val === 'string' && isLocalImage(val)) {
        acc[key] = `/conferences/${conferenceId}/${val}`;
      } else if (isPlainObject(val)) {
        acc[key] = convertImagePathsToExact(val, conferenceId);
      } else {
        acc[key] = val;
      }
      return acc;
    },
    {}
  );
};

/**
 * Parses Node JSON template tree to TNodes format
 * @param template Partial<INodeTemplate[]>
 * @returns TNodes
 */
export const filterSavedTemplateData = (
  nodes: TNodes,
  conferenceId: string
): TSavedNodeTamplates => {
  const filtered: TSavedNodeTamplates = { ...nodes };
  Object.keys(nodes).forEach((key) => {
    filtered[key] = (({
      id,
      nodeId,
      type,
      order,
      content,
      override,
    }: INodeSavedTemplate) => ({
      id,
      nodeId,
      type,
      order,
      content: convertImagePathsToExact(content, conferenceId),
      override,
    }))(nodes[key]);
  });
  return filtered;
};

/**
 * Merges saved template nodes with schemas
 * @param nodes TSavedNodeTamplates
 * @param parentId string
 * @returns TNodeTemplates
 */
export const mergeSavedTemplateData = (
  nodes: TSavedNodeTamplates,
  parentId: string,
  parentOrder: number
): TMergedNodeTamplates => {
  // Handle for special case when root:
  const isRootTemplate = Object.values(nodes).find((n) => n.type === 'root');
  if (isRootTemplate) {
    delete nodes[isRootTemplate.id]; // Remove root node
    Object.values(nodes).forEach((n) => {
      if (n.nodeId === isRootTemplate.id) {
        n.nodeId = null; // Set all other to point as root
      }
    });
  }

  const filtered: Partial<TMergedNodeTamplates> = {};
  // Create new IDs and keep track on who's parent to whome
  const newIdMapper = Object.keys(nodes).reduce<{ [key: string]: string }>(
    (val: any, key: string) => {
      val[key] = uuidv4();
      return val;
    },
    {}
  );
  Object.keys(nodes).forEach((key) => {
    const temp = mergeTemplates(
      getTemplate(nodes[key].type.toLowerCase()),
      nodes[key]
    );
    const isParent = !nodes[key].nodeId;
    filtered[newIdMapper[nodes[key].id]] = {
      ...temp,
      id: newIdMapper[nodes[key].id],
      order: isParent ? parentOrder : nodes[key].order, // Keep saved order for children
      nodeId: isParent ? parentId : newIdMapper[nodes[key].nodeId], // Add parent id to "root" node
    };
  });
  return filtered;
};

/**
 * Parses Node JSON template tree to TNodes format
 * @param template Partial<INodeTemplate[]>
 * @returns TNodes
 */
export const mergeSavedWith = (nodes: TNodes): TSavedNodeTamplates => {
  const filtered: TSavedNodeTamplates = { ...nodes };
  Object.keys(nodes).forEach((key) => {
    filtered[key] = (({
      id,
      nodeId,
      type,
      order,
      content,
      override,
    }: INodeSavedTemplate) => ({ id, nodeId, type, order, content, override }))(
      nodes[key]
    );
  });
  return filtered;
};

/**
 * Parses db INodes[] to TNodes
 * @param nodes INode[]
 * @returns TNodes
 */
export const fromDBEntities = (nodes: INode[]): TNodes => {
  return nodes.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.id]: curr,
    };
  }, {});
};

interface IEntity {
  name: string;
  nodes?: INode[];
  template?: Partial<INodeTemplate[]>;
}

/**
 * Selects wich parser depending on structure of nodes provided by entity
 *
 * @param entity
 * @returns TNodes
 */
export const getNodes = (
  entity: IEntity,
  templates: Record<string, IEntity['template']>
): TNodes => {
  if ('template' in entity) {
    return fromJsonTemplate(entity.template);
  }

  if ('nodes' in entity && entity.nodes.length >= 1) {
    return fromDBEntities(entity.nodes);
  }

  if (templates[entity.name.toLowerCase()]) {
    return fromJsonTemplate(templates[entity.name.toLowerCase()]);
  }

  return fromJsonTemplate(templates.blank);
};

/**
 * A wrapper on top of React.lazy to lazily resolve node from named export and select by TRenderContext
 *
 * @param factory
 * @param provided
 * @returns
 *
 * @example
 * nodeResolver(() => import(path/to/component), {node, context});
 */
export const nodeResolver = (
  factory: () => Promise<unknown>,
  provided: { node: INode; context: TRenderContext }
): LazyExoticComponent<ComponentType<INodeProps>> => {
  const asyncResolver = (): Promise<{ default: ComponentType<INodeProps> }> => {
    return new Promise(async (resolve, reject) => {
      const { node, context } = provided;

      try {
        const module = ((await factory()) as unknown) as INodeElement;

        isNodeValid(context, node, module);

        return resolve({
          default: module[context],
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  return lazy(() => asyncResolver());
};

/**
 *
 *
 * @param ticketTypes - Comma separated ticket string
 * @returns boolean
 *
 * @example
 * userHasTicket(tickets = 'regular,vip')
 */
export const userHasTicket = (ticketTypes: string): boolean => {
  const { authState } = useContext(AuthContext);

  // Empty ticket types means open for everyone
  if (ticketTypes === '') {
    return true;
  }

  // User need to be logged in if ticket is demanded
  if (!authState.userLoggedIn && ticketTypes !== '') {
    return false;
  }

  if (typeof authState.user.registration_data !== 'object') {
    return false;
  }

  if (
    'tickets' in authState.user.registration_data &&
    ticketTypes.split(',').includes(authState.user.registration_data.tickets)
  ) {
    return true;
  }

  return false;
};

interface IGridWidth {
  [width: string]: GridSize;
}

const widthConverter: IGridWidth = {
  '1': 12,
  '2': 6,
  '3': 4,
  '4': 3,
  '2/3': 8,
  '3/4': 9,
};
/**
 * Returns a grid size value from string
 * @param width string
 * @returns
 */
export const getGridNumber = (width: string): GridSize => {
  return widthConverter[width] || 1;
};
