import axios from 'axios';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import threadReducer from '../reducers/threadReducer';
import { AuthContext } from './AuthContext';

export const ThreadContext = createContext();

const ThreadContextProvider = (props) => {
  const { authState } = useContext(AuthContext);

  const [threadState, dispatch] = useReducer(threadReducer, {
    threadsLoading: true,
    threads: [],
    currentThread: null,
    addingMessage: false,
  });

  const loadThreads = () => {
    dispatch({ type: 'THREADS_LOAD' });
    axios
      .get(`/api/visitor/listthreads`)
      .then((response) => {
        dispatch({ type: 'THREADS_LOADED', threads: response.data.threads });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (authState.userLoggedIn) {
      dispatch({
        type: 'THREADS_LOADED',
        threads: authState.user.threads || [],
      });
    }
    return () => {};
  }, [authState.userLoggedIn]);

  useEffect(() => {
    if (
      threadState.currentThread &&
      threadState.currentThread.threadMessages &&
      threadState.currentThread.threadMessages.length > 0
    ) {
      const lastMsg =
        threadState.currentThread.threadMessages[
          threadState.currentThread.threadMessages.length - 1
        ];
      if (lastMsg.socket && lastMsg.visitorId !== authState.user.id) {
        // Have received a message while having the thread open.
        axios
          .put(`/api/visitor/threads/${lastMsg.threadId}/didread`)
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [
    threadState.currentThread &&
      threadState.currentThread.threadMessages &&
      threadState.currentThread.threadMessages.length,
  ]);

  return (
    <ThreadContext.Provider value={{ threadState, dispatch, loadThreads }}>
      {props.children}
    </ThreadContext.Provider>
  );
};

export default ThreadContextProvider;
