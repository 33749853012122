import axios from 'axios';
import React from 'react';

const withS3Bucket = () => <Props extends object>(
  WrappedComponent: React.ComponentType<Props>
) => {
  const WithS3Bucket = <
    T extends Props & { entityId: string; signUrl?: string }
  >(
    props: T
  ) => {
    const { entityId, signUrl = null } = props;

    const getSignedRequest = async (file: File) => {
      try {
        const response = await axios.get(
          signUrl
            ? `${signUrl}?file-name=${file.name}&file-type=${file.type}`
            : `/api/visitor/entities/${entityId}/signmediaupload?file-name=${file.name}&file-type=${file.type}`
        );

        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const upload = async (
      file: File,
      callback: (process: {
        generated: string;
        original: string;
        result: number;
      }) => unknown
    ) => {
      try {
        const response = await getSignedRequest(file);

        const result = await axios.put(response.signedRequest, file);

        callback({
          generated: response.generatedName,
          original: file.name,
          result: result.status,
        });

        return result.data;
      } catch (error) {
        console.error(error);
      }
    };

    return <WrappedComponent upload={upload} {...props} />;
  };

  return WithS3Bucket;
};

export { withS3Bucket };
