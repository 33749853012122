import React from 'react';
import invariant from 'tiny-invariant';
import CoeolyticsContext from './CoeolyticsContext';

const CoeolyticsProvider = ({ children, instance }) => {
  invariant(
    instance,
    `CoeolyticsProvider instance not provided to <CoeolyticsProvider />`
  );
  return (
    <CoeolyticsContext.Provider value={instance} children={children || null} />
  );
};

export default CoeolyticsProvider;
