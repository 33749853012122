import { NodeContext } from '@/node-editor';
import { INode, INodeTemplate, TNodes } from '@/types/node';
import { useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { mergeSavedTemplateData } from '../utilities';
import { EditorStoreActions } from './index';
import { getChildrenCount } from './selectors';

interface IProvided {
  nodes: TNodes;
  dispatch: React.Dispatch<EditorStoreActions>;
}

/**
 * Construct node from template
 *
 * @param template - INodeTemplate
 * @param parentId - string
 * @returns INode
 */
export const createNode = <T extends IProvided>(
  template: INodeTemplate,
  parentId: string
) => ({ nodes }: Partial<T>): INode => {
  const { icon, name, tag, children, ...rest } = template;

  return {
    ...rest,
    nodeId: parentId,
    order: getChildrenCount(parentId, nodes),
    id: uuidv4(),
    children: [],
  };
};

/**
 * Saves node to state
 *
 * @param template - INodeTemplate
 * @param parentId - string
 * @returns void
 */
export const addNode = <T extends IProvided>(
  template: INodeTemplate,
  parentId: string
) => ({ nodes, dispatch }: T): void => {
  const payload = createNode(template, parentId)({ nodes });

  dispatch({ type: 'NODES_ADD', payload });
};

/**
 * Adds template to state
 *
 * @param template - INodeTemplate
 * @param parentId - string
 * @returns void
 */
export const addTemplate = <T extends IProvided>(
  nodeMap: TNodes,
  parentId: string
) => ({ nodes, dispatch }: T): void => {
  let nodeMapWithParent: TNodes = { ...nodeMap };

  Object.keys(nodeMapWithParent).forEach((id) => {
    if (!nodeMapWithParent[id].nodeId) {
      nodeMapWithParent[id].nodeId = parentId;
    }
  });
  console.log('nodeMapWithParent', nodeMapWithParent);
  dispatch({ type: 'NODELISTS_ADD', payload: nodeMapWithParent });
};

/**
 * Removes node from state
 *
 * @param payload - INode
 * @returns void
 */
export const removeNode = <T extends IProvided>(payload: INode) => ({
  dispatch,
}: T): void => {
  dispatch({ type: 'NODES_REMOVE', payload });
};

/**
 * Updates node on state
 *
 * @param payload - INode
 * @returns void
 */
export const updateNode = <T extends IProvided>(payload: INode) => ({
  nodes,
  dispatch,
}: T): void => {
  const currentNode = nodes[payload.id];
  const contentUnchanged =
    JSON.stringify(payload.content) === JSON.stringify(currentNode.content);
  const overrideUnchanged =
    'override' in payload &&
    'override' in currentNode &&
    JSON.stringify(payload.override) === JSON.stringify(currentNode.override);

  if (contentUnchanged && overrideUnchanged) {
    return;
  }

  dispatch({ type: 'NODES_UPDATE', payload });
};

/**
 * Reorders node on drop action
 *
 * @param id - string
 * @param from - index number
 * @param to - index number
 * @returns void
 */
export const reorderNode = <T extends IProvided>(
  id: string,
  from: number,
  to: number
) => ({ dispatch }: T) => {
  dispatch({ type: 'NODES_REORDER', payload: { id, from, to } });
};

/**
 * resets the whole state with provided payload
 *
 * @param payload - TNodes
 * @returns void
 */
export const resetNodes = <T extends IProvided>(payload: TNodes) => ({
  dispatch,
}: T): void => {
  dispatch({ type: 'NODES_RESET', payload });
};

/**
 * sets the current node in reducer
 *
 * @param id - string
 * @returns  void
 */
export const setCurrentNode = <T extends IProvided>(id: string) => ({
  dispatch,
}: T): void => {
  dispatch({ type: 'CURRENT_NODE', payload: id });
};

/**
 * Decorates actions with state and dispatcher
 *
 * @param callback - ({ store: EditorStore, dispatch: React.Dispatch<EditorStoreActions> }) => T
 * @returns - object of decorated and returned actions
 * @example
 * const actions = useActions(() => ({
 *     actionA,
 *     actionB
 * }));
 */
export const useActions = <T extends { [action: string]: any }>(
  callback: (provided?: IProvided) => T
) => {
  const context = useContext(NodeContext);

  if (context == undefined) {
    throw new Error('useActions must be used within NodeContextProvider.');
  }

  const { nodes, dispatch } = context;

  const inputMap = useRef(callback({ nodes, dispatch }));

  const withNodesDispatch = (func: (...args: any[]) => any) => (
    ...args: any[]
  ) => func(...args)({ nodes, dispatch });

  return Object.keys(inputMap.current).reduce((actions, action) => {
    if (typeof inputMap.current[action] === 'function') {
      return {
        ...actions,
        [action]: withNodesDispatch(inputMap.current[action]),
      };
    }

    return actions;
  }, inputMap.current);
};
