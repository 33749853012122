import React, { createContext, useEffect, useReducer } from 'react';
import managerConferenceActions from '../actions/managerConferenceActions';
import managerConferenceReducer from '../reducers/managerConferenceReducer';

export const ManagerConferenceContext = createContext();

const ManagerConferenceContextProvider = (props) => {
  const [conferenceData, dispatch] = useReducer(managerConferenceReducer, {
    updateProgress: false,
    updateError: false,
    conferenceProgress: false,
    conferenceLoaded: false,
    conferenceError: null,
    conference: null,
  });
  const actions = managerConferenceActions(dispatch);

  useEffect(() => {
    if (conferenceData.conferenceLoaded && conferenceData.conference) {
      document.title = conferenceData.conference.title + ' - Manager';
    }
  }, [conferenceData.conferenceLoaded, conferenceData.conference]);

  return (
    <ManagerConferenceContext.Provider
      value={{ conferenceData, dispatch, actions }}
    >
      {props.children}
    </ManagerConferenceContext.Provider>
  );
};

export default ManagerConferenceContextProvider;
