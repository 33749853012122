import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { description, received, topicSlug },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const post = () => {
    setSending(true);
    axios
      .post(`/api/visitor/conferences/${conferenceId}/discussions`, {
        topicSlug,
        title: title,
      })
      .then(() => {
        setTitle('');
        setError('');
        setSuccess(true);
      })
      .catch((e) => {
        setError(e.response.data.message);
      })
      .then(() => {
        setSending(false);
      });
  };
  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <>
          <MarkdownRender text={description} />
          {!topicSlug && (
            <Typography color="error">Please select topic</Typography>
          )}
          <TextField
            name="title"
            value={title}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
          />
          <Box display="flex">
            <Box flexGrow={1} p={1}>
              {success && received}
              {error && <Typography color="error">{error}</Typography>}
            </Box>
            <Box pt={1}>
              <UserAvatar user={authState.user} size="small" />
            </Box>
            <Button
              disabled={sending || title === '' || !topicSlug}
              endIcon={<Icon>send</Icon>}
              color="primary"
              onClick={post}
            >
              post
            </Button>
          </Box>
        </>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
