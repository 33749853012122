export const MEDIA_LOADED = "MEDIA_LOADED";
export const MEDIA_ADD = "MEDIA_ADD";

interface IMediaState {
    loaded: boolean;
    media: any[];
}

interface IAction {
    type: 'MEDIA_LOADED' | 'MEDIA_ADD';
    payload?: any;
}

export const mediaInitialState: IMediaState = {
  loaded: false,
  media: [],
};

export const mediaReducer = (state: IMediaState, action: IAction) => {
  switch (action.type) {
    case MEDIA_LOADED:
      return {
        loaded: true,
        media: action.payload,
      };
    case MEDIA_ADD:
      return { ...state, media: [...action.payload, ...state.media] };
    default:
      return state;
  }
};