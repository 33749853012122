import { Button, Icon, Select } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';

export const LocalLinkSelector = ({
  conferenceId,
  dataCallback = console.log,
  buttonText = 'Pick Local link',
  userType = 'moderator',
  ButtonProps = {},
  SelectProps = {},
}) => {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLinks = () => {
    setLoading(true);
    const url =
      userType === 'manager'
        ? `/api/conferences/${conferenceId}/links`
        : `/api/moderator/conferences/${conferenceId}/links`;
    axios
      .get(url)
      .then((response) => {
        setLinks(response.data.links);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Link list error', error);
        setLoading(false);
      });
  };
  const onChange = (e) => {
    dataCallback(e.target.value);
  };
  return links.length > 0 ? (
    <Select
      native
      defaultValue=""
      label="Links"
      onChange={onChange}
      {...SelectProps}
    >
      <option aria-label="None" value="">
        Select a link
      </option>
      {links.map((group, idx1) => (
        <optgroup label={group.group} key={idx1}>
          {group.links.map((link, idx2) => (
            <option key={idx2} value={link.url}>
              {link.title}
            </option>
          ))}
        </optgroup>
      ))}
    </Select>
  ) : (
    <Button
      startIcon={<Icon>link</Icon>}
      size="small"
      disabled={loading}
      onClick={fetchLinks}
      {...ButtonProps}
    >
      {buttonText}
    </Button>
  );
};
LocalLinkSelector.displayName = 'LocalLinkSelector';
