import { INodeTemplate } from "@/types/node";

export class Models {

    models: Map<string, INodeTemplate>;

    private static instance: Models;

    private constructor() {

        this.models = new Map();
    }

    register( type: string, schema: INodeTemplate ): void {

        if (!this.models.has( type )) {

            this.models.set( type, schema );
        }
    }

    has( type: string ): boolean {

        return this.models.has( type );
    }

    get( type: string ): INodeTemplate {

        return this.models.get( type );
    }

    all() {

        return Array.from( this.models.values() );
    }

    static getInstance(): Models {

         if (!Models.instance) {

            Models.instance = new Models();
         }

         return Models.instance;
    }
}