import { RenderChildren } from '@/node-editor/components';
import { INodeProps } from '@/types/node';
import { Box, CircularProgress } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

export const Render: React.FC<INodeProps> = ({ children }) => {
  const [newWidgets, setNewWidgets] = useState(false);
  const childrenListIds = children.map((c) => c.id).join('-');
  useEffect(() => {
    setNewWidgets(true);
    setTimeout(() => setNewWidgets(false), 1000);
  }, [childrenListIds]);
  return (
    <Fragment>
      {newWidgets && (
        <Box pb={1} textAlign="center">
          <CircularProgress size={15} />
        </Box>
      )}
      <RenderChildren>{children}</RenderChildren>
    </Fragment>
  );
};
