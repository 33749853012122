import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SlideControl } from '@/components/slide-control';
import { SwitchControl } from '@/components/switch-control';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Box, Icon, IconButton, InputAdornment } from '@material-ui/core';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IContent, IVisitorGoal } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IGoalEdit {
  icon: string;
  label: string;
  dataName: string;
  data: IVisitorGoal;
  max?: number;
}

const GoalEdit = ({ icon, label, dataName, data, max = 10 }: IGoalEdit) => {
  const { watch } = useFormContext();
  const watchVal = watch(`${dataName}.count`, data.count);
  const [useUrl, setUseUrl] = useState(!!data.url);

  return (
    <Box pb={4}>
      <SlideControl
        label=""
        name={`${dataName}.count`}
        defaultValue={data.count}
        min={0}
        max={max}
        step={1}
        marks
        valueLabelDisplay="on"
      />
      <Box display="flex">
        <Box flexGrow={1}>
          <InputControl
            style={{ opacity: watchVal ? 1 : 0.5 }}
            name={`${dataName}.text`}
            defaultValue={data.text}
            fullWidth
            variant="outlined"
            label={label}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>{icon}</Icon>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <IconButton onClick={() => setUseUrl(!useUrl)}>
            <Icon>link</Icon>
          </IconButton>
        </Box>
      </Box>
      {useUrl && (
        <Box>
          <InputControl
            name={`${dataName}.url`}
            defaultValue={data.url}
            fullWidth
            variant="outlined"
            label={'Override default url'}
          />
        </Box>
      )}
    </Box>
  );
};
export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      description,
      completed,
      design,
      info,
      follow,
      discussion,
      message,
      chat,
      thread,
      entityvisits,
    },
  },
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <InputControl
          name="description"
          defaultValue={description}
          fullWidth
          variant="outlined"
          label="Description"
        />
        <InputControl
          name="completed"
          defaultValue={completed}
          fullWidth
          variant="outlined"
          label="Text when completed"
        />
        <RadioGroupControl
          row
          label="Design"
          name="design"
          defaultValue={design}
          options={[
            {
              label: 'Vertical',
              value: 'vertical',
            },
            {
              label: 'Horizontal',
              value: 'horizontal',
            },
          ]}
        />
        <br />
        <br />

        <GoalEdit
          icon="info"
          label="Goal: User info (Img, org, city, title)"
          dataName="info"
          data={info}
          max={4}
        />

        <GoalEdit
          icon="contact_mail"
          label="Goal: Nr E-Cards"
          dataName="follow"
          data={follow}
        />

        <GoalEdit
          icon="comment"
          label="Goal: Nr started discussions"
          dataName="discussion"
          data={discussion}
        />

        <GoalEdit
          icon="reply"
          label="Goal: Nr discussion replies"
          dataName="message"
          data={message}
        />
        <GoalEdit
          icon="chat"
          label="Goal: Nr live chats"
          dataName="chat"
          data={chat}
        />
        <GoalEdit
          icon="forum"
          label="Goal: Nr private chats"
          dataName="thread"
          data={thread}
        />
        <GoalEdit
          icon="star"
          label="Goal: Sponsor visits (expo w. visitor counter)"
          dataName="entityvisits"
          data={entityvisits}
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
