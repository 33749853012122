import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  icon: 'video_library',
  name: 'Video',
  tag: 'content',
  type: 'videomux',
  content: {
    mediaId: undefined,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Edit, Render };
