import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { Anchorme } from 'react-anchorme';
import { withRouter } from 'react-router-dom';
import C from '../../../../../shared/constants/general';
import { VideoChatContext } from '../../context/VideoChatContext';
import { markdownMini } from '../../utils/DataFormat';
import { getDurationShort } from '../../utils/StringFormat';
import DiscussionList from '../discussion/DiscussionList';
import ParticipantPopup from '../ui/ParticipantPopup';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles((theme) => ({
  descriptionText: {
    whiteSpace: 'pre-line',
  },
  currentText: {
    width: 30,
    backgroundColor: '#f33',
    color: '#fff',
    padding: '2px 4px 0px 4px',
    fontSize: 10,
    borderRadius: 3,
    marginBottom: 5,
    marginTop: 5,
  },
  linkButton: {
    fontSize: '0.7rem',
    marginRight: 5,
    marginBottom: 5,
    // marginTop: -3,
    // padding: '2px 4px 2px 0',
  },
  linkButtonIcon: {
    marginRight: 2,
    '& > .MuiIcon-root': {
      fontSize: 14,
    },
  },
  linkListHolder: {
    fontSize: '0.75rem',
  },
  individualText: {
    padding: 10,
  },
  bookmarkIcon: {
    verticalAlign: 'middle',
  },
  titleHolder: {
    position: 'relative',
  },
  titleText: {
    fontSize: '1.1rem',
  },
  profileName: {
    color: theme.palette.text.primary,
    fontSize: '0.8rem',
    textDecoration: 'underline',
  },
  profileInfo: {
    fontSize: '0.8rem',
    // color: 'rgba(255, 255, 255, 0.7)',
  },
}));
const infoList = (infos) => {
  const filtered = infos.filter((info) => !!info);
  return filtered.join(', ');
};
const EventDescription = withRouter((props) => {
  const classes = useStyles();
  const userModalRef = useRef();
  const isLive = props.current;

  const displayedVisitors = props.event.visitors.filter(
    (v) =>
      v.eventVisitors.type === C.EVENT_VISITOR_TYPE.SPEAKER ||
      v.eventVisitors.type === C.EVENT_VISITOR_TYPE.MODERATOR
  );
  const userPopup = (visitor) => (e) => {
    e.preventDefault();
    userModalRef.current.setState({ open: true, user: visitor });
  };
  const toggleFavorite = () => {
    console.log('toggleFavorite');
  };
  return (
    <Box>
      <ParticipantPopup ref={userModalRef} />
      <Grid container spacing={0}>
        <Grid item xs={12} md={8} className={classes.titleHolder}>
          {isLive && <Box className={classes.currentText}>LIVE</Box>}
          <Typography variant="h5" className={classes.titleText}>
            {props.event.title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box align="right">
            {props.timeReactions && props.timeReactions.length > 0 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexGrow={1}
                align="right"
              >
                {props.timeReactions.map((reaction) => (
                  <Link
                    color="inherit"
                    underline="always"
                    component="button"
                    onClick={() => props.reactionCallback(reaction)}
                    variant="body2"
                    key={reaction.id}
                  >
                    <Icon className={classes.bookmarkIcon} color="primary">
                      bookmark
                    </Icon>
                    {getDurationShort(reaction.eventTimeStart)}
                  </Link>
                ))}
                <Box px={1}>
                  <Typography variant="subtitle2">Favorites</Typography>
                </Box>
              </Box>
            )}
            {props.nextEventId && (
              <Link href={`/live/session/${props.nextEventId}`}>
                Next&nbsp;&gt;
              </Link>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box>
          <Typography
            variant="body2"
            gutterBottom
            dangerouslySetInnerHTML={markdownMini(props.event.description)}
            className={classes.descriptionText}
          />
        </Box>
        {displayedVisitors.length > 0 && (
          <Grid container>
            {displayedVisitors.map((v) => (
              <Grid item xs={12} md={6} key={v.id}>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" py={1}>
                    <UserAvatar size="medium" user={v} />
                    <Box pl={1}>
                      <Typography variant="body2">
                        <Link
                          href="#"
                          className={classes.profileName}
                          onClick={userPopup(v)}
                        >
                          {v.full_name}
                        </Link>
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.profileInfo}
                      >
                        <em>{infoList([v.title, v.company])}</em>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
});

export default EventDescription;
