import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import moment from 'moment';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
  })
);

const useItemStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.background.shadedown_10}`,
    marginBottom: theme.spacing(1),
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
    },
  },
  icon: {
    fontSize: '1.2rem',
    marginTop: 3,
    marginRight: theme.spacing(1),
  },
  user: {
    fontSize: '0.8rem',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
  },
  replyTo: {
    fontSize: '0.7rem',
    fontWeight: 'bold',
    opacity: 0.5,
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  text: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(0.5),
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  date: {
    flexGrow: 1,
    textAlign: 'right',
    fontSize: '0.8rem',
    marginLeft: theme.spacing(1),
    opacity: 0.5,
  },
}));

const ItemDiscussion = ({ event }: { event: any }) => {
  const classes = useItemStyles();
  const { conferenceState } = useContext(ConferenceContext);
  const isDiscussion = event.type === 'discussion';

  const displayText = event.content.title || event.content.text || '';
  const text =
    displayText.length > 30 ? `${displayText.slice(0, 30)}...` : displayText;
  const replyToText = event.content.discussion
    ? event.content.discussion.title
    : '';
  const replyTo =
    replyToText.length > 15 ? `${replyToText.slice(0, 15)}...` : replyToText;

  const fromNow = moment
    .utc(event.content.createdAt)
    .utcOffset(-conferenceState.conferenceDetail.utc_offset)
    .fromNow();

  return (
    <ButtonBase
      className={classes.root}
      href={`/live/discussion?discussion=${
        isDiscussion ? event.content.id : event.content.discussion.id
      }`}
    >
      <Icon className={classes.icon}>{isDiscussion ? 'forum' : 'reply'}</Icon>
      <div className={classes.content}>
        {!isDiscussion && <div className={classes.replyTo}>{replyTo}</div>}
        <div className={classes.text}>{text}</div>
        <div className={classes.user}>
          <UserAvatar user={event.content.visitor} size="small" />
          &nbsp;
          <span>
            {event.content.visitor ? event.content.visitor.first_name : '...'}
          </span>
          <span className={classes.date}>{fromNow}</span>
        </div>
      </div>
    </ButtonBase>
  );
};

export const Render = ({
  node: {
    id,
    override,
    content: { limit, types },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [forumEvents, setForumEvents] = useState<any[]>([]);
  useEffect(() => {
    axios
      .get(
        `/api/visitor/conferences/${conferenceId}/listlatestdiscussionsandreplies?limit=${
          limit || 5
        }&types=${types || 'all'}`
      )
      .then((response) => {
        setForumEvents(response.data);
      });
  }, []);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <Box display="flex" flexDirection="column">
          {forumEvents.map((event) => (
            <ItemDiscussion key={event.content.id} event={event} />
          ))}
        </Box>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
