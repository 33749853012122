import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  small_tagChip_skill_dark: {
    display: 'inline-block',
    padding: '1px 6px',
    margin: '2px 5px 2px 0px',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  small_tagChip_scouting_dark: {
    display: 'inline-block',
    padding: '1px 6px',
    margin: '2px 5px 2px 0px',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.secondary.light,
    textTransform: 'capitalize',
    color: theme.palette.secondary.light,
  },
  smaller_tagChip_skill_dark: {
    fontSize: '0.75rem',
    display: 'inline',
    textTransform: 'capitalize',
    color: theme.palette.primary.main,
  },
  smaller_tagChip_scouting_dark: {
    fontSize: '0.75rem',
    display: 'inline',
    textTransform: 'capitalize',
    color: theme.palette.secondary.light,
  },
}));

const CoChipSmall = React.memo((props) => {
  const classes = useStyles();
  const size = props.size || 'small';
  const tagChipColor = classes[`${size}_tagChip_${props.tagtype}_dark`];

  return <span className={tagChipColor}>{props.label}</span>;
});

export default CoChipSmall;
