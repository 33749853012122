import React, { FC, Fragment, ReactNode } from 'react';

interface IProps {
  condition: boolean;
  render: (children: ReactNode | ReactNode[]) => JSX.Element;
}

/**
 *
 * A wrapper for conditional rendering
 *
 * @returns JSX.Element
 * @example
 * <ConditionalRenderer
 *   condition={booleanCondition}
 *   render={(children) => <ExampleComponent>{children}</ExampleComponent>}
 * >
 *   {the prop to either render here or in render prop}
 * </ConditionalRenderer>
 */
export const ConditionalRenderer: FC<IProps> = ({
  condition,
  render,
  children,
}) => {
  if (condition && typeof render === 'function') {
    return render(children);
  }

  return <Fragment>{children}</Fragment>;
};
