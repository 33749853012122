import { Edit } from './edit';
import { Render } from './render';
import { INodeTemplate } from '../../types/node';

const defaultHiddenFields = {
  title: false,
  company: false,
  city: false,
  country: false,
  phone: false,
  ecard: false,
  description: false,
};

const template: INodeTemplate = {
  version: 1,
  name: 'Registration Form',
  icon: 'app_registration',
  type: 'registration',
  tag: 'components',
  content: {
    countryCode: '', // Uses conference lang_iso if empty
    textTagSelect: '',
    hiddenFields: {
      title: false,
      company: false,
      city: false,
      country: false,
      phone: false,
      ecard: false,
      description: false,
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: false,
  },
  depricated: [
    {
      migrate({ hiddenFields, ...content }) {
        return {
          ...content,
          hiddenFields: { ...defaultHiddenFields, ...hiddenFields },
        };
      },
    },
  ],
};

export { template, Render, Edit };
