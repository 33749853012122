import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles((theme) => ({
  selfMargins: {
    marginBottom: '0.7rem',
    paddingLeft: '5rem',
  },
  margins: {
    marginBottom: '0.7rem',
    paddingRight: '2rem',
  },
  avatar: {},
  avatarButton: {
    padding: 10,
    paddingLeft: 0,
  },
  textFormat: {
    whiteSpace: 'pre-wrap',
  },
  userNameHolder: {
    height: 17,
    lineHeight: '13px',
  },
  userName: {
    textTransform: 'capitalize',
    fontSize: 10,
    padding: 0,
    height: 17,
    color: theme.palette.text.secondary,
    opacity: 0.8,
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  chatHolder: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  chatText: {
    position: 'relative',
    backgroundColor: theme.palette.background.shadedown_10,
    borderRadius: '1rem',
    borderBottomLeftRadius: '0',
    padding: '1rem',
  },
  selfChatText: {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
    backgroundColor: theme.palette.background.shadedown_10,
    borderRadius: '1rem',
    borderBottomRightRadius: '0',
    padding: '1rem',
    marginRight: 5,
  },
}));

const ThreadMessageBubble = (props) => {
  const { authState } = useContext(AuthContext);
  const classes = useStyles();

  return props.message.visitorId === authState.user.id ? (
    <Box className={classes.selfMargins}>
      <Box display="flex" alignItems="flex-end">
        <Box className={classes.selfChatText} flexGrow={1}>
          <Typography variant="body2" className={classes.textFormat}>
            {props.message.tempText
              ? `${props.message.tempText}...`
              : props.message.text}
          </Typography>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className={classes.margins}>
      <Box display="flex" alignItems="flex-end">
        <Box>
          <Box className={classes.avatarButton}>
            <UserAvatar size="small" user={props.message.visitor} />
          </Box>
        </Box>
        <Box flexGrow={1} className={classes.chatHolder}>
          <Box className={classes.chatText}>
            <Typography variant="body2" className={classes.textFormat}>
              {props.message.text}
            </Typography>
          </Box>
          <Box className={classes.userNameHolder}>
            <Button
              className={classes.userName}
              component="a"
              onClick={() => props.openParticipant(props.message.visitor)}
            >
              {props.message.visitor.full_name}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(ThreadMessageBubble, () => {
  return true;
});
