import { makeStyles, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ColorPalette } from '../color-palette';
import { ColorPicker } from '../color-picker';

export interface IColorSetting {
  name: string;
  label: string;
  defaultValue: any;
  palette?: string[];
}

export interface IProps {
  helpText?: string;
  settings: IColorSetting[];
}

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(2),
  },
  pickerAction: {
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}));

/**
 * ColorControl is a composition of ColorPicker and ColorPalette
 * implemented with react-hook-form Controller
 *
 * @link https://react-hook-form.com/api/usecontroller/controller
 */
export const ColorControl = ({ helpText, settings }: IProps) => {
  const classes = useStyles();

  const { control } = useFormContext();

  return (
    <Fragment>
      <div className={classes.flexContainer}>
        {settings.map(({ name, label, defaultValue, palette }, index) => (
          <Controller
            key={index}
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
              <ColorPalette
                label={label}
                color={value}
                palette={palette}
                setColor={onChange}
              >
                <ColorPicker color={value} setColor={onChange}>
                  {/*({ setAnchor }) => (
                    <Typography
                      variant="body2"
                      color="primary"
                      classes={{ root: classes.pickerAction }}
                      onClick={setAnchor}
                    >
                      Custom
                    </Typography>
                  )*/}
                </ColorPicker>
              </ColorPalette>
            )}
          />
        ))}
      </div>
      {helpText && <Typography variant="caption">{helpText}</Typography>}
    </Fragment>
  );
};
