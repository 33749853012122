import axios from 'axios';
import { api } from '@/js/manager/utils/api';

export const fetchMediaService = (conferenceId: string) =>
  api.get(`/conferences/${conferenceId}/mediafiles`);

export const fetchSignedUploadUrlService = (
  conferenceId: string,
  folder: string,
  filename: string,
  type: string
) =>
  api.get(`/conferences/${conferenceId}/upload/${folder}`, {
    params: { filename, type },
  });

export const addMediaService = (conferenceId: string, name: string, filename: string) =>
  api.post(`/conferences/${conferenceId}/media`, { name, filename });

export const updateMediaService = (mediaId: string) => api.put(`/media/${mediaId}/`);

export const uploadToS3 = (url: string, file: any) => {
  return axios.put(url, file);
};