import React from 'react';
import { INode, INodeProps } from '@/types/node';
import { IContent } from './contract';
import { Render as NodeRender } from '../embed/render';
import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Render = (props: IProps) => {
  const passProps = { ...props };
  passProps.node.override.root = {
    ...passProps.node.override.root,
    '& iframe': { width: '100%', borderRadius: 8 },
  };
  return (
    <WidgetWrapper>
      <NodeRender {...passProps} />
    </WidgetWrapper>
  );
};
