import {
  Box,
  Button,
  Checkbox,
  Container,
  Icon,
  Select,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import C from '../../../../../shared/constants/general';
import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';
import { PusherContext } from '../../context/PusherContext';
import { withErrorBoundary } from '../../../../error-boundary';
import { Discussion } from './Discussion';
import { DiscussionDetail } from './DiscussionDetail';
import { useCoeolytics } from '@/js/shared/context/CoeolyticsHooks';
import { ConditionalRenderer } from '@/components/conditional-renderer';
import { Pagination } from '@material-ui/lab';
import { usePaginator } from '@/hooks/usePaginator';

const useStyles = makeStyles((theme) => ({
  holder: {
    minHeight: 150,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
  field: {
    backgroundColor: 'rgba(90, 90, 90, 0.7)',
  },
  menu: {
    paddingBottom: 8,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  checkbox: {
    padding: 13,
  },
}));

const ErrorDisplay = () => (
  <Box p={1} align="center" color="error.main">
    Sorry, there was an error fetching discussions
  </Box>
);

const DiscussionList = withErrorBoundary({
  FallbackComponent: ErrorDisplay,
})(
  ({
    listType = 'all',
    topicSlug = '',
    location,
    history,
    isEmbedded = false,
    alwaysSmall = false,
    connectionType,
    connectionId,
    hideMenu = false,
    hideNewPost = false,
    title,
    topic = null,
  }) => {
    const PAGINATION_LIMIT = 50;

    const classes = useStyles();
    const { authState } = useContext(AuthContext);
    const { openNewDiscussion } = useContext(ConferenceContext);
    const { pusher, ROOM_TYPE, actions: pusherActions } = useContext(
      PusherContext
    ); // Hämta Context
    const coeoLytics = useCoeolytics();

    const [discussions, setDiscussions] = useState([]);
    // const [selectedMenu, setSelectedMenu] = useState('all');
    const [discussionId, setDiscussionId] = useState(
      queryString.parse(location.search).discussion
    );
    const [editing, setEditing] = useState(false);
    const [checked, setChecked] = useState([]);
    const [topics, setTopics] = useState([]); // Only used for moderators, when editing

    let [page, setPage] = useState(1);

    const count = Math.ceil(discussions.length / PAGINATION_LIMIT);
    const _DATA = usePaginator(discussions, PAGINATION_LIMIT);

    const handleChange = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };

    const toggleChecked = (discussionId) => {
      if (checked.includes(discussionId)) {
        setChecked(checked.filter((c) => c !== discussionId));
      } else {
        setChecked([...checked, discussionId]);
      }
    };

    const isModerator =
      authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;
    // const [editDiscussion, setEditDiscussions] = useState(null);
    // const handleInputChange = (e) => {
    //   const { name, value } = e.target;
    //   setEditDiscussions({ ...editDiscussion, [name]: value });
    // };

    const editAction = (e) => {
      // const nrToMove = checked.length;
      const [action, param] = e.target.options[
        e.target.selectedIndex
      ].value.split('__');
      if (
        action === 'delete' &&
        !window.confirm(
          `Sure you want to completly delete these ${checked.length} posts?`
        )
      ) {
        return;
      }
      axios
        .put(
          `/api/visitor/conferences/${window.CONFERENCE_ID}/updatediscussions`,
          {
            action: action,
            param: param,
            ids: checked,
          }
        )
        .then((response) => {
          // const nrAdded = parseInt(response.data.added);
          // const duplicates = nrToMove - nrAdded;
          // openSnackbar(
          //   `${nrAdded > 0 ? `Added ${nrAdded} to "${roomTitle}"` : ``} ${
          //     duplicates ? `(Ignored ${duplicates} duplicates)` : ''
          //   }`,
          //   8000
          // );
          refresh();
          setChecked([]);
          // refreshEntityList();
        });
    };

    const newDiscussion = () => {
      // setEditDiscussions({ title: '', url: '', text: '' });
      openNewDiscussion({
        discussion: {
          title: '',
          url: '',
          text: '',
          state: 'show',
          pinned: 0,
          connectionType,
          connectionId,
          topicSlug: topicSlug || (topic && topic.slug) || null,
        },
        topicTitle: topic ? topic.title : title || 'General',
        callback: (data) => {
          const topicData = topic || topics.find((t) => t.slug === topicSlug);
          if (topicData && topicData.ai) {
            gotoDiscussion(data.id);
          }
        },
      });
    };

    const getApiUrl = () => {
      switch (listType) {
        // Visitors own disussions
        case 'my':
          return `mydiscussions?`;
        // Only show direct connections (in expo, event)
        case 'connection':
          return `connecteddiscussions?connectionType=${connectionType}&connectionId=${connectionId}`;
        // List all for a topic
        case 'topic':
          return `topicdiscussions?topic=${topicSlug}`;
        // All discussions (main forum list)
        default:
          return `discussions?`;
      }
    };

    const refresh = () => {
      const apiUrl = `/api/visitor/conferences/${
        window.CONFERENCE_ID
      }/${getApiUrl()}`;

      axios.get(apiUrl).then((response) => {
        setDiscussions(response.data);
      });

      isModerator &&
        axios
          .get(
            `/api/visitor/conferences/${window.CONFERENCE_ID}/discussiontopics`
          )
          .then((response) => {
            setTopics(response.data);
          });
    };
    useEffect(() => {
      if (!discussionId) {
        refresh();
      }
    }, [discussionId, connectionId, topicSlug]);

    useEffect(() => {
      if (history.action != 'POP') {
        coeoLytics.page();
      }
      setDiscussionId(queryString.parse(location.search).discussion);
    }, [queryString.parse(location.search).discussion]);

    const backToList = () => {
      // TODO: Pass scroll position as state using navigate()
      // https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically

      if (queryString.parse(location.search).discussion) {
        // If in forum
        history.push(history.location.pathname);
      } else {
        // If embedded
        setDiscussionId(null);
      }
    };

    const gotoDiscussion = (id) => {
      if (!isEmbedded) {
        history.push(`${history.location.pathname}?discussion=${id}`);
      } else {
        setDiscussionId(id);
      }
    };

    const updateDiscussionReplies = (data) => {
      try {
        setDiscussions((oldDiscusions) => {
          const newDiscussions = [...oldDiscusions];
          const discussionIndex = newDiscussions.findIndex(
            (d) => d.id === data.id
          );
          if (discussionIndex !== -1) {
            newDiscussions[discussionIndex] = {
              ...newDiscussions[discussionIndex],
              ...data,
            };
          }
          newDiscussions.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          return newDiscussions;
        });
      } catch (e) {
        console.log('Could not update discussion');
      }
    };

    useEffect(() => {
      const pusherRoomId =
        connectionType && connectionId
          ? `${window.CONFERENCE_ID}-${connectionType}-${connectionId}` // Listen to only connected updates
          : topicSlug
          ? `${window.CONFERENCE_ID}-topic-${topicSlug}` // Listen to all in topic
          : window.CONFERENCE_ID; // Full forum

      if (pusher.current) {
        const entityChannel = pusherActions.subscribe(
          ROOM_TYPE.DISCUSSIONLIST,
          pusherRoomId
        );
        entityChannel.bind('new-discussion', () => {
          refresh();
        });
        entityChannel.bind('discussion-reply-update', updateDiscussionReplies);
      }
      return () => {
        if (pusher.current) {
          pusherActions.unSubscribe(ROOM_TYPE.DISCUSSIONLIST, pusherRoomId);
        }
      };
    }, [!!pusher.current, discussionId, connectionId, topicSlug]);

    return (
      <Box className={classes.holder}>
        {discussionId ? (
          <Box>
            <Box pb={1}>
              <Button
                onClick={backToList}
                startIcon={<Icon>arrow_back_ios</Icon>}
                size="small"
              >
                Back to list
              </Button>
            </Box>
            <Discussion
              alwaysSmall={alwaysSmall}
              discussionId={discussionId}
              isEmbedded={isEmbedded}
            />
          </Box>
        ) : (
          <>
            <Box flexGrow={1}>
              <Box className={classes.menu}>
                <Box pt={1} flexGrow={1}>
                  {!topic && title && (
                    <Typography variant="h6">{title}</Typography>
                  )}
                  {topic && topicSlug !== '' && (
                    <Box>
                      <Typography variant="h6">
                        {title || topic.title}
                      </Typography>
                      {topic.description && (
                        <Typography variant="body1">
                          {topic.description}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {isModerator && (
                    <Box display="flex">
                      {editing && (
                        <Box pr={2}>
                          <Checkbox
                            className={classes.checkbox}
                            size="small"
                            checked={checked.length === discussions.length}
                            onClick={() =>
                              checked.length === discussions.length
                                ? setChecked([])
                                : setChecked(discussions.map((p) => p.id))
                            }
                          />{' '}
                          <strong>All</strong>
                        </Box>
                      )}
                      {editing && checked.length > 0 && (
                        <Box pr={2}>
                          ({checked.length}) &nbsp;
                          <Select
                            native
                            defaultValue=""
                            color="secondary"
                            value="none"
                            label="Move to"
                            className={classes.select}
                            onChange={editAction}
                          >
                            <option value="none">Action:</option>
                            <optgroup label="Set state to:">
                              <option value={`setstate__show`}>Show</option>
                              <option value={`setstate__suggested`}>
                                Suggested
                              </option>
                              <option value={`setstate__hidden`}>Hidden</option>
                            </optgroup>

                            <optgroup label="Move to topic:">
                              {topics.map((t) => (
                                <option key={t.id} value={`settopic__${t.id}`}>
                                  #{t.title}
                                </option>
                              ))}
                            </optgroup>
                            <optgroup label="Hard changes:">
                              <option value={`delete`}>DELETE selected</option>
                            </optgroup>
                          </Select>
                        </Box>
                      )}

                      <Button
                        size="small"
                        color="secondary"
                        startIcon={<Icon>edit</Icon>}
                        onClick={() => setEditing(!editing)}
                      >
                        {editing ? 'Stop edit' : 'Edit posts'}
                      </Button>
                    </Box>
                  )}
                </Box>
                {!hideNewPost &&
                  !(topic && topic.poststate === 'closed' && !isModerator) && (
                    <Box>
                      <Button
                        onClick={newDiscussion}
                        startIcon={<Icon>add_circle</Icon>}
                        color="primary"
                      >
                        {topic && topic.poststate === 'suggest' && !isModerator
                          ? 'Post Suggestion'
                          : 'New post'}
                      </Button>
                    </Box>
                  )}
              </Box>

              {_DATA.currentData().length === 0 ? (
                <Box p={2} align="center">
                  No posts yet
                </Box>
              ) : (
                <Box>
                  {_DATA.currentData().map((discussion) => (
                    <ConditionalRenderer
                      key={discussion.id}
                      condition={editing}
                      render={(children) => (
                        <Box display="flex" alignItems="flex-start">
                          <Box>
                            <Checkbox
                              className={classes.checkbox}
                              size="small"
                              checked={checked.includes(discussion.id)}
                              onClick={() => toggleChecked(discussion.id)}
                            />
                          </Box>
                          <Box flexGrow={1}>{children}</Box>
                        </Box>
                      )}
                    >
                      <DiscussionDetail
                        gotoDiscussion={gotoDiscussion}
                        path={location.pathname}
                        discussion={discussion}
                        isEmbedded={isEmbedded}
                        alwaysSmall={alwaysSmall}
                      />
                    </ConditionalRenderer>
                  ))}
                  {discussions.length > PAGINATION_LIMIT && (
                    <Box display="flex" justifyContent="center">
                      <Pagination
                        count={count}
                        size="small"
                        page={page}
                        color="primary"
                        onChange={handleChange}
                      />
                      <br />
                      <br />
                    </Box>
                  )}
                  <br />
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default DiscussionList;
