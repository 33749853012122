import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { MediaPlaceholder } from '@/components/media-placeholder';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { LocalLinkSelector } from '@/js/shared/components/ui/LocalLinkSelector';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Typography } from '@material-ui/core';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type: settingType,
    override,
    content: { label, description, link },
  },
  conferenceId,
}: IProps) => {
  const { watch, setValue } = useFormContext();

  const setLink = (linkUrl: string) => {
    setValue('link.targetUrl', linkUrl);
  };
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <InputControl
          fullWidth
          name="label"
          label="Button Label"
          defaultValue={label}
          variant="outlined"
        />
        <InputControl
          fullWidth
          name="description"
          label="Description (below button)"
          defaultValue={description}
          variant="outlined"
        />

        <InputControl
          name="link.targetUrl"
          defaultValue={link.targetUrl}
          fullWidth
          variant="outlined"
          label="URL"
        />
        <div>
          <LocalLinkSelector
            userType="manager"
            conferenceId={conferenceId}
            ButtonProps={{ color: 'primary' }}
            dataCallback={setLink}
          />
        </div>
        <SwitchControl
          name="link.target"
          label="Open link in new tab?"
          defaultValue={link.target}
          options={['_blank', '_self']}
        />

        <InputControl type="hidden" name="link.targetRel" value="noreferrer" />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(settingType, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(settingType, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
