import React from 'react';
import ErrorBoundary from './ErrorBoundary';

interface IProps {
  FallbackComponent?: React.FunctionComponent<any>;
}

const withErrorBoundary = (fallbackSettings: IProps) => <
  Props extends object
>(
  WrappedComponent: React.ComponentType<Props>
) => {
  const WithErrorBoundary = (props: Props) => {
    return (
      <ErrorBoundary {...fallbackSettings}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    );
  };

  return WithErrorBoundary;
};

export { withErrorBoundary };
