import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      textTagSelect,
      countryCode,
      hiddenFields = {
        title: false,
        company: false,
        city: false,
        country: false,
        phone: false,
        ecard: false,
        description: false,
      },
    },
  },
}: IProps) => {
  return (
    <>
      <SettingsPanel label="Content" initialOpen>
        <RadioGroupControl
          row
          label="Dates to list"
          name="countryCode"
          defaultValue={countryCode}
          options={[
            {
              label: 'English',
              value: 'EN',
            },
            {
              label: 'Swedish',
              value: 'SE',
            },
          ]}
        />
        <InputControl
          name="textTagSelect"
          defaultValue={textTagSelect}
          fullWidth
          variant="outlined"
          label="Override Tag description"
        />
        What input fiedls should show?
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.title"
              label="Title"
              defaultValue={hiddenFields.title}
              options={[false, true]}
            />
          </Grid>
          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.company"
              label="Organisation"
              defaultValue={hiddenFields.company}
              options={[false, true]}
            />
          </Grid>

          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.city"
              label="City"
              defaultValue={hiddenFields.city}
              options={[false, true]}
            />
          </Grid>
          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.country"
              label="Country"
              defaultValue={hiddenFields.country}
              options={[false, true]}
            />
          </Grid>
          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.phone"
              label="Phone"
              defaultValue={hiddenFields.phone}
              options={[false, true]}
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchControl
              name="hiddenFields.ecard"
              label="E Card (image / avatar)"
              defaultValue={hiddenFields.ecard}
              options={[false, true]}
            />
          </Grid>
          <Grid item xs={6}>
            <SwitchControl
              name="hiddenFields.description"
              label="Description"
              defaultValue={hiddenFields.description}
              options={[false, true]}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
