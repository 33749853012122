import { useEffect, useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-mux';
//require('../js/shared/utils/video-offset.js');
import '../js/shared/utils/video-offset';

const qualityLogger = (player: VideoJsPlayer): void => {
  // @ts-expect-error: Wrong typings
  const qualityLevels = player.qualityLevels();
  qualityLevels.on('change', () => {
    console.log('Quality Level changed!');
    console.log('New level:', qualityLevels[qualityLevels.selectedIndex]);
  });
};

const defaultOptions: VideoJsPlayerOptions = {
  autoplay: false,
  controls: true,
  fluid: false,
  liveui: false,
  preload: 'none',
  responsive: false,
  html5: {
    hlsjsConfig: {
      liveSyncDurationCount: 3,
    },
    vhs: {
      limitRenditionByPlayerDimensions: false,
      useDevicePixelRatio: true,
      overrideNative: !videojs.browser.IS_SAFARI,
      bandwidth: 10000000,
      experimentalBufferBasedABR: true,
    },
  },
  userActions: {
    doubleClick: () => false,
  },
};

export const useVideoJs = (
  options: VideoJsPlayerOptions & {
    liveTracker?: { trackingThreshold: number };
  },
  onReady?: (player: VideoJsPlayer) => any
) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const playerRef = useRef<VideoJsPlayer | null>(null);

  const onPlayerReady = () => {
    const player = playerRef.current as VideoJsPlayer;

    if (player != null) {
      player.on('ended', () => console.log('video ended!'));

      player.on('error', (error: any) => console.log('Videojs error', error));

      if (player.options_.liveui) {
        player.one('play', () => {
          player.on('play', () => player.liveTracker.seekToLiveEdge());
        });
      }
      if (window.MediaSource) {
        player.src(options.sources as videojs.Tech.SourceObject[]);
      }

      qualityLogger(player);
      onReady && onReady(player);
    }
  };

  const playerDestroy = () => {
    if (playerRef.current === null) return;

    playerRef.current.reset();
    playerRef.current = null;
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    if (playerRef.current == null) {
      const mergedOptions = {
        ...defaultOptions,
        ...options,
      };

      playerRef.current = videojs(videoElement, mergedOptions, onPlayerReady);
    }
  }, [videoRef]);

  useEffect(() => {
    return playerDestroy;
  }, [playerRef]);

  return {
    videoRef,
    playerRef,
    playerDestroy,
  };
};
