import { ButtonGroupControl } from '@/components/buttongroup-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid, Icon } from '@material-ui/core';
import React, { FC, Fragment, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { defaultDisplay, alignment, verticalSpacing },
  },
}: IProps) => {
  const { VERTICAL_SPACE_OPTIONS, ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;

  return (
    <Fragment>
      <SettingsPanel label="Display settings" initialOpen>
        <SelectControl
          label="Initial display"
          name="defaultDisplay"
          defaultValue={defaultDisplay}
          options={[
            {
              label: 'Auto',
              value: 'auto',
            },
            {
              label: 'List',
              value: 'list',
            },
            {
              label: 'Grid',
              value: 'grid',
            },
          ]}
        />
      </SettingsPanel>
      <SettingsPanel label="Alignment Settings" initialOpen>
        <Grid container spacing={4}>
          <Grid item>
            <ButtonGroupControl
              label="Vertical alignment"
              name="alignment.vertical"
              defaultValue={alignment.vertical}
              options={[
                {
                  label: 'Top',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>vertical_align_top</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>vertical_align_center</Icon>,
                },
                {
                  label: 'Bottom',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>vertical_align_bottom</Icon>,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroupControl
              label="Horizontal alignment"
              name="alignment.horizontal"
              defaultValue={alignment.horizontal}
              options={[
                {
                  label: 'Left',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>format_align_left</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>format_align_justify</Icon>,
                },
                {
                  label: 'Right',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>format_align_right</Icon>,
                },
              ]}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel label="Vertical Space Settings" initialOpen>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
