import { JSONControl } from '@/components/json-control';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid } from '@material-ui/core';
import React, { FC, Fragment, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { cardSize, verticalSpacing },
  },
}: IProps) => {
  const { VERTICAL_SPACE_OPTIONS } = SETTINGS_DEFAULTS;

  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <RadioGroupControl
          row
          label=""
          name="cardSize"
          defaultValue={cardSize}
          options={[
            {
              label: 'Full width',
              value: '12',
            },
            {
              label: '1/2',
              value: '6',
            },
            {
              label: '1/3',
              value: '4',
            },
            {
              label: '1/4',
              value: '3',
            },
          ]}
        />
      </SettingsPanel>
      <SettingsPanel label="Vertical Space Settings" initialOpen>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
