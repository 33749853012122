import { INodeTemplate } from '@/types/node';
import { Button, Icon, IconButton, makeStyles } from '@material-ui/core';
import { useRef } from 'react';

export interface NodeTarget {
  target: HTMLElement;
  id: string;
  template: INodeTemplate;
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
  },
  corner: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  line: {
    backgroundColor: '#f00',
    position: 'absolute',
  },
  button: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    padding: '2px 8px',
    fontSize: '0.7rem',
    color: 'white',
    backgroundColor: '#f00',
    position: 'absolute',
    zIndex: 10000,
    '&:hover': {
      backgroundColor: '#c00',
    },
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#f00',
    position: 'absolute',
    zIndex: 10000,
    borderRadius: 0,
    padding: 1,
    width: 30,
    '&:hover': {
      backgroundColor: '#c00',
    },
  },
  addButtonHolder: {
    position: 'absolute',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
  addButton: {
    // position: 'absolute',
    backgroundColor: '#f00',
    color: 'white',

    '&:hover': {
      backgroundColor: '#c00',
    },
  },
}));

export const NodeEditMarker = ({
  nodeTarget: { target, id, template },
  callback,
}: {
  nodeTarget?: NodeTarget;
  callback?: (id: string, action: string) => void;
}): JSX.Element | null => {
  const classes = useStyles();
  const corderRef = useRef<HTMLDivElement>(null);

  if (!target) {
    return null;
  }

  var viewportOffset = target.getBoundingClientRect();
  // If page is inside scrollable LiveContainer, the offset will be relative to that
  var parentOffset = corderRef.current
    ? corderRef.current.getBoundingClientRect()
    : { top: 0, left: 0 };

  const rect = {
    top: viewportOffset.top - parentOffset.top,
    left: viewportOffset.left - parentOffset.left,
    // top: target.offsetTop + window.scrollY,
    // left: target.offsetLeft + window.scrollX,
    width: target.offsetWidth,
    height: target.offsetHeight,
  };

  return (
    <>
      <div className={classes.corner} ref={corderRef}></div>
      <Button
        className={classes.button}
        startIcon={<Icon>settings</Icon>}
        size="small"
        variant="contained"
        color="primary"
        style={{
          top: rect.top,
          left: rect.left,
        }}
        onClick={() => {
          console.log('Edit', id);
          callback(id, 'edit');
        }}
      >
        {template?.name}
      </Button>
      {template.supports?.hasOwnProperty('children') && (
        <div
          className={classes.addButtonHolder}
          style={{
            top: rect.top + rect.height - 15,
            left: rect.left,
            width: rect.width,
          }}
        >
          <IconButton
            className={classes.addButton}
            size="small"
            variant="contained"
            onClick={() => {
              callback(id, 'add');
            }}
          >
            <Icon>add_circle</Icon>
          </IconButton>
        </div>
      )}
      <IconButton
        className={classes.deleteButton}
        size="small"
        variant="contained"
        onClick={() => {
          callback(id, 'delete');
        }}
        style={{
          top: rect.top,
          left: rect.left + rect.width - 30,
        }}
      >
        <Icon>delete</Icon>
      </IconButton>
      <div
        className={classes.line}
        style={{
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: 1,
        }}
      />
      <div
        className={classes.line}
        style={{
          top: rect.top,
          left: rect.left,
          width: 1,
          height: rect.height,
        }}
      />
      <div
        className={classes.line}
        style={{
          top: rect.top,
          left: rect.left + rect.width,
          width: 1,
          height: rect.height,
        }}
      />
      <div
        className={classes.line}
        style={{
          top: rect.top + rect.height,
          left: rect.left,
          width: rect.width,
          height: 1,
        }}
      />
    </>
  );
};
NodeEditMarker.displayName = 'NodeEditMarker';
