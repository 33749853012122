import { ColorControl } from '@/components/color-control';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { MediaPlaceholder } from '@/components/media-placeholder';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { useConferencePalette } from '@/hooks/useConferencePalette';
import { LocalLinkSelector } from '@/js/shared/components/ui/LocalLinkSelector';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Box, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      text = '',
      link,
      icon = '',
      textColor,
      backgroundColor,
      backgroundImage,
      backgroundSize = 'cover',
      maxWidth = '1',
      minWidth = '1',
      visibleForTicket = '',
    },
  },
  conferenceId,
}: IProps) => {
  const { watch, setValue } = useFormContext();

  const { getSwatch } = useConferencePalette('live');

  const watchTarget = watch('link.target', link.target);
  const iconStr = watch('icon', icon);

  useEffect(() => {
    setValue(
      'link.targetRel',
      watchTarget === '_blank' ? 'noreferrer' : undefined
    );
  }, [watchTarget]);

  return (
    <Fragment>
      <SettingsPanel label="Buttons per row" initialOpen>
        <Box>
          <Box>Large screen:</Box>
          <RadioGroupControl
            row
            label=""
            name="maxWidth"
            defaultValue={maxWidth}
            options={[
              {
                label: '■',
                value: '1',
              },
              {
                label: '■ ■',
                value: '2',
              },
              {
                label: '■ ■ ■',
                value: '3',
              },
              {
                label: '■ ■ ■ ■',
                value: '4',
              },
            ]}
          />
          <Box>Mobile:</Box>
          <RadioGroupControl
            row
            label=""
            name="minWidth"
            defaultValue={minWidth}
            options={[
              {
                label: '■',
                value: '1',
              },
              {
                label: '■ ■',
                value: '2',
              },
              {
                label: '■ ■ ■',
                value: '3',
              },
              {
                label: '■ ■ ■ ■',
                value: '4',
              },
            ]}
          />
        </Box>
      </SettingsPanel>
      <SettingsPanel label="Content Settings" initialOpen>
        <InputControl
          name="text"
          defaultValue={text}
          fullWidth
          variant="outlined"
          label="Text content"
        />
        <Box display="flex" alignItems="center">
          <Box>
            <InputControl
              name="icon"
              defaultValue={icon}
              style={{ width: 150 }}
              variant="outlined"
              label="Card icon"
            />
          </Box>
          <Box flexGrow={1} pl={1}>
            <Icon>{iconStr}</Icon>
          </Box>
        </Box>

        <div>
          {[
            'star',
            'apps',
            'info',
            'people',
            'home',
            'handshake',
            'groups',
            'play_circle_filled',
            'newspaper',
            'forum',
          ].map((ic) => (
            <IconButton
              key={ic}
              size="small"
              onClick={() => setValue('icon', ic)}
            >
              <Icon>{ic}</Icon>
            </IconButton>
          ))}{' '}
          <a
            href="https://fonts.google.com/icons"
            target="_blank"
            rel="noreferrer"
          >
            Find more..
          </a>
        </div>
      </SettingsPanel>
      <SettingsPanel label="Link Settings" initialOpen>
        <InputControl
          name="link.targetUrl"
          defaultValue={link.targetUrl}
          fullWidth
          variant="outlined"
          label="URL"
          InputLabelProps={{ shrink: true }}
        />
        <div>
          <LocalLinkSelector
            userType="manager"
            conferenceId={conferenceId}
            ButtonProps={{ color: 'primary' }}
            dataCallback={(linkUrl) => setValue('link.targetUrl', linkUrl)}
          />
        </div>
        <SwitchControl
          name="link.target"
          label="Open link in new tab?"
          defaultValue={link.target}
          options={['_blank', '_self']}
        />

        <InputControl
          disabled
          type="hidden"
          name="link.targetRel"
          defaultValue={link.targetRel}
        />
      </SettingsPanel>
      <SettingsPanel label="Color Settings" initialOpen>
        <ColorControl
          helpText="Select from your dynamic theme colors or specify a custom static color."
          settings={[
            {
              name: 'textColor',
              label: 'Text Color',
              defaultValue: textColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
            {
              name: 'backgroundColor',
              label: 'Background Color',
              defaultValue: backgroundColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
          ]}
        />
      </SettingsPanel>
      <SettingsPanel label="Image Settings" initialOpen>
        <Grid container>
          <Grid item md={4}>
            <Controller
              name="backgroundImage"
              defaultValue={backgroundImage}
              render={({ field: { onChange, value } }) => (
                <MediaPlaceholder
                  onChange={onChange}
                  value={value}
                  label="Background image"
                  conferenceId={conferenceId}
                />
              )}
            />
          </Grid>
          <Grid item md={8}>
            <SelectControl
              name="backgroundSize"
              defaultValue={backgroundSize}
              label="Image Size"
              options={[
                {
                  label: 'Cover',
                  value: 'cover',
                },
                {
                  label: 'Contain',
                  value: 'contain',
                },
              ]}
            />
            <Typography variant="caption">
              Cover scales image to fill the entire card while Contain keeps the
              images original size.
            </Typography>
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel label="Only visible for Ticket(s), advanced" initialOpen>
        <InputControl
          name="visibleForTicket"
          defaultValue={visibleForTicket}
          fullWidth
          variant="outlined"
          label="Ticket name"
          helperText='Separate with "," if multiple'
        />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
