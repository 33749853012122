export interface Action<T = any> {
    type: T
}

export interface AnyAction extends Action {
    [key: string]: any
}

export type Reducer<S = any, A = AnyAction> = (
    state: S | undefined,
    action: A
) => S

/**
 * 
 * combines reducers to a shared state and dispatcher
 * 
 * @param reducers 
 * @returns combined reducers
 */
export const combineReducers = <S, A = AnyAction>(...reducers: Reducer<any, A>[]): Reducer<S, A> => (state, action): S => {
    return reducers.reduce((acc, reducer, index) => ({
        ...acc,
        [Object.keys(acc)[index]]: reducer(Object.values(acc)[index], action)
    }), state);
}