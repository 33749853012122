import { makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { INode, INodeProps } from '@/types/node';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
// import { ManagerMarkdownRender } from '@/js/shared/components/markdown/ManagerMarkdownRender';
import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import { AuthContext } from '@/js/shared/context/AuthContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'html'> & { override: Record<string, any> }
>((theme) => ({
  markdownContainer: ({ alignment, verticalSpacing, override }) => ({
    width: '100%',
    textAlign:
      alignment.horizontal === 'flex-start'
        ? 'left'
        : alignment.horizontal === 'flex-end'
        ? 'right'
        : alignment.horizontal,
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    ...override.root,
  }),
}));

/**
 * Embed Node description...
 */
export const Render = ({
  node: {
    id,
    override,
    content: { text, alignment, verticalSpacing },
  },
}: IProps) => {
  const classes = useStyles({ alignment, verticalSpacing, override });
  const { authState } = useContext(AuthContext);

  return (
    <div className={classes.markdownContainer} {...useNodeProps(id)}>
      <MarkdownRender text={text} templateData={authState.user} />
    </div>
  );
};
