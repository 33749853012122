import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../forum-reply-text/edit';
import {
  Render as ForumReplyTextRender,
  template as forumReplyTextTemplate,
} from '../forum-reply-text';

const template: INodeTemplate = {
  version: 1,
  name: 'Reply Text',
  icon: forumReplyTextTemplate.icon,
  type: 'forum-reply-text-widget',
  tag: 'forum',
  content: {
    widgetName: 'Forum Reply Text',
    ...forumReplyTextTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(ForumReplyTextRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
