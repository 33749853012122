import { withErrorBoundary } from '@/error-boundary';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Anchorme } from 'react-anchorme';
import { Controller, useFormContext } from 'react-hook-form';

const CustomTypography = ({ field }) => {
  return (
    <Box align="center" p={1}>
      <Typography>{field.label}</Typography>
    </Box>
  );
};
CustomTypography.displayName = 'CustomTypography';

const useStyles = makeStyles((theme) => {
  return {
    menu: {
      // backgroundColor: '#fff',
      backgroundColor: theme.palette.background.default,
      marginTop: 13,
    },
  };
});

const CustomSelect = ({ field, register, fieldClass, control }) => {
  const classes = useStyles();
  return (
    <Controller
      control={control}
      {...register(`custom.${field.name}`)}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <InputLabel shrink={true} id={`custom.${field.name}`}>
            {field.label}
          </InputLabel>
          <Select
            labelId={`custom.${field.name}`}
            // {...register(`custom.${field.name}`)}
            className={fieldClass}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            // defaultValue={field.default || null}
            value={value}
            // SelectProps={{
            //   native: true,
            // }}
            // label={field.label}
            onChange={onChange}
            classes={{ selectMenu: classes.menu }}
            MenuProps={{
              classes: {
                paper: classes.menu,
              },
            }}
            required={field.required ? true : false}
          >
            {!field.default && <MenuItem value={''}> </MenuItem>}
            {field.options.map(({ label, value }, index) => (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
CustomSelect.displayName = 'CustomSelect';

const checkboxStyles = makeStyles((theme) => {
  return {
    check: {
      verticalAlign: 'middle',
      marginRight: 0,
    },
  };
});

const CustomCheckbox = ({ field, register, control }) => {
  const classes = checkboxStyles();

  return (
    <>
      <Controller
        control={control}
        {...register(`custom.${field.name}`)}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            value={field.value}
            className={classes.check}
            control={
              <Checkbox
                inputProps={{
                  required: field.required ? true : false,
                }}
                checked={value}
                onChange={onChange}
              />
            }
          />
        )}
      />
      <Anchorme
        truncate={20}
        linkComponent={Link}
        target="_blank"
        rel="noreferrer noopener"
      >
        {field.label}
      </Anchorme>
    </>
  );
};
CustomCheckbox.displayName = 'CustomCheckbox';

const CustomTextfield = ({ field, register, fieldClass }) => {
  return (
    <TextField
      {...register(`custom.${field.name}`)}
      label={field.label}
      className={fieldClass}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      required={field.required ? true : false}
    />
  );
};
CustomTextfield.displayName = 'RegisterCustomFields';

const FieldTypes = {
  'text-field': CustomTextfield,
  'display-text': CustomTypography,
  checkbox: CustomCheckbox,
  select: CustomSelect,
};

const ErrorDisplay = () => (
  <Box p={1} align="center" color="error.main">
    Sorry, could not display custom registration fields
  </Box>
);

export const RegisterCustomFields = withErrorBoundary({
  FallbackComponent: ErrorDisplay,
})(({ fields, register, control, fieldClass, setValue }) => {
  try {
    return fields.map((field, index) => {
      const CustomField = Object.prototype.hasOwnProperty.call(
        FieldTypes,
        field.component
      )
        ? FieldTypes[field.component]
        : () => (
            <Box color="error.main">
              Custom field miss match ({field.component})
            </Box>
          );

      return (
        <Grid item xs={12} md={field.cols || null} key={field.name || index}>
          <CustomField
            field={field}
            register={register}
            control={control}
            fieldClass={fieldClass}
            setValue={setValue}
          />
          {field.description && <Box p={1}>{field.description}</Box>}
        </Grid>
      );
    });
  } catch {
    <Box color="error.main">Custom field error</Box>;
  }
});
RegisterCustomFields.displayName = 'RegisterCustomFields';
