import { InputControl } from '@/components/input-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { EditChildren, SettingsContainer } from '@/node-editor/components';
import { INode, INodeProps } from '@/types/node';
import { WIDGET_AREAS } from '@/widget-editor/utilities';
import React, { Fragment } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const Settings = ({
  node: {
    content: { areaName, areaLabel },
  },
}: IProps) => {
  const areas = WIDGET_AREAS.map((name) => {
    const label = name.split('-').join(' ');

    return {
      label: label.substring(0, 1).toUpperCase() + label.substring(1),
      value: name,
    };
  });

  return (
    <Fragment>
      <SettingsPanel label="Widget Area" initialOpen>
        <SelectControl
          name="areaName"
          label="Area"
          defaultValue={areaName}
          options={[
            {
              label: 'Select area',
              value: '',
            },
            ...areas,
          ]}
        />
      </SettingsPanel>
      <SettingsPanel label="Widget Label Name" initialOpen>
        <InputControl
          name="areaLabel"
          defaultValue={areaLabel}
          fullWidth
          variant="outlined"
          label="Widget label name"
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Fragment>
      <SettingsContainer>
        <Settings {...props} />
      </SettingsContainer>
      <EditChildren>{children}</EditChildren>
    </Fragment>
  );
};
