import LoginBox from '@/js/shared/components/loginbox';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import {
  Box,
  Button,
  Icon,
  Link as RegularLink,
  makeStyles,
  Theme,
} from '@material-ui/core';
import C from '../../../shared/constants/general';
import React, { useContext, useState } from 'react';
import { INode, INodeProps } from '@/types/node';
import { useNodeProps } from '@/node-editor/hooks';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>({
  root: ({ override }) => ({
    ...override.root,
  }),
  loginLink: {
    textTransform: 'none',
  },
  regButton: {
    marginBottom: 8,
  },
});

export const Render = ({
  node: {
    id,
    override,
    content: { btnText, startWithLogin },
  },
}: IProps) => {
  const classes = useStyles({ override });
  const { conferenceState } = useContext(ConferenceContext);
  const { authState } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(startWithLogin);
  const showOnlyLoginBox =
    conferenceState.conferenceDetailLoaded &&
    conferenceState.conferenceDetail.registration_type !==
      C.CONFERENCE.REG_TYPE.INVITATION &&
    conferenceState.conferenceDetail.registration_type !==
      C.CONFERENCE.REG_TYPE.ANONYMOUS;

  const startLogin = (e: any) => {
    e.preventDefault();
    setShowLogin(true);
  };

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {!conferenceState.conferenceDetailLoaded ? null : authState.userLoggedIn ? (
        <Box textAlign="center" p={2}>
          {window.LANG.WELCOME} {authState.user.first_name}!
          <br />
          <Button href="/live" color="primary" variant="contained">
            {btnText}
          </Button>
        </Box>
      ) : showOnlyLoginBox && !showLogin ? (
        <Box textAlign="center" p={2}>
          <Button
            className={classes.regButton}
            variant="contained"
            color="primary"
            href="/register"
          >
            {window.LANG.REGISTER}
          </Button>
          <br />

          <RegularLink
            href="#"
            color="primary"
            className={classes.loginLink}
            onClick={startLogin}
          >
            {window.LANG.OR_LOGIN}
          </RegularLink>
        </Box>
      ) : (
        <Box textAlign="center" p={2}>
          {showOnlyLoginBox && (
            <Box textAlign="left">
              <Button
                startIcon={<Icon>arrow_left</Icon>}
                onClick={() => setShowLogin(false)}
                size="small"
              >
                {window.LANG.BACK}
              </Button>
            </Box>
          )}
          <LoginBox />
        </Box>
      )}
    </div>
  );
};
