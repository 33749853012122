module.exports = {
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  URLS: {
    IMAGES: process.env.STATIC_IMAGES, // Lambda
  },
  CONFERENCE: {
    REG_TYPE: {
      OPEN: 'open',
      VERIFY: 'verify',
      INVITATION: 'invitation',
      ANONYMOUS: 'anonymous',
    },
    STATES: {
      HIDDEN: 'hidden', // When manager is working on it
      WELCOME: 'welcome', // Show welcome message, but open for moderators and speakers
      OPEN: 'open', // For registration etc.
      ARCHIVED: 'archived', // Only visible for coeo admin
    },
    CHAT_LEVEL: {
      ALL: 'all',
      FOLLOWER: 'follower',
      SPEAKERS: 'speakers',
      MODERATORS: 'moderators',
      NONE: 'none',
    },
  },
  VISITOR: {
    INTERACTION_TYPE: {
      INDIVIDUAL: 'individual',
      GROUP: 'group',
    },
    ACCESS_LEVEL: {
      NORMAL: 'normal',
      MODERATOR: 'moderator',
    },
    PARTICIPATION_LEVEL: {
      VIEW: 'view',
      CONTRIBUTE: 'contribute',
    },
    TICKET: {
      IRL: 'irl',
      ONLINE: 'online',
    },
  },
  PROGRAM_ROOM: {
    VISIBILITY: {
      NORMAL: 'normal',
      HIDE_FROM_PROGRAM: 'hidefromprogram',
    },
    ACCESS: {
      CLOSED: 'closed',
      OPEN_FOR_MANAGERS: 'managers',
      OPEN_FOR_VISITORS: 'visitors',
      OPEN_FOR_PUBLIC: 'public',
    },
    VIDEO_STATE: {
      NONE: 'none',
      STREAMING: 'streaming',
      RECORDING: 'recording',
      FILE: 'file',
      DISABLED: 'disabled',
      STREAMING_NO_EVENT_SELECTED: 'noeventselected',
    },
  },
  EVENT: {
    TYPE: {
      HAS_VIDEO: 0,
      ACTIVITY: 1,
    },
    TIME_STATES: {
      UNDEFINED: 0,
      PRELIMINARY: 1,
      ADJUSTED: 2,
    },
  },
  BREAKOUT: {
    TYPE: {
      URL: 'url',
    },
    VISITOR_TYPE: {
      NORMAL: 'normal',
      MODERATOR: 'moderator',
      SPEAKER: 'speaker',
    },
  },
  EVENT_VISITOR_TYPE: {
    MODERATOR: 'moderator',
    SPEAKER: 'speaker',
    AUDIENCE: 'audience',
  },
  EVENT_VISITOR_AT: {
    ONLINE: 'online',
    IRL: 'irl',
  },
  TIME_REACTION: {
    LIKE: 'like',
  },
  NOTIFICATION: {
    TYPE: {
      EMAIL: 'email',
      SMS: 'sms',
    },
    STATUS: {
      DRAFT: 'draft',
      SENT: 'sent',
    },
    RECIPIENT: {
      STATUS: {
        NONE: 'none',
        SENT: 'sent',
        RECEIVED: 'received',
      },
    },
    DIRECT: {
      PASSWORD_RESET: 'password_reset',
      INVITATION: 'invitation',
      GENERAL: 'general',
      VERIFICAION: 'verification',
      WELCOME: 'welcome',
      NOTIFICATION: 'notification',
      INVITATIONTOKEN: 'invitationtoken',
    },
  },
  ENTITY: {
    LIVE_STATE: {
      OFF: 'off',
      OPEN: 'open',
      QUEUE: 'queue',
    },
  },
  POLL: {
    TYPE: {
      VOTE: 'vote',
      EMBED: 'embed',
      MENTIMETER: 'mentimeter',
    },
    DISPLAY_TYPE: {
      VOTE_DEFAULT: 'vote_default',
      VOTE_SHOWUSERS: 'vote_showusers',
      EMBED_HTML: 'embed_html',
      EMBED_MENTIMETER: 'embed_mentimeter',
    },
  },
  MESSAGES: {
    TYPE: {
      GENERAL: 'general',
    },
    FLAG: {
      NONE: '',
      MOVEDTOASK: 'movedtoask',
      FAVORITE: 'favorite',
    },
    MODERATION: {
      REMOVED: 'removed',
      FLAGGED: 'flagged',
    },
    CHANNELS: {
      MODERATOR: 'moderator',
      STUDIO: 'studio',
      // BACKSTAGE: 'backstage', // Used for chonference chat
    },
  },
  USER: {
    STATUS: {
      UNREGISTERED: 'unregistered', // Ta bort
      TEMPORARY: 'temporary', // Created at invitation
      REGISTERED: 'registered',
      VERIFIED: 'verified', // Email verified
      BANNED: 'banned',
    },
  },
  TAGS: {
    TYPE: {
      SKILL: 'skill',
      SCOUTING: 'scouting',
    },
  },
  WEBHOOKS: {
    TYPE: {
      TRIPPUS: 'trippus',
      HTTP: 'http',
      TICKSTER: 'tickster',
    },
  },
  ISSUE: {
    TYPE: {
      MAIL: 'mail',
      VIDEO: 'video',
      REGISTRATION: 'registration',
      MISC: 'misc',
    },
    REPORTERS: {
      MANUAL: 'manual',
      POSTMARK: 'postmark',
      MUX: 'mux',
      PUSHER: 'pusher',
      TRIPPUS: 'trippus',
      TICKSTER: 'tickster',
      PRETIX: 'pretix',
    },
  },
  EMAILNOTIFICATION: {
    TYPE: {
      ECARD: 'ecard',
      FORUM: 'forum',
      MESSAGES: 'messages',
    },
    STATUS: {
      UNSENT: 'unsent',
      SENT: 'sent',
      FAILED: 'failed',
    },
    ARCHIVED: {
      STATUS: {
        YES: 1,
        NO: 0,
      },
    },
  },
};
