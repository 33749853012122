import { ButtonGroupControl } from '@/components/buttongroup-control';
import { ColorControl } from '@/components/color-control';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { useConferencePalette } from '@/hooks/useConferencePalette';
import { EditChildren, SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import {
  FormControlLabel,
  Grid,
  Icon,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      containerWidth,
      alignment,
      textColor,
      backgroundColor,
      verticalSpacing,
      minHeight = 0,
    },
  },
}: IProps) => {
  const [isFullWidth, setIsFullWidth] = useState<boolean>(
    typeof containerWidth !== 'string'
  );

  const [isHeightAuto, setIsHeightAuto] = useState<boolean>(minHeight === 0);

  const { getSwatch } = useConferencePalette('live');

  const { setValue } = useFormContext();

  const { VERTICAL_SPACE_OPTIONS, ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;
  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <Grid container alignItems="center">
          <Grid item sm={3}>
            <FormControlLabel
              label={`${isFullWidth ? 'Full' : 'Max'} width`}
              control={
                <Switch
                  checked={isFullWidth}
                  onChange={(_e, checked) => {
                    setIsFullWidth(checked);
                    setValue('containerWidth', checked ? !checked : 'xl');
                  }}
                />
              }
            />
          </Grid>
          {!isFullWidth && (
            <Grid item sm={9}>
              <SelectControl
                name="containerWidth"
                label="Sizes"
                defaultValue={containerWidth}
                options={[
                  {
                    label: 'Small',
                    value: 'sm',
                  },
                  {
                    label: 'Medium',
                    value: 'md',
                  },
                  {
                    label: 'Large',
                    value: 'lg',
                  },
                  {
                    label: 'X-Large',
                    value: 'xl',
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
        <Typography paragraph variant="caption">
          Select width of the container, either full width or a contained width
          from options.
        </Typography>
        <Grid container alignItems="center">
          <Grid item sm={3}>
            <FormControlLabel
              label={`${isHeightAuto ? 'Auto' : 'Min'} height`}
              control={
                <Switch
                  checked={isHeightAuto}
                  onChange={(_e, checked) => {
                    setIsHeightAuto(checked);
                    setValue('minHeight', checked ? 0 : 200);
                  }}
                />
              }
            />
          </Grid>
          {!isHeightAuto && (
            <Grid item sm={9}>
              <InputControl
                fullWidth
                name="minHeight"
                label="Min height"
                type="number"
                defaultValue={minHeight}
                variant="outlined"
                style={{ marginBottom: 0 }}
              />
            </Grid>
          )}
        </Grid>
        <Typography paragraph variant="caption">
          Select a minimum pixel height for the container, value 0 gives the
          container an min height of auto.
        </Typography>
      </SettingsPanel>
      <SettingsPanel label="Display Settings" initialOpen>
        <Grid container spacing={4}>
          <Grid item>
            <ButtonGroupControl
              label="Vertical alignment"
              name="alignment.vertical"
              defaultValue={alignment.vertical}
              options={[
                {
                  label: 'Top',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>vertical_align_top</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>vertical_align_center</Icon>,
                },
                {
                  label: 'Bottom',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>vertical_align_bottom</Icon>,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroupControl
              label="Horizontal alignment"
              name="alignment.horizontal"
              defaultValue={alignment.horizontal}
              options={[
                {
                  label: 'Left',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>format_align_left</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>format_align_justify</Icon>,
                },
                {
                  label: 'Right',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>format_align_right</Icon>,
                },
              ]}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel label="Color Settings" initialOpen>
        <ColorControl
          helpText="Select color or specify a custom static color."
          settings={[
            {
              name: 'textColor',
              label: 'Text Color',
              defaultValue: textColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
            {
              name: 'backgroundColor',
              label: 'Background Color',
              defaultValue: backgroundColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
          ]}
        />
      </SettingsPanel>
      <SettingsPanel label="Vertical Spacing Settings" initialOpen>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <Fragment>
      <SettingsContainer>
        <Settings {...props} />
      </SettingsContainer>

      <EditChildren>{children}</EditChildren>
    </Fragment>
  );
};
