import { INodeTemplate } from '@/types/node';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HookFormDialogRef, IHookFormDialogOptions } from '.';
import {
  EditNodeTemplate,
  IEditNodeTemplateOptions,
} from '../edit-node-template';

interface IStyleProps {
  minHeight: string;
  py: 0 | 1 | 2 | 3 | 4 | 5;
  px: 0 | 1 | 2 | 3 | 4 | 5;
}

interface IProps extends Partial<IStyleProps> {
  maxWidth?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
  fullWidth?: boolean;
}

const DEFAULT_OPTIONS = {
  title: 'Edit',
  content: 'React Hook Form dialog.',
  submitLabel: 'Save',
  cancelLabel: 'Cancel',
};

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  dialog: {
    minHeight: ({ minHeight }) => minHeight,
    paddingTop: ({ py }) => theme.spacing(py),
    paddingBottom: ({ py }) => theme.spacing(py),
    paddingRight: ({ px }) => theme.spacing(px),
    paddingLeft: ({ px }) => theme.spacing(px),
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
    margin: theme.spacing(1),
  },
  scrollPaper: {
    justifyContent: 'flex-start',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

export const HookFormDialog = forwardRef<HookFormDialogRef, IProps>(
  (
    { minHeight = '600px', maxWidth = 'sm', fullWidth = true, py = 0, px = 0 },
    ref
  ) => {
    const classes = useStyles({ minHeight, py, px });

    // const { reset, getValues } = useFormContext();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const templateRef = useRef<{
      open: (initOptions: IEditNodeTemplateOptions) => void;
    } | null>(null);

    const [options, setOptions] = useState<IHookFormDialogOptions>(
      DEFAULT_OPTIONS
    );

    useImperativeHandle(ref, () => ({
      open: (providedOptions: IHookFormDialogOptions) => {
        setOptions({ ...options, ...providedOptions });
        setIsOpen(true);
      },
    }));

    const formMethods = useForm();

    const { reset, getValues } = formMethods;

    const cleanUp = () => {
      if (typeof options.finishedAction === 'function') {
        options.finishedAction();
      }

      setOptions(DEFAULT_OPTIONS);
      setIsOpen(false);
      reset();
    };

    const handleSubmit = () => {
      if (typeof options.submitAction === 'function') {
        options.submitAction(getValues());
      }

      cleanUp();
    };

    const openSaveAsTemplate = () => {
      templateRef.current.open({
        saveAsTemplate: options.saveAsTemplate,
      });
      // if (typeof options.saveAsTemplate === 'function') {
      //   options.saveAsTemplate(getValues());
      // }
    };
    const handleDuplicate = () => {
      if (typeof options.duplicate === 'function') {
        options.duplicate(getValues());
      }
    };

    return (
      <Dialog
        open={isOpen}
        onClose={cleanUp}
        maxWidth={fullscreen ? 'xl' : maxWidth}
        fullWidth={fullWidth}
        classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      >
        <FormProvider {...formMethods}>
          <DialogTitle>
            {options.title}
            {maxWidth === 'sm' && (
              <IconButton
                aria-label="close"
                onClick={() => setFullscreen(!fullscreen)}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  opacity: 0.5,
                }}
              >
                <Icon>{fullscreen ? 'minimize' : 'open_in_full'}</Icon>
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent>
            {isValidElement(options.content)
              ? cloneElement(options.content)
              : options.content}
          </DialogContent>
          <DialogActions>
            <Box className={classes.buttons}>
              <Box>
                <Button
                  onClick={handleDuplicate}
                  startIcon={<Icon>content_copy</Icon>}
                  color="secondary"
                >
                  Duplicate
                </Button>
                <Button
                  onClick={openSaveAsTemplate}
                  startIcon={<Icon>bookmark_add</Icon>}
                  color="secondary"
                >
                  template
                </Button>
              </Box>
              <Box textAlign="right" flexGrow={1}>
                <Button onClick={cleanUp}>{options.cancelLabel}</Button>
                <Button color="primary" onClick={handleSubmit}>
                  {options.submitLabel}
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </FormProvider>
        <EditNodeTemplate ref={templateRef} />
      </Dialog>
    );
  }
);
