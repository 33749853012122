import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { EditChildren, SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import {
  FormControlLabel,
  Grid,
  Icon,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { containerWidth },
  },
}: IProps) => {
  const [isFullWidth, setIsFullWidth] = useState<boolean>(
    typeof containerWidth !== 'string'
  );

  const { setValue } = useFormContext();

  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <Typography paragraph variant="caption">
          Maximum width of the menu
        </Typography>
        <Grid container alignItems="center">
          <Grid item sm={3}>
            <FormControlLabel
              label={`${isFullWidth ? 'Full' : 'Max'} width`}
              control={
                <Switch
                  checked={isFullWidth}
                  onChange={(_e, checked) => {
                    setIsFullWidth(checked);
                    setValue('containerWidth', checked ? !checked : 'xl');
                  }}
                />
              }
            />
          </Grid>
          {!isFullWidth && (
            <Grid item sm={9}>
              <SelectControl
                name="containerWidth"
                label="Sizes"
                defaultValue={containerWidth}
                options={[
                  {
                    label: 'Small',
                    value: 'sm',
                  },
                  {
                    label: 'Medium',
                    value: 'md',
                  },
                  {
                    label: 'Large',
                    value: 'lg',
                  },
                  {
                    label: 'X-Large',
                    value: 'xl',
                  },
                ]}
              />
            </Grid>
          )}
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
