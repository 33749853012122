import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  snackbarContainer: {
    whiteSpace: 'pre-wrap',
    maxWidth: '500px',
  },
  info: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.main,
  },
  action: {
    // border: '1px solid #ff0000',
    marginLeft: 0,
    paddingLeft: 0,
  },
  root: {
    flexDirection: 'column',
    position: 'relative',
    paddingRight: 20,
  },
}));

export const AnnouncementProvider = (props) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={{
        containerRoot: classes.snackbarContainer,
        variantInfo: classes.info,
        action: classes.action,
        contentRoot: classes.root,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
AnnouncementProvider.displayName = 'AnnouncementProvider';
