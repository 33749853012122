import { useMessage } from '@/hooks/useMessage';
import { SettingsContainer } from '@/node-editor/components';
import { useNodeContext, useNodeDialog } from '@/node-editor/hooks';
import {
  removeNode,
  reorderNode,
  setCurrentNode,
  updateNode,
  useActions,
  addTemplate,
} from '@/node-editor/store/actions';
import {
  filterSavedTemplateData,
  fromJsonTemplate,
} from '@/node-editor/utilities';
import { validateNodeProperties } from '@/node-editor/validation';
import { addNodeTemplateService } from '@/page-editor/utilities';
import { INode, INodeTemplate } from '@/types/node';
import {
  Card,
  CardContent,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, Fragment, ReactNode, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FieldValues } from 'react-hook-form';
import { EditorWindowContext } from '../preview/editor-window';

interface IProps {
  node: INode;
  template: INodeTemplate;
  children:
    | ReactNode
    | ReactNode[]
    | ((provided: {
        supportsChildren: boolean;
        reorderNode: (clientId: string, from: number, to: number) => void;
      }) => ReactNode);
  error?: boolean;
  slot?: string | JSX.Element;
}

interface IStyleProps {
  hasChildren: boolean;
  hasError: boolean;
}

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  root: ({ hasChildren, hasError }) => ({
    backgroundColor: hasChildren ? theme.palette.grey[200] : undefined,
    borderRadius: 4,
    padding: 0,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: hasChildren ? theme.spacing(1) : 0,
    marginBottom: 4, //theme.spacing(1),
    border: '1px solid',
    borderColor: hasError ? theme.palette.error.main : theme.palette.grey[400],
  }),
  isCurrent: {
    borderColor: '#f00!important',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 100%',
    width: '100%',
    height: 30,
    // '& > *:nth-last-child(2)': {
    //   marginLeft: 'auto',
    // },
    '&:hover': {
      '& > ._setting_': {
        display: 'inline',
      },
    },
  },
  settingsButton: {
    display: 'none',
  },
  nodeHeader: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  nodeInactive: {
    fontSize: '0.7rem',
    color: theme.palette.error.main,
    paddingTop: 2,
  },
  spacing: {
    marginLeft: 3, //theme.spacing(1),
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  slot: {
    fontStyle: 'italic',
    fontSize: '0.8rem',
  },
  // Animation
  '@keyframes scaledown': {
    '0%': {
      transform: 'scale(3)',
    },
    '100%': {
      // transform: 'scale(2.8)',
      transform: 'scale(1)',
    },
  },
  anim: {
    border: '1px solid #f00!important',
    animation: '$scaledown 1s ease-in-out 1',
  },
}));

export const NodeControls: FC<IProps> = ({
  children,
  error,
  node,
  slot,
  template,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const actions = useActions(() => ({
    updateNode,
    removeNode,
    reorderNode,
    setCurrentNode,
    addTemplate,
  }));
  const handleEditCallback = (returnCallback, data) => {
    // console.log('handleEditCallback', data, node.id);

    if (data.type === 'editcallback' && data.nodeId === node.id) {
      if (data.action === 'hover') {
        actions.setCurrentNode(node.id);
      } else if (data.action === 'edit') {
        handleSettings();
      } else if (data.action === 'add') {
        openAppender(data.nodeId);
      } else if (data.action === 'delete') {
        handleRemove();
      }
    }
  };

  // const { sendMessage } = useMessage();
  const { sendMessage } = useMessage('editcallback', handleEditCallback);

  const { current } = useNodeContext();

  const { templates, openSettings, openAppender } = useNodeDialog();

  const supportsChildren = Boolean(templates[node.type].supports.children);

  const classes = useStyles({
    hasChildren: supportsChildren || error,
    hasError: error,
  });

  const startUpdatedAnimation = () => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 2000);
  };

  const settingsContent = <SettingsContainer.Fill id={node.id} />;

  const handleAction = (values: FieldValues) => {
    return actions.updateNode(validateNodeProperties(node, values));
  };

  const handleFinished = () => {
    startUpdatedAnimation();
  };

  const handleSaveAsTemplate = (
    { title, description, picture, public: showPublic }: FieldValues,
    conferenceId: string
  ) => {
    const nodeTemplate: Partial<INodeTemplate[]> = [
      (node as unknown) as INodeTemplate,
    ] as Partial<INodeTemplate[]>;
    const nodeList = filterSavedTemplateData(
      fromJsonTemplate(nodeTemplate),
      conferenceId
    );

    addNodeTemplateService({
      title,
      description,
      picture,
      public: showPublic,
      type: node.type,
      nodes: nodeList,
    });
  };

  const handleUpdateAsTemplate = ({
    title,
    description,
    picture,
    public: showPublic,
  }: FieldValues) => {
    addNodeTemplateService({
      title,
      description,
      picture,
      public: showPublic,
      type: node.type,
    });
  };

  const handleDuplicate = (values: FieldValues) => {
    const nodeTemplate: Partial<INodeTemplate[]> = [
      (node as unknown) as INodeTemplate,
    ] as Partial<INodeTemplate[]>;
    const nodeList = fromJsonTemplate(nodeTemplate);
    console.log('handleDuplicate list', nodeList);
    actions.addTemplate(nodeList, node.nodeId);
  };

  const handleSettings = () =>
    openSettings({
      title: `Edit ${template.name}`,
      content: settingsContent,
      submitLabel: 'Apply',
      finishedAction: handleFinished,
      submitAction: handleAction,
      saveAsTemplate: handleSaveAsTemplate,
      duplicate: handleDuplicate,
    });

  useEffect(() => {
    if (node.justAdded) {
      const { justAdded, ...withoutJustAdded } = node;
      updateNode(withoutJustAdded);

      setTimeout(() => {
        try {
          handleSettings();
        } catch (e) {
          console.log('could not open edit window', e);
        }
      }, 1);
    }
  }, [node.justAdded]);

  const handleRemove = () => actions.removeNode(node);

  const slotTitle = <span className={classes.slot}>{slot}</span>;

  const handleMouseOver = ({
    contentWindow,
    slug,
  }: {
    contentWindow: HTMLIFrameElement;
    slug: string;
  }) => {
    actions.setCurrentNode(node.id);

    sendMessage(contentWindow.contentWindow, {
      type: 'preview_window',
      payload: {
        slug,
        type: 'CURRENT_NODE',
        payload: node.id,
      },
    });
  };

  const handleMouseLeave = ({
    contentWindow,
    slug,
  }: {
    contentWindow: HTMLIFrameElement;
    slug: string;
  }) => {
    actions.setCurrentNode('');

    sendMessage(contentWindow.contentWindow, {
      type: 'preview_window',
      payload: {
        slug,
        type: 'CURRENT_NODE',
        payload: '',
      },
    });
  };

  return (
    <Draggable draggableId={node.id} index={node.order}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <EditorWindowContext.Consumer>
          {(provided) => (
            <Card
              {...draggableProps}
              innerRef={innerRef}
              classes={{
                root: clsx(classes.root, {
                  [classes.isCurrent]: current === node.id,
                }),
              }}
              onMouseOverCapture={() => provided && handleMouseOver(provided)}
              onMouseOutCapture={() => provided && handleMouseLeave(provided)}
              role="listitem"
              title={template.name}
              className={clsx({
                [classes.anim]: showAnimation,
              })}
            >
              <div className={classes.cardHeader}>
                <Icon {...dragHandleProps}>drag_handle</Icon>
                <Icon classes={{ root: classes.spacing }}>{template.icon}</Icon>
                <div className={classes.nodeHeader}>
                  {/* <Tooltip
                    title={
                      <Fragment>
                        {template.name} {slotTitle}
                      </Fragment>
                    }
                  > */}
                  <Typography
                    noWrap
                    onClick={() => !error && handleSettings()}
                    classes={{ root: classes.spacing }}
                    style={{ cursor: error ? 'initial' : 'pointer' }}
                  >
                    {template.name} {slotTitle}
                  </Typography>
                  {/* </Tooltip> */}
                </div>
                {node.hasOwnProperty('active') && node.active === false && (
                  <div>
                    <Tooltip title="Not active">
                      <span>
                        <Icon className={classes.nodeInactive}>
                          visibility_off
                        </Icon>
                      </span>
                    </Tooltip>
                  </div>
                )}
                {/* <Tooltip title="Settings"> */}
                <span className={clsx(classes.settingsButton, '_setting_')}>
                  <IconButton
                    disabled={error}
                    size="small"
                    aria-label="open node settings"
                    onClick={handleSettings}
                  >
                    <Icon>settings</Icon>
                  </IconButton>
                </span>
                {/* </Tooltip> */}
                {/* <Tooltip title="Delete"> */}
                <IconButton
                  size="small"
                  aria-label="remove node"
                  onClick={handleRemove}
                >
                  <Icon>delete</Icon>
                </IconButton>
                {/* </Tooltip> */}
              </div>
              <CardContent className={classes.content}>
                {typeof children === 'function'
                  ? children({
                      supportsChildren,
                      reorderNode: actions.reorderNode,
                    })
                  : children}
              </CardContent>
            </Card>
          )}
        </EditorWindowContext.Consumer>
      )}
    </Draggable>
  );
};
