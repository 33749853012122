import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Free HTML',
  icon: 'code',
  type: 'embed',
  tag: 'content',
  content: {
    html: '',
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    verticalSpacing: {
      top: '0',
      bottom: '0',
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
