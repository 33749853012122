import { JSONControl } from '@/components/json-control';
import { Placeholder } from '@/components/placeholder';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SlideControl } from '@/components/slide-control';
import { EditChildren, SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Box, Grid } from '@material-ui/core';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { columns, colSpacing, verticalSpacing },
  },
}: IProps) => {
  const { VERTICAL_SPACE_OPTIONS } = SETTINGS_DEFAULTS;

  const [spacing, setSpacing] = useState(colSpacing);

  const [cols, setCols] = useState(columns);

  return (
    <Fragment>
      <SettingsPanel label="Column settings" initialOpen>
        <Grid container spacing={6}>
          <Grid item sm={6}>
            <Box mb={4}>
              <SlideControl
                label="Columns"
                name="columns"
                defaultValue={columns}
                min={1}
                max={12}
                marks={[...Array(12).keys()].map((value) => ({
                  value: value + 1,
                  label: value + 1,
                }))}
                onChange={(cols) => setCols(cols as typeof columns)}
              />
            </Box>
          </Grid>
          <Grid item sm={6}>
            <Box mb={4}>
              <SlideControl
                label="Column spacing"
                name="colSpacing"
                defaultValue={colSpacing}
                step={1}
                min={1}
                max={4}
                marks={[...Array(4).keys()].map((value) => ({
                  value: value + 1,
                  label: value + 1,
                }))}
                onChange={(spacing) => setSpacing(spacing as typeof colSpacing)}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          display="grid"
          gridGap={spacing * 8}
          gridTemplateColumns={`repeat(${cols}, 1fr)`}
        >
          {Array.from(Array(cols).keys()).map((_child, i) => (
            <Placeholder key={i} text={`${i + 1}`} />
          ))}
        </Box>
      </SettingsPanel>
      <SettingsPanel label="Vertical Space Settings" initialOpen>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <Fragment>
      <SettingsContainer>
        <Settings {...props} />
      </SettingsContainer>

      <EditChildren>{children}</EditChildren>
    </Fragment>
  );
};
