import {
  CardContent as MuiCardContent,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { FC } from 'react';

interface IProps {
  gutter?: 1 | 2 | false;
  contentJustification: 'flex-start' | 'center' | 'flex-end';
  color?: string;
  override?: any;
}

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  root: ({ gutter, contentJustification, color, override }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: contentJustification,
    position: 'absolute',
    inset: 0,
    padding: !gutter ? 0 : theme.spacing(gutter),
    '&:last-child': {
      padding: !gutter ? 0 : theme.spacing(gutter),
    },
    color: color || 'inherit',
    ...override,
  }),
}));

export const CardContent: FC<IProps> = ({
  children,
  gutter,
  contentJustification,
  color,
  override = {},
}) => {
  const classes = useStyles({
    gutter,
    contentJustification,
    color,
    override,
  });

  return (
    <MuiCardContent classes={{ root: classes.root }}>{children}</MuiCardContent>
  );
};
