import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../forum-reply-image/edit';
import {
  Render as ForumReplyImageRender,
  template as forumReplyImageTemplate,
} from '../forum-reply-image';

const template: INodeTemplate = {
  version: 1,
  name: 'Reply Image',
  icon: forumReplyImageTemplate.icon,
  type: 'forum-reply-image-widget',
  tag: 'forum',
  content: {
    widgetName: 'Forum Reply Image',
    ...forumReplyImageTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(ForumReplyImageRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
