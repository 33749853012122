import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Icon,
    IconButton,
    makeStyles,
    Popover,
    TextField,
    Tooltip,
  } from '@material-ui/core';
  import clsx from 'clsx';
  import { RichUtils } from 'draft-js';
  import React, { Fragment, useEffect, useState } from 'react';
  
  interface ILinkPopoverProps {
    addLink: (url: string, blankChecked: boolean) => void;
    blankChecked: boolean;
    handleClose: () => void;
    handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleLinkChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    linkBlank: boolean;
    linkPopup: string;
    linkUrl: string | false;
    url: string | null;
  }
  
  const LinkPopoverContent = (props: ILinkPopoverProps) => (
    <Box className={props.linkPopup}>
      {' '}
      <TextField
        defaultValue={props.linkUrl || null}
        onChange={props.handleLinkChange}
        fullWidth
        variant="outlined"
        label="Link Url"
        autoFocus
      />
      <FormControlLabel
        control={
          <Checkbox
            defaultValue={props.linkBlank as any}
            checked={props.blankChecked}
            onChange={props.handleCheck}
            name="checkedB"
            color="primary"
          />
        }
        label="Link will open new tab"
      />
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button color="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.addLink(
              props.url ? props.url : (props.linkUrl as string),
              props.blankChecked
            );
          }}
        >
          OK
        </Button>
      </Box>{' '}
    </Box>
  );
  
  interface IProps {
    editorState: Draft.EditorState;
    dispatch: (state: Draft.EditorState) => void;
  }
  
  const useStyles = makeStyles((theme) => ({
    formatButton: {
      borderRadius: 0,
    },
    formatButtonFocused: {
      color: '#000',
      backgroundColor: theme.palette.grey[100],
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
    },
    toolTip: {
      fontSize: 12,
    },
    linkPopup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 10,
  
      '& .MuiFormControl-root': {
        marginBottom: '10px',
      },
    },
  }));
  
  export const LinkControls = ({ editorState, dispatch }: IProps) => {
    const classes = useStyles();
  
    const [anchorEl, setAnchorEl] = useState(null);
    const [url, setUrl] = useState(null);
    const open = Boolean(anchorEl);
    const [popoverType, setPopoverType] = useState(null);
    const [checkBoxModified, setCheckBoxModified] = useState(false);
  
    const selection = editorState.getSelection();
  
    const linkEntityId = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getEntityAt(selection.getStartOffset());
  
    const isLink = linkEntityId
      ? editorState.getCurrentContent().getEntity(linkEntityId).getType() ===
        'LINK'
      : false;
  
    const linkUrl = linkEntityId
      ? editorState.getCurrentContent().getEntity(linkEntityId).getData().url
      : false;
    const linkBlank = linkEntityId
      ? editorState.getCurrentContent().getEntity(linkEntityId).getData()
          .targetBlank
      : false;
    const [blankChecked, setBlankChecked] = useState(linkBlank);
  
    const addLink = (url: string, blankChecked: boolean) => {
      const contentState = editorState.getCurrentContent();
      const entityKey = contentState
        .createEntity('LINK', 'MUTABLE', {
          url: url,
          targetBlank: blankChecked,
        })
        .getLastCreatedEntityKey();
      dispatch(
        RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey)
      );
      setAnchorEl(null);
    };
  
    const handleClick = (event: React.MouseEvent, type: any) => {
      setPopoverType(type);
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value);
    };
  
    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!checkBoxModified) {
        setCheckBoxModified(true);
      }
      setBlankChecked(event.target.checked);
    };
  
    useEffect(() => {
      if (checkBoxModified) {
        return;
      }
      if (linkBlank) {
        setBlankChecked(true);
      } else {
        setBlankChecked(false);
      }
    }, [linkBlank]);
  
    return (
      <Fragment>
        <Tooltip
          title="Link"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <span>
            <IconButton
              className={clsx(
                classes.formatButton,
                isLink && classes.formatButtonFocused
              )}
              onClick={(event) => {
                handleClick(event, 'link');
              }}
              disabled={
                !isLink && selection.getStartOffset() === selection.getEndOffset()
              }
            >
              <Icon>link</Icon>
            </IconButton>
          </span>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {popoverType === 'link' && (
            <LinkPopoverContent
              linkPopup={classes.linkPopup}
              url={url}
              linkUrl={linkUrl}
              linkBlank={linkBlank}
              blankChecked={blankChecked}
              addLink={addLink}
              handleClose={handleClose}
              handleLinkChange={handleLinkChange}
              handleCheck={handleCheck}
            ></LinkPopoverContent>
          )}
        </Popover>
      </Fragment>
    );
  };