import { createTheme, darken, lighten } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import themeLiveDefault from '../../../shared/schemas/conferenceTemplates/themeLiveDefault.json';
import { lightOrDark } from '../shared/utils/detect';
import themeModerator from './theme_moderator';

// Defined using
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFF3E0&secondary.color=FBC02D

/*
"common":{
  "black":"#000","white":"#fff"},
  "background":{
    "paper":"#fff","default":"#fafafa"},
    "primary":{"light":"#7986cb","main":"#3f51b5","dark":"#303f9f","contrastText":"#fff"},
    "secondary":{"light":"#ff4081","main":"#f50057","dark":"#c51162","contrastText":"#fff"},
    "error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},
    "text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)",
      "disabled":"rgba(0, 0, 0, 0.38)",
      "hint":"rgba(0, 0, 0, 0.38)"}

      }}
*/

let themeMap = window.THEME_LIVE;

const fontIdToCSS = (fontId) => {
  return `"${themeMap.typography.fontIds[fontId].name}", Helvetica, Arial, sans-serif`;
};

const mapVariantsToFonts = (variants) => {
  return Object.entries(variants).reduce((obj, [variant, data]) => {
    // Fallback to default if a variant points to a font that doesnt exist
    if (
      Object.prototype.hasOwnProperty.call(
        themeMap.typography.fontIds,
        data.font
      )
    ) {
      obj[variant] = {
        fontFamily: fontIdToCSS(data.font),
        fontWeight: parseInt(data.fontWeight),
      };
    } else {
      obj[variant] = {
        fontFamily: fontIdToCSS(themeMap.typography.default.id),
        fontWeight: parseInt(themeMap.typography.default.regular),
      };
    }
    return obj;
  }, {});
};

const mapToVariants = (variantPrio) => {
  for (const variant of variantPrio) {
    if (themeMap.typography.variantMap[variant]) {
      return themeMap.typography.variantMap[variant];
    }
  }
  // If no variant is set, return nothing (which uses default theme values)
  return {};
};
// Make sure fonts map
try {
  // First add default fonts
  themeMap.typography.fonts = [
    ...themeMap.typography.fonts,
    ...themeLiveDefault.typography.fonts,
  ];
  // Here we'll put the mapped fonts ("tags" to fonts list)
  themeMap.typography.fontIds = themeMap.typography.fonts.reduce((ids, f) => {
    ids[f.id] = f;
    return ids;
  }, {});

  // Map all variant fontIds to fontname (and make sure they exist). "heading", "text" (could be more in the future)
  themeMap.typography.variantMap = mapVariantsToFonts(
    themeMap.typography.variants
  );
  // Add a "default" variant
  themeMap.typography.variantMap.default = {
    fontFamily: fontIdToCSS(themeMap.typography.default.font),
    fontWeight: parseInt(themeMap.typography.default.regular),
  };
  themeMap.typography.variantMap.default_bold = {
    fontFamily: fontIdToCSS(themeMap.typography.default.font),
    fontWeight: parseInt(themeMap.typography.default.bold),
  };

  // themeMap.typography.default.fontName = themeMap.typography.fontIds[themeMap.themeMap.typography.default.font];
} catch (e) {
  console.error('theme pallette mismatch', e);
}

const setCSSVar = (name, value) => {
  try {
    window.CSS.registerProperty({
      name: name,
      syntax: '<color>',
      inherits: false,
      initialValue: value,
    });
  } catch (e) {
    console.error('CSS registerProperty error', e);
  }
};

let theme;
try {
  // Detect if light or dark
  const theme_type = lightOrDark(themeMap.palette.background);

  const shadeDirection = {
    up: theme_type === 'dark' ? darken : lighten,
    down: theme_type === 'dark' ? lighten : darken,
  };
  const breakpoints = createBreakpoints({});
  theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 820,
        lg: 1280,
        xl: 1920,
      },
    },
    overrides: {
      // Style sheet name ⚛️
      MuiLink: {
        // Name of the rule
        root: {
          fontWeight: parseInt(themeMap.typography.default.bold),
        },
      },
      MuiBadge: {
        badge: {
          fontWeight: parseInt(themeMap.typography.default.bold),
        },
      },
      MuiPaper: {
        // Name of the rule
        root: {
          backgroundColor: 'inherit',
        },
      },
      MuiTab: {
        root: {
          [breakpoints.up('sm')]: {
            minWidth: 110,
          },
        },
      },
      MuiChip: {
        root: {
          fontSize: '0.95rem',
        },
      },
    },
    typography: {
      fontWeightLight: parseInt(themeMap.typography.default.regular),
      fontWeightRegular: parseInt(themeMap.typography.default.regular),
      fontWeightMedium: parseInt(themeMap.typography.default.bold),
      fontWeightBold: parseInt(themeMap.typography.default.bold),
      fontFamily: fontIdToCSS(themeMap.typography.default.font),
      h1: {
        fontSize: '3.5rem',
        lineHeight: 1,
        ...mapToVariants(['h1', 'heading']),
      },
      h2: {
        fontSize: '3rem',
        lineHeight: 1,
        ...mapToVariants(['h2', 'heading']),
      },
      h3: {
        fontSize: '2.5rem',
        lineHeight: 1,
        ...mapToVariants(['h3', 'heading']),
      },
      h4: mapToVariants(['h4', 'heading']),
      h5: mapToVariants(['h5', 'heading']),
      h6: {
        lineHeight: 1.2,
        ...mapToVariants(['h6', 'heading']),
      },
      subtitle1: {
        fontSize: '1.1rem',
        ...mapToVariants(['subtitle1', 'default_bold']),
      }, // Uses default if not set
      subtitle2: {
        fontSize: '1.1rem',
        ...mapToVariants(['subtitle2', 'default_bold']),
      }, // Uses default if not set
      body1: {
        fontSize: '1rem',
        ...mapToVariants(['body1']),
      }, // Uses default if not set
      body2: {
        fontSize: '1.1rem',
        ...mapToVariants(['body2']),
      }, // Uses default if not set
      button: mapToVariants(['button']), // Uses default if not set
    },
    palette: {
      type: theme_type,
      background: {
        // We're using some grades of
        // Coeo shade dark
        shadeup_10: shadeDirection.up(themeMap.palette.background, 0.6),
        default: themeMap.palette.background,
        // Paper is same as Coeo shade light
        paper: shadeDirection.down(themeMap.palette.background, 0.06),
        // Coeo shade light
        shadedown_10: shadeDirection.down(themeMap.palette.background, 0.06),
        // Coeo shade lighter
        shadedown_20: shadeDirection.down(themeMap.palette.background, 0.12),
      },
      primary: {
        main: themeMap.palette.primary, //#fbc02d
        // light: window.THEME_DATA.properties.COLORS.PRIMARY_LIGHT, //#fff263
        // dark: window.THEME_DATA.properties.COLORS.PRIMARY_DARK,
      },
      secondary: {
        main: themeMap.palette.secondary,
        // light: window.THEME_DATA.properties.COLORS.SECONDARY_LIGHT,
        // dark: window.THEME_DATA.properties.COLORS.SECONDARY_DARK,
      },
      text: {
        // primary: '#440000',
        // secondary: window.THEME_DATA.properties.COLORS.TEXT_SECONDARY,
        // disabled: window.THEME_DATA.properties.COLORS.TEXT_DISBABLED,
        // hint: window.THEME_DATA.properties.COLORS.TEXT_HINT,
      },
    },
    shape: {
      borderRadius: 2,
    },
    shadows: Array(25).fill('none'),
  });
  // For use in Manager Page editor (CSS Override)
  setCSSVar('--background', theme.palette.background.default);
  setCSSVar('--primary', theme.palette.primary.main);
  setCSSVar('--secondary', theme.palette.secondary.main);
  setCSSVar('--text', theme.palette.text.primary);
} catch (e) {
  console.error('Theme error, use default', e);
  theme = themeModerator;
}

export default theme;
