import { InputControl } from '@/components/input-control';
import { MediaPlaceholder } from '@/components/media-placeholder';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { SettingsContainer } from '@/node-editor/components';
import { INode, INodeProps } from '@/types/node';
import { Grid, Typography } from '@material-ui/core';
import React, { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    content: {
      backgroundImage,
      displayTitle,
      displaySubtitle,
      logo,
      maxHeight,
      subTitle,
      title,
    },
  },
  conferenceId,
}: IProps) => {
  const { watch } = useFormContext();

  const titleWatch = watch('displayTitle', displayTitle);

  const subTitleWatch = watch('displaySubtitle', displaySubtitle);

  return (
    <Fragment>
      <SettingsPanel label="Background settings" initialOpen>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Controller
              name="backgroundImage"
              defaultValue={backgroundImage}
              render={({ field: { onChange, value } }) => (
                <MediaPlaceholder
                  onChange={onChange}
                  value={value}
                  label="Background image"
                  conferenceId={conferenceId}
                />
              )}
            />
          </Grid>
          <Grid item xs={9}>
            <SelectControl
              fullWidth
              label="Header Height"
              name="maxHeight"
              defaultValue={maxHeight}
              options={[
                {
                  label: 'Small',
                  value: '25vh',
                },
                {
                  label: 'Medium',
                  value: '50vh',
                },
                {
                  label: 'Large',
                  value: '75vh',
                },
              ]}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel label="Content settings" initialOpen>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <SwitchControl
              label="Show title"
              name="displayTitle"
              defaultValue={displayTitle}
              options={[true, false]}
            />
          </Grid>
          {(titleWatch === undefined ? displayTitle : titleWatch) && (
            <Grid item xs={9}>
              <Controller
                name="logo"
                defaultValue={logo}
                render={({ field: { onChange, value } }) => (
                  <MediaPlaceholder
                    onChange={onChange}
                    value={value}
                    label="Logo"
                    conferenceId={conferenceId}
                  />
                )}
              />
              <Typography variant="caption">
                By uploading a logo the title will not apply.
              </Typography>
              <InputControl
                fullWidth
                label="Title"
                name="title"
                defaultValue={title}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            <SwitchControl
              label="Show subtitle"
              name="displaySubtitle"
              defaultValue={displaySubtitle}
              options={[true, false]}
            />
          </Grid>
          {(subTitleWatch === undefined ? displaySubtitle : subTitleWatch) && (
            <Grid item xs={9}>
              <InputControl
                fullWidth
                label="Subtitle"
                name="subTitle"
                defaultValue={subTitle}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
