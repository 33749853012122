import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../program/edit';
import {
  Render as ProgramRender,
  template as programTemplate,
} from '../program';

const template: INodeTemplate = {
  version: 1,
  name: 'Program Widget',
  icon: programTemplate.icon,
  type: 'program-widget',
  tag: 'widgets',
  content: {
    widgetName: 'Program Widget',
    ...programTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(ProgramRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
