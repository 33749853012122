import { IProvided } from '@/hooks/useEventGetters';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { markdownMini } from '@/js/shared/utils/DataFormat';
import { IEvent } from '@/types/event';
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  CardHeader,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { AspectRatio } from '../aspect-ratio/aspect-ratio';
import { ConditionalRenderer } from '../conditional-renderer';

interface IProps extends IProvided {
  event: IEvent;
  nrOfRooms: number;
  hideProgramSubHeader: boolean;
  hideFavoriteButton: boolean;
}

const useStyles = makeStyles<Theme, Pick<IProps, 'eventAssets'>>((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.subtitle1.fontSize,
    lineHeight: 0,
    '& > a': {
      color: 'inherit !important',
    },
  },
  liveText: {
    color: theme.palette.error.main,
    lineHeight: 0,
  },
  subheader: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.subtitle2.fontSize,
    '& > a': {
      color: 'inherit !important',
    },
  },
  contentRoot: {
    '& > div': {
      paddingTop: 0,
      paddingBottom: theme.spacing(1),
    },
    '& > div:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  replay: ({ eventAssets }) => ({
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    backgroundImage: 'none',

    /*backgroundImage:
      eventAssets && eventAssets.thumb_jwt
        ? `url(https://image.mux.com/${eventAssets.mux_asset_playback_id}/thumbnail.jpg?token=${eventAssets.thumb_jwt})`
        : 'none',
    */
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: 'rgba(0,0,0,1)',
    opacity: 1,
    '& > div': {
      cursor: 'pointer',
      color: '#ffffff',
      backgroundColor: 'rgba(0,0,0,.5)',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.1)',
      },
    },
  }),
  replayContent: {
    borderRadius: 5,
    flexDirection: 'row',
  },
  visitor: {
    marginRight: theme.spacing(1),
  },
  forum: {
    '& a': {
      color: `${theme.palette.grey[500]} !important`,
    },
  },
  description: {
    whiteSpace: 'pre-wrap',
  },
  buttonHolder: {
    paddingLeft: theme.spacing(2),
  },
  favoriteButton: {
    float: 'right',
  },
}));

export const EventListContent: FC<IProps> = ({
  event,
  eventStatus,
  eventUrl,
  eventAssets,
  eventAccess,
  eventVisitors,
  eventRoomTitle,
  children,
  nrOfRooms,
  hideProgramSubHeader,
  hideFavoriteButton = false,
}) => {
  const classes = useStyles({
    eventAssets,
  });

  const { authState } = useContext(AuthContext);
  const { openUserPopup, toggleFavorite } = useContext(ConferenceContext);

  const history = useHistory();

  const triggerToggleFavorite = (viewEvent: IEvent) => {
    toggleFavorite(viewEvent.id, viewEvent.favorites.length === 0).then(() => {
      // setViewEvent((curr) => ({
      //   ...curr,
      //   favorites: viewEvent.favorites.length
      //     ? []
      //     : [{ id: authState.user.id }],
      // }));
    });
  };

  const programHeader = (
    <Typography
      component="span"
      variant="subtitle1"
      classes={{ root: classes.title }}
    >
      <ConditionalRenderer
        condition={eventStatus === 'passed' && eventAccess}
        render={(children) => <RouterLink to={eventUrl}>{children}</RouterLink>}
      >
        {eventStatus === 'live' && (
          <span className={classes.liveText}>LIVE </span>
        )}
        {event.title}
      </ConditionalRenderer>
    </Typography>
  );

  const programSubHeader = (
    <ConditionalRenderer
      condition={eventStatus !== 'passed'}
      render={(children) => <RouterLink to={eventUrl}>{children}</RouterLink>}
    >
      <strong>{eventRoomTitle}</strong>
    </ConditionalRenderer>
  );

  const replay = eventAccess && (
    <Grid item xs={12} md={2} onClick={() => history.push(eventUrl)}>
      <AspectRatio
        styles={{
          aspectContainer: classes.replay,
          aspectContent: classes.replayContent,
        }}
      >
        <Icon>play_arrow</Icon>
        <strong>Replay</strong>
      </AspectRatio>
    </Grid>
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={eventAccess ? 10 : 12}
        classes={{ root: classes.contentRoot }}
      >
        {!hideFavoriteButton && event.favorites && (
          <IconButton
            size="small"
            color="primary"
            onClick={() => triggerToggleFavorite(event)}
            className={classes.favoriteButton}
          >
            <Icon>{event.favorites.length > 0 ? 'star' : 'star_border'}</Icon>
          </IconButton>
        )}

        <CardHeader
          title={programHeader}
          subheader={
            nrOfRooms > 1 &&
            hideProgramSubHeader !== true &&
            !(window as any).SETTINGS.HIDE_PROGRAM_ROOMS_IN_MENU
              ? programSubHeader
              : null
          }
          classes={{ title: classes.title, subheader: classes.subheader }}
        />

        {event.description && (
          <CardContent>
            <Typography
              component="p"
              variant="body1"
              className={classes.description}
              dangerouslySetInnerHTML={markdownMini(event.description)}
            ></Typography>
          </CardContent>
        )}
        {eventVisitors.length > 0 && (
          <CardContent>
            {eventVisitors.map((visitor) => (
              <ButtonBase
                key={visitor.id}
                className={classes.visitor}
                disabled={!authState.userLoggedIn}
                onClick={() => openUserPopup(visitor)}
              >
                <UserAvatar size="medium" user={visitor} />
              </ButtonBase>
            ))}
          </CardContent>
        )}
        {event.booking_type === 'entity' &&
          event.entityListId &&
          event.booking_settings && (
            <div className={classes.buttonHolder}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon>handshake</Icon>}
                href={`/live/list/${event.entityListId}`}
              >
                {event.booking_settings.button}
              </Button>
            </div>
          )}
        {children && <CardContent>{children}</CardContent>}
      </Grid>
      {replay}
    </Grid>
  );
};

export const EventListLiveContent: FC<IProps> = ({
  event,
  eventAssets,
  eventAccess,
  eventVisitors,
  children,
}) => {
  const classes = useStyles({
    eventAssets,
  });
  const { authState } = useContext(AuthContext);
  const { openUserPopup } = useContext(ConferenceContext);

  const programHeader = (
    <Typography
      component="span"
      variant="subtitle1"
      classes={{ root: classes.title }}
    >
      {event.title}
    </Typography>
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={eventAccess ? 10 : 12}
        classes={{ root: classes.contentRoot }}
      >
        <CardHeader title={programHeader} classes={{ title: classes.title }} />
        {event.description && (
          <CardContent>
            <Typography
              component="p"
              variant="body1"
              className={classes.description}
              dangerouslySetInnerHTML={markdownMini(event.description)}
            ></Typography>
          </CardContent>
        )}
        {eventVisitors.length > 0 && (
          <CardContent>
            {eventVisitors.map((visitor) => (
              <ButtonBase
                key={visitor.id}
                className={classes.visitor}
                onClick={() => openUserPopup(visitor)}
                disabled={!authState.userLoggedIn}
              >
                <UserAvatar size="medium" user={visitor} />
              </ButtonBase>
            ))}
          </CardContent>
        )}
        {event.booking_type === 'entity' &&
          event.entityListId &&
          event.booking_settings && (
            <div className={classes.buttonHolder}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon>handshake</Icon>}
                href={`/live/list/${event.entityListId}`}
              >
                {event.booking_settings.button}
              </Button>
            </div>
          )}
        {children && <CardContent>{children}</CardContent>}
      </Grid>
    </Grid>
  );
};
