import { ButtonGroupControl } from '@/components/buttongroup-control';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { ManagerMarkdownEditor } from '@/js/shared/components/markdown/ManagerMarkdownEditor';
import { SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid, Icon, Typography } from '@material-ui/core';
import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { text, alignment, verticalSpacing },
  },
  conferenceId,
}: IProps) => {
  const { control } = useFormContext();

  const { VERTICAL_SPACE_OPTIONS, ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;

  return (
    <Fragment>
      <SettingsPanel label="" initialOpen>
        <Controller
          control={control}
          name="text"
          defaultValue={text}
          render={({ field: { onChange, value } }) => (
            <ManagerMarkdownEditor
              value={value}
              setValue={onChange}
              conferenceId={conferenceId}
            />
            // <RichTextEditor onChange={onChange} value={value} />
          )}
        />
        <br />
        <Typography variant="caption">
          Use{' '}
          <code>{'{{first_name}}, {{location_city}}, {{company}}, etc'}</code>{' '}
          to insert variables from the partcipant data.
        </Typography>
      </SettingsPanel>
      <SettingsPanel label="Alignment Settings" initialOpen>
        <Grid container spacing={4}>
          <Grid item>
            <ButtonGroupControl
              label="Horizontal alignment"
              name="alignment.horizontal"
              defaultValue={alignment.horizontal}
              options={[
                {
                  label: 'Left',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>format_align_left</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>format_align_justify</Icon>,
                },
                {
                  label: 'Right',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>format_align_right</Icon>,
                },
              ]}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel label="Vertical Space Settings" initialOpen>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
