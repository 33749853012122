import {
  Box,
  CssBaseline,
  Link,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { ConferenceContext } from '../../context/ConferenceContext';

const useStyles = makeStyles(() => ({
  coeoLogo: {
    height: 13,
    margin: '0 2px',
    width: 'auto',
    verticalAlign: 'middle',
  },
  coeoFooter: {
    opacity: 0.5,
  },
  coeoFooterWhite: {
    opacity: 0.5,
    color: '#fff',
  },
  coeoFooterText: {
    fontSize: 12,
  },
}));

const CoeoFooter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const lightTheme = theme.palette.type === 'light';
  const { conferenceState } = useContext(ConferenceContext);

  return (
    conferenceState.conferenceDetail &&
    !conferenceState.conferenceDetail.url_cname && (
      <Box
        align="center"
        pb={2}
        className={lightTheme ? classes.coeoFooter : classes.coeoFooterWhite}
      >
        <CssBaseline />
        <Typography
          className={classes.coeoFooterText}
          align="center"
          variant="body1"
          gutterBottom
        >
          Powered by{' '}
          <a target="_blank" rel="noreferrer" href="https://coeo.events">
            <img
              className={classes.coeoLogo}
              src={
                lightTheme
                  ? '/images/coeo-logo.svg'
                  : '/images/coeo-logo-white.svg'
              }
            />
          </a>{' '}
          event system
          <br />
          <Link href="mailto:support@coeo.events">support@coeo.events</Link>
        </Typography>
      </Box>
    )
  );
};

export default CoeoFooter;
