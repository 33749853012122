import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Pretix Shop',
  icon: 'confirmation_number',
  type: 'pretix',
  tag: 'components',
  content: {
    eventUrl: '',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
