import { Edit } from './edit';
import { Render } from './render';
import { INodeTemplate } from '@/types/node';

const template: INodeTemplate = {
  version: 1,
  name: 'Rich Text',
  icon: 'text_fields',
  type: 'text',
  tag: 'basic',
  content: {
    richContent: {
      blocks: [],
      entityMap: {},
    },
    verticalSpacing: {
      top: '0',
      bottom: '0',
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: false, //true,
  },
};

export { template, Render, Edit };
