import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';

const { ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;

const template: INodeTemplate = {
  version: 1,
  name: 'Styled Text',
  icon: 'text_fields',
  type: 'markdown',
  tag: 'content',
  content: {
    text: '',
    alignment: {
      vertical: ALIGNMENT_OPTIONS.start,
      horizontal: ALIGNMENT_OPTIONS.start,
    },
    verticalSpacing: {
      top: '0',
      bottom: '0',
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
