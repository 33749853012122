import { Badge, BadgeTypeMap, makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { Fragment } from 'react';

interface IProps {
  color?: BadgeTypeMap['props']['color'];
  badgeContent: string | number;
}

const commonStyles = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0.75,
  transform: 'scale(1)',
  borderRadius: '100%',
  position: 'absolute',
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  '@keyframes signal': {
    to: {
      opacity: 0,
      transform: 'scale(1.5)',
    },
  },
  '@keyframes ripple': {
    '0%': {
      opacity: 0,
      transform: 'scale(1.8)',
    },
    '75%': {
      opacity: 1,
      transform: 'scale(1)',
    },
    '100%': {
      // transform: 'scale(2.8)',
      transform: 'scale(8)',
      opacity: 0,
    },
  },
  badge: {
    '&::after': {
      ...commonStyles,
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.primary.main,
      animation: '$ripple 2s ease-in-out infinite',
      // animation: '$ripple 2s ease-in-out 10',
      zIndex: -1,
    },
  },
  span: {
    ...commonStyles,
    transform: 'scale(1)',
    backgroundColor: theme.palette.primary.main,
    animation: '$signal .7s ease forwards',
  },
}));

export const RippleBadge: React.FC<IProps> = ({
  color = 'primary',
  badgeContent,
  children,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Badge
        classes={{ badge: classes.badge }}
        color={color}
        badgeContent={badgeContent}
      >
        {children}
      </Badge>
      {badgeContent > 0 && <span className={classes.span}></span>}
    </Fragment>
  );
};
