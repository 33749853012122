import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';

interface IProps {
  label: string;
  initialOpen?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopColor: theme.palette.grey[200],
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    padding: 0,
  },
  content: {
    marginTop: '0!important',
    marginBottom: '0!important',
    justifyContent: 'center',
    opacity: 0.4,
    textTransform: 'uppercase',
    fontSize: '1rem',
    margin: 0,
    minHeight: '48px!important',
    alignItems: 'center',
    // '&.Mui-expanded': {
    //   minHeight: 48,
    // },
  },
  expanded: {
    marginTop: '0!important',
    marginBottom: '0!important',
    // margin: 0,
    minHeight: '48px!important',
  },
  expandIcon: {
    position: 'absolute',
    right: 0,
  },
}));

/**
 * Abstraction of MUI Accordion.
 * Purpose as a panel for Node settings
 *
 * @link https://v4.mui.com/api/accordion/
 */
export const SettingsPanel: FC<IProps> = ({ label, initialOpen, children }) => {
  const classes = useStyles();

  return (
    <Accordion
      defaultExpanded={initialOpen}
      classes={{ expanded: classes.expanded }}
    >
      <AccordionSummary
        expandIcon={<Icon>expand_more</Icon>}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          expandIcon: classes.expandIcon,
        }}
      >
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ width: '100%' }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};
