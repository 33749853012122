import { rgbToCssColor } from '@/js/shared/utils/format';
import { makeStyles, Popover, Theme } from '@material-ui/core';
import React, { Fragment, ReactNode, useState } from 'react';
import { ChromePicker, RGBColor } from 'react-color';

export interface IProps {
  color: any;
  setColor: (color: any) => void;
  children?: ({
    anchorEl,
    setAnchor,
  }: {
    anchorEl: Element | null;
    setAnchor: (event: React.MouseEvent<Element>) => void;
  }) => ReactNode;
}

const useStyles = makeStyles<Theme, { color: RGBColor }>(() => ({
  borderBox: {
    display: 'inline-flex',
    padding: '3px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
  },
  colorBox: {
    width: '30px',
    height: '15px',
    backgroundColor: ({ color }) =>
      !color ? 'transparent' : rgbToCssColor(color as any),
  },
}));
/**
 * ColorPicker Popover component
 *
 * @link https://v4.mui.com/api/popover
 * @link https://casesandberg.github.io/react-color/
 */
export const ColorPicker = ({ color, setColor, children }: IProps) => {
  const classes = useStyles({ color });

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const setAnchor = (event: React.MouseEvent<Element>) =>
    setAnchorEl(event.currentTarget);

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <div className={classes.borderBox} onClick={setAnchor}>
        <div className={classes.colorBox}></div>
      </div>
      {children && children({ anchorEl, setAnchor })}
      <Popover
        id={`picker`}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <ChromePicker
          color={color}
          onChangeComplete={(color) => setColor(color.rgb)}
        />
      </Popover>
    </Fragment>
  );
};
