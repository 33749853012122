import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { RichTextEditor } from '@/rich-text/editor';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';
import { Grid } from '@material-ui/core';
import { SelectControl } from '@/components/select-control';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { richContent, verticalSpacing },
  },
}: IProps) => {
  const { control } = useFormContext();

  const { VERTICAL_SPACE_OPTIONS } = SETTINGS_DEFAULTS;

  return (
    <Fragment>
      <Controller
        control={control}
        name="richContent"
        defaultValue={richContent.blocks.length === 0 ? null : richContent}
        render={({ field: { onChange, value } }) => (
          <RichTextEditor onChange={onChange} value={value} />
        )}
      />
      <div style={{ height: '16px' }} />
      <SettingsPanel label="Vertical Spacing Settings">
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <SelectControl
              label="Top"
              name="verticalSpacing.top"
              defaultValue={verticalSpacing.top}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectControl
              label="Bottom"
              name="verticalSpacing.bottom"
              defaultValue={verticalSpacing.bottom}
              options={VERTICAL_SPACE_OPTIONS}
            />
          </Grid>
        </Grid>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <Fragment>
      <SettingsContainer>
        <Settings {...props} />
      </SettingsContainer>
    </Fragment>
  );
};
