import CoChip from '@/js/shared/components/ui/CoChip';

interface IProps {
  tagList: any[];
  selectedTag: string | null;
  selectTag: (id: string) => void;
}

export const ParticipantTagSorter = ({
  tagList,
  selectedTag,
  selectTag,
}: IProps) => {
  return tagList.length > 0
    ? tagList.map((tag) => (
        <CoChip
          clickable
          onClick={() => selectTag(tag.id)}
          disabled={(selectedTag && selectedTag !== tag.id) || tag.count === 0}
          key={tag.id}
          count={tag.count}
          tagtype={tag.type}
          label={tag.title}
        />
      ))
    : null;
};
