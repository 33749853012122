import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Icon,
  Link,
  Paper,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { LiveContainer } from '../../../live/LiveContainer';
import { AuthContext } from '../../../shared/context/AuthContext';
import axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  settingsbg: {
    backgroundColor: theme.palette.background.shadedown_20,
  },
  noPadding: {
    padding: 0,
  },
  settingsbox: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #fff',
    marginTop: '10px',
  },
  settingsbox_dropdown: {
    backgroundColor: theme.palette.background.shadedown_20,
    border: '1px solid',
    borderColor: theme.palette.background.shadedown_20,
    width: '100%',
  },
  settings_line: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

const ProfileNotificationSettings = withRouter((props) => {
  const classes = useStyles();
  const [notificationssettings, setNotificationSettings] = useState(null);
  const { authState } = useContext(AuthContext); // Hämta Context

  const handleChange = (event) => {
    setNotificationSettings((prevState) => {
      let obj = Object.assign({}, prevState);
      obj[event.target.name].email = event.target.checked;
      saveSettings(obj);
      return obj;
    });
  };
  const saveSettings = (setting) => {
    (async () => {
      let response = await axios.put(
        `/api/visitor/notificationsetting/`,
        setting
      );
      //setNotificationSettings(response.data);
    })();
  };

  useEffect(() => {
    (async () => {
      console.log(authState.user.id);
      let response = await axios.get(`/api/visitor/notificationsetting/`);
      setNotificationSettings(response.data);
    })();
  }, [authState.userLoggedIn]);

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#00FF00',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#00FF00',
    },
  }));

  const SettingsBox = ({ headline, paramname, data }) => (
    <div className={classes.settingsbox}>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon>expand_more</Icon>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle2">{headline.toUpperCase()}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.settingsbox_dropdown}>
          <Box className={classes.settings_line}>
            <Box flexGrow={1}>Send e-mail</Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={data.email}
                      name={paramname}
                      onChange={handleChange}
                    />
                  }
                  label={data.email ? 'On' : 'Off'}
                  labelPlacement="start"
                />
              </FormGroup>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
  return notificationssettings ? (
    <LiveContainer welcomeState={props.welcomeState}>
      <Box className={classes.bg}>
        <Box>
          <Container maxWidth="md">
            <Container className={classes.noPadding} maxWidth="lg">
              <Typography variant="h6">Notification settings</Typography>
              <Typography variant="body2">
                We might still send you important notifications about your
                account even if notification option is turned off.
              </Typography>
              <Box>
                {SettingsBox({
                  headline: 'Private messages',
                  paramname: 'messages',
                  data: notificationssettings.messages,
                })}
                {SettingsBox({
                  headline: 'Forum responses',
                  paramname: 'forum',
                  data: notificationssettings.forum,
                })}
                {SettingsBox({
                  headline: 'New E-cards',
                  paramname: 'ecards',
                  data: notificationssettings.ecards,
                })}
              </Box>
            </Container>
          </Container>
        </Box>
      </Box>
    </LiveContainer>
  ) : (
    <Box align="center">
      <CircularProgress disableShrink />
    </Box>
  );
});

export default ProfileNotificationSettings;
