import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { EditorStateContext } from '../editor';
import { SizeControls } from '../controls/size-controls';
import { AlignmentControls } from '../controls/alignment-controls';
import { FormatControls } from '../controls/format-controls';
import { LinkControls } from '../controls/link-controls';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[100]
  },
  divider: {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  }
}));

export const Toolbar = () => {

  const classes = useStyles();

  return (
    <EditorStateContext.Consumer>
      {(provided) => (
        <div className={classes.toolbar} onMouseDownCapture={(e) => e.preventDefault()}>
            <SizeControls {...provided} />
            <Divider flexItem orientation="vertical" className={classes.divider} />
            <AlignmentControls {...provided} />
            <Divider flexItem orientation="vertical" className={classes.divider} />
            <FormatControls {...provided} />
            <LinkControls {...provided} />
        </div>
      )}
    </EditorStateContext.Consumer>
  );
};