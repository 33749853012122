const userReducer = (state, action) => {
  switch (action.type) {
    case 'USER_LOAD':
      return {
        ...state,
        userProgress: true,
        userLoaded: false,
        userError: null,
        errorType: null,
      };
    case 'USER_SUCCESS':
      return {
        ...state,
        userProgress: false,
        userLoaded: true,
        user: { ...action.user, fullData: true },
        userError: null,
        errorType: null,
        registerSuccess: Boolean(action.registerSuccess),
      };
    case 'USER_ERROR':
      return {
        ...state,
        userProgress: false,
        userLoaded: true,
        userError: action.error,
        errorType: action.errorType,
      };
    case 'STATUS_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.status,
        },
      };
    case 'STATUS_ERROR':
      return {
        ...state,
        statusError: action.error,
      };
    case 'VERIFICATION_SUCCESS':
      return {
        ...state,
        userProgress: false,
        userLoaded: true,
        user: action.user,
        userError: null,
        errorType: null,
        registerSuccess: Boolean(action.registerSuccess),
      };
    case 'INVITATION_LOAD':
      return { ...state, invitationProgress: true, invitationError: null };
    case 'INVITATION_SUCCESS':
      return {
        ...state,
        invitationProgress: false,
        invitationLoaded: true,
        invitation: action.invitation,
        invitationError: null,
        registerSuccess: Boolean(action.registerSuccess),
      };
    case 'INVITATION_ERROR':
      return {
        ...state,
        invitationProgress: false,
        invitationLoaded: true,
        invitationError: action.error,
      };
    case 'LIST_LOAD':
      return { ...state, listLoaded: true, listError: null };
    case 'LIST_SUCCESS':
      return { ...state, listLoaded: true, listError: null, list: action.list };
    case 'LIST_ERROR':
      return { ...state, listLoaded: false, listError: action.error };
    case 'USER_FOLLOW_UPDATE':
      return { ...state, updatingFollowing: true };
    case 'USER_FOLLOW_DONE':
      return {
        ...state,
        updatingFollowing: false,
        user: { ...action.user, fullData: true },
      };
    default:
      return state;
  }
};

export default userReducer;
