import {
  HookFormDialog,
  IHookFormDialogOptions,
} from '@/components/hook-form-dialog';
import { getTemplates } from '@/node-editor/utilities';
import React, { createContext, FC, useRef } from 'react';
import { AppenderDialog } from './appender-dialog';

interface IProps {
  supports: 'page' | 'room';
}

interface IProvided {
  openAppender: (nodeId: string) => void;
  openSettings: (options: IHookFormDialogOptions) => void;
  templates: Record<string, any>;
}

export const NodeDialogContext = createContext<IProvided | undefined>(
  undefined
);

export const NodeDialogProvider: FC<IProps> = ({ children, supports }) => {
  const appenderRef = useRef<{ open: IProvided['openAppender'] } | null>(null);

  const settingsRef = useRef<{ open: IProvided['openSettings'] } | null>(null);

  const openAppender = (nodeId: string): void =>
    appenderRef.current && appenderRef.current.open(nodeId);

  const openSettings = (options: IHookFormDialogOptions): void =>
    settingsRef.current && settingsRef.current.open(options);

  const allowedTemplates = useRef(
    getTemplates()
      .filter((template) => template.supports.contexts.includes(supports))
      .reduce(
        (templates, template) => ({ ...templates, [template.type]: template }),
        {}
      )
  );

  return (
    <NodeDialogContext.Provider
      value={{
        templates: allowedTemplates.current,
        openAppender,
        openSettings,
      }}
    >
      {children}
      <HookFormDialog ref={settingsRef} />
      <AppenderDialog ref={appenderRef} templates={allowedTemplates.current} />
    </NodeDialogContext.Provider>
  );
};
