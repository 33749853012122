import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { MarkdownRender } from '../markdown/MarkdownRender';
import { EntityCards } from '../ui/EntityCards';
import C from '../../../../../shared/constants/general';
import { useHistory } from 'react-router-dom';
import {
  findCurrentDayId,
  getHashtagList,
  getHashtags,
} from '@/js/shared/utils/DataFormat';
import clsx from 'clsx';
import { EntityListHostEditor } from './EntityListHostEditor';
import { ConferenceContext } from '../../context/ConferenceContext';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';

const useStyles = makeStyles((theme) => ({
  description: {
    // whiteSpace: 'pre-wrap',
  },
  cardDescription: {
    maxHeight: 70,
    overflowY: 'auto',
  },
  container: {
    justifyContent: 'center',
  },
  breakoutSetting: {
    backgroundColor: theme.palette.background.shadedown_20,
    borderRadius: 5,
    padding: '16px 8px',
  },
  tag: {
    display: 'inline-block',
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: 4,
    fontSize: '1.1rem',
    padding: '2px 9px',
    margin: 3,
    backgroundColor: theme.palette.background.shadedown_10,
    borderRadius: 2,
  },
  tagSelected: {
    opacity: 0.5,
  },
  tagCount: {
    fontSize: '0.9rem',
    fontWeight: 'normal',
    padding: 3,
  },
  imageLogo: {
    width: 25,
    height: 25,
    borderRadius: 5,
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: 5,
  },
  bookedTime: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 3,
  },
}));
const listUrls = {
  loggedin: '/activeuserids',
  verified: '/alluserids?status=verified',
  room: '/roomuserids',
  all: '/alluserids',
};
const getListUrl = (type) => {
  if (type.startsWith('room')) {
    return `/api/visitor/rooms/${type.split('_')[1]}/roomuserids`;
  } else {
    return `/api/visitor/conferences/${window.CONFERENCE_ID}${listUrls[type]}`;
  }
};

const EntityListView = ({ entityListId, minGridWith = 3 }) => {
  const classes = useStyles();
  const [entityList, setEntityList] = useState(null);
  const [hashtagList, setHashtagList] = useState([]);
  const [hashtag, setHashtag] = useState(null);
  const [sortedEntities, setSortedEntities] = useState([]);
  const [error, setError] = useState('');
  const { authState } = useContext(AuthContext);
  const { conferenceState } = useContext(ConferenceContext);
  const history = useHistory();

  const [bookings, setBookings] = useState([]);

  // Breakout stuff
  const [hostsInFirstEnity, setHostsInFirstEnity] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [showBreakout, setShowBreakouts] = useState(false);
  const [manualAdd, setManualAdd] = useState(false);
  const [destroyForum, setDestroyForum] = useState(false);
  const [spreadExtraToOtherRooms, setSpreadExtraToOtherRooms] = useState(false);
  const [listType, setListType] = useState('loggedin'); // 'loggedin' | 'veriied' | 'all'
  const [breakoutType, setBreakoutType] = useState('hosts'); // 'hosts' | 'rooms'
  const [breakoutNr, setBreakoutNr] = useState(5); // defined by nr of hosts or rooms
  // const [checkedInRoom, setCheckedInRoom] = useState([]); // Used to edit people in rooms

  const roomsHasVisitors =
    sortedEntities.length > 0 && !!sortedEntities[0].visitors;
  const hasBookingEvents = entityList && entityList.events.length > 0;
  const isModerator =
    authState.user &&
    authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;

  const currentDayId = findCurrentDayId(conferenceState.conferenceDetail);

  const programRooms =
    (currentDayId &&
      conferenceState.conferenceDetail.programDays.find(
        (pd) => pd.id === currentDayId
      ).programRooms) ||
    [];

  const refreshEntityList = () => {
    axios
      .get(
        `/api/visitor/entitylists/${entityListId}?includehosts=${
          showBreakout ? 1 : 0
        }`,
        {}
      )
      .then((response) => {
        setEntityList(response.data);
      });
  };
  const refreshEntityBookings = () => {
    axios
      .get(`/api/visitor/entitylists/${entityListId}/mybookings`)
      .then((r) => {
        setBookings(r.data.events);
      });
  };
  useEffect(() => {
    if (hasBookingEvents) {
      refreshEntityBookings();
    }
  }, [hasBookingEvents]);

  useEffect(() => {
    setEntityList(null);
    refreshEntityList();
  }, [entityListId, showBreakout]);

  useEffect(() => {
    if (entityList) {
      try {
        setHashtagList(
          getHashtagList(
            entityList.entities.map((ent) => ent.description_short)
          )
        );
      } catch (e) {
        console.log('Error in #hashtags', e);
      }
    }
  }, [entityList && entityList.entities.length]);

  useEffect(() => {
    if (entityList) {
      if (hashtagList.length > 0 && hashtag) {
        setSortedEntities(
          entityList.entities.filter((ent) =>
            getHashtags(ent.description_short).includes(hashtag)
          )
        );
      } else {
        setSortedEntities(entityList.entities);
      }
    }
  }, [entityList, hashtagList.length, hashtag]);

  const refreshVisitorNr = () => {
    if (listType === 'none') {
      setActiveUsers(0);
    } else {
      axios
        .get(getListUrl(listType))
        .then((response) => {
          setActiveUsers(response.data.ids.length);
        })
        .catch((e) => {
          setError(e.response.data.message);
        });
    }
  };
  const refreshHostsInFirstRoom = () => {
    axios
      .get(`/api/visitor/entities/${entityList.entities[0].id}`)
      .then((response) => {
        setHostsInFirstEnity(response.data.visitors.length);
      })
      .catch((e) => {
        setError(e.response.data.message);
      });
  };
  useEffect(() => {
    if (showBreakout && entityList && entityList.entities.length === 1) {
      refreshHostsInFirstRoom();
    }
  }, [showBreakout, entityList && entityList.entities.length]);

  useEffect(() => {
    if (showBreakout) {
      refreshVisitorNr();
    }
  }, [showBreakout, listType]);

  const createBreakout = () => {
    setError('');
    const [list, param] = listType.split('_');
    axios
      .post(`/api/visitor/entitylists/${entityListId}/createbreakouts`, {
        // programRoomId: programRoomId,
        list: list, // 'programroom'
        listParam: param, // 'programroom'
        breakout: breakoutType,
        nr: breakoutNr,
        spread: spreadExtraToOtherRooms,
      })
      .then(() => {
        refreshEntityList();
      })
      .catch((e) => {
        setError(e.response.data.message);
      });
  };
  const removeBreakoutRooms = () => {
    setError('');
    axios
      .put(`/api/visitor/entitylists/${entityListId}/removerooms`, {
        destroyForum,
      })
      .then(() => {
        refreshEntityList();
      })
      .catch((e) => {
        setError(e.response.data.message);
      });
  };
  const unConnectBreakoutRooms = () => {
    setError('');
    axios
      .put(`/api/visitor/entitylists/${entityListId}/unconnectrooms`)
      .then(() => {
        refreshEntityList();
        refreshHostsInFirstRoom();
      })
      .catch((e) => {
        setError(e.response.data.message);
      });
  };
  const calculatedNrOfItems =
    breakoutType === 'hosts'
      ? Math.ceil(activeUsers / breakoutNr)
      : Math.floor(activeUsers / breakoutNr);
  const peopleOutside = activeUsers === 0 ? 0 : activeUsers % breakoutNr; // If there are uneven nr of people

  const totalVisitors = roomsHasVisitors
    ? sortedEntities.reduce((tot, e) => tot + e.visitors.length, 0)
    : 0;

  return (
    <Box>
      {entityList && (
        <Box align="center">
          <Typography variant="h5" gutterBottom>
            {entityList.title}
          </Typography>
          <Box pb={1} className={classes.description}>
            <MarkdownRender text={entityList.description} />
          </Box>
          {hasBookingEvents && isModerator && (
            <Box pb={1}>
              <Button
                startIcon={<Icon>grid_on</Icon>}
                href={`/moderator/list/${entityListId}`}
                color="secondary"
                target="_blank"
              >
                booking overview (moderator)
              </Button>
            </Box>
          )}
          {bookings.length > 0 && (
            <Box pb={1}>
              {bookings.map((b) => (
                <Box key={b.id} pb={1}>
                  <Typography variant="h6" gutterBottom>
                    {b.title}
                  </Typography>
                  {b.event_booking_times.length > 0 ? (
                    <Box display="inline-block">
                      {b.event_booking_times.map((t) => (
                        <Box key={t.id} className={classes.bookedTime}>
                          <Box pr={1}>{t.start_time.substring(0, 5)}</Box>
                          <ButtonBase
                            onClick={() =>
                              history.push(`/live/item/${t.entities[0].id}`)
                            }
                            flexGrow={1}
                          >
                            <span
                              className={classes.imageLogo}
                              style={{
                                backgroundImage: `url(${getImage(
                                  t.entities[0].picture ||
                                    t.entities[0].bg_picture,
                                  ImageSize.Icon
                                )})`,
                              }}
                            />
                            <strong>{t.entities[0].title}</strong>
                          </ButtonBase>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <div>
                      <em>{window.LANG.BOOK_NO_BOOKINGS}</em>
                    </div>
                  )}
                </Box>
              ))}
            </Box>
          )}

          {isModerator && entityList.type === 'breakout' && (
            <Box pb={1}>
              <Button
                startIcon={<Icon>call_split</Icon>}
                onClick={() => setShowBreakouts(!showBreakout)}
              >
                {showBreakout ? 'hide settings' : 'edit breakouts'}
              </Button>
              {entityList.entities.length > 1 && (
                <Box>
                  <Button
                    size="small"
                    startIcon={<Icon>forum</Icon>}
                    href={`/moderator/breakoutforum/${entityListId}`}
                  >
                    View breakout forum
                  </Button>
                </Box>
              )}
              {showBreakout && (
                <Grid
                  container
                  spacing={1}
                  alignItems="flex-end"
                  // justifyContent="stretch"
                >
                  <Grid item xs={12}>
                    1. Start with ONE room.
                    <br />
                    2. "Create" will make copies of that room (incl. Live State)
                    and connect specified users randomly.
                    <br />
                    3. Un-connect people and create another list if you want to
                    start over.
                    {error && <Typography color="error">{error}</Typography>}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.breakoutSetting}>
                      {entityList.entities.length === 1 ? (
                        <>
                          Select users (moderators are excluded)
                          <br />
                          <NativeSelect
                            style={{ maxWidth: 300 }}
                            size="small"
                            variant="outlined"
                            name="source"
                            label="Create from"
                            value={listType}
                            onChange={(e) => setListType(e.target.value)}
                          >
                            <optgroup label="General">
                              <option value="loggedin">All logged in</option>
                              <option value="verified">All verified</option>
                              <option value="all">Everyone</option>
                              <option value="none">
                                None (only create rooms)
                              </option>
                            </optgroup>
                            <optgroup label="People in Live Rooms">
                              {programRooms.map((pd) => (
                                <option key={pd.id} value={`room_${pd.id}`}>
                                  {pd.title}
                                </option>
                              ))}
                            </optgroup>
                          </NativeSelect>
                          <br />
                          Create by:
                          <br />
                          <NativeSelect
                            style={{ maxWidth: 300 }}
                            size="small"
                            variant="outlined"
                            name="hosts"
                            label="Create by"
                            value={breakoutType}
                            onChange={(e) => setBreakoutType(e.target.value)}
                          >
                            <option value="hosts">
                              Nr of people in each room
                            </option>
                            <option value="rooms">Nr of rooms</option>
                          </NativeSelect>
                          <br />
                          <br />
                          <TextField
                            style={{ width: 130 }}
                            type="number"
                            size="small"
                            variant="outlined"
                            name="hosts"
                            label={
                              breakoutType === 'hosts'
                                ? 'Nr in each room'
                                : 'Nr of rooms'
                            }
                            value={breakoutNr}
                            onChange={(e) => setBreakoutNr(e.target.value)}
                          />
                          <br />
                          <br />
                          {activeUsers > 0 ? (
                            <Box>
                              {breakoutType === 'rooms' && (
                                <>
                                  Will be{' '}
                                  <strong>
                                    {calculatedNrOfItems} in each room
                                  </strong>
                                  .
                                  {peopleOutside > 0 && (
                                    <Box>
                                      And {calculatedNrOfItems + 1} in{' '}
                                      {peopleOutside} of them.
                                    </Box>
                                  )}
                                </>
                              )}

                              {breakoutType === 'hosts' && (
                                <>
                                  Will create{' '}
                                  <strong>{calculatedNrOfItems} rooms</strong>.
                                  {calculatedNrOfItems > 1 &&
                                    peopleOutside > 0 && (
                                      <Box>
                                        There are {peopleOutside} people in the
                                        last room.
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={spreadExtraToOtherRooms}
                                              onChange={() =>
                                                setSpreadExtraToOtherRooms(
                                                  !spreadExtraToOtherRooms
                                                )
                                              }
                                            />
                                          }
                                          label="Spread extra people to other rooms"
                                        />
                                      </Box>
                                    )}
                                </>
                              )}
                            </Box>
                          ) : (
                            <Typography color="error">
                              No users to connect
                            </Typography>
                          )}
                          {entityList.entities.length === 1 &&
                            hostsInFirstEnity > 0 && (
                              <Box>
                                <Typography color="error">
                                  ATT! Please remove hosts from first room
                                </Typography>
                              </Box>
                            )}
                        </>
                      ) : (
                        <Box>
                          To create breakouts, there have to be only one room
                        </Box>
                      )}
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={entityList.entities.length !== 1}
                        startIcon={<Icon>call_split</Icon>}
                        onClick={() =>
                          window.confirm('Ok, really create rooms?') &&
                          createBreakout()
                        }
                      >
                        create breakout rooms
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.breakoutSetting}>
                      They can stay in their rooms, but won't see the room in
                      their profile
                      <br />
                      <br />
                      <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<Icon>person_off</Icon>}
                        onClick={() =>
                          window.confirm('Really unconnect all people?') &&
                          unConnectBreakoutRooms()
                        }
                        disabled={totalVisitors === 0}
                      >
                        unconnect all people
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box className={classes.breakoutSetting}>
                      If people are in the rooms they will see a 404 or error.
                      <br />
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={destroyForum}
                            onChange={() => setDestroyForum(!destroyForum)}
                          />
                        }
                        label="Delete all forum posts (in generated rooms)"
                      />
                      <br />
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={entityList.entities.length < 2}
                        startIcon={<Icon>delete</Icon>}
                        onClick={() =>
                          window.confirm(
                            'Really remove all rooms (but first)?'
                          ) && removeBreakoutRooms()
                        }
                      >
                        remove generated rooms
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}

          {hashtagList.length > 0 && (
            <Box p={1}>
              {hashtagList.map((tag) => (
                <ButtonBase
                  key={tag.tag}
                  onClick={() =>
                    setHashtag(tag.tag === hashtag ? null : tag.tag)
                  }
                  className={clsx(classes.tag, {
                    [classes.tagSelected]: tag.tag === hashtag,
                  })}
                  style={{
                    ...(tag.color ? { color: `#${tag.color}` } : {}),
                  }}
                >
                  {tag.title}{' '}
                  <span className={classes.tagCount}>{tag.count}</span>
                </ButtonBase>
              ))}
            </Box>
          )}
          <Grid container>
            <Grid item xs={12} md={showBreakout ? 6 : 12}>
              <EntityCards
                entities={sortedEntities}
                history={history}
                classes={classes}
                minGridWith={minGridWith}
                showParticipants={showBreakout}
                // checkedInRoom={checkedInRoom}
                // toggleCheckInRoom={toggleCheckInRoom}
                nrEntities={sortedEntities.length}
                totalVisitors={totalVisitors}
                refreshEntityList={refreshEntityList}
              />
            </Grid>
            {showBreakout && (
              <Grid item xs={12} md={6}>
                {entityList.entities.length > 1 && (
                  <Box className={classes.breakoutSetting}>
                    {manualAdd ? (
                      <EntityListHostEditor
                        entityListId={entityListId}
                        entities={entityList.entities}
                        refreshEntityList={refreshEntityList}
                      />
                    ) : (
                      <Box>
                        <Button
                          onClick={() => setManualAdd(!manualAdd)}
                          startIcon={<Icon>person_add</Icon>}
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          Manual visitor control
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default EntityListView;
