import { Box, ButtonBase, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import C from '../../../../../shared/constants/general';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    borderRadius: 5,
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '40%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 0,
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.8,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    lineHeight: '1.2rem',
    textAlign: 'left',
    color: theme.palette.common.white,
  },
  imageLogo: {
    position: 'absolute',
    left: 5,
    top: 5,
    width: '18%',
    paddingBottom: '18%',
    borderRadius: 5,
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageContainSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    opacity: 0,
    borderRadius: 5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    width: '100%',
    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.4))',
    fontSize: '1.1rem',
    lineHeight: '1.2rem',
    position: 'relative',
    padding: 7,
    textAlign: 'center',
  },
  hidden: {
    opacity: 0.5,
  },
  visitorCount: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 600,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  visitorIcon: {
    verticalAlign: 'bottom',
    fontSize: 20,
    height: 19,
  },
  isLive: {
    fontSize: 14,
    fontWeight: 600,
    position: 'absolute',
    top: 5,
    left: 5,
  },
  isLiveIcon: {
    fontSize: '1rem',
    verticalAlign: 'baseline',
    lineHeight: '1.3rem',
  },
}));
const EntityListItem = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const logo =
    props.entity.picture &&
    getImage(props.entity.picture, ImageSize.Small, props.entity.conferenceId);

  const goto = (url) => {
    if (history) {
      history.push(url);
    } else {
      window.location.href = url;
    }
  };

  return (
    <ButtonBase
      focusRipple
      href={history ? null : props.url}
      onClick={
        history
          ? () => goto(props.url || `/live/item/${props.entity.id}`)
          : null
      }
      className={clsx(classes.image, {
        [classes.hidden]:
          props.entity.entityVisitors && props.entity.entityVisitors.hidden,
      })}
      focusVisibleClassName={classes.focusVisible}
      style={{
        backgroundColor: props.entity.bg_color || '#000000',
        ...(props.entity.bg_picture
          ? {
              backgroundImage: `url(${getImage(
                props.entity.bg_picture,
                ImageSize.Medium,
                props.entity.conferenceId
              )})`,
            }
          : null),
      }}
    >
      <span className={classes.imageBackdrop} />
      {logo && (
        <span
          className={classes.imageLogo}
          style={{
            backgroundImage: `url(${logo})`,
            ...(!props.entity.bg_picture
              ? {
                  width: '35%',
                  paddingBottom: '35%',
                }
              : {}),
          }}
        />
      )}
      {props.entity.livestate !== C.ENTITY.LIVE_STATE.OFF && (
        <Typography
          component="div"
          variant="subtitle1"
          color="error"
          className={classes.isLive}
          style={logo ? { left: '22%' } : {}}
        >
          <Icon className={classes.isLiveIcon}>fiber_manual_record</Icon>
          LIVE
        </Typography>
      )}
      {Object.prototype.hasOwnProperty.call(props.entity, 'visitorCount') &&
        props.entity.visitorCount > 0 && (
          <Box className={classes.visitorCount}>
            {props.entity.visitorCount}{' '}
            <Icon className={classes.visitorIcon}>people</Icon>
          </Box>
        )}
      {props.entity.title && (
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            className={classes.imageTitle}
          >
            {props.entity.title}
          </Typography>
        </span>
      )}
    </ButtonBase>
  );
};

export default EntityListItem;
