import { Icon, IconButton, Snackbar } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const CoSnackbar = (props, ref) => {
  const [snackState, setSnackState] = useState({
    open: false,
    text: '',
    duration: 4000,
  });

  const handleSnackClose = () => {
    setSnackState(false);
  };
  useImperativeHandle(ref, () => ({
    setState: (options) => {
      setSnackState({ ...snackState, ...options });
    },
  }));

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={snackState.open}
      autoHideDuration={snackState.duration}
      onClose={handleSnackClose}
      message={<span id="message-id">{snackState.text}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleSnackClose}
        >
          <Icon>close</Icon>
        </IconButton>,
      ]}
    />
  );
};

export default forwardRef(CoSnackbar);
