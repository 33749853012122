import { Box, ButtonBase, Icon, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useContext } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import C from '../../../../../shared/constants/general';
// import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import UserAvatar from '../ui/UserAvatar';
import { DiscussionConnection } from './DiscussionConnection';
import { DiscussionVisitor } from './DiscussionVisitor';

const itemStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'left',
  },
  titleText: {
    fontSize: '1.25rem',
    lineHeight: 1.2,
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  arrowButton: {
    color: theme.palette.text.primary,
    padding: 5,
    paddingTop: 0,
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  holder: {
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'left',
    width: '100%',
    borderTopColor: theme.palette.background.shadedown_20,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    paddingTop: 8,
    paddingBottom: 16,
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: theme.palette.background.shadedown_20,
    },
  },
  replies: {
    textDecoration: 'underline',
  },
  content: {
    position: 'relative',
    width: '100%',
  },
  pin: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
    padding: 5,
    opacity: 0.6,
    '& > *': {
      fontSize: '1rem',
    },
  },
  timeAgo: {
    // paddingLeft: 8,
    fontSize: '0.75rem',
    opacity: 0.5,
    fontStyle: 'italic',
  },
  eventCircle: {
    display: 'inline-block',
    marginRight: 8,
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    fontSize: 12,
    padding: 5,
    width: 30,
    textAlign: 'center',
  },
  avatar: {
    marginTop: 5,
    marginRight: 8,
  },
  topic: {
    textTransform: 'uppercase',
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    padding: 2,
    fontSize: '0.7rem',
    borderRadius: 3,
    opacity: 0.6,
  },
}));

export const DiscussionDetail = (props) => {
  const classes = itemStyles({
    alwaysSmall: props.alwaysSmall,
  });
  // const { authState } = useContext(AuthContext);

  const { conferenceState, openUserPopup } = useContext(ConferenceContext);
  const fullyDisplayed =
    props.discussion.state === 'show' ||
    props.discussion.state === 'in_connection';

  const lastReply = moment
    .utc(props.discussion.last_message_date)
    .utcOffset(-conferenceState.conferenceDetail.utc_offset)
    .fromNow();

  const connectionData =
    props.discussion.connection_type !== null && !props.isEmbedded
      ? props.discussion[props.discussion.connection_type]
      : null;
  const anonymous =
    (props.discussion &&
      props.discussion.discussion_topic &&
      props.discussion.discussion_topic.ai &&
      window.SETTINGS.AI_ANONYMOUS) ||
    false;
  return (
    <Box
      onClick={() => props.gotoDiscussion(props.discussion.id)}
      className={classes.holder}
      style={{ opacity: fullyDisplayed ? 1 : 0.7 }}
    >
      {/*
      Vi väntar med up/down vote tills vi har en vettig aloritm för sortering
      <Box>
        <Box display="flex" flexDirection="column">
          <ButtonBase className={classes.arrowButton}>
            <Icon>arrow_drop_up</Icon>
          </ButtonBase>
          {authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR && (
            <ButtonBase className={classes.arrowButton}>
              <Icon>arrow_drop_down</Icon>
            </ButtonBase>
          )}
        </Box>
      </Box>
          */}
      <Box className={classes.avatar}>
        {anonymous ? (
          <Icon fontSize="medium">help</Icon>
        ) : (
          <UserAvatar user={props.discussion.visitor} size="small" />
        )}
      </Box>
      <Box flexGrow={1} pb={1} className={classes.content}>
        {props.discussion.state === 'suggested' && (
          <Box>Suggested, sent to moderator</Box>
        )}
        {props.discussion.state === 'hidden' && (
          <Box>Hidden, only moderators can see</Box>
        )}
        <Box className={classes.title}>
          <Typography variant="body2" className={classes.titleText}>
            {props.discussion.title}
          </Typography>
        </Box>
        {props.discussion.visitor && !anonymous && (
          <Box>by {props.discussion.visitor.full_name}</Box>
        )}
        {props.discussion.nr_messages > 0 && (
          <Box>
            <span className={classes.timeAgo}>Last reply {lastReply}</span>
          </Box>
        )}

        {props.discussion.url && (
          <Box pt={1}>
            <Link target="_blank" href={props.discussion.url}>
              {props.discussion.url}
            </Link>
          </Box>
        )}
        {connectionData && (
          <Box pt={1}>
            <DiscussionConnection
              type={props.discussion.connection_type}
              picture={connectionData.picture}
              title={connectionData.title}
            />
          </Box>
        )}

        {props.discussion.pinned > 0 && (
          <Box className={classes.pin}>
            <Icon>push_pin</Icon>
          </Box>
        )}
        {props.discussion.text && (
          <Box pt={1}>
            {props.discussion.text.length > 120
              ? `${props.discussion.text.substring(0, 120)}...`
              : props.discussion.text}
          </Box>
        )}
        {props.discussion.discussion_topic && (
          <Box pt={1}>
            <span className={classes.topic}>
              # {props.discussion.discussion_topic.title}
            </span>
          </Box>
        )}
      </Box>
      <Box className={classes.info}>
        <Icon fontSize="small">comment</Icon>
        {props.discussion.nr_messages}
      </Box>
      <Box className={classes.info}>
        <Icon fontSize="small">visibility</Icon>
        <br />
        {props.discussion.views > 999
          ? `${props.discussion.views.toString().substring(0, 1)}K`
          : props.discussion.views}
      </Box>
    </Box>
  );
};

DiscussionDetail.displayName = 'DiscussionDetail';
