import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

interface IProps {
  id: string;
  onDrop?: (props: {
    source: DraggableLocation;
    destination: DraggableLocation;
  }) => unknown;
  children: ReactNode | ReactNode[];
}

interface IProvidedProps extends Pick<DroppableProvided, 'droppableProps'> {
  innerRef: DroppableProvided['innerRef'];
  [x: string]: any;
}

export const withDroppable = (
  WrappedComponent: React.ComponentType<IProps & Partial<IProvidedProps>>
) => {
  const WithDroppable = ({
    id,
    onDrop,
    children,
    ...props
  }: IProps & Partial<IProvidedProps>) => {
    const theme = useTheme<Theme>();

    const handleDragEnd = async ({ source, destination }: DropResult) => {
      if (!destination) {
        return;
      }

      if (typeof onDrop === 'function') {
        onDrop({ source, destination });
      }
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={id}>
          {(provided, snapshot) => (
            <WrappedComponent
              id={id}
              innerRef={provided.innerRef}
              style={
                snapshot.isDraggingOver
                  ? { background: theme.palette.grey[300] }
                  : {}
              }
              {...provided.droppableProps}
              {...props}
            >
              {children}
              {provided.placeholder}
            </WrappedComponent>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return WithDroppable;
};
