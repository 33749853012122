import { makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { INode, INodeProps } from '@/types/node';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
import clsx from 'clsx';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'start' | 'end' | 'title' | 'description' | 'location'> & {
    override: Record<string, any>;
  }
>((theme) => ({
  root: ({ override }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textTransform: 'uppercase',
    ...override.root,
  }),
}));

/**
 * Embed Node description...
 */
export const Render = ({
  node: {
    id,
    override,
    content: { start, end, label, title, description },
  },
}: IProps) => {
  const classes = useStyles({ override });
  const { conferenceState } = useContext(ConferenceContext);
  // git tix

  return (
    <div {...useNodeProps(id)}>
      <div
        title="Add to Calendar"
        className={clsx(classes.root, 'addeventatc')}
      >
        {label}
        <span className="start">{start}</span>
        <span className="end">{end}</span>
        <span className="timezone">
          {conferenceState.conferenceDetail.timezone}
        </span>
        <span className="title">{title}</span>
        <span className="description">{description}</span>
        <span className="location">{`https://${
          (window as any).location.hostname
        }`}</span>
      </div>
    </div>
  );
};
