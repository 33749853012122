import { AuthContext } from '@/js/shared/context/AuthContext';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { useContext, useMemo } from 'react';

export const useLinkAction = () => {
  const { openUserPopup, openNewThreadPopup } = useContext(ConferenceContext);
  const { authState } = useContext(AuthContext);

  const linkActionCall = useMemo(
    () => (item: any, urlMethod: 'to' | 'href' = 'to') => {
      // console.log('item', item);
      const ACTION_STRING = 'action:';
      if (item.url.startsWith(ACTION_STRING)) {
        const [action, param] = item.url
          .substr(ACTION_STRING.length)
          .split(':');

        if (!authState.userLoggedIn) {
          return {
            onClick: (e: any) => {
              e.preventDefault();
              console.log('not logged in!', action);
              return false;
            },
          };
        }
        switch (action) {
          case 'userpopup': {
            return {
              onClick: (e: any) => {
                e.preventDefault();
                openUserPopup({ id: param, full_name: 'Loading...' });
                return false;
              },
            };
          }
          case 'userdm': {
            return {
              onClick: (e: any) => {
                e.preventDefault();
                openNewThreadPopup([], 'center', param);
                return false;
              },
            };
          }
        }
        return {
          onClick: (e: any) => {
            e.preventDefault();
            console.log('action', action);
            return false;
          },
        };
      } else {
        return {
          [urlMethod]: urlMethod === 'to' ? { pathname: item.url } : item.url,
          target: item.target == '_blank' ? '_blank' : '_self',
        };
      }
    },
    []
  );

  return {
    linkActionCall,
  };
};
