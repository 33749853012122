import { Box, makeStyles, Theme } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { INode, INodeProps } from '@/types/node';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'html'> & { override: Record<string, any> }
>((theme) => ({
  root: ({ override }) => ({
    width: '100%',
    '& .pretix-widget': {
      border: 'none',
    },
    '& .pretix-widget a': {
      color: theme.palette.primary.main,
    },
    '& .pretix-widget button': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    ...override.root,
  }),
}));

/**
 * Embed Node description...
 */
export const Render = ({
  node: {
    id,
    override,
    content: { eventUrl },
  },
}: IProps) => {
  const classes = useStyles({ override });
  const { conferenceState } = useContext(ConferenceContext);

  useEffect(() => {
    window.PretixWidget && window.PretixWidget.buildWidgets();
  }, []);

  const url =
    eventUrl ||
    (conferenceState.conferenceDetail.pretix_id &&
      conferenceState.conferenceDetail.organization &&
      conferenceState.conferenceDetail.organization.pretix_id &&
      `https://pretix.eu/${conferenceState.conferenceDetail.organization.pretix_id}/${conferenceState.conferenceDetail.pretix_id}`) ||
    '';
  console.log('url', url);
  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {url ? (
        <pretix-widget event={url}></pretix-widget>
      ) : (
        <Box p={2}>No pretix url or integration</Box>
      )}
    </div>
  );
};
