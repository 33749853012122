import { SiteHeader } from '@/components/site-header';
import { Box, Container, CssBaseline, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getImage, ImageSize } from '../../../../shared/utils/imageFormat';
import CoeoFooter from './ui/CoeoFooter';
import VisitorNav from './VisitorNav';

const useStyles = makeStyles((theme) => ({
  bodyBackground: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  contentHolder: {
    padding: '0 0 40px 0',
  },
}));

const VisitorContainer = (props) => {
  const classes = useStyles();

  return (
    <Box
      flexGrow={1}
      className={classes.bodyBackground}
      display="flex"
      flexDirection="column"
    >
      <CssBaseline />
      {props.isLoggedIn && <VisitorNav />}
      <SiteHeader
        conferenceId={props.conferenceId}
        backdropSrc={getImage(
          props.conference.image,
          ImageSize.Original,
          props.conference.id
        )}
        title={
          props.conference.image_logo ? (
            <img
              id="heading_logo"
              src={getImage(
                props.conference.image_logo,
                ImageSize.Large,
                props.conference.id
              )}
              alt={props.conference.title}
            />
          ) : (
            <Typography align="center" variant="h2">
              {props.conference.title}
            </Typography>
          )
        }
        subTitle={
          props.conference.sub_title && (
            <Typography align="center" variant="h6">
              {props.conference.sub_title}
            </Typography>
          )
        }
      />
      <Box flexGrow={1}>
        <Container className={classes.contentHolder} maxWidth="lg">
          {props.children}
        </Container>
      </Box>
      <CoeoFooter {...props} />
    </Box>
  );
};

export default VisitorContainer;
