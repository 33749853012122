import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { TopicSelector } from '@/components/topic-selector';
import { ManagerMarkdownEditor } from '@/js/shared/components/markdown/ManagerMarkdownEditor';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';

import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';
import { PollSelector } from '@/components/poll-selector';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { pollIds = [], answered = '' },
  },
  conferenceId,
}: IProps) => {
  const { control, setValue } = useFormContext();

  // Prepare to use multiple polls
  const setPollId = (newVal: string) => {
    setValue('pollIds', newVal ? [newVal] : []);
  };
  const pollId = pollIds.length > 0 ? pollIds[0] : '';
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        Select a Poll
        <PollSelector
          onChange={(newVal) => setPollId(newVal)}
          conferenceId={conferenceId}
          value={pollId}
        />
        <br />
        <InputControl
          fullWidth
          name="answered"
          label="Text when answered"
          defaultValue={answered}
          variant="outlined"
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
