import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import moment from 'moment';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { RegisterCustomFields } from '@/js/shared/components/ui/RegisterCustomFields';
import { useForm } from 'react-hook-form';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useItemStyles = makeStyles((theme: Theme) => ({
  root: {},
  field: {
    // backgroundColor: '#222',
    width: '100%',
    '& input::placeholder': {
      opacity: 0.1,
    },
  },
}));

const FormHandler = ({
  callback,
  cancel,
  showTickets,
}: {
  callback: (formData: any) => void;
  cancel: () => void;
  showTickets: boolean;
}) => {
  const classes = useItemStyles();
  const { conferenceState } = useContext(ConferenceContext);
  const { authState } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm(
    {
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        custom: authState.user.registration_data,
      },
    },
    []
  );
  useEffect(() => {
    // reset({ custom: authState.user.registration_data });
  }, []);

  const onSubmit = () => {
    const formData = getValues();
    callback(formData);
  };

  const regFields = conferenceState.conferenceDetail.registration_fields.fields.filter(
    (f: any) => showTickets || f.name !== 'tickets'
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RegisterCustomFields
          fields={regFields}
          register={register}
          fieldClass={classes.field}
          setValue={setValue}
          control={control}
        />
        <Box display="flex">
          <Box>
            <Button color="default" onClick={cancel}>
              Cancel
            </Button>
          </Box>

          <Box textAlign="right" flexGrow={1}>
            <Button type="submit" variant="contained" color="primary">
              Done
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
    info: {
      padding: 8,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.background.shadedown_20,
      '&:last-child': {
        border: 'none',
      },
    },
    text: {
      padding: 8,
      fontStyle: 'italic',
      opacity: 0.7,
    },
  })
);

interface MappedData {
  label: string;
  type: string;
  component: string;
  value: any;
}

export const Render = ({
  node: {
    id,
    override,
    content: { editable, showTickets },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState, actions } = useContext(AuthContext);
  const { conferenceState } = useContext(ConferenceContext);
  const [mappedData, setMappedData] = useState<MappedData[]>([]);
  const [editing, setEditing] = useState<boolean>(false);

  const mapCustomData = () => {
    if (conferenceState.conferenceDetail?.registration_fields?.fields) {
      const mapped: MappedData[] = [];
      const currentRegData = authState.user.registration_data || {};
      const regFields = conferenceState.conferenceDetail.registration_fields.fields.filter(
        (f: any) => showTickets || f.name !== 'tickets'
      );
      regFields.forEach((f: any) => {
        if (f.component != 'display-text') {
          const val = !currentRegData.hasOwnProperty(f.name)
            ? null
            : f.component === 'select'
            ? (
                currentRegData.hasOwnProperty(f.name) &&
                f.options.find(
                  (fo: { value: string; label: string }) =>
                    fo.value === currentRegData[f.name]
                )
              )?.label || null
            : currentRegData[f.name];
          mapped.push({
            component: f.component,
            label: f.label,
            type: f.type,
            value: val,
          });
        }
      });
      setMappedData(mapped);
    }
  };

  useEffect(() => {
    authState.userLoggedIn && mapCustomData();
  }, [authState.user && authState.user.registration_data]);

  const saveData = (formData: any) => {
    axios
      .put('/api/me/customdata', {
        registration_data: {
          ...formData.custom,
          ...(!showTickets && authState.user.registration_data?.tickets
            ? { tickets: authState.user.registration_data?.tickets }
            : {}),
        },
      })
      .then(() => {
        actions.getUser();
        setEditing(false);
      });
  };

  const cancel = () => {
    setEditing(false);
  };
  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <Box display="flex" flexDirection="column">
          {editing ? (
            <Box>
              <FormHandler
                callback={saveData}
                cancel={cancel}
                showTickets={showTickets}
              />
            </Box>
          ) : (
            <Box>
              {mappedData.map((md, i) => (
                <Box key={i} className={classes.info}>
                  <Typography variant="subtitle2">{md.label}</Typography>
                  {md.value === null ? (
                    <Typography color="error">-</Typography>
                  ) : md.type === 'string' ? (
                    md.value
                  ) : md.type === 'boolean' ? (
                    md.value ? (
                      <Icon>check</Icon>
                    ) : (
                      <Icon>not_interested</Icon>
                    )
                  ) : (
                    ''
                  )}
                </Box>
              ))}
              {editable && (
                <Box textAlign="right">
                  <Button
                    startIcon={<Icon>edit</Icon>}
                    onClick={() => setEditing(true)}
                    color="primary"
                  >
                    {window.LANG.EDIT}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
