import { useReducer } from 'react';
import { mediaReducer, mediaInitialState } from './reducer';
import { addMediaService, fetchMediaService } from './services';

export const useMedia = (conferenceId: string) => {

    const [state, dispatch] = useReducer(
        mediaReducer,
        mediaInitialState
    )

    const addMedia = (name: string, fileName: string) => addMediaService(conferenceId, name, fileName)
        .then((response) => dispatch({type: 'MEDIA_ADD', payload: response.data}))

    const loadMedia = () => fetchMediaService(conferenceId)
        .then((response) => dispatch({type: 'MEDIA_LOADED', payload: response.data.files}))

    return {
        ...state,
        loadMedia,
        addMedia
    }
}
