import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  Popper,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from 'react';
import C from '../../../../../shared/constants/general';
import { AuthContext } from '../../../shared/context/AuthContext';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import UserAvatar from '../ui/UserAvatar';
import { infoList } from '../../utils/DataFormat';

const useStyles = makeStyles((theme) => ({
  paperBg: {
    backgroundColor: theme.palette.background.shadedown_10,
  },
  visitorList: {
    backgroundColor: theme.palette.background.default,
  },
  parentText: {
    maxHeight: 60,
    overflow: 'hidden',
  },
  pinField: {
    width: 80,
  },
}));

export const DiscussionTagSomeone = forwardRef((props, ref) => {
  const classes = useStyles();
  const [visitors, setVisitors] = useState([]);
  const [taggedVisitor, setTaggedVisitor] = useState(null);
  const [open, setOpen] = useState(false);
  const [postError, setPostError] = useState(null);
  const [sending, setSending] = useState(false);
  const [discussionId, setDiscussionId] = useState(null);

  useImperativeHandle(ref, () => ({
    open: ({ discussionId: id }) => {
      setOpen(true);
      setDiscussionId(id);
      loadVisitorsToTag();
    },
  }));

  const cancelHandler = () => {
    setOpen(false);
    setPostError(null);
    setVisitors([]);
    setSending(false);
    setDiscussionId(null);
    setTaggedVisitor(null);
  };

  const sendingDone = () => {
    cancelHandler();
  };

  const save = () => {
    if (taggedVisitor) {
      axios
        .post(`/api/visitor/discussions/${discussionId}/suggest`, {
          taggedVisitorId: taggedVisitor.id,
        })
        .then((response) => {
          setSending(true);
          setTimeout(() => sendingDone(), 2000);
        })
        .catch((error) => setPostError(error.response.data.message));
    } else {
      setPostError('You need to pick someone');
    }
  };

  const loadVisitorsToTag = () => {
    axios
      .get(`/api/visitor/conferences/${window.CONFERENCE_ID}/visitors/minimal`)
      .then((response) => {
        setVisitors(
          response.data.map((v) => ({
            ...v,
            optionLabel: infoList([v.full_name, v.company, v.location_city]),
          }))
        );
      })
      .catch((error) => {
        setPostError(error.response.data.message);
      });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={cancelHandler}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="Notify someone"
      PaperProps={{ className: classes.paperBg }}
    >
      {' '}
      {sending ? (
        <DialogContent>
          <Box p={4} align="center" color="primary.main">
            {taggedVisitor.full_name} have been notified!
          </Box>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>Notify someone you think will be interested</DialogTitle>
          <DialogContent>
            <Box pb={1} display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Autocomplete
                  options={visitors}
                  getOptionLabel={(option) => option.optionLabel || ''}
                  renderOption={(option) => (
                    <>
                      <Chip
                        key={option.id}
                        avatar={<UserAvatar user={option} />}
                        label={infoList([
                          option.full_name,
                          option.company,
                          option.location_city,
                        ])}
                      />
                    </>
                  )}
                  PopperComponent={(props) => {
                    return <Popper {...props} placement="top-start" />;
                  }}
                  classes={{
                    popper: classes.visitorList,
                  }}
                  getOptionSelected={(opt, val) => opt.id === val.id}
                  filterOptions={createFilterOptions({
                    limit: 8,
                  })}
                  fullWidth
                  onChange={(e, v) => setTaggedVisitor(v)}
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      label="Send notification to:"
                      variant="outlined"
                      placeholder="Find by Name, Org, city."
                    />
                  )}
                />
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Box display="flex" className={classes.buttonList} flexGrow={1}>
              {postError && (
                <Box align="right" color="error.main">
                  {postError}
                </Box>
              )}
              <Box flexGrow={1} align="right">
                <Button onClick={cancelHandler}>Cancel</Button>

                <Button color="primary" onClick={save}>
                  Post
                </Button>
              </Box>
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
});

DiscussionTagSomeone.displayName = 'DiscussionTagSomeone';
