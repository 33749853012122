import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ConferenceContext } from '../../context/ConferenceContext';
import { lineBreakToBR } from '../../utils/StringFormat';

// import C from '../../../../shared/constants/general';

const userTermsText = `

The Coeo platform using Google Analitics to learn about what types of phones and computers are being used, to improve the service, but no personal data are being saved there.

All recorded video and handling of other data collected during the ticket registration are under the event organiser's privacy agreement.

By regestering to this event you also agree to Coeo Privacy Policy, read more at:
https://www.coeo.events/privacy-policy

For other questions or actions relating to GDPR please contact support@coeo.events`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  userAgreementDialog: {
    margin: '0 -16px',
  },
  userAgreementBg: {
    backgroundColor: theme.palette.background.default,
  },
  userAgreementLink: {
    fontSize: 'inherit',
  },
  checkbox: {
    color: theme.palette.text.primary,
    padding: '0 9px',
    marginLeft: -9,
  },
}));

export const UserAgreement = ({
  errors,
  values,
  onChange,
}: {
  errors: any;
  values: any;
  onChange: (val: string) => void;
}) => {
  const classes = useStyles();
  const descriptionElementRef = useRef(null);

  const [userAgreementDialog, setUserAgreementDialog] = useState({
    open: false,
    agreement: '',
  });

  const { conferenceState } = useContext(ConferenceContext);

  useEffect(() => {
    if (userAgreementDialog.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [userAgreementDialog.open]);

  const toggleUserAgreement = (open: boolean) => {
    console.log('toggleUserAgreement', open);
    setUserAgreementDialog({
      open: open,
      agreement: lineBreakToBR(
        conferenceState?.conferenceDetail?.privacy_agreement + userTermsText
      ),
    });
  };

  return (
    <>
      <div>
        {errors.agreed && (
          <FormHelperText error>{window.LANG.REG_CHECK}</FormHelperText>
        )}
        <Box display="flex">
          <Checkbox
            checked={values.checked}
            className={classes.checkbox}
            color="primary"
            onChange={(e) => onChange(e.target.checked)}
          />
          <Box flexGrow={1}>
            {window.LANG.REG_AGREE}{' '}
            <Link
              href="#"
              onFocus={(e) => console.log('onFocus', e)}
              // component="a"
              className={classes.userAgreementLink}
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                toggleUserAgreement(true);
              }}
            >
              {window.LANG.REG_TERMS}
            </Link>
          </Box>
        </Box>
      </div>

      <Dialog
        className={classes.userAgreementDialog}
        open={userAgreementDialog.open}
        onClose={() => toggleUserAgreement(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{ className: classes.userAgreementBg }}
      >
        <DialogTitle id="scroll-dialog-title">User Terms & Privacy</DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            dangerouslySetInnerHTML={{
              __html: userAgreementDialog.agreement,
            }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleUserAgreement(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
UserAgreement.displayName = 'UserAgreement';
