import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Link,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { infoList } from '../../utils/DataFormat';
import FollowToggle from '../lists/FollowToggle';
import EntityListItem from './EntityListItem';
import CoChipSmall from './CoChipSmall';
import UserAvatar from './UserAvatar';
import C from '../../../../../shared/constants/general';

const useStyles = makeStyles((theme) => ({
  avatarBg: {
    paddingTop: '8px!important',
    paddingBottom: '8px!important',
    backgroundColor: theme.palette.background.shadedown_20,
    overflow: 'hidden',
    flexShrink: 0,
    position: 'relative',
  },
  isOnline: {
    display: 'inline-block',
    backgroundColor: '#000000aa',
    position: 'absolute',
    left: 8,
    top: 8,
    padding: '2px 12px 3px 23px',
    color: '#fff',
    borderRadius: 15,
    lineHeight: '19px',
    '&::before': {
      position: 'absolute',
      top: 7,
      left: 7,
      content: '""',
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: 10,
      backgroundColor: '#29ff00',
    },
  },
  paperHolder: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  paperBg: {
    backgroundColor: theme.palette.background.default,
    margin: '40px 8px 32px 8px',
  },
  dialogContent: {
    padding: 8,
    minWidth: 300,
  },
  dialogTitle: {
    padding: '8px 16px 0px 16px',
  },
  dialogBg: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
  },
  infoCompany: {
    color: '#777777',
  },
  infoTitle: {
    textTransform: 'uppercase',
    opacity: 0.7,
  },
  infoCity: {
    fontWeight: 600,
  },
  launchIcon: {
    verticalAlign: 'bottom',
  },
  info_description: {
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    padding: 15,
  },
  buttonsPrevNext: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.shadedown_20,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ParticipantPopup = (props, ref) => {
  const classes = useStyles();
  const { userState, actions } = useContext(UserContext);
  const { authState } = useContext(AuthContext);
  const [dialogState, setDialog] = useState({
    open: false,
    user: null,
    userlist: null,
  });

  const isMe =
    authState.user &&
    dialogState.user &&
    authState.user.id === dialogState.user.id;

  const handleDialogClose = () => {
    setDialog({ open: false, user: null, userlist: null });
  };

  const updateUser = (userData) => {
    setDialog((prevState) => ({ ...prevState, user: userData }));
  };

  const stepInList = (step) => {
    const index = dialogState.userlist.findIndex(
      (item) => item.id === dialogState.user.id
    );
    const nextUserIndex =
      (dialogState.userlist.length + index + step) %
      dialogState.userlist.length;
    setDialog({
      ...dialogState,
      user: dialogState.userlist[nextUserIndex],
    });
    actions.loadPublicProfile(dialogState.userlist[nextUserIndex].id);
  };

  useEffect(() => {
    if (dialogState.user && dialogState.user.id && userState.userLoaded) {
      setDialog({ ...dialogState, user: userState.user });
    }
  }, [dialogState.user && dialogState.user.id, userState.userLoaded]);

  useImperativeHandle(ref, () => ({
    setState: (options) => {
      setDialog({ ...dialogState, ...options });
      if (options.user) {
        actions.loadPublicProfile(options.user.id);
      }
    },
  }));

  return (
    <Dialog
      style={{ zIndex: 1350 }}
      open={dialogState.open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogBg}
      classes={{
        container: classes.paperHolder,
      }}
      PaperProps={{ className: classes.paperBg }}
    >
      {dialogState.user && (
        <>
          {dialogState.userlist && dialogState.userlist.length > 1 && (
            <div className={classes.buttonsPrevNext}>
              <Button
                startIcon={<Icon>chevron_left</Icon>}
                size="small"
                color="primary"
                onClick={() => stepInList(-1)}
              >
                {window.LANG.ECARD_PREV}
              </Button>
              <Button
                endIcon={<Icon>chevron_right</Icon>}
                size="small"
                color="primary"
                onClick={() => stepInList(1)}
              >
                {window.LANG.ECARD_NEXT}
              </Button>
            </div>
          )}

          <DialogContent align="center" className={classes.avatarBg}>
            <UserAvatar
              customSize={{ width: 150, height: 150, fontSize: 30 }}
              user={dialogState.user}
              rerender={true}
            />
            {dialogState.user.isOnline && (
              <div className={classes.isOnline}>online</div>
            )}
          </DialogContent>
          <DialogTitle
            className={classes.dialogTitle}
            id="alert-dialog-title"
            align="center"
          >
            {dialogState.user.full_name}
          </DialogTitle>

          <DialogContent className={classes.dialogContent} align="center">
            {dialogState.user.fullData ? (
              <Box>
                {dialogState.user.title && (
                  <Box className={classes.infoTitle}>
                    {dialogState.user.title}
                  </Box>
                )}
                {(dialogState.user.company ||
                  dialogState.user.location_city) && (
                  <Box pb={1} className={classes.infoCity}>
                    {infoList([
                      dialogState.user.company,
                      dialogState.user.location_city,
                    ])}
                  </Box>
                )}
                {!isMe && (
                  <>
                    <FollowToggle
                      updatedCallback={updateUser}
                      user={dialogState.user}
                    />
                  </>
                )}
                <br />
                {dialogState.user.tags
                  .filter((tag) => tag.type === 'skill')
                  .map((tag) => (
                    <CoChipSmall
                      key={tag.id}
                      tagtype={tag.type}
                      label={tag.title}
                    />
                  ))}
                {dialogState.user.tags
                  .filter((tag) => tag.type === 'scouting')
                  .map((tag) => (
                    <CoChipSmall
                      key={tag.id}
                      tagtype={tag.type}
                      label={tag.title}
                    />
                  ))}
                {dialogState.user.entities.map((ent) => (
                  <Box p={1} key={ent.id}>
                    <EntityListItem
                      url={`/live/item/${ent.id}`}
                      entity={ent}
                      // history={props.history}
                    />
                  </Box>
                ))}
                {authState.user.access_level ===
                  C.VISITOR.ACCESS_LEVEL.MODERATOR && (
                  <>
                    <br />
                    <Link
                      target="_blank"
                      href={`/profile/${dialogState.user.id}`}
                    >
                      View profile{' '}
                      <Icon className={classes.launchIcon} fontSize="small">
                        launch
                      </Icon>
                    </Link>
                  </>
                )}
                <br />
                {dialogState.user.tags && dialogState.user.tags.length > 0 && (
                  <br />
                )}
                <Box className={classes.info_description}>
                  {dialogState.user.info_description}
                </Box>
              </Box>
            ) : (
              <Box align="center">
                <CircularProgress disableShrink />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="default">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default forwardRef(ParticipantPopup);
