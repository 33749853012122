import { ConditionalRenderer } from '@/components/conditional-renderer';
import { NodeContextProvider } from '@/node-editor';
import { NodeContext } from '@/node-editor/context';
import { getNodes } from '@/node-editor/utilities';
import { Render } from '@/nodes/root';
import { INode, INodeTemplate } from '@/types/node';
import React from 'react';
import * as layouts from '../../../../shared/templates/layouts';
import { ContentWindow } from '../preview/content-window';

interface IProps {
  model?: 'page';
  entity: {
    name: string;
    nodes?: INode[];
    slug: string;
    template?: Partial<INodeTemplate[]>;
  };
}

export const RenderComponent = ({ entity }: IProps) => {
  const templates: Record<string, INodeTemplate[]> = layouts as any;

  return (
    <NodeContextProvider
      value={{ ...getNodes(entity, templates) }}
      readOnly
    >
      <ConditionalRenderer
        condition={window.self !== window.top}
        render={(children) => (
          <NodeContext.Consumer>
            {(provided) => (
              <ContentWindow slug={entity.slug} {...provided}>{children}</ContentWindow>
            )}
          </NodeContext.Consumer>
        )}
      >
        <Render />
      </ConditionalRenderer>
    </NodeContextProvider>
  );
};
