import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Custom Fields',
  icon: 'perm_identity',
  type: 'custom-fields',
  tag: 'components',
  content: {
    editable: false,
    showTickets: false,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
