import { Box, Button, Container, Icon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthContext } from '../shared/context/AuthContext';

import Profile from './components/dashboards/Profile';
import ProfileEdit from './components/dashboards/ProfileEdit';
// import Register from './components/dashboards/Register';
import VerifyEmail from './components/dashboards/VerifyEmail';
import PasswordReset from './components/PasswordReset';
import TokenLogin from './components/dashboards/TokenLogin';
import { BundleRoute } from '@/components/bundle-route';
import ProfileNotificationSettings from './components/dashboards/ProfileNotificationSettings';
import { Loader } from '@/components/loader';
import { SitePage } from '@/pages/site/site-page';
import LoginBox from '../shared/components/loginbox';
import { ConferencePagesContext } from '@/page-editor/context';
import { AuthWall } from '../shared/components/AuthWall';
import { OpenASK } from './components/dashboards/OpenASK';

const SiteRoutes = () => {
  const { authState } = useContext(AuthContext);

  return authState.stateLoaded ? (
    <Router>
      <React.Suspense fallback={<Loader />}>
        <ConferencePagesContext.Consumer>
          {({ getPagesArray }) => (
            <Switch>
              <Route
                exact
                path="/reset/:type/:id"
                render={(props) => <PasswordReset {...props} />}
              />
              <Route
                exact
                path={`/verify/:verificationId`} // Site
                render={(props) => <VerifyEmail {...props} />}
              />
              <Route
                exact
                path={`/autologin/:tokenid`} //
                render={(props) => (
                  <TokenLogin
                    conferencename={window.CONFERENCE_NAME}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path={`/profile/edit`} // Site
                render={(props) => (
                  <AuthWall>
                    <ProfileEdit {...props} />
                  </AuthWall>
                )}
              />
              <Route
                exact
                path={`/profile/account`} // Site
                render={(props) => (
                  <AuthWall>
                    <Profile isMe={true} {...props} />
                  </AuthWall>
                )}
              />
              <Route
                exact
                path={`/profile/notifications/`} // Site
                render={(props) => (
                  <AuthWall>
                    <ProfileNotificationSettings {...props} />
                  </AuthWall>
                )}
              />
              <Route
                exact
                path={`/profile/:id`} // Site
                render={(props) =>
                  !authState.userLoggedIn ? (
                    <LoginBox />
                  ) : (
                    <Profile {...props} />
                  )
                }
              />
              <Route
                exact
                path={`/ask/:id`} // Site
                render={(props) => <OpenASK id={props.match.params.id} />}
              />
              {/* <Route
                exact
                path={`/video/:roomId`} // Används inte
                render={(props) => (
                  <RoomContext>
                    <VideoFullscreen
                      conferencename={window.CONFERENCE_NAME}
                      {...props}
                    />
                  </RoomContext>
                )}
              /> */}

              {getPagesArray().map(({ id, slug, locked }) => (
                <Route
                  key={`${slug}-${id}`}
                  exact
                  path={slug}
                  render={(props) =>
                    locked && !authState.userLoggedIn ? (
                      <Box>
                        <Container maxWidth="xs">
                          <Box>
                            <Button startIcon={<Icon>home</Icon>} href="/">
                              Home
                            </Button>
                          </Box>
                          <LoginBox />
                        </Container>
                      </Box>
                    ) : (
                      <SitePage authState={authState} {...props} />
                    )
                  }
                />
              ))}
              <BundleRoute bundle="site" />
            </Switch>
          )}
        </ConferencePagesContext.Consumer>
      </React.Suspense>
    </Router>
  ) : (
    <Box align="center">
      <CircularProgress disableShrink />
    </Box>
  );
};

export default SiteRoutes;
