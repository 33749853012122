import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { design, addtocalendar },
  },
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <RadioGroupControl
          row
          label="Design"
          name="design"
          defaultValue={design}
          options={[
            {
              label: 'Image, Date & Title',
              value: 'dateimage',
            },
            {
              label: 'Only image',
              value: 'image',
            },
          ]}
        />
        <InputControl
          name="addtocalendar"
          defaultValue={addtocalendar}
          fullWidth
          variant="outlined"
          label="Calendar button (blank to remove)"
        />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
