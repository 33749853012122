import EntityListView from '@/js/shared/components/dashboards/EntityListView';
import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      ...override.root,
    }),
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { entityListId = '', minGridWith = 3 },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [entityList, setEntityList] = useState<any>(null);

  useEffect(() => {
    if (entityListId) {
      axios.get(`/api/visitor/entitylists/${entityListId}`).then((response) => {
        setEntityList(response.data);
      });
    }
  }, [entityListId]);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        entityList ? (
          <EntityListView
            entityListId={entityListId}
            minGridWith={minGridWith}
          />
        ) : null
      ) : (
        <Box textAlign="center">{(window as any).LANG.AUTH_REQUIRED}</Box>
      )}
    </div>
  );
};
