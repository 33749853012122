import { Edit } from './edit';
import { Render } from './render';
import { INodeTemplate } from '../../types/node';

const template: INodeTemplate = {
  version: 1,
  name: 'Button',
  icon: 'local_activity',
  type: 'card',
  tag: 'content',
  content: {
    text: 'Title',
    icon: 'star',
    link: {
      target: '_self',
      targetUrl: '',
      targetRel: undefined,
    },
    backgroundColor: undefined,
    textColor: undefined,
    backgroundImage: undefined,
    backgroundSize: 'cover',
    maxWidth: '4',
    minWidth: '1',
    visibleForTicket: '',
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
  override: {
    root: {},
    aspect: {},
    content: {},
  },
  depricated: [
    {
      migrate({ spacing, ...content }) {
        return {
          ...content,
        };
      },
    },
  ],
};

export { template, Render, Edit };
