import React from 'react';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core'

interface IProps {
    fixed?: boolean;
}

const useStyles = makeStyles<Theme, IProps>(() => ({
    container: {
        margin: 'auto'
    }
}))

export const Loader = ({ fixed }: IProps) => {

    const classes = useStyles({ fixed });

    return(
        <div className={classes.container}>
            <CircularProgress disableShrink />
        </div>
    )
}