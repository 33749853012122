import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import C from '../../../shared/constants/general';
import { Discussion } from '@/js/shared/components/discussion/Discussion';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { received, discussionId },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [discussion, setDiscussion] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [viewDiscussion, setViewDiscussion] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const post = () => {
    setSending(true);
    axios
      .post(`/api/visitor/discussions/${discussionId}/messages`, {
        text: text,
        skipNotifications: true,
      })
      .then(() => {
        setText('');
        setError('');
        setSuccess(true);
      })
      .catch((e) => {
        setError(e.response.data.message);
      })
      .then(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (discussionId) {
      axios
        .get(`/api/visitor/discussions/${discussionId}`)
        .then((response) => {
          setDiscussion(response.data);
        })
        .catch((e) => {
          setError(e.response.data.message);
        });
    }
  }, []);
  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <>
          <Box textAlign="center">
            {!discussionId && (
              <Typography color="error">Please select post</Typography>
            )}
            {discussion && discussion.title && (
              <Typography variant="h6">{discussion.title}</Typography>
            )}
            {discussion && discussion.text && (
              <MarkdownRender text={discussion.text} />
            )}
          </Box>
          <TextField
            name="text"
            value={text}
            variant="outlined"
            size="small"
            fullWidth
            multiline
            minRows={3}
            maxRows={6}
            onChange={(e) => setText(e.target.value)}
          />
          <Box display="flex">
            <Box flexGrow={1} p={1}>
              {success && received}
              {error && <Typography color="error">{error}</Typography>}
            </Box>
            <Box pt={1}>
              <UserAvatar user={authState.user} size="small" />
            </Box>
            <Button
              disabled={sending || text === '' || !discussion}
              endIcon={<Icon>send</Icon>}
              color="primary"
              onClick={post}
            >
              post
            </Button>
          </Box>
          {(authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR ||
            authState.user.participation_level ===
              C.VISITOR.PARTICIPATION_LEVEL.CONTRIBUTE) && (
            <Box>
              <Box display="flex">
                <Button
                  color="secondary"
                  startIcon={<Icon>forum</Icon>}
                  onClick={() => setViewDiscussion(!viewDiscussion)}
                >
                  {viewDiscussion ? 'hide list' : 'view list'}
                </Button>
                {authState.user.access_level ===
                  C.VISITOR.ACCESS_LEVEL.MODERATOR && (
                  <Box flexGrow={1} textAlign="right">
                    <Button
                      color="secondary"
                      startIcon={<Icon>fullscreen</Icon>}
                      href={`/moderator/forumreplies/${discussionId}`}
                      target="_blank"
                    >
                      Fullscreen view
                    </Button>
                  </Box>
                )}
              </Box>

              {viewDiscussion && (
                <Discussion
                  discussionId={discussionId}
                  isEmbedded={true}
                  alwaysSmall={true}
                />
              )}
            </Box>
          )}
        </>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
