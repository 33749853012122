import {
  Avatar,
  Badge,
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Height } from '@material-ui/icons';
import axios from 'axios';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import { DiscussionContext } from '../../context/DiscussionContext';
import { ThreadContext } from '../../context/ThreadContext';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles((theme) => ({
  followMenu: {
    width: 300,
    maxHeight: 400,
    border: '1px solid #FFFFFF99',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.background.shadedown_20,
    boxShadow: '0 0 15px rgb(0, 0, 0, 0.15)',
    padding: 5,
    borderRadius: 3,
  },
  tightMenuItem: {
    padding: '0 16px',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 1,
    top: 30,
    border: `1px solid ${theme.palette.background.default}`,
    backgroundColor: theme.palette.background.default,
    padding: '0 4px',
  },
}))(Badge);

const NOTOFICATION_TEXTS = {
  'message-response': 'New forum response to',
  'message-suggest': 'New forum suggestion',
  'discussion-response': 'New forum response to',
  'discussion-suggest': 'New forum suggestion',
};

const ThreadMenu = (props, ref) => {
  const classes = useStyles();

  const { authState } = useContext(AuthContext);
  const { threadState, loadThreads } = useContext(ThreadContext);
  const { openThreadPopup } = useContext(ConferenceContext);
  const {
    discussionNotifications,
    updateReadState,
    gotoDiscussion,
  } = useContext(DiscussionContext);

  const [menuState, setMenu] = useState({
    open: false,
    menuTarget: null,
    threads: [],
  });

  const handleClose = () => {
    setMenu({ open: false, menuTarget: null, threads: [] });
  };

  const getStortedThreads = () => {
    return [...threadState.threads].sort((a, b) =>
      a.threadVisitors.hasUnread && !b.threadVisitors.hasUnread
        ? -1
        : !a.threadVisitors.hasUnread && b.threadVisitors.hasUnread
        ? 1
        : 0
    );
  };

  useImperativeHandle(ref, () => ({
    open: (menuTarget) => {
      setMenu({ ...menuState, open: true, menuTarget: menuTarget });
      // Load list
      loadThreads();
      updateReadState();
    },
    close: handleClose,
  }));

  return (
    <Menu
      id="menu-followbar"
      anchorEl={menuState.menuTarget}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={menuState.open}
      onClose={handleClose}
      PaperProps={{ className: classes.followMenu }}
    >
      {threadState.threadsLoading || !menuState.open ? (
        <MenuItem>Loading...</MenuItem>
      ) : threadState.threads.length === 0 ? (
        <MenuItem>No private messages</MenuItem>
      ) : (
        [
          ...getStortedThreads(threadState.threads).map((thread) => {
            const txt = thread.lastThreadMessage
              ? thread.lastThreadMessage.text.substring(0, 15)
              : '';
            if (thread.visitors) {
              const visitors = thread.visitors.filter(
                (v) => v.id !== authState.user.id
              );
              const badgeContent =
                visitors.length < 2 ? 0 : `+${visitors.length - 1}`;
              return (
                <MenuItem
                  key={thread.id}
                  onClick={() => openThreadPopup(thread.id)}
                >
                  <ListItemIcon>
                    <StyledBadge badgeContent={badgeContent} color="secondary">
                      <Badge
                        invisible={!thread.threadVisitors.hasUnread}
                        variant="dot"
                        color="primary"
                      >
                        {visitors[0] ? (
                          <UserAvatar
                            size="medium"
                            user={
                              visitors.length === 1
                                ? visitors[0]
                                : thread.lastThreadMessage // Check the last message still exists
                                ? thread.lastThreadMessage.visitor
                                : null
                            }
                          />
                        ) : (
                          <Avatar />
                        )}
                      </Badge>
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      !visitors[0]
                        ? `User don't exist`
                        : txt.length === 15
                        ? `${txt}..`
                        : txt
                    }
                  />
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={thread.id}
                  onClick={() => openThreadPopup(thread.id)}
                >
                  <ListItemIcon>
                    <Badge variant="dot" color="primary">
                      <Avatar />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={'New message...'} />
                </MenuItem>
              );
            }
          }),
        ]
      )}
      <Divider />
      {discussionNotifications.length > 0 ? (
        discussionNotifications.map((dn) => (
          <MenuItem
            key={dn.id}
            onClick={() => gotoDiscussion(dn.discussionId)}
            className={classes.tightMenuItem}
          >
            <ListItemIcon>
              <StyledBadge badgeContent={dn.count} color="secondary">
                <Badge invisible={dn.viewed} variant="dot" color="primary">
                  <Icon>reply</Icon>
                </Badge>
              </StyledBadge>
            </ListItemIcon>
            <ListItemText
              primary={NOTOFICATION_TEXTS[dn.type] || 'New response'}
              secondary={dn.text}
            />
          </MenuItem>
        ))
      ) : (
        <MenuItem>No forum responses</MenuItem>
      )}
    </Menu>
  );
};

export default forwardRef(ThreadMenu);
