import { LiveContainer } from '@/js/live/LiveContainer';
import CoeoFooter from '@/js/shared/components/ui/CoeoFooter';
import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

interface IProps {
  userLoggedIn: boolean;
  path: string;
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flex: '1 0 100%',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
    backgroundColor: palette.background.default,
  },
  content: {
    marginBottom: 'auto',
  },
}));

export const SiteContainer: FC<Partial<IProps>> = ({
  userLoggedIn,
  path,
  children,
}) => {
  const classes = useStyles();

  const PageContent = () => (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
      <CoeoFooter />
    </div>
  );

  return (
    <LiveContainer isActive={(to: string) => to === path}>
      <PageContent />
    </LiveContainer>
  );
};
