export const getSortedEvents = (roomEvents) => {
  // return [...roomEvents].sort((a, b) => moment(a.start_time, 'hh:mm') - moment(b.start_time, 'hh:mm'));
  return [...roomEvents].sort((a, b) =>
    a.start_time > b.start_time ? 1 : b.start_time > a.start_time ? -1 : 0
  );
};

export const timeStringToNum = (timeStr) => {
  const [h, m, s] = timeStr.split(':').map((v) => parseInt(v));
  return h * 3600 + m * 60 + s;
};

export const getAllEvents = (conference) => {
  return conference.programDays
    .reduce((days, day) => [...days, ...day.programRooms], [])
    .reduce((events, room) => [...events, ...room.events], []);
};

export const getAllProgramRooms = (conference) => {
  return conference.programDays.reduce(
    (days, day) => [
      ...days,
      ...day.programRooms.map((programRoom) => ({
        ...programRoom,
        dayTitle: day.title,
      })),
    ],
    []
  );
};

export const getEventPlaceInProgram = (roomEvents, eventId) => {
  // Sort based on planned start time
  let sortedEvents = getSortedEvents(roomEvents);
  let eventBefore = null,
    eventAfter = null,
    placeIndex = 0;
  for (const ev of sortedEvents) {
    if (ev.id === eventId) {
      if (sortedEvents.length > placeIndex + 1) {
        eventAfter = sortedEvents[placeIndex + 1];
      }
      break;
    }
    placeIndex++;
    eventBefore = ev;
  }

  let videoLength = -1;
  //We will not have this in the future
  /*
  if (sortedEvents[placeIndex].recording_end > 0) {
    videoLength =
      sortedEvents[placeIndex].recording_end -
      sortedEvents[placeIndex].recording_start;
  } else if (placeIndex + 1 < sortedEvents.length) {
    videoLength =
      sortedEvents[placeIndex + 1].recording_start -
      sortedEvents[placeIndex].recording_start;
  }
  */
  return {
    isFirst: eventBefore === null,
    eventBefore,
    eventAfter,
    placeIndex,
    videoLength,
    isLast: placeIndex + 1 === sortedEvents.length,
  };
};

export const getProgramRoomStartAndStop = (
  conference,
  programDay,
  timeSortedEvents
) => {
  const first = timeSortedEvents[0],
    last = timeSortedEvents[timeSortedEvents.length - 1],
    endTimeString = last.end_time ? last.end_time : last.start_time,
    myUTCOffset = new Date().getTimezoneOffset();

  return {
    startTimeString: first.start_time.substring(0, 5),
    endTimeString: endTimeString.substring(0, 5),
    startTimeDate: new Date(`${programDay.date}T${first.start_time}`),
    programLength:
      new Date(`${programDay.date}T${endTimeString}`) -
      new Date(`${programDay.date}T${first.start_time}`),
    conferenceOffset: (myUTCOffset - conference.utc_offset) * 60000,
  };
};

const strNewDate = (date) => {
  if (date) {
    return new Date(date.replace(/\s/, 'T'));
  } else {
    return new Date();
  }
};

const REACTION_REALTIME_LAG = 4000; //10000; // Jump 10 seconds back
const adjustReactionTime = (time) => {
  return strNewDate(time).getTime() - REACTION_REALTIME_LAG;
};
export const getEventTimeReactions = (
  timeReactions,
  roomRecordingStart,
  firstEventOffset,
  eventPlaceInProgram,
  event
) => {
  const startDate = new Date(
    strNewDate(roomRecordingStart).getTime() - firstEventOffset
  );
  // Find reaction from this event (until eventAfter.recording_start)
  const eventReactions = timeReactions.filter((reaction) => {
    const reactionTime = adjustReactionTime(reaction.time);
    return (
      reactionTime > startDate.getTime() + event.recording_start &&
      (eventPlaceInProgram.isLast ||
        reactionTime <
          startDate.getTime() + eventPlaceInProgram.eventAfter.recording_start)
    );
  });
  eventReactions.sort((a, b) =>
    a.time > b.time ? 1 : b.time > a.time ? -1 : 0
  );
  return eventReactions.map((reaction) => ({
    ...reaction,
    eventTimeStart:
      adjustReactionTime(reaction.time) -
      event.recording_start -
      startDate.getTime(),
  }));
};

export const mapTimeReactionsToEvents = (
  roomRecordingStart,
  firstEventOffset,
  timeSortedEvents,
  timeReactions,
  userId
) => {
  const startDate = new Date(
    strNewDate(roomRecordingStart).getTime() - firstEventOffset
  );
  const reactionMillisecond = timeReactions.map((reaction) => ({
    ...reaction,
    timeMilliseconds: Math.max(
      0,
      adjustReactionTime(reaction.time) - startDate.getTime()
    ),
  }));
  const myReactions = reactionMillisecond.filter(
    (reaction) => reaction.visitorId === userId
  );
  const othersReactions = reactionMillisecond.filter(
    (reaction) => reaction.visitorId !== userId
  );

  const mappedEvents = timeSortedEvents.map((event, index) => {
    const [myMappedReactions, otherMappedReactions] = [
      myReactions,
      othersReactions,
    ].map((reactionList) =>
      reactionList.filter((reaction) => {
        if (index === timeSortedEvents.length - 1) {
          // Last event
          return reaction.timeMilliseconds > event.recording_start;
        } else {
          return (
            reaction.timeMilliseconds > event.recording_start &&
            reaction.timeMilliseconds <
              timeSortedEvents[index + 1].recording_start
          );
        }
      })
    );
    return {
      ...event,
      myReactions: myMappedReactions,
      otherReactions: otherMappedReactions,
    };
  });

  return mappedEvents;
};
