import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputBase,
  Slide,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import { ThreadContext } from '../../context/ThreadContext';
import UserAvatar from '../ui/UserAvatar';
import ThreadMessageBubble from './ThreadMessageBubble';

const useStyles = makeStyles((theme) => ({
  dialogBg: {
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
  },
  visitorName: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  threadHeader: {
    height: 40,
  },
  paperHolderCenter: {},
  paperHolder: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  paperBg: {
    border: '1px solid rgba(150, 150, 150, 0.2)',
    backgroundColor: theme.palette.background.shadedown_20,
    maxWidth: 400,
    minWidth: 300,
    maxHeight: '85vh',
    margin: '40px 8px 32px 8px',
  },
  dialogTitle: {
    position: 'relative',
    padding: 8,
  },
  dialogContent: {
    padding: '0px 8px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 5,
    backgroundColor: theme.palette.background.shadedown_20,
    color: theme.palette.text.default,
  },
  commentActions: {
    padding: 0,
  },
  commentHolder: {
    backgroundColor: theme.palette.background.shadedown_20,
    width: '100%',
  },
  commentField: {
    paddingLeft: 10,
    paddingRight: 0,
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px',
  },
  commentAvatar: {
    width: 30,
    height: 30,
    marginLeft: 5,
  },
  groupAvatar: {
    width: 22,
    height: 22,
  },
  groupAvatarRoot: {
    fontSize: '1rem',
  },
}));

const MAX_NR_MESSAGES = 20;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ThreadMessagePopup = (props, ref) => {
  const classes = useStyles();
  const listRef = useRef();
  const { authState } = useContext(AuthContext);
  const { threadState, dispatch } = useContext(ThreadContext);
  const { openUserPopup } = useContext(ConferenceContext);

  const [dialogState, setDialog] = useState({
    open: false,
    threadId: null,
    visitors: [],
  });
  const [messageText, setMessageText] = useState('');
  const [showParticipantList, setShowParticipantList] = useState(false);
  const [showNrOfMessages, setNrOfMessages] = useState(MAX_NR_MESSAGES);

  const otherParticipants = !dialogState.open
    ? []
    : dialogState.visitors.length > 0
    ? dialogState.visitors
    : (threadState.currentThread &&
        threadState.currentThread.visitors.filter(
          (v) => v.id !== authState.user.id
        )) ||
      [];
  const ThreadTitle = (
    <Box
      display="flex"
      alignItems="flex-start"
      className={showParticipantList ? '' : classes.threadHeader}
    >
      {otherParticipants.length === 0 ? (
        <Box>Loading...</Box>
      ) : otherParticipants.length > 1 ? (
        <>
          <Box pt={1}>
            <Avatar className={classes.groupAvatar}>
              <Icon classes={{ root: classes.groupAvatarRoot }}>group</Icon>
            </Avatar>
          </Box>
          <Box pl={1} flexGrow={1}>
            <Box>
              <Button
                component="a"
                endIcon={
                  showParticipantList ? (
                    <Icon>arrow_drop_up</Icon>
                  ) : (
                    <Icon>arrow_drop_down</Icon>
                  )
                }
                onClick={() => setShowParticipantList(!showParticipantList)}
                className={classes.visitorName}
              >
                Group Chat
              </Button>
              {showParticipantList && (
                <Box>
                  {otherParticipants.map((op) => (
                    <Box key={op.id} display="flex">
                      <UserAvatar size="small" user={op} />
                      <Box flexGrow={1}>
                        <Button
                          component="a"
                          onClick={() => openParticipant(op)}
                          className={classes.visitorName}
                        >
                          {op.full_name}
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box pt={1}>
            <UserAvatar size="small" user={otherParticipants[0]} />
          </Box>
          <Box pl={1} flexGrow={1}>
            <Button
              component="a"
              onClick={() => openParticipant(otherParticipants[0])}
              className={classes.visitorName}
            >
              {otherParticipants[0].full_name}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
  const messageList = threadState.currentThread
    ? threadState.currentThread.threadMessages.slice(-showNrOfMessages)
    : [];

  const totalMessageLength = threadState.currentThread
    ? threadState.currentThread.threadMessages.length
    : 0;
  // Keep scroll at bottom
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [
    threadState.addingMessage,
    threadState.currentThread &&
      threadState.currentThread.threadMessages.length,
    listRef.current && listRef.current.scrollHeight,
  ]);

  const handleDialogClose = () => {
    dispatch({ type: 'THREAD_LOADED', thread: null });
    setDialog({ open: false, threadId: null, visitors: [] });
    setMessageText('');
    setShowParticipantList(false);
  };
  const handleInputChange = (e) => {
    setMessageText(e.target.value.substring(0, 2000));
  };
  const openParticipant = (user) => {
    // userState.userPopup(user);
    openUserPopup(user);
    handleDialogClose();
  };

  const showMoreMessages = () => {
    setNrOfMessages(
      Math.min(showNrOfMessages + MAX_NR_MESSAGES, totalMessageLength)
    );
    keepScrollPosition();
  };
  const keepScrollPosition = () => {
    if (listRef.current) {
      const lastScrollHeight = listRef.current.scrollHeight;
      setTimeout(() => {
        listRef.current.scrollTop =
          listRef.current.scrollHeight - lastScrollHeight;
      }, 10);
    }
  };

  const sendMessage = (text) => {
    dispatch({ type: 'MESSAGE_ADD' });

    if (dialogState.threadId === 'new') {
      const visitorIds = dialogState.visitors.map((v) => v.id);
      axios
        .post(`/api/visitor/threadstartoradd`, {
          visitorIds: visitorIds,
          text: text,
        })
        .then((response) => {
          setDialog({
            ...dialogState,
            threadId: response.data.id,
            visitors: [],
          });
          dispatch({
            type: 'THREAD_LOADED',
            thread: { ...response.data, threadMessages: [] },
          });
          refreshThread(response.data.id);
        });
    } else {
      axios
        .post(`/api/visitor/threads/${dialogState.threadId}/addmessage`, {
          text: text,
        })
        .then(() => {})
        .catch(() => {});
    }

    setMessageText('');
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    sendMessage(messageText);
  };
  const watchKeypress = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (messageText.length > 0) {
        sendMessage(messageText);
      }
    }
  };

  const refreshThread = (threadId) => {
    dispatch({ type: 'THREAD_LOAD' });

    axios
      .get(`/api/visitor/threads/${threadId}`)
      .then((response) => {
        dispatch({ type: 'THREAD_LOADED', thread: response.data });
        // dispatch({type: 'TICKET_UPDATE_SUCCESS', ticket_type: response.data.ticket_type });
      })
      .catch(() => {});
  };

  useImperativeHandle(ref, () => ({
    open: async (options) => {
      setNrOfMessages(MAX_NR_MESSAGES);
      setShowParticipantList(false);
      if (options.threadId) {
        setDialog({
          open: true,
          threadId: options.threadId,
          visitors: [],
          position: options.position,
        });
        refreshThread(options.threadId);
      } else {
        let visitorList = options.visitors;
        if (visitorList.length === 0 && options.onlyVisitorId) {
          const loadedVisitor = await axios.get(
            `/api/users/${options.onlyVisitorId}`
          );
          visitorList = [loadedVisitor.data];
        }
        setDialog({
          open: true,
          threadId: 'new',
          visitors: visitorList,
          position: options.position,
        });
      }
    },
  }));

  return (
    <Dialog
      style={{ zIndex: 1350 }}
      open={dialogState.open}
      onClose={handleDialogClose}
      TransitionComponent={Transition}
      scroll="paper"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogBg}
      classes={{
        container:
          dialogState.position && dialogState.position === 'center'
            ? classes.paperHolderCenter
            : classes.paperHolder,
      }}
      PaperProps={{ className: classes.paperBg }}
    >
      <Box className={classes.dialogTitle}>
        {ThreadTitle}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleDialogClose}
        >
          <Icon>close</Icon>
        </IconButton>
      </Box>
      <DialogContent
        ref={listRef}
        className={classes.dialogContent}
        align="left"
      >
        {totalMessageLength > MAX_NR_MESSAGES &&
          messageList.length < totalMessageLength && (
            <Box align="center" pb={1}>
              <Button
                startIcon={<Icon>add_circle</Icon>}
                size="small"
                color="primary"
                onClick={showMoreMessages}
              >
                Load more
              </Button>
            </Box>
          )}

        {threadState.currentThread ? (
          <Box>
            {messageList.map((msg) => (
              <ThreadMessageBubble
                openParticipant={openParticipant}
                key={msg.id}
                message={msg}
              />
            ))}
            {threadState.addingMessage && <Box align="center">...</Box>}
          </Box>
        ) : (
          <Box align="center">
            {dialogState.threadId === 'new' ? (
              <Box pb={2}>Write message...</Box>
            ) : (
              <CircularProgress disableShrink />
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.commentActions}>
        <Box className={classes.commentHolder} display="flex" alignItems="left">
          <Box p={0.5}>
            <UserAvatar
              className={classes.commentAvatar}
              user={authState.user}
              size="small"
            />
          </Box>
          <Box flexGrow={1} p={0.5}>
            <form onSubmit={onFormSubmit}>
              <InputBase
                autoFocus
                value={messageText}
                multiline={true}
                maxRows={3}
                onKeyPress={watchKeypress}
                onChange={handleInputChange}
                fullWidth
                placeholder="Write..."
                id="comment"
                name="comment"
                inputProps={{ 'aria-label': 'Write...' }}
                className={classes.commentField}
              />
            </form>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ThreadMessagePopup);
