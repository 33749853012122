export const toDelimitedList = (values, delimiter) =>
  values.filter((value) => !!value).join(`${delimiter} `);

export const toCssMargin = ({ top, left, bottom, right } = {}) => ({
  ...(top && { marginTop: top }),
  ...(right && { marginRight: right }),
  ...(bottom && { marginBottom: bottom }),
  ...(left && { marginLeft: left }),
});

export const toCssPadding = ({ top, left, bottom, right } = {}) => ({
  ...(top && { paddingTop: top }),
  ...(right && { paddingRight: right }),
  ...(bottom && { paddingBottom: bottom }),
  ...(left && { paddingLeft: left }),
});

export const rgbToCssColor = ({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`;

export const toCssSizing = ({
  width = '100%',
  height = '100%',
  maxWidth = 'none',
  minWidth = '0',
  maxHeight = 'auto',
  minHeight = '0',
} = {}) => ({ width, height, maxWidth, minWidth, maxHeight, minHeight });

export const getSpacerValue = (unit) => {
  const units = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  };

  if (units[unit]) {
    return units[unit];
  }

  return 0;
};

const FILE_TO_ICON_MAP = {
  pdf: 'picture_as_pdf',
  png: 'image',
  jpg: 'image',
  jpeg: 'image',
  webp: 'image',
  gif: 'gif',
  doc: 'description',
  docx: 'description',
  xls: 'dataset',
  xlsx: 'dataset',
  csv: 'dataset',
  txt: 'text_snippet',
  zip: 'archive',
  rar: 'archive',
  tar: 'archive',
  mp3: 'audiotrack',
  mp4: 'movie',
  mov: 'movie',
  avi: 'movie',
  ppt: 'slideshow',
  pptx: 'slideshow',
  key: 'slideshow',
  pages: 'slideshow',
  numbers: 'slideshow',
};
const FILE_TO_ICON_MAP_DEFAULT = 'insert_drive_file';
export const getFileIcon = (filename) => {
  const extension = filename.split('.').pop();
  return FILE_TO_ICON_MAP[extension] || FILE_TO_ICON_MAP_DEFAULT;
};

export const fileSizeToHumanReadable = (size) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let unitIndex = 0;
  while (size >= 1024) {
    size /= 1024;
    unitIndex++;
  }
  return `${Math.round(size)} ${units[unitIndex]}`;
};
