import { INodeTemplate } from '@/types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Content Area',
  icon: 'check_box_outline_blank',
  type: 'column',
  tag: 'layout',
  content: {
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    backgroundColor: undefined,
    backgroundImage: undefined,
    maxWidth: '2',
    minHeight: 0,
    textColor: undefined,
    videoId: '-',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
    children: [],
  },
};

export { template, Edit, Render };
