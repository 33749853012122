import { useReducerWithMiddleware } from '@/hooks/useReducerWithMiddleware';
import { getConferenceId } from '@/js/shared/utils/DataFormat';
import { currentReducer, nodeReducer } from '@/node-editor/store';
import { INodeTemplate, TNodes, TRenderContext } from '@/types/node';
import { combineReducers } from '@/utilities/combine-reducers';
import { Button, Icon, debounce } from '@material-ui/core';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  NodeEditMarker,
  NodeTarget,
} from './components/controls/node-edit-marker';
import { useIsPreviewWindow } from './hooks/use-is-preview-window';
import { EditorStore, EditorStoreActions } from './store/index';
import { Models } from './store/models';

interface IProps {
  value: TNodes;
  children:
    | React.ReactNode
    | ((provided: {
        nodes: TNodes;
        dispatch: React.Dispatch<EditorStoreActions>;
      }) => React.ReactNode);
  onChange?: (nodes: TNodes) => void;
  readOnly?: boolean;
  middleware?: ((state: EditorStore, action: EditorStoreActions) => void)[];
}

export interface IProvided {
  conferenceId: string;
  context: TRenderContext;
  current: string;
  nodeTarget: NodeTarget | null;
  setCurrentNodeTarget: (target: HTMLElement, id: string) => void;
  dispatch: React.Dispatch<EditorStoreActions>;
  nodes: TNodes;
}

export const NodeContext = createContext<IProvided>(undefined);

export const NodeContextProvider: React.FC<IProps> = ({
  children,
  readOnly,
  value: initialValue = {},
  onChange,
  middleware = [],
}) => {
  const mode = useRef<TRenderContext>(!readOnly ? 'Edit' : 'Render');
  const [nodeTarget, setNodeTarget] = useState<NodeTarget | null>(null);

  const isEditMode = useIsPreviewWindow();

  const initialState = {
    nodes: { ...initialValue },
    current: '',
  };

  const [store, dispatch] = useReducerWithMiddleware(
    combineReducers<typeof initialState>(nodeReducer, currentReducer),
    initialState,
    middleware
  );
  const nodes = useMemo(() => ({ ...store.nodes }), [store.nodes]);
  const sendMessageToEditor = (nodeId: string, action: string) => {
    if (isEditMode) {
      window.top.postMessage(
        {
          nodeId,
          action,
          type: 'editcallback',
        },
        '*'
      );
      if (action === 'delete') {
        setNodeTarget(null);
      }
    }
  };
  const debounceSendMessageToEditor = useCallback(
    debounce(sendMessageToEditor, 100),
    []
  );

  const setCurrentNodeTarget = (target: HTMLElement, id: string) => {
    if (nodes[id]) {
      const template = nodes[id]
        ? Models.getInstance().get(nodes[id].type)
        : null;

      setNodeTarget({ target, id, template });
    } else {
      setNodeTarget(null);
    }
  };
  useEffect(() => {
    if (store.current) {
      // When mouseover on leftpanel
      setNodeTarget(null);
    }
  }, [store.current]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(nodes);
    }
  }, [nodes]);

  useEffect(() => {
    if (isEditMode) {
      let timer: any = null;
      window.addEventListener('wheel', () => {
        if (timer === null) {
          setNodeTarget(null);
          timer = setTimeout(() => {
            setNodeTarget(null);
            timer = null;
          }, 300);
        }
      });
    }
  }, [isEditMode]);

  useEffect(() => {
    if (isEditMode && nodeTarget) {
      debounceSendMessageToEditor(nodeTarget.id, 'hover');
    }
  }, [isEditMode, nodeTarget]);

  return (
    <NodeContext.Provider
      value={{
        nodes,
        current: store.current,
        context: mode.current,
        setCurrentNodeTarget,
        nodeTarget: nodeTarget,
        conferenceId: getConferenceId(),
        dispatch,
      }}
    >
      {isEditMode && nodeTarget && (
        <NodeEditMarker
          nodeTarget={nodeTarget}
          callback={sendMessageToEditor}
        />
      )}
      {typeof children === 'function'
        ? children({ nodes, dispatch })
        : children}
    </NodeContext.Provider>
  );
};

NodeContextProvider.displayName = 'NodeContextProvider';
