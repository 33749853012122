import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IProps } from './contract';
import { RadioGroup } from './radiogroup';

/**
 * RadioGroupControl is an abstraction of MUI RadioGroup
 * implemented with react-hook-form Controller
 *
 * @link https://react-hook-form.com/api/usecontroller/controller
 * @link https://v4.mui.com/api/radio-group
 */
export const RadioGroupControl = ({
  defaultValue,
  name,
  ...props
}: Omit<IProps, 'onChange' | 'value'>) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <RadioGroup value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
