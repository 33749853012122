import React from 'react';
import CoeolyticsContext from './CoeolyticsContext';

const useContext = React.useContext;

export const useCoeolytics = () => {
  return useContext(CoeolyticsContext);
};

export const useTrack = () => {
  return useContext(CoeolyticsContext).track;
};

export const usePage = () => {
  return useContext(CoeolyticsContext).page;
};

export const useIdentify = () => {
  return useContext(CoeolyticsContext).identify;
};
