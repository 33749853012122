import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  ImageList,
  GridListTile,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import DropZone from '@/js/shared/components/ui/DropZone';
import { IMedia } from '@/types/media';
import { useMedia } from '@/media';

interface IProps {
  open: boolean;
  onClose: (value?: string) => void;
  conferenceId: string;
}

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    width: '100%',
    height: '100%',
    borderStyle: 'dashed',
  },
  mediaTile: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  image: {
    backgroundColor: theme.palette.grey[300],
  },
}));

export const MediaPicker: FC<IProps> = ({ open, onClose, conferenceId }) => {
  const classes = useStyles();

  const { media, loadMedia } = useMedia(conferenceId);

  const onUploadComplete = () => loadMedia();

  const handleItemClick = (value: IMedia) => onClose(value.source_id);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      TransitionProps={{ onEnter: loadMedia }}
      onClose={() => onClose()}
    >
      <DialogTitle>Add media file</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Box mb={2} textAlign="center">
            <DropZone
              acceptedfiletypes="image/jpeg, image/png, image/webp"
              uploadHandler={onUploadComplete}
              buttonText={'Upload Image'}
              uploadurl={`/api/conferences/${conferenceId}/signmediaupload`}
            />
            <Typography variant="caption">
              Upload an image file or pick one from your media library
            </Typography>
          </Box>
          <ImageList rowHeight={128} cols={4} gap={8}>
            {!!media &&
              media.map((item: IMedia) => (
                <GridListTile
                  className={classes.mediaTile}
                  key={item.id}
                  onClick={() => handleItemClick(item)}
                >
                  <img
                    className={classes.image}
                    src={getImage(
                      item.source_id,
                      ImageSize.Medium,
                      conferenceId
                    )}
                  />
                </GridListTile>
              ))}
          </ImageList>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
