import { AspectRatio } from '@/components/aspect-ratio';
import { CardContent } from '@/components/card';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { findCurrentDayId } from '@/js/shared/utils/DataFormat';
import { getSpacerValue } from '@/js/shared/utils/format';
import { useNodeProps } from '@/node-editor/hooks';
import { IDay } from '@/types/day';
import { INode, INodeProps } from '@/types/node';
import { IProgramRoom } from '@/types/room';
import { Grid, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { Fragment, useContext } from 'react';
import C from '../../../shared/constants/general';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps extends Pick<IContent, 'verticalSpacing'> {
  rooms: number;
  override: Record<string, any>;
}

interface IRoomStyleProps {
  backgroundImage: string;
  useDefaultImage: boolean;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>((theme) => ({
  holder: ({ verticalSpacing, override }) => ({
    width: '100%',
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    ...override.root
  }),
  grid: {
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
    },
  },
  item: ({ rooms }) => ({
    borderRadius: 5,
    flexGrow: 0,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: rooms === 1 ? '100%' : '75%',
      flexBasis: rooms === 1 ? '100%' : '75%',
    },
  }),
}));

const useRoomStyles = makeStyles<Theme, IRoomStyleProps>((theme) => ({
  live: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'auto',
  },
  cardTypography: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(0,0,0,.4)',
    padding: `${theme.spacing(1)}px 0`,
    borderRadius: '0 0 5px 5px',
    '& > .material-icons': {
      marginRight: 4,
    },
  },
  aspectContainer: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    transition: 'box-shadow .3s linear',
    '&:hover, &:focus-within': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
  },
  aspectContent: ({backgroundImage, useDefaultImage}) => ({
    '&:before': {
        content: '""',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'block',
        position: 'absolute',
        opacity: useDefaultImage ? .6 : 1,
        borderRadius: 5,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    }
  })
}));

const Room = ({ room, index }: { room: IProgramRoom; index: number }) => {
  const classes = useRoomStyles({
    backgroundImage: getImage(
      room.bg_picture || `/coeo/room_bg_${index % 5}.jpg`,
      ImageSize.Medium
    ),
    useDefaultImage: !room.bg_picture,
  });

  return (
    <AspectRatio
      maxHeight="150px"
      styles={{
        aspectContainer: classes.aspectContainer,
        aspectContent: classes.aspectContent
      }}
    >
      <a href={`/live/room/${room.id}`}>
        <CardContent contentJustification="flex-end" color="#fff">
          <Fragment>
            {Boolean(room.currentEventId) && (
              <Typography
                className={classes.live}
                variant="subtitle1"
                color="error"
              >
                <Icon>fiber_manual_record</Icon>
                LIVE
              </Typography>
            )}
            <Typography className={classes.cardTypography} variant="body1">
              <Icon>videocam</Icon>
              {room.title}
            </Typography>
          </Fragment>
        </CardContent>
      </a>
    </AspectRatio>
  );
};

export const Render = ({
  node: {
    id,
    override,
    content: { cardSize, verticalSpacing },
  },
}: IProps) => {
  const { conferenceState } = useContext(ConferenceContext); // Hämta Context
  if (conferenceState.conferenceDetail.programDays.length === 0) {
    return null;
  }
  const currentDayId = findCurrentDayId(conferenceState.conferenceDetail);
  const currentDay = conferenceState.conferenceDetail.programDays.find(
    (d: IDay) => d.id === currentDayId
  );
  if (!currentDay) {
    return null;
  }

  const programRooms = currentDay.programRooms.filter(
    ({ video_state }: any) => video_state !== C.PROGRAM_ROOM.VIDEO_STATE.FILE
  );

  const classes = useContainerStyles({
    verticalSpacing,
    rooms: programRooms.length,
    override
  });

  return (
    currentDay && (
      <div className={classes.holder} {...useNodeProps(id)}>
        <Grid container spacing={1} justifyContent="center" className={classes.grid}>
          {programRooms.map((room: IProgramRoom, index: number) => (
            <Grid
              item
              key={room.id}
              md={parseInt(cardSize) as 12 | 6 | 4 | 3}
              className={classes.item}
            >
              <Room room={room} index={index} />
            </Grid>
          ))}
        </Grid>
      </div>
    )
  );
};
