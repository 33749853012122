import { api } from '@/js/manager/utils/api';
import { SettingsContainer } from '@/node-editor/components';
import { INode, INodeProps, INodeTemplate, TNodes } from '@/types/node';
import { TWidgets, Widget } from '@/widget-editor';
import React from 'react';

export const WIDGET_AREAS = ['area-1', 'area-2'] as const; // TODO: add areas as needed.

/**
 *
 * @param roomId - string
 * @returns Promise<INode[] | INodeTemplate[]>
 */
export const getManagerWidgetsByRoomService = async (
  roomId: string
): Promise<INode[] | INodeTemplate[]> => {
  try {
    const { data } = await api.get(`/rooms/${roomId}/widgets`);

    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param roomId - string
 * @returns Promise<INode[] | INodeTemplate[]>
 */
export const getWidgetsByRoomService = async (
  roomId: string,
  isManager: boolean
): Promise<INode[] | INodeTemplate[]> => {
  try {
    const { data } = await api.get(
      isManager
        ? `/rooms/${roomId}/widgets`
        : `/visitor/rooms/${roomId}/widgets`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Attaches widgets to room.
 *
 * @param roomId - string
 * @param widgets - Promise<INode[]>
 */
export const updateWidgetsByRoomService = async (
  roomId: string,
  widgets: TNodes
): Promise<INode[]> => {
  try {
    const { data } = await api.put(`/rooms/${roomId}/widgets`, {
      widgets,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Modifies widget active state
 *
 * @param roomId - string
 * @param widgets - Promise<INode[]>
 */
export const updateActiveWidgetsByRoomService = async (
  roomId: string,
  widgets: TNodes
): Promise<INode[]> => {
  try {
    // Dont update widget areas
    const widgetList = Object.values(widgets).filter(
      (w) => w.type !== 'root' && w.type !== 'widget-area'
    );

    const allActiveWidgets = widgetList
      .filter((w) => w.active)
      .map((w) => w.id);
    const inActiveWidgets = widgetList
      .filter((w) => !w.active)
      .map((w) => w.id);

    const { data } = await api.put(`/visitor/rooms/${roomId}/activewidgets`, {
      active: allActiveWidgets,
      inActive: inActiveWidgets,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param widgets
 * @returns
 */
export const getWidgetRoot = (widgets: TWidgets): Widget =>
  Object.values(widgets).find(({ nodeId }) => !nodeId);

/**
 *
 * @param area
 * @param widgets
 * @returns
 */
export const getWidgetAreaRoot = (
  area: typeof WIDGET_AREAS[number],
  widgets: TWidgets
): Widget =>
  Object.values(widgets).find(({ content }) => content.areaName === area);

/**
 * get widget map by area
 * @param area
 * @param widgets
 * @returns TWidgets
 */
export const getWidgetsByAreaMap = (
  area: typeof WIDGET_AREAS[number],
  widgets: TWidgets
): TWidgets => {
  const root = getWidgetRoot(widgets);

  const areaRoot = getWidgetAreaRoot(area, widgets);

  return Object.values(widgets).reduce(
    (map, current) => {
      if (current.nodeId === areaRoot.id) {
        return {
          ...map,
          [current.id]: current,
        };
      }

      return map;
    },
    {
      [root.id]: root,
      [areaRoot.id]: areaRoot,
    }
  );
};

/**
 *
 * @param area
 * @returns
 */
export const getWidgetsByAreaList = (
  area: typeof WIDGET_AREAS[number],
  widgets: TWidgets
): Widget[] => {
  const areaRoot = getWidgetAreaRoot(area, widgets);

  return [
    ...Object.values(widgets).filter(({ nodeId }) => nodeId === areaRoot.id),
  ].sort((a, b) => a.order - b.order);
};

/**
 *
 * @param WrappedComponent Node Render Component
 * @returns Widget Render Component
 */
export const withWidgetDisplay = (
  WrappedComponent: React.ComponentType<any>
): React.ComponentType<Omit<INodeProps, 'node'> & { node: Widget }> => {
  return ({ node: { active = false, ...nodeProps }, ...rest }) => {
    return active ? <WrappedComponent node={nodeProps} {...rest} /> : null;
  };
};

/**
 *
 * @param Settings Settings Component with additional settings
 * @returns (ExtendedSettings) => Widgets Edit Component
 */
export const withWidgetSettings = <T extends INodeProps>(
  Settings?: React.ComponentType<T>
) => (ExtendedComponent: React.ComponentType<any>): React.ComponentType<T> => {
  const WithSettings = (props: T) => {
    return (
      <SettingsContainer>
        <Settings {...props}>
          <ExtendedComponent {...props} />
        </Settings>
      </SettingsContainer>
    );
  };

  return WithSettings;
};
