import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type: settingType,
    override,
    content: { label, call, description },
  },
  conferenceId,
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <InputControl
          fullWidth
          name="label"
          label="Button Label"
          defaultValue={label}
          variant="outlined"
        />
        <InputControl
          fullWidth
          name="description"
          label="Description (below button)"
          defaultValue={description}
          variant="outlined"
        />

        <SelectControl
          name="call"
          defaultValue={call}
          label="Call action"
          options={[
            {
              label: 'Open video mingle',
              value: 'videochat',
            },
            {
              label: 'Open profile popup',
              value: 'profile',
            },
          ]}
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(settingType, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(settingType, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
