import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { TopicSelector } from '@/components/topic-selector';
import { ManagerMarkdownEditor } from '@/js/shared/components/markdown/ManagerMarkdownEditor';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';

import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';
import { RadioGroupControl } from '@/components/radiogroup-control';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { limit, types },
  },
  conferenceId,
}: IProps) => {
  const { control, setValue } = useFormContext();
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <RadioGroupControl
          row
          label="What to show"
          name="types"
          defaultValue={types}
          options={[
            {
              label: 'Posts',
              value: 'discussions',
            },
            {
              label: 'Replies',
              value: 'replies',
            },
            {
              label: 'Both (mixed)',
              value: 'all',
            },
          ]}
        />
        <br />
        <br />
        <InputControl
          name="limit"
          type="number"
          label="Number of items to show"
          defaultValue={limit}
          variant="outlined"
        />
        <br />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
