import { NodeContext } from '@/node-editor/context';
import { useContext } from 'react';

export const useNodeContext = () => {

    const context = useContext(NodeContext)

    if (context == undefined) {

        throw new Error('useNodes must be used within NodeContexProvider.');
    }

    return context;
}