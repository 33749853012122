import { Card, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { CSSProperties, FC, Fragment } from 'react';

interface IProps {
  multiple: boolean;
  isFirstEvent: boolean;
  isLastEvent: boolean;
  eventPassed: boolean;
  eventLive: boolean;
  startTime: string;
}

interface IStyleProps extends IProps {
  bulletSize: number;
  timeSize: number;
}

const commonPsuedo = {
  position: 'absolute',
  content: '""',
  display: 'block',
} as CSSProperties;

const useStyles = makeStyles<Theme, IStyleProps>((theme) => ({
  root: ({
    bulletSize,
    isFirstEvent,
    isLastEvent,
    multiple,
    eventPassed,
    timeSize,
    eventLive,
  }) => ({
    position: 'relative',
    width: timeSize,
    color: eventLive ? theme.palette.text.primary : theme.palette.grey[500],
    fontSize: '0.9rem',
    paddingBottom: theme.spacing(2),
    '&::before': {
      ...commonPsuedo,
      content: !isLastEvent && '""',
      top: 23,
      right: 9,
      bottom: 0,
      width: 1,
      borderRightWidth: 1,
      borderRightStyle: multiple ? 'dashed' : 'solid',
      borderRightColor: 'rgba(153, 153, 153, 0.5)',
    },
    '&::after': {
      ...commonPsuedo,
      top: multiple ? bulletSize - bulletSize / 3 : bulletSize / 3,
      right: multiple ? 0 : bulletSize / 4,
      width: bulletSize,
      height: multiple ? bulletSize / 2 : bulletSize,
      borderRadius: multiple ? bulletSize / 6 : bulletSize,
      backgroundColor: theme.palette.primary.main,
      opacity: eventPassed || (multiple && !isFirstEvent) ? 0.5 : 1,
    },
  }),
  cardRoot: ({ timeSize }) => ({
    width: `calc(100% - ${timeSize}px)`,
  }),
}));

export const EventListItem: FC<IProps> = ({ children, ...props }) => {
  const classes = useStyles({
    ...props,
    bulletSize: 12,
    timeSize: 60,
  });

  return (
    <Fragment>
      <Typography classes={{ root: classes.root }}>
        {props.isFirstEvent && props.startTime.substring(0, 5)}
      </Typography>
      <Card classes={{ root: classes.cardRoot }}>{children}</Card>
    </Fragment>
  );
};
