import axios from 'axios';

const userActions = (dispatch) => {
  return {
    loadPublicProfile: (id) => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .get('/api/users/' + id, {})
        .then((response) => {
          if (response.data) {
            dispatch({ type: 'USER_SUCCESS', user: response.data });
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong list format' });
          }
        })
        .catch((error) => {
          dispatch({ type: 'USER_ERROR', error: 'Could not load users' });
        });
    },
    updateVisitorStatus: (conferenceId, userId, status) => {
      axios
        .put(
          `/api/visitor/conferences/${conferenceId}/visitors/${userId}/status`,
          { status }
        )
        .then((response) => {
          dispatch({
            type: 'STATUS_SUCCESS',
            status: response.data,
          });
        })
        .catch((error) => {
          dispatch({ type: 'STATUS_SUCCESS', error: error });
        });
    },
    getUserList: (conferenceId) => {
      dispatch({ type: 'LIST_LOAD' });
      axios
        .get(`/api/visitor/conferences/${conferenceId}/visitors`, {})
        .then((response) => {
          if (response.data) {
            const sortUsersWithTags = response.data.sort((a, b) => {
              if (a.tags.length > 0 && b.tags.length === 0) return -1;
              else if (b.tags.length > 0 && a.tags.length === 0) return 1;
              else return 0;
            });
            dispatch({ type: 'LIST_SUCCESS', list: sortUsersWithTags });
          } else {
            dispatch({ type: 'LIST_ERROR', error: 'Wrong list format' });
          }
        })
        .catch((error) => {
          console.log('USER ERROR', error);
          dispatch({ type: 'USER_ERROR', error: 'Could not load users' });
        });
    },
    loadMe: () => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .get('/api/me', {})
        .then((response) => {
          if (response.data) {
            dispatch({ type: 'USER_SUCCESS', user: response.data });
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong list format' });
          }
        })
        .catch((error) => {
          console.log('USER ERROR', error);
          dispatch({ type: 'USER_ERROR', error: 'Could not load users' });
        });
    },
    updateMe: () => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .post('/api/me', {})
        .then((response) => {
          if (response.data) {
            dispatch({ type: 'USER_SUCCESS', user: response.data });
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong list format' });
          }
        })
        .catch((error) => {
          console.log('USER ERROR', error);
          dispatch({ type: 'USER_ERROR', error: 'Could not load users' });
        });
    },
    updateFollowing: (id, follow, message, cb) => {
      dispatch({ type: 'USER_FOLLOW_UPDATE' });
      axios
        .put(`/api/visitor/${follow ? 'follow' : 'unfollow'}/${id}`, {
          text: message,
        })
        .then((response) => {
          dispatch({ type: 'USER_FOLLOW_DONE', user: response.data });
          cb && cb({ ...response.data, fullData: true });
        })
        .catch((error) => {
          dispatch({ type: 'USER_ERROR', error: error.response.data.message });
        });
    },

    verifyEmailWithCode: (id, verificationId, conferenceId) => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .post(`/api/visitor/verifywithcode`, {
          id,
          verificationId,
          conferenceId,
        })
        .then((response) => {
          if (window.location.pathname === '/register') {
            window.location.href = '/live';
          } else {
            dispatch({
              type: 'VERIFICATION_SUCCESS',
              user: response.data.user,
              registerSuccess: true,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: 'USER_ERROR', error: error.response.data.message });
        });
    },
    verifyEmailWithString: (verificationId, conferenceId) => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .post(`/api/visitor/verifywithstring`, {
          verificationId,
          conferenceId,
        })
        .then((response) => {
          if (window.location.pathname === '/register') {
            window.location.href = '/live';
          } else {
            dispatch({
              type: 'VERIFICATION_SUCCESS',
              user: response.data.user,
              registerSuccess: true,
            });
          }
        })
        .catch((error) => {
          dispatch({ type: 'USER_ERROR', error: error.response.data.message });
        });
    },
    registerVerify: (conferenceId, data) => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .post(`/api/visitor/conferences/${conferenceId}/registerverify`, data)
        .then((response) => {
          if (response.data) {
            dispatch({
              type: 'USER_SUCCESS',
              user: response.data.user,
              registerSuccess: true,
            });
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong format' });
          }
        })
        .catch((error) => {
          console.log('USER_ERROR', error.response.data);
          dispatch({
            type: 'USER_ERROR',
            error: error.response.data.message,
            errorType: error.response.data.type,
          });
        });
    },
    registerOpen: (conferenceId, data) => {
      dispatch({ type: 'USER_LOAD' });
      axios
        .post(`/api/visitor/conferences/${conferenceId}/registeropen`, data)
        .then((response) => {
          if (response.data) {
            if (window.location.pathname === '/register') {
              window.location.href = '/live';
            } else {
              dispatch({
                type: 'USER_SUCCESS',
                user: response.data.user,
                registerSuccess: true,
              });
            }
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong format' });
          }
        })
        .catch((error) => {
          dispatch({
            type: 'USER_ERROR',
            error: error.response.data.message,
            errorType: error.response.data.type,
          });
        });
    },
    register: (invitationCode, data) => {
      dispatch({ type: 'USER_LOAD' });

      axios
        .post(`/api/visitor/invitation/${invitationCode}/register`, data)
        .then((response) => {
          if (response.data) {
            if (window.location.pathname === '/register') {
              window.location.href = '/live';
            } else {
              dispatch({
                type: 'USER_SUCCESS',
                user: response.data.user,
                registerSuccess: true,
              });
            }
          } else {
            dispatch({ type: 'USER_ERROR', error: 'Wrong format' });
          }
        })
        .catch((error) => {
          console.log('USER_ERROR', error.response.data);
          dispatch({
            type: 'USER_ERROR',
            error: error.response.data.message,
            errorType: error.response.data.type,
          });
        });
    },
    acceptInvitation: (invitationCode) => {
      axios
        .post(`/api/visitor/invitation/${invitationCode}/accept`, {})
        .then((response) => {
          if (response.data) {
            if (window.location.pathname === '/register') {
              window.location.href = '/live';
            } else {
              dispatch({
                type: 'USER_SUCCESS',
                user: response.data.visitor,
                registerSuccess: true,
              });
            }
          } else {
            dispatch({ type: 'INVITATION_ERROR', error: 'Wrong format' });
          }
        })
        .catch((error) => {
          console.log('USER ERROR', error);
          dispatch({ type: 'INVITATION_ERROR', error: error });
        });
    },
    loadInvitation: (invitationCode) => {
      dispatch({ type: 'INVITATION_LOAD' });
      axios
        .get(`/api/visitor/invitation/${invitationCode}`, {})
        .then((response) => {
          if (response.data) {
            dispatch({ type: 'INVITATION_SUCCESS', invitation: response.data });
          } else {
            dispatch({
              type: 'INVITATION_ERROR',
              error: 'Could not find invitation',
            });
          }
        })
        .catch((error) => {
          console.log('INVITATION ERROR', error);
          dispatch({
            type: 'INVITATION_ERROR',
            error: 'Could not find invitation',
          });
        });
    },
  };
};
export default userActions;
