import DropZone from '@/js/shared/components/ui/DropZone';
import { INodeTemplate } from '@/types/node';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, {
  cloneElement,
  forwardRef,
  isValidElement,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { getImage } from '../../../shared/utils/imageFormat';
import { EditNodeTemplateRef, IEditNodeTemplateOptions } from '.';
import { InputControl } from '../input-control';
import { MediaPlaceholder } from '../media-placeholder';
import { SwitchControl } from '../switch-control';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { ManagerConferenceContext } from '@/js/shared/context/ManagerConferenceContext';

const useStyles = makeStyles<Theme>((theme) => ({
  dialog: {},
  scrollPaper: {
    justifyContent: 'flex-start',
  },
  img: {
    margin: 'auto',
    width: 90,
    height: 90,
    backgroundColor: '#666',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
}));

const defaultValues = {
  id: '',
  title: '',
  description: '',
  picture: '',
  public: false,
};
export const EditNodeTemplate = forwardRef<EditNodeTemplateRef>(({}, ref) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IEditNodeTemplateOptions | null>(null);
  const { authState } = useContext(AuthContext);
  const { conferenceData } = useContext(ManagerConferenceContext);
  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const { reset, getValues, watch } = formMethods;
  const values = getValues();
  const watchTitle = watch('title', values.title);
  useImperativeHandle(ref, () => ({
    open: (initOptions: IEditNodeTemplateOptions) => {
      setOptions(initOptions);
      reset(initOptions.data || defaultValues);
      setIsOpen(true);
    },
  }));

  const cleanUp = () => {
    setIsOpen(false);
    setOptions(null);
    reset();
  };

  const handleSubmit = () => {
    if (typeof options.saveAsTemplate === 'function') {
      options.saveAsTemplate(getValues(), conferenceData.conference.id);
    }
    cleanUp();
  };
  const handleDelete = () => {
    if (options && typeof options.deleteTemplate === 'function') {
      options.deleteTemplate(values.id);
    }
    cleanUp();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={cleanUp}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
    >
      <DialogTitle>
        Save this (and everything inside) as a template?
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <Box display="flex" alignItems="flex-start">
            <Box>
              <Controller
                name="picture"
                defaultValue={values.picture}
                render={({ field: { onChange, value } }) => (
                  <Box pr={1} textAlign="center">
                    <div
                      className={classes.img}
                      style={{
                        backgroundImage: value
                          ? `url(${getImage(value, 'small', null, 'managers')})`
                          : '',
                      }}
                    />
                    <br />
                    <DropZone
                      acceptedfiletypes="image/jpeg, image/png, image/webp"
                      uploadHandler={(uploadInfo: any) => {
                        onChange(uploadInfo.generated);
                      }}
                      buttonText={'Upload Image'}
                      uploadurl={'/api/managersignmedia'}
                    />
                  </Box>
                )}
              />
            </Box>
            <Box flexGrow={1}>
              <Box pb={1}>
                <InputControl
                  name="title"
                  label="Title"
                  defaultValue={values.title}
                  fullWidth
                  variant="outlined"
                />
              </Box>
              <Box pb={1}>
                <InputControl
                  name="description"
                  label="Description"
                  defaultValue={values.description}
                  fullWidth
                  variant="outlined"
                />
              </Box>
              {authState.user.access_level === 400 && (
                <Box pb={1}>
                  <SwitchControl
                    name="public"
                    label="In public library?"
                    defaultValue={values.public}
                    options={[true, false]}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Box className={classes.buttons}>
          <Box>
            {values.id && (
              <Button
                onClick={handleDelete}
                startIcon={<Icon>delete</Icon>}
                color="secondary"
              >
                delete
              </Button>
            )}
          </Box>
          <Box textAlign="right" flexGrow={1}>
            <Button onClick={cleanUp}>Cancel</Button>
            <Button
              disabled={!watchTitle}
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
});
