import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

interface IProps {
  styles?: {
    aspectContainer?: string | string[];
    aspectContent?: string | string[];
  };
  maxHeight?: string;
}

const useStyles = makeStyles<Theme, Omit<IProps, 'children'>>((theme) => ({
  aspectContainer: ({ maxHeight }) => ({
    backgroundColor: (theme.palette.background as any).shadedown_20,
    position: 'relative',
    '&::before': {
      content: '""',
      width: '1px',
      marginLeft: '-1px',
      float: 'left',
      height: 0,
      paddingTop: maxHeight,
    },
    '&::after': {
      content: '""',
      display: 'table',
      clear: 'both',
    },
  }),
  aspectContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

export const AspectRatio: FC<IProps> = ({
  styles = {
    aspectContainer: '',
    aspectContent: '',
  },
  maxHeight = '56.25%',
  children,
}) => {
  const classes = useStyles({
    styles,
    maxHeight,
  });

  return (
    <div className={clsx(classes.aspectContainer, styles.aspectContainer)}>
      <div className={clsx(classes.aspectContent, styles.aspectContent)}>
        {children}
      </div>
    </div>
  );
};
