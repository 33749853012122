import { darken, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { IOption as IRadioGroupOption } from '../radiogroup-control/contract';
import { RadioGroupControl } from '../radiogroup-control/radiogroup-control';
import { IProps, IOption } from './contract';

const useStyles = makeStyles((theme) => ({
  formlabelRoot: {
    paddingBottom: theme.spacing(1),
  },
  formgroupRoot: {
    '& span:nth-child(1) > label > span': {
      borderRadius: '5px 0 0 5px',
    },
    '& span:nth-last-child(1) > label > span': {
      borderRadius: '0 5px 5px 0',
    },
    // borderWidth: 1,
    // borderColor: theme.palette.grey[200],
  },
  formControlLabelRoot: {
    margin: 0,
  },
  formControlLabel: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0, 0, 0, 0)',
    whiteSpace: 'nowrap',
    border: 0,
  },
  radio: {
    borderRadius: 0,
    // backgroundColor: theme.palette.primary.main,
    '&:hover': {
      // backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {
    backgroundColor: theme.palette.grey[200],
    // backgroundColor: darken(theme.palette.primary.main, 0.3),
    // opacity: 0.8,
  },
}));

/**
 * ButtonGroupControl is an layer ontop of RadioGroupControl Component.
 * Simpler API to provide component rendered istead of default <Radio /> icon.
 *
 * @link https://github.com/subpublic/joinup/tree/development/client/components/radiogroup-control
 *
 * @example
 * <ButtonGroupControl
 *   control={control}
 *   label="Horizontal alignment"
 *   name="alignment.horizontal"
 *   defaultValue={alignment.horizontal}
 *   options={[
 *     {
 *       label: 'Left',
 *       value: ALIGNMENT_OPTIONS.start,
 *       component: <Icon>format_align_left</Icon>,
 *     },
 *     {
 *       label: 'Center',
 *       value: ALIGNMENT_OPTIONS.center,
 *       component: <Icon>format_align_justify</Icon>,
 *     },
 *     {
 *       label: 'Right',
 *       value: ALIGNMENT_OPTIONS.end,
 *       component: <Icon>format_align_right</Icon>,
 *     },
 *   ]}
 * />
 */
export const ButtonGroupControl: FC<Omit<IProps, 'onChange' | 'value'>> = ({
  options,
  ...props
}) => {
  const classes = useStyles();

  const mergeOptions = (options: IOption[]) =>
    options.map(
      (option): IRadioGroupOption => ({
        label: option.label,
        value: option.value,
        radioProps: {
          icon: option.component,
          checkedIcon: option.component,
          disableRipple: true,
          classes: {
            root: classes.radio,
            checked: classes.checked,
          },
        },
      })
    );

  return (
    <RadioGroupControl
      row
      withTooltip
      options={mergeOptions(options)}
      classes={{
        formLabel: {
          root: classes.formlabelRoot,
        },
        radioGroup: {
          root: classes.formgroupRoot,
        },
        formControlLabel: {
          root: classes.formControlLabelRoot,
          label: classes.formControlLabel,
        },
      }}
      {...props}
    />
  );
};
