/**
 * OBS! Ändringar här uppdaterar inte klienten (webpack) om man inte även ändrar i de klient-filer som använder den (page-editor.tsx).
 */

exports.blank = [
  {
    type: 'root',
    children: [],
  },
];

exports.reception = [
  {
    type: 'root',
    children: [
      {
        type: 'column',
        content: {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
          backgroundColor: { r: 48, g: 48, b: 48, a: 1 },
          textColor: { r: 255, g: 255, b: 255, a: 1 },
          maxWidth: '1',
          minHeight: 200,
        },
        children: [
          {
            type: 'markdown',
            content: {
              alignment: {
                vertical: 'center',
                horizontal: 'center',
              },
              text: `# Title`,
            },
          },
        ],
      },
      {
        type: 'container',
        content: {
          verticalSpacing: {
            top: 'sm',
            bottom: 'sm',
          },
        },
        children: ['rooms', 'program'],
      },
    ],
  },
];

exports.home = [
  {
    type: 'root',
    children: [
      {
        type: 'topmenu',
        content: {
          containerWidth: 'md',
        },
      },
      {
        type: 'column',
        content: {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
          backgroundColor: { r: 48, g: 48, b: 48, a: 1 },
          textColor: { r: 255, g: 255, b: 255, a: 1 },
          maxWidth: '1',
          minHeight: 350,
        },
        children: [
          {
            type: 'markdown',
            content: {
              alignment: {
                vertical: 'center',
                horizontal: 'center',
              },
              text: `# Title`,
            },
          },
        ],
      },
      {
        type: 'container',
        content: {
          containerWidth: 'sm',
          verticalSpacing: {
            top: 'sm',
            bottom: 'sm',
          },
        },
        children: [
          'navigation',
          {
            type: 'markdown',
            content: {
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Curabitur id purus sit amet eros ultricies pulvinar ac ac massa. Nulla feugiat sem in arcu placerat facilisis.`,
            },
          },
        ],
      },
    ],
  },
];

exports.register = [
  {
    type: 'root',
    children: [
      {
        type: 'topmenu',
        content: {
          containerWidth: 'md',
        },
      },
      {
        type: 'column',
        content: {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
          backgroundColor: { r: 48, g: 48, b: 48, a: 1 },
          textColor: { r: 255, g: 255, b: 255, a: 1 },
          maxWidth: '1',
          minHeight: 350,
        },
        children: [
          {
            type: 'markdown',
            content: {
              alignment: {
                vertical: 'center',
                horizontal: 'center',
              },
              text: `# Register
You need to create an account to follow the livestream`,
            },
          },
        ],
      },
      {
        type: 'container',
        content: {
          verticalSpacing: {
            top: 'sm',
            bottom: 'sm',
          },
        },
        children: ['registration'],
      },
    ],
  },
];

exports.login = [
  {
    type: 'root',
    children: [
      {
        type: 'topmenu',
        content: {
          containerWidth: 'md',
        },
      },
      {
        type: 'container',
        content: {
          verticalSpacing: {
            top: 'sm',
            bottom: 'sm',
          },
        },
        children: [
          {
            type: 'navigation',
            content: {
              btnText: 'till live-receptionen',
              startWithLogin: true,
            },
          },
        ],
      },
    ],
  },
];

exports.event = [
  {
    type: 'root',
    children: [
      {
        type: 'topmenu',
        content: {
          containerWidth: 'md',
        },
      },
      {
        type: 'container',
        content: {
          containerWidth: 'md',
          verticalSpacing: {
            top: 'sm',
            bottom: 'sm',
          },
        },
        children: [
          {
            type: 'static-eventinfo',
            content: {
              design: 'dateimage',
            },
          },
        ],
      },
    ],
  },
];

// exports.ticket = [
//   {
//     type: 'root',
//     children: [
//       {
//         type: 'container',
//         content: {
//           containerWidth: 'md',
//           verticalSpacing: {
//             top: 'sm',
//             bottom: 'sm',
//           },
//         },
//         children: [
//           {
//             type: 'markdown',
//             content: {
//               alignment: {
//                 vertical: 'center',
//                 horizontal: 'center',
//               },
//               text: `Check in:`,
//             },
//           },
//           {
//             type: 'pretix-ticket',
//             content: {},
//           },
//         ],
//       },
//     ],
//   },
// ];

exports.welcome = [
  {
    type: 'root',
    children: [
      {
        type: 'topmenu',
        content: {
          containerWidth: 'md',
        },
      },
      {
        type: 'container',
        content: {
          containerWidth: 'sm',
          verticalSpacing: {
            top: 'md',
            bottom: 'sm',
          },
        },
        children: [
          {
            type: 'markdown',
            content: {
              alignment: {
                vertical: 'center',
                horizontal: 'center',
              },
              text: `## Välkommen {{first_name}}!`,
            },
          },
          {
            type: 'markdown',
            content: {
              alignment: {
                vertical: 'center',
                horizontal: 'center',
              },
              text: `Vi öppnar den här sidan närmre datumet!  \nDu får ett mail när det händer.  \n\n[Till startsidan](/)`,
            },
          },
        ],
      },
    ],
  },
];
