import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { VideoChatContext } from '@/js/shared/context/VideoChatContext';
import { useNodeProps } from '@/node-editor/hooks';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  root: ({ override }) => ({
    textAlign: 'center',
    ...override.root,
  }),
  button: ({ override }) => ({
    ...override.button,
  }),
  image: ({ override }) => ({
    width: 160,
    height: 'auto',
    ...override.button,
  }),
}));

export const Render = ({
  node: {
    id,
    content: { label, call, description },
    override,
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({ override });

  const { handleSendInvitation } = useContext(VideoChatContext); // Hämta Context
  const { openUserPopup } = useContext(ConferenceContext); // Hämta Context
  const { authState } = useContext(AuthContext); // Hämta Context

  const functionCall = (func) => {
    switch (func) {
      case 'videochat': {
        return handleSendInvitation();
      }
      case 'profile': {
        return openUserPopup(authState.user);
      }
    }
  };

  return (
    <WidgetWrapper>
      <Box className={classes.root}>
        {call === 'videochat' && (
          <div>
            <img className={classes.image} src="/images/watch-together.jpg" />
          </div>
        )}

        <Button
          {...useNodeProps(id)}
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          startIcon={
            call === 'videochat' ? (
              <Icon>videocam</Icon>
            ) : call === 'profile' ? (
              <Icon>person</Icon>
            ) : null
          }
          onClick={() => functionCall(call)}
        >
          {label}
        </Button>
        {description && <div>{description}</div>}
      </Box>
    </WidgetWrapper>
  );
};
