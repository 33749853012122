import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from '@material-ui/core';
import React from 'react';
import { IProps } from './contract';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, {label: boolean}>((theme) => ({
    labelRoot: ({ label }) => ({
        '& + .MuiInput-formControl': {
            margin: label ? 0 : 'auto',
            marginTop: label ? theme.spacing(2) : 'auto',
        }
    })
}));

export const Select = ({
  fullWidth = false,
  labelId,
  label,
  value,
  onChange,
  options,
  variant = 'outlined',
}: IProps) => {
  
  const classes = useStyles({label: Boolean(label)});
  
  return (
    <FormControl fullWidth={fullWidth} variant={variant}>
      <InputLabel id={labelId} classes={{ root: classes.labelRoot }}>{label}</InputLabel>
      <MUISelect
        fullWidth={fullWidth}
        labelId={labelId}
        value={value}
        onChange={(e) => onChange((e.target.value as typeof value))}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
