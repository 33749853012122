import { Box, Button, Container, CssBaseline, Icon } from '@material-ui/core';
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import LoginBox from './loginbox';
import { ConferenceContext } from '../context/ConferenceContext';

export const AuthWall = (props) => {
  const { authState } = useContext(AuthContext);
  const { conferenceState } = useContext(ConferenceContext);
  return !authState.userLoggedIn ||
    conferenceState.conferenceDetailProgress ||
    (conferenceState.conferenceDetail &&
      !conferenceState.conferenceDetail.fullInfo) ? (
    <Box>
      <CssBaseline />
      <Container maxWidth="xs">
        <Box>
          <Button startIcon={<Icon>home</Icon>} href="/">
            {window.LANG.HOME}
          </Button>
        </Box>
        <LoginBox />
      </Container>
    </Box>
  ) : (
    props.children
  );
};
