import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import { browser } from '@/js/shared/utils/detect';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      // width: '100%',
      // padding: theme.spacing(1),
      textAlign: 'center',
      ...override.root,
    }),
    icon: {
      verticalAlign: 'middle',
    },
    instruction: {
      backgroundColor: theme.palette.background.shadedown_10,
      borderRadius: 5,
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    note: {
      fontSize: '0.7rem',
    },
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { description },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const insideIframe = window.self !== window.top; // Page editor
  const isIOS = browser.isIOS;
  const isTouch = browser.isTouchScreen;
  const isPWA = (window as any).INSIDE_PWA;

  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {(insideIframe || (isTouch && !isPWA && authState.userLoggedIn)) && (
        <>
          {!showInfo ? (
            <Button
              startIcon={<Icon>add_box</Icon>}
              variant="contained"
              color="primary"
              onClick={() => setShowInfo(true)}
            >
              {window.LANG.WEBAPP_BTN}
            </Button>
          ) : (
            <div className={classes.instruction}>
              {isIOS ? (
                <div>
                  <Icon className={classes.icon}>ios_share</Icon>{' '}
                  {window.LANG.WEBAPP_IOS}
                </div>
              ) : (
                <div>
                  <Icon className={classes.icon}>more_vert</Icon>{' '}
                  {window.LANG.WEBAPP_ANDROID}
                </div>
              )}
            </div>
          )}
          {description && (
            <Box mt={1} className={classes.note}>
              <Typography>{description}</Typography>
            </Box>
          )}

          {insideIframe && (
            <Box mt={1} className={classes.note}>
              <Typography color="error">
                This button only show in mobile, when logged in and not added to
                homescreen.
              </Typography>
              {!(window as any).SETTINGS.WEB_APP && (
                <Typography color="error">
                  Alert: You need enable web app in conference settings!
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </div>
  );
};
