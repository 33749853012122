import React, { createContext, useEffect, useReducer, useRef } from 'react';
import authActions from '../actions/authActions';
import authReducer from '../reducers/authReducer';
import CoSnackbar from '../components/ui/CoSnackbar';
import C from '../../../../shared/constants/general';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [authState, dispatch] = useReducer(authReducer, {
    stateLoaded: false,
    stateProgress: false,
    stateError: null,
    updateProgress: false,
    updateError: null,
    userLoggedIn: false,
    loginProgress: false,
    loginError: null,
    forgotSuccess: false,
    forgotError: null,
    user: null,
  });
  const actions = authActions(dispatch);
  const snackRef = useRef();

  const isModerator =
    authState.user &&
    authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;
  const isModeratorOrSpeaker =
    authState.user &&
    (isModerator ||
      authState.user.participation_level ===
        C.VISITOR.PARTICIPATION_LEVEL.CONTRIBUTE);

  useEffect(() => {
    if (!props.hasOwnProperty('autoLoad') || props.autoLoad === true) {
      actions.getUser(window.CONFERENCE_NAME);
    }
  }, []);

  const openSnackbar = (text, duration = 4000) => {
    snackRef.current.setState({ open: true, text, duration });
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        dispatch,
        actions,
        openSnackbar,
        isModerator,
        isModeratorOrSpeaker,
      }}
    >
      <CoSnackbar ref={snackRef} />
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
