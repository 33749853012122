import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import COUNTRIES from '../../../../../shared/constants/countries';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';
import { LiveContainer } from '../../../live/LiveContainer';
import CoChip from '../../../shared/components/ui/CoChip';
import DropZone from '../../../shared/components/ui/DropZone';
import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';

const useStyles = makeStyles((theme) => ({
  field: {
    // backgroundColor: '#000',
    width: '100%',
  },
  selectField: {
    padding: 10,
  },
  btn: {
    marginLeft: 8,
  },
  uploadprogress: {
    position: 'absolute',
    left: 80,
    top: 25,
  },
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
    opacity: 0.2,
  },
  fullOpacity: {
    opacity: 1,
  },
  avatarHolder: {
    width: 200,
    height: 200,
    position: 'relative',
  },
  avatarDrop: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  checkbox: {},
  profileAvatarBg: {
    backgroundColor: theme.palette.background.shadedown_20,
  },
  noPadding: {
    padding: 0,
  },
  groupDivider: {
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 5,
    '&::before, &::after': {
      display: 'block',
      flex: 1,
      content: '""',
      backgroundColor: theme.palette.text.secondary,
      height: 1,
      margin: '0 1em',
    },
  },
}));

const schema = yup
  .object()
  .shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    // email: yup.string().email().required(),
    info_website: yup.string().url().nullable(),
  })
  .required();

const CountrySelect = React.memo(
  (props) => {
    return (
      <FormControl className={props.classes.field} variant="filled">
        <InputLabel htmlFor="location_countrycode">Country</InputLabel>
        <NativeSelect
          name="location_countrycode"
          onChange={props.handleSelectChange}
          value={props.value}
          className={props.classes.field}
          inputProps={{
            className: props.classes.selectField,
            name: 'location_countrycode',
            id: 'location_countrycode',
          }}
        >
          {Object.keys(COUNTRIES.COUNTRIES).map((code) => (
            <option key={code} value={code}>
              {COUNTRIES.COUNTRIES[code]}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);
CountrySelect.displayName = 'CountrySelect';

const ProfileEdit = withRouter((props) => {
  const classes = useStyles();

  const { authState, actions } = useContext(AuthContext); // Hämta Context
  const { conferenceState, actions: conferenceActions } = useContext(
    ConferenceContext
  ); // Hämta Context
  const [picture, setPicture] = useState(null);
  const [tags, setTags] = useState([]);
  const [conferenceTags, setConferenceTags] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [valuesLoaded, setValuesLoaded] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      first_name: '',
      picture: null,
      location_countrycode: 'SE',
      // info_links: [],
    },
  });
  register('picture', {
    type: 'text',
  });
  register('location_countrycode', {
    type: 'text',
  });

  const values = getValues();

  const handleSelectChange = (ev) => {
    setValue('location_countrycode', ev.target.value);
  };

  const onSubmit = (data) => {
    actions.updateUser(
      {
        form: data,
        tags: tags,
      },
      () => {
        window.location.href = '/profile/account';
      }
    );
  };

  const uploadStart = () => {
    setUploading(true);
  };
  const uploadHandler = (fileInfo) => {
    setPicture(fileInfo.generated);
    setValue('picture', fileInfo.generated);
  };
  const avatarImageloaded = () => {
    setUploading(false);
  };
  const toggleTag = (tagId) => {
    const tagIndex = tags.findIndex((tag) => tag === tagId);

    if (tagIndex === -1) {
      setTags([...tags, tagId]);
    } else {
      const newTags = [...tags];
      newTags.splice(tagIndex, 1);
      setTags(newTags);
    }
  };

  useEffect(() => {
    reset({
      first_name: authState.user.first_name,
      last_name: authState.user.last_name,
      location_countrycode: authState.user.location_countrycode || 'SE',
      location_city: authState.user.location_city,
      email: authState.user.email,
      phone: authState.user.phone,
      title: authState.user.title,
      company: authState.user.company,
      info_description: authState.user.info_description,
      info_website: authState.user.info_website,
      picture: authState.user.picture,
    });

    setPicture(authState.user.picture);
    setTags(authState.user.tags.map((userTag) => userTag.id));
    setValuesLoaded(true);

    conferenceActions.loadPrefetchedDetail();
  }, []);

  useEffect(() => {
    if (conferenceState.conferenceDetailLoaded) {
      const selectedTags = conferenceState.conferenceDetail.tags.map((tag) => {
        return { ...tag, selected: tags.includes(tag.id) };
      });
      setConferenceTags([
        ...selectedTags.filter((t) => t.type === 'skill'),
        ...selectedTags.filter((t) => t.type === 'scouting'),
      ]);
    }
  }, [tags.length, conferenceState.conferenceDetailLoaded]);

  return (
    <LiveContainer welcomeState={props.welcomeState}>
      <Box>
        <Box className={classes.profileAvatarBg}>
          <Container className={classes.noPadding} maxWidth="lg">
            <Box align="center" pb={2} pt={2}>
              <Box className={classes.avatarHolder}>
                {uploading && (
                  <CircularProgress
                    className={classes.uploadprogress}
                    disableShrink
                  />
                )}
                <Avatar
                  className={clsx(
                    classes.avatar,
                    picture && !uploading && classes.fullOpacity
                  )}
                  onLoad={avatarImageloaded}
                  src={getImage(
                    picture || '/avatars/co_avatars_14.png',
                    ImageSize.Small
                  )}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.avatarDrop}
                >
                  <DropZone
                    acceptedfiletypes="image/jpeg, image/png"
                    uploadStart={uploadStart}
                    uploadHandler={uploadHandler}
                    buttonText={picture ? 'Change photo' : 'Upload photo'}
                    uploadurl={`/api/visitor/conferences/${window.CONFERENCE_ID}/signmediaupload`}
                  />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box pb={4}>
          {valuesLoaded && (
            <Container maxWidth="md">
              <Box py={2}>
                <Box align="center">
                  <Link href="/profile/account">Back to profile</Link>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.groupDivider}
                      >
                        BASIC INFO
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        autoComplete="nope"
                        error={errors.hasOwnProperty('first_name')}
                        {...register('first_name')}
                        label="First Name"
                        placeholder="Jane"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.first_name && (
                        <FormHelperText error>
                          {errors.first_name.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={errors.hasOwnProperty('last_name')}
                        {...register('last_name')}
                        label="Last Name"
                        placeholder="Doe"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.last_name && (
                        <FormHelperText error>
                          {errors.last_name.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...register('title')}
                        label="Title"
                        placeholder="CEO, Student, ..."
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...register('company')}
                        label="Organization"
                        placeholder="University, Company, ..."
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={7} sm={6}>
                      <TextField
                        {...register('location_city')}
                        label="City"
                        placeholder="New York"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={5} sm={6}>
                      <CountrySelect
                        classes={classes}
                        handleSelectChange={handleSelectChange}
                        value={values.location_countrycode}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.groupDivider}
                      >
                        CONTACT
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled
                        error={errors.hasOwnProperty('email')}
                        {...register('email')}
                        label="Email (can't change)"
                        placeholder="jane@doe.com"
                        type="email"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.email && (
                        <FormHelperText error>
                          {errors.email.message}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        {...register('phone')}
                        label="Phone"
                        placeholder="+4670123456"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.groupDivider}
                      >
                        ABOUT YOU
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.hasOwnProperty('info_description')}
                        {...register('info_description')}
                        label="Description"
                        multiline
                        minRows="4"
                        fullWidth
                        placeholder="Introduce yourself"
                        type="text"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.hasOwnProperty('info_website')}
                        {...register('info_website')}
                        label="Website"
                        placeholder="https://www.yourweb.com"
                        type="text"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.info_website && (
                        <FormHelperText error>
                          {errors.info_website.message}
                        </FormHelperText>
                      )}
                    </Grid>

                    {conferenceTags.length > 0 && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            className={classes.groupDivider}
                          >
                            SKILLS, ETC
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Box pb={1}>{window.LANG.SELECT_TAGS}</Box>
                          {conferenceTags.map((tag) => (
                            <CoChip
                              onClick={() => toggleTag(tag.id)}
                              variant="default"
                              selected={tag.selected}
                              key={tag.id}
                              tagtype={tag.type}
                              label={tag.title}
                            />
                          ))}
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.groupDivider}
                      >
                        DONE?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Box>
                          {authState.updateProgress ? (
                            <CircularProgress disableShrink />
                          ) : (
                            <>
                              <Button
                                className={classes.btn}
                                href="/profile/account"
                                variant="contained"
                                color="default"
                              >
                                Cancel
                              </Button>
                              <Button
                                className={classes.btn}
                                type="submit"
                                variant="contained"
                                color="primary"
                              >
                                Save
                              </Button>
                            </>
                          )}
                          {authState.updateError && (
                            <FormHelperText error>
                              {authState.updateError}
                            </FormHelperText>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Container>
          )}
        </Box>
      </Box>
    </LiveContainer>
  );
});

export default ProfileEdit;
