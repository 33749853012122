import { useEventEmitter } from '@byteclaw/use-event-emitter';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import C from '../../../../shared/constants/general';
import conferenceActions from '../actions/conferenceActions';
import { DiscussionEdit } from '../components/discussion/DiscussionEdit';
import ThreadMessagePopup from '../components/interact/ThreadMessagePopup';
import { Announcement } from '../components/ui/Announcement';
import ParticipantPopup from '../components/ui/ParticipantPopup';
import conferenceReducer from '../reducers/conferenceReducer';
import { AuthContext } from './AuthContext';
import { PusherContext } from './PusherContext';
import { DateUtils } from '../utils/dateUtils';
import { findCurrentDayId } from '../utils/DataFormat';
import { ThreadContext } from './ThreadContext';
import { DiscussionContext } from './DiscussionContext';
import { browser } from '../utils/detect';

export const ConferenceContext = createContext();

const ConferenceContextProvider = (props) => {
  const threadModalRef = useRef();
  const discussionModalRef = useRef();
  const userModalRef = useRef();
  const announcementRef = useRef();
  const [conferenceUpdateStepper, setConferenceUpdateStepper] = useState(0);
  const {
    authState,
    actions: authActions,
    openSnackbar,
    isModeratorOrSpeaker,
  } = useContext(AuthContext);
  const { loadThreads } = useContext(ThreadContext);

  const { loadNotifications } = useContext(DiscussionContext);
  const { pusher, ROOM_TYPE, actions: pusherActions } = useContext(
    PusherContext
  ); // Hämta Context

  const [conferenceState, dispatch] = useReducer(conferenceReducer, {
    conferenceListProgress: false,
    conferenceListLoaded: false,
    conferenceListError: null,
    conferenceList: [],
    conferenceDetailProgress: false,
    conferenceDetailLoaded: false,
    conferenceDetailError: null,
    conferenceDetail: null,
    pollProgress: false, // TODO: Move to better Context
    pollError: null, // TODO: Move to better Context
  });

  const welcomeState =
    conferenceState.conferenceDetail &&
    conferenceState.conferenceDetail.state === 'welcome' &&
    !isModeratorOrSpeaker;

  // create array of all updatedAt of days and their program rooms, to use in useEffect
  const updatedAtArray = conferenceState.conferenceDetail
    ? [conferenceState.conferenceDetail.updatedAt]
    : [];
  conferenceState.conferenceDetail &&
    conferenceState.conferenceDetail.programDays.forEach((day) => {
      updatedAtArray.push(day.updatedAt);
      day.programRooms.forEach((room) => {
        updatedAtArray.push(room.updatedAt);
      });
    });
  useEffect(() => {
    setConferenceUpdateStepper((oldVal) => oldVal + 1);
  }, [updatedAtArray.join('')]); // Update when any of the updatedAt changes

  const actions = conferenceActions(dispatch);
  const eventEmitter = useEventEmitter();

  const openUserPopup = (user, userlist = []) => {
    userModalRef.current.setState({ open: true, user: user, userlist });
  };
  const openThreadPopup = (threadId) => {
    threadModalRef.current.open({ threadId });
  };
  const openNewThreadPopup = (visitors, position, onlyVisitorId) => {
    threadModalRef.current.open({
      visitors,
      position: position,
      onlyVisitorId,
    });
  };
  /**
   * Create new Forum Discussion
   * @param {*} options conference, callback, visitor (sender can be used by moderators), discussionTags
   */
  const openNewDiscussion = (options) => {
    discussionModalRef.current.open(options);
  };

  const openSnack = (announcement) => {
    announcementRef.current.displayAnnouncement(announcement);
  };

  // Method to toggle favorite with axios call
  const toggleFavorite = (eventId, favorite) => {
    return actions.toggleFavorite(eventId, favorite);
  };

  //Server time should always be UTC. Can I be sure of that?
  const serverutctime = new Date().toISOString();
  //console.log('serverlocaltime', serverutctime);

  const findNearestNonStartedEvent = (room) => {
    const currentDayId = findCurrentDayId(conferenceState.conferenceDetail);
    const activeCurrentDay = conferenceState.conferenceDetail.programDays.find(
      (day) => day.id === currentDayId
    );
    let eventtimestampDiffArray = [];
    let timestampDiffArray = [];
    room.events.forEach((event) => {
      if (!activeCurrentDay.date) {
        return null;
      }
      let datestring = new String(activeCurrentDay.date);
      let dateparts = datestring.split('-');
      let year = dateparts[0];
      let month = dateparts[1];
      let day = dateparts[2];
      let timestring = new String(event.start_time).substring(0, 5);
      let timeparts = timestring.split(':');
      let hours = timeparts[0];
      let minutes = timeparts[1];

      //Create utc date from event date and date,
      let modifiedmonth = month - 1; //Note Month starts with 0, weird that js can compute this 01-X, but seems to work.
      let utcdate_timestamp = Date.UTC(
        year,
        modifiedmonth,
        day,
        hours,
        minutes
      );

      let utc_offest = Number(
        DateUtils.getOffsetByTimezone2(
          conferenceState.conferenceDetail.timezone,
          utcdate_timestamp
        ) / 60
      );
      //console.log('utc_offest test', utc_offest);
      //Milliseconds timestamp
      let utcdate_timestamp_offset_compensated =
        utcdate_timestamp + 3600000 * utc_offest;

      let timestampDiff =
        new Date(
          new Date(utcdate_timestamp_offset_compensated).toISOString()
        ).getTime() - new Date(serverutctime).getTime();

      //if timestampDiff negative, add event that has passed in array
      if (timestampDiff <= 0) {
        eventtimestampDiffArray.push({ timediff: timestampDiff, event: event });
        timestampDiffArray.push(timestampDiff);
      }
    });
    //All time diffs in timestampDiffArray is negative,
    //so  Math.max return the timediff of the event that last passed.
    let timediffOfNearestEvent = Math.max(...timestampDiffArray);
    let nearestobj = eventtimestampDiffArray.find(
      (x) => x.timediff === timediffOfNearestEvent
    );
    if (nearestobj) {
      return nearestobj.event;
    } else {
      // If no events has passed, return first event:
      return room.events.length > 0 ? room.events[0] : null;
    }
  };

  const autoEventStepper = () => {
    const currentDayId = findCurrentDayId(conferenceState.conferenceDetail);

    const activeCurrentDay = conferenceState.conferenceDetail.programDays.find(
      (day) => day.id === currentDayId
    );

    const daysToUpdate = [...conferenceState.conferenceDetail.programDays];

    daysToUpdate.forEach((day) => {
      if (activeCurrentDay && activeCurrentDay.id === day.id) {
        day.programRooms.forEach((room, roomIndex) => {
          let event = findNearestNonStartedEvent(room);
          if (event && room.video_state === 'recording') {
            day.programRooms[roomIndex] = {
              ...room,
              currentEventId: event.id,
            };
          }
        });
      }
    });
    conferenceState.conferenceDetail.programDays = daysToUpdate;
    dispatch({
      type: 'PROGRAM_LIVE_EVENT_CHANGE',
      conference: conferenceState.conferenceDetail,
    });
  };

  useEffect(() => {
    if (
      !conferenceState.conferenceDetail ||
      conferenceState.conferenceDetail.fullInfo !== authState.userLoggedIn
    ) {
      actions.loadConferenceDetail(
        props.conferencename,
        !authState.userLoggedIn
      );
    }
  }, [authState.userLoggedIn]);

  useEffect(() => {
    if (authState.userLoggedIn && window.SETTINGS.WEB_APP) {
      // PWA service worker
      if ('serviceWorker' in navigator && browser.isTouchScreen) {
        window.addEventListener('load', () => {
          // openSnackbar('PWA LOAD', 10000);
          navigator.serviceWorker
            .register('/serviceworker.js')
            .then((registration) => {
              console.log(
                'Service Worker registered with scope:',
                registration.scope
              );
            })
            .catch((error) => {
              console.error('Service Worker registration failed:', error);
            });
        });
      }

      // PWA reload on app open
      // window.addEventListener('appinstalled', () => {
      //   openSnackbar('PWA INSTALLED', 10000);
      // });
      window.addEventListener('focus', function () {
        // openSnackbar(
        //   `PWA FOCUS (${window.INSIDE_PWA ? 'installed' : 'not installed'})`,
        //   10000
        // );
        if (window.INSIDE_PWA) {
          console.log('PWA FOCUS - RELOAD');
          // reload conference each time app is opened
          actions.reloadConferenceDetail(props.conferencename);
          // Reload Direct Messages
          loadThreads && loadThreads();
          // Reload Discussion Notifications
          loadNotifications && loadNotifications();
        }
      });
    }
  }, [authState.userLoggedIn]);

  useEffect(() => {
    if (pusher.current && authState.userLoggedIn) {
      // Subscribe only to display online status and use for breakouts
      pusherActions.subscribe(
        ROOM_TYPE.CONFERENCE_USER,
        `${window.CONFERENCE_ID}_${authState.user.id}`
      );
      return () => {
        if (pusher.current && authState.userLoggedIn) {
          pusherActions.unSubscribe(
            ROOM_TYPE.CONFERENCE_USER,
            `${window.CONFERENCE_ID}_${authState.user.id}`
          );
        }
      };
    }
  }, [!!pusher.current, authState.userLoggedIn]);

  useEffect(() => {
    if (
      pusher.current &&
      conferenceState.conferenceDetailLoaded &&
      conferenceState.conferenceDetail
    ) {
      const conferenceChannel = pusherActions.subscribe(
        ROOM_TYPE.CONFERENCE,
        conferenceState.conferenceDetail.id
      );
      conferenceChannel.bind('conference-update', (conference) => {
        dispatch({ type: 'PROGRAM_LIVE_LIST_UPDATE', conference: conference });
        autoEventStepper();
      });
      if (props.useSnackbar) {
        conferenceChannel.bind('conference-announcement', (announcement) => {
          openSnack(announcement);
        });
      }
    }
  }, [!!pusher.current, conferenceState.conferenceDetailLoaded]);

  useEffect(() => {
    if (conferenceState.conferenceDetailLoaded) {
      autoEventStepper(); //Run once onloading data from db, go get fast setting. Interval stepper only runs every 1m.
    }
  }, [conferenceState.conferenceDetailLoaded]);

  useEffect(() => {
    const timeoutId = setInterval(() => {
      autoEventStepper();
    }, 30000);
    return function cleanup() {
      clearInterval(timeoutId);
    };
  }, [conferenceState.conferenceDetail]);

  useEffect(() => {
    const profileUnsubscribe = eventEmitter.on('profile', (userData) => {
      openUserPopup(userData);
    });
    const threadUnsubscribe = eventEmitter.on('thread', (threadId) =>
      openThreadPopup(threadId)
    );
    const newThreadUnsubscribe = eventEmitter.on(
      'newthread',
      (visitors, position) => openNewThreadPopup(visitors, position)
    );
    return () => {
      profileUnsubscribe();
      threadUnsubscribe();
      newThreadUnsubscribe();
    };
  }, []);

  const canIStartChat = (chat_level, visitor, isFriend) => {
    if (chat_level === C.CONFERENCE.CHAT_LEVEL.ALL) {
      // All
      return true;
    } else if (
      // Moderators
      chat_level === C.CONFERENCE.CHAT_LEVEL.MODERATORS &&
      visitor.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR
    ) {
      return true;
    } else if (
      // Speakers & moderaotrs
      chat_level === C.CONFERENCE.CHAT_LEVEL.SPEAKERS &&
      (visitor.access_level === C.VISITOR.ACCESS_LEVEL.SPEAKER ||
        visitor.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR)
    ) {
      return true;
    } else if (
      // Only friends and moderators/speakers
      (chat_level === C.CONFERENCE.CHAT_LEVEL.FOLLOWER && isFriend) ||
      (chat_level === C.CONFERENCE.CHAT_LEVEL.FOLLOWER &&
        (visitor.access_level === C.VISITOR.ACCESS_LEVEL.SPEAKER ||
          visitor.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR))
    ) {
      return true;
    }
    return false;
  };

  return (
    <ConferenceContext.Provider
      value={{
        conferenceState,
        dispatch,
        actions,
        conferenceUpdateStepper,
        openThreadPopup,
        openNewThreadPopup,
        openNewDiscussion,
        openUserPopup,
        canIStartChat,
        toggleFavorite,
        welcomeState,
      }}
    >
      <Announcement ref={announcementRef} />
      <ParticipantPopup ref={userModalRef} />
      <ThreadMessagePopup ref={threadModalRef} />
      <DiscussionEdit ref={discussionModalRef} />
      {conferenceState.conferenceDetail && props.children}
    </ConferenceContext.Provider>
  );
};

export default ConferenceContextProvider;
