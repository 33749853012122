import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface IProps {
  text: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
}));

// A unified placeholder
export const Placeholder = ({ text }: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography>{text}</Typography>
    </div>
  );
};
