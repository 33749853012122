import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { ManagerConferenceContext } from '@/js/shared/context/ManagerConferenceContext';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid } from '@material-ui/core';
import React, { FC, Fragment, useContext, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { start, end, label, title, description },
  },
}: IProps) => {
  const { conferenceData } = useContext(ManagerConferenceContext);

  if (!title) {
    title = conferenceData.conference.title;
  }
  return (
    <Fragment>
      <SettingsPanel label="Dates" initialOpen>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <InputControl
              name="start"
              label="Start date"
              type="datetime-local"
              defaultValue={start || new Date().toISOString().substring(0, 16)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: '900', // 5 min
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputControl
              name="end"
              label="End date"
              type="datetime-local"
              defaultValue={end || new Date().toISOString().substring(0, 16)}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: '300', // 5 min
              }}
            />
          </Grid>
        </Grid>
        {conferenceData.conference.timezone}
      </SettingsPanel>
      <SettingsPanel label="Button & text" initialOpen>
        <InputControl
          name="label"
          defaultValue={label}
          fullWidth
          variant="outlined"
          label="Button label"
        />
        <InputControl
          name="title"
          defaultValue={title}
          fullWidth
          variant="outlined"
          label="Event Title"
        />
        <InputControl
          name="description"
          defaultValue={description}
          fullWidth
          multiline
          minRows={2}
          maxRows={5}
          variant="outlined"
          label="Event Description"
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
