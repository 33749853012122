import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FC } from 'react';
import { CirclePicker, RGBColor } from 'react-color';

export interface IProps {
  label: string;
  color: RGBColor;
  palette?: string[];
  setColor: (color: RGBColor) => void;
}

const useStyles = makeStyles((theme) => ({
  card: {
    flex: '1 1 50%',
  },
  action: {
    margin: 'auto',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
}));

/**
 * Color palette picker
 *
 * @link https://casesandberg.github.io/react-color/
 * @link https://v4.mui.com/api/card/
 */
export const ColorPalette: FC<IProps> = ({
  children,
  color,
  palette,
  label,
  setColor,
}) => {
  const classes = useStyles({ color });

  return (
    <Card classes={{ root: classes.card }}>
      <CardHeader
        classes={{ action: classes.action, title: classes.title }}
        avatar={<Typography variant="body1">{label}</Typography>}
        title={children}
        action={
          <IconButton size="small" onClick={() => setColor(undefined)}>
            <Icon>reset_alt</Icon>
          </IconButton>
        }
      />
      <CardContent classes={{ root: classes.content }}>
        <CirclePicker
          color={color}
          colors={palette}
          onChangeComplete={(color) => setColor(color.rgb)}
        />
      </CardContent>
    </Card>
  );
};
