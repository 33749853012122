import { updateNode, useActions } from '@/node-editor/store/actions';
import { getNodeContent } from '@/node-editor/store/selectors';
import { getTemplate, migrateContent } from '@/node-editor/utilities';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { NodeContentError } from '../../validation/index';

interface IProps {
  error: NodeContentError;
  reset: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  button: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
}));

export const MigrationWarning: FC<IProps> = ({ error, reset, children }) => {
  const classes = useStyles();

  const actions = useActions(() => ({ updateNode }));

  const handleReset = () => {
    actions.updateNode({
      ...error.getNode(),
      content: {
        ...getTemplate(error.getNode().type).content,
      },
    });

    reset();
  };

  const handleRecover = () => {
    actions.updateNode({
      ...error.getNode(),
      content: {
        ...migrateContent(
          error.getMigrationStrategy(),
          getNodeContent(error.getNode())
        ),
      },
    });

    reset();
  };

  return (
    <div className={classes.container}>
      <Typography color="error" variant="caption">
        {children}
      </Typography>
      <br />
      <Button
        size="small"
        variant="outlined"
        classes={{ root: classes.button }}
        onClick={handleRecover}
      >
        Try to Recover
      </Button>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleReset}
      >
        Reset Content
      </Button>
    </div>
  );
};
