import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme?: any) => ({
  widgetwrapper: {
    borderRadius: 8,
    backgroundColor: theme.palette.background.shadedown_10,
    padding: 8,
    marginBottom: 8,
  },
}));

const WidgetWrapper = ({ children }: { children: any }) => {
  const classes = useStyles();

  return <div className={classes.widgetwrapper}>{children}</div>;
};

export default React.memo(WidgetWrapper);
