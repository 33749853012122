import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { RenderChildren } from '@/node-editor/components';
import { getSpacerValue } from '@/js/shared/utils/format';
import { INode, INodeProps } from '@/types/node';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, any>((theme) => ({
  root: ({ colSpacing, columns, verticalSpacing, override }) => ({
    display: 'grid',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(1, 1fr)`,
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
    },
    gridGap: theme.spacing(colSpacing),
    width: '100%',
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    ...override.root
  }),
}));

export const Render = ({
  node: {
    id,
    override,
    content: { colSpacing, columns, verticalSpacing },
  },
  children,
}: IProps) => {
  const classes = useStyles({ colSpacing, columns, verticalSpacing, override });

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      <RenderChildren>{children}</RenderChildren>
    </div>
  );
};
