import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: (props) =>
      props.hasPicture ? 'transparent' : theme.palette.primary.main,
    width: (props) => props.width,
    height: (props) => props.height,
    fontSize: (props) => props.fontSize,
    borderRadius: (props) => props.borderRadius,
  },
}));

const sizes = {
  small: { width: 22, height: 22, fontSize: 10 },
  medium: { width: 35, height: 35, fontSize: 15 },
  medium2: { width: 55, height: 55, fontSize: 17 },
  large: { width: 250, height: 250, fontSize: 40 },
  cover: { width: '100%', height: '100%' },
  expo: { width: '28.12%', height: '50%', fontSize: 40 },
};

const shouldReRender = function (prevProps, nextProps) {
  if (nextProps.rerender) {
    return false; // always re-render
  }
  return (
    nextProps.customSize &&
    prevProps.customSize &&
    prevProps.customSize.width === nextProps.customSize.width
  );
};

/**
 * Component for user avatar
 * @param {*} props A user object and an optional size string, user needs to contain initals.
 * Size can be 'small', 'medium', 'large' or 'cover'
 * @returns
 */
const UserAvatar = ({ user, customSize, size, borderRadius }) => {
  const { picture = '', initials = '..', conferenceId = '' } = user || {};
  const classes = useStyles({
    hasPicture: !!picture,
    ...(customSize || sizes[size || 'medium']),
    borderRadius: borderRadius,
  });

  const avatar = picture
    ? {
        src:
          size === 'large' || customSize
            ? getImage(picture, ImageSize.Small, conferenceId || undefined)
            : getImage(picture, ImageSize.Icon, conferenceId || undefined),
      }
    : {};

  return (
    <Avatar className={classes.avatar} src={avatar.src}>
      {avatar.src ? '' : initials}
    </Avatar>
  );
};

export default React.memo(UserAvatar, shouldReRender);
