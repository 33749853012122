import React, { createContext, useReducer, useContext, useEffect } from 'react';
import roomActions from '../actions/roomActions';
import roomReducer from '../reducers/roomReducer';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';

export const RoomContext = createContext();

const RoomContextProvider = (props) => {
  const { conferenceState } = useContext(ConferenceContext);

  const [roomState, dispatch] = useReducer(roomReducer, {
    roomProgress: false,
    roomLoaded: false,
    roomError: null,
    hasTicketAccess: true,
    room: null,
    currentEventId: null,
    currentEvent: null,
    timeReactionProgress: false,
    timeReactionSuccess: false,
    timeReactionError: null,
  });
  const actions = roomActions(dispatch);

  const updateRoomWithConfStateData = () => {
    const daysFromConfState = [...conferenceState.conferenceDetail.programDays];
    daysFromConfState.forEach((day) => {
      day.programRooms.forEach((room) => {
        if (roomState.room && roomState.room.id) {
          if (room.id === roomState.room.id) {
            dispatch({
              type: 'CHANGE_CURRENT_EVENT',
              currentEventId: room.currentEventId,
              currentEvent: room.events.find(
                (event) => event.id === room.currentEventId
              ),
            });
            return;
          }
        }
      });
    });
  };

  useEffect(() => {
    if (roomState.roomLoaded) {
      //Conference context contains latest updates.
      //We load some data into room context from conference context.
      updateRoomWithConfStateData();
    }
  }, [roomState.roomLoaded, conferenceState.conferenceDetail]);

  return (
    <RoomContext.Provider value={{ roomState, dispatch, actions }}>
      {props.children}
    </RoomContext.Provider>
  );
};

export default RoomContextProvider;
