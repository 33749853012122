import LoginBox from '@/js/shared/components/loginbox';
import { AuthContext } from '@/js/shared/context/AuthContext';
import {
  Box,
  Button,
  CssBaseline,
  Icon,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    width: '100%',
    textAlign: 'center',
    flexDirection: 'column',
  },
  formHolder: {
    width: '100%',
    maxWidth: '400px',
    padding: theme.spacing(1),
  },
  form: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.shadedown_10,
    borderRadius: 5,
  },
  smallText: {
    fontSize: '0.8rem',
  },
  input: {
    backgroundColor: theme.palette.background.default,
  },
  headerLink: {
    color: theme.palette.primary.main,
  },
}));

export const OpenASK = ({ id }: { id: string }) => {
  const { authState } = useContext(AuthContext);
  const [roomInfo, setRoomInfo] = useState(null);
  const [showLogin, toggleLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasPosted, setHasposted] = useState(false);
  const [data, setData] = useState({
    name: '',
    text: '',
  });
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    formRef.current?.focus();
    axios.get(`/api/visitor/publicrooms/${id}`).then((res) => {
      setRoomInfo(res.data);
    });
  }, []);

  const postQuestion = () => {
    setLoading(true);
    axios
      .post(`/api/visitor/publicrooms/${id}/ask`, data)
      .then((res) => {
        console.log(res.data);
        setHasposted(true);
        setData({
          ...data,
          text: '',
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDataChange = (e: any) => {
    const { name, value } = e.currentTarget;
    setData({
      ...data,
      [name]: value,
    });
  };

  const formLabel = useMemo(
    () =>
      window.LANG.BTN_ASK?.charAt(0).toUpperCase() +
      window.LANG.BTN_ASK?.slice(1).toLowerCase(),
    []
  );

  return (
    <Box className={classes.container}>
      <CssBaseline />
      <Box textAlign="center">
        {authState.userLoggedIn ? (
          <Typography variant="h6">
            <Link className={classes.headerLink} to={`/live/room/${id}`}>
              {roomInfo?.title}
            </Link>
          </Typography>
        ) : (
          <Typography variant="h6">{roomInfo?.title}</Typography>
        )}
      </Box>
      <Box className={classes.formHolder}>
        <Box className={classes.form}>
          <Box>
            {authState.userLoggedIn ? (
              <Typography variant="body2" className={classes.smallText}>
                {authState.user.full_name}
              </Typography>
            ) : (
              <TextField
                value={data.name}
                onChange={handleDataChange}
                label="Name"
                fullWidth
                name="name"
                variant="outlined"
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            )}
          </Box>
          <Box pt={1}>
            <TextField
              value={data.text}
              onChange={handleDataChange}
              label={formLabel}
              multiline
              name="text"
              inputRef={formRef}
              minRows={3}
              autoFocus
              fullWidth
              variant="outlined"
              InputProps={{
                className: classes.input,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          {hasPosted && (
            <Box pt={1}>
              <Typography variant="body2" className={classes.smallText}>
                {window.LANG.THANKS}
              </Typography>
            </Box>
          )}

          <Box textAlign="right" pt={1}>
            <Button
              endIcon={<Icon>send</Icon>}
              variant="contained"
              color="primary"
              size="small"
              onClick={postQuestion}
              disabled={loading}
            >
              post
            </Button>
          </Box>
        </Box>
        {!authState.userLoggedIn && (
          <Box pt={1} textAlign="left">
            {showLogin ? (
              <LoginBox />
            ) : (
              <Button
                size="small"
                onClick={() => toggleLogin(!showLogin)}
                color="primary"
              >
                Login?
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

OpenASK.displayName = 'OpenASK';
