import { FormHelperText, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EntityListData } from '.';
import { Select, SelectControl } from '../select-control';

interface IProps {
  onChange: (topicId: string) => void;
  conferenceId: string;
  value: string;
}

const useStyles = makeStyles((theme) => ({}));

export const EntityListSelector: FC<IProps> = ({
  onChange,
  conferenceId,
  value,
}) => {
  const classes = useStyles();

  const [entityLists, setEntityLists] = useState<EntityListData[]>([]);
  const [entityListId, setEntityListId] = useState(value);

  useEffect(() => {
    axios
      .get(`/api/conferences/${conferenceId}/entitylists`)
      .then((response: any) => {
        setEntityLists(response.data);
      });
  }, []);

  useEffect(() => {
    onChange(entityListId);
  }, [entityListId]);

  return (
    <div>
      <Select
        fullWidth
        value={entityListId || ''}
        onChange={(val) => setEntityListId(val.toString())}
        name="entityListId"
        options={[
          {
            label: 'Select list',
            value: '',
          },
          ...entityLists.map((t: EntityListData, index: number) => ({
            label: `${index + 1}. ${t.title || 'Undefined'} (${
              t.entities.length
            })`,
            value: t.id,
          })),
        ]}
      />
    </div>
  );
};
EntityListSelector.displayName = 'EntityListSelector';
