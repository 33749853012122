import Pusher from 'pusher-js';
import PusherBatchAuthorizer from 'pusher-js-auth';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthContext } from './AuthContext';
import { ThreadContext } from './ThreadContext';
import { DiscussionContext } from './DiscussionContext';

export const PusherContext = createContext();

const ROOM_TYPE = {
  CONFERENCE: 'conference',
  CONFERENCE_USER: 'conferenceuser',
  USER: 'user',
  ROOM: 'room',
  LISTROOM: 'listroom',
  STUDIOROOM: 'studioroom',
  // BACKSTAGE: 'backstage',
  MODERATORROOM: 'moderatorroom',
  VIDEO_CHAT: 'videochat',
  DISCUSSIONLIST_EVENT: 'discussionlistevent', // Used to display new discussions in event list
  DISCUSSION: 'discussion',
  DISCUSSIONLIST: 'discussionlist',
  ENTITY_CHAT: 'entitychat',
  ENTITY_QUEUE: 'entityqueue',
  ENTITY_CHAT_PRIVATE: 'entityprivate',
  ENTITY_COUNT: 'entitycount',
  ENTITY: 'entity',
};

const PusherContextProvider = (props) => {
  const { authState, dispatch: authDispatch } = useContext(AuthContext);
  const { dispatch: threadDispatch } = useContext(ThreadContext);
  const { dispatch: discussionDispatch } = useContext(DiscussionContext);
  const pusher = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  // Store channel for each room type
  const [channels, setChannels] = useState(
    Object.values(ROOM_TYPE).reduce((obj, v) => {
      obj[v] = null;
      return obj;
    }, {})
  );

  useEffect(() => {
    if (authState.userLoggedIn) {
      const pusherInstance = new Pusher(process.env.PUSHER_KEY, {
        authorizer: PusherBatchAuthorizer,
        cluster: process.env.PUSHER_CLUSTER,
        authEndpoint: '/api/pusher/auth',
      });
      pusherInstance.connection.bind('state_change', function (states) {
        setIsConnected(states.current === 'connected');
      });
      pusher.current = pusherInstance;
    } else if (pusher.current) {
      pusher.current.disconnect();
      pusher.current = null;
    }
  }, [authState.userLoggedIn]);

  const subscribe = (roomType, roomId, pusherType = 'private') => {
    const channel = pusher.current.subscribe(
      `${pusherType}-${roomType}-${roomId}`
    );
    setChannels((oldChannels) => ({ ...oldChannels, [roomType]: channel }));
    return channel;
  };
  const unSubscribe = (roomType, roomId, pusherType = 'private') => {
    if (pusher && pusher.current) {
      pusher.current.unsubscribe(`${pusherType}-${roomType}-${roomId}`);
    }
    setChannels({ ...channels, [roomType]: null });
    setChannels((oldChannels) => ({ ...oldChannels, [roomType]: null }));
  };

  useEffect(() => {
    if (pusher.current !== null) {
      const privateChannel = subscribe(ROOM_TYPE.USER, authState.user.id);
      privateChannel.bind('follow-update', (followData) =>
        authDispatch({ type: 'UPDATE_FOLLOW', followData: followData })
      );
      privateChannel.bind('thread-new-msg', (threadMsg) =>
        threadDispatch({ type: 'THREAD_NEW_MSG', msg: threadMsg })
      );
      privateChannel.bind('new-discussion-response', (msg) =>
        discussionDispatch({ type: 'DISCUSSION_NEW', msg: msg })
      );
    }
  }, [pusher.current === null]);

  return (
    <PusherContext.Provider
      value={{
        pusher,
        isConnected,
        channels,
        ROOM_TYPE,
        actions: { subscribe, unSubscribe },
      }}
    >
      {props.children}
    </PusherContext.Provider>
  );
};

export default PusherContextProvider;
