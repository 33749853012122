import { RenderChildren } from '@/node-editor/components';
import {
  getChildren,
  getRootNode,
  useSelectors,
} from '@/node-editor/store/selectors';
import { Container } from '@material-ui/core';
import React from 'react';

export const Render = () => {
  const selectors = useSelectors(() => ({
    getRootNode,
    getChildren,
  }));

  return (
    <Container disableGutters maxWidth={false}>
      <RenderChildren>
        {selectors.getChildren(selectors.getRootNode().id)}
      </RenderChildren>
    </Container>
  );
};
