module.exports.favoriteEvents = (conference, programDayId = null) => {
  let totalFavorites = [];
  const days = programDayId
    ? conference.programDays.filter((d) => d.id === programDayId)
    : conference.programDays;
  days.forEach((day) => {
    day.programRooms.forEach((room) => {
      room.events.forEach((event, index) => {
        if (event.favorites && event.favorites.length) {
          if (!event.end_time) {
            if (index < room.events.length - 1) {
              event.end_time = room.events[index + 1].start_time;
            } else {
              const endHour =
                (parseInt(event.start_time.split(':')[0]) + 1) % 24;
              event.end_time = `${endHour}:${event.start_time.substr(3)}`;
            }
          }
          // Used for calendar
          event.startAsDate = new Date(`${day.date}T${event.start_time}`);
          event.endAsDate = new Date(`${day.date}T${event.end_time}`);
          event.roomTitle = room.title;
          event.roomData = room;
          totalFavorites.push(event);
        }
      });
    });
  });

  return totalFavorites;
};

module.exports.timeStringToNum = (timeStr) => {
  const [h, m, s] = timeStr.split(':').map((v) => parseInt(v));
  return h * 3600 + m * 60 + s;
};

module.exports.markdownMiniCleanUp = (text) => {
  let marked = !text
    ? ''
    : text.replace(/\*\*(.*)\*\*/gim, '$1').replace(/_(.*)_/gim, '$1');
  return marked;
};
