import { useParticipantList } from '@/hooks/useParticipantList';
import { useParticipantTags } from '@/hooks/useParticipantTags';
import { ParticipantTagSorter } from '@/js/site/components/dashboards/ParticipantTagSorter';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Icon,
  makeStyles,
  Select,
  Switch,
} from '@material-ui/core';
import axios from 'axios';
import { FC, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import { infoList } from '../../utils/DataFormat';
import CoChipSmall from '../ui/CoChipSmall';
import SearchBar from '../ui/SearchBar';

interface IProps {
  entityListId: string;
  entities: any[];
  refreshEntityList: () => void;
}

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: 1,
  },
  online: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: '#29ff00',
    // margin: 3,
  },
  select: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

export const EntityListHostEditor: FC<IProps> = ({
  entityListId,
  entities = [],
  refreshEntityList,
}) => {
  const classes = useStyles();
  const { conferenceState } = useContext(ConferenceContext);
  const { openSnackbar } = useContext(AuthContext);

  const {
    participants,
    fullList,
    keyword,
    loadParticipants,
    handleSearch,
    filterByTag,
    online,
    setOnline,
    totalCount,
    showMore,
  } = useParticipantList({
    conferenceId: window.CONFERENCE_ID,
    programRoomId: null,
  });

  const { tagList } = useParticipantTags({
    participants: fullList,
    conferenceTags: conferenceState.conferenceDetail.tags,
  });

  const [checked, setChecked] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const toggleChecked = (visitorId: string) => {
    if (checked.includes(visitorId)) {
      setChecked(checked.filter((c) => c !== visitorId));
    } else {
      setChecked([...checked, visitorId]);
    }
  };
  const moveTo = (e: any) => {
    const nrToMove = checked.length;
    const roomTitle = e.target.options[e.target.selectedIndex].text;
    axios
      .post(`/api/visitor/entities/${e.target.value}/publicvisitors`, {
        ids: checked,
      })
      .then((response) => {
        const nrAdded = parseInt(response.data.added);
        const duplicates = nrToMove - nrAdded;
        openSnackbar(
          `${nrAdded > 0 ? `Added ${nrAdded} to "${roomTitle}"` : ``} ${
            duplicates ? `(Ignored ${duplicates} duplicates)` : ''
          }`,
          8000
        );
        setChecked([]);
        refreshEntityList();
      });
  };

  const spreadOnRooms = () => {
    const nrToMove = checked.length;
    axios
      .put(`/api/visitor/entitylists/${entityListId}/spreadpublicvisitors`, {
        ids: checked,
      })
      .then((response) => {
        const nrAdded = parseInt(response.data.added);
        const duplicates = nrToMove - nrAdded;
        openSnackbar(
          `${nrAdded > 0 ? `Added ${nrAdded} ` : ``} ${
            duplicates ? `(Ignored ${duplicates} duplicates)` : ''
          }`,
          8000
        );
        setChecked([]);
        refreshEntityList();
      });
  };
  const toggleOnline = () => {
    setOnline(!online);
    setSelectedTag(null);
  };
  const selectTag = (tagId: string) => {
    setSelectedTag(selectedTag === tagId ? null : tagId);
  };
  useEffect(() => {
    loadParticipants();
  }, []);

  const onFocusChange = () => {
    setSelectedTag(null);
  };
  useEffect(() => {
    filterByTag(selectedTag);
  }, [selectedTag]);

  return (
    <div>
      <Box pb={1}>
        <Switch
          className={classes.checkbox}
          size="small"
          checked={online}
          onClick={toggleOnline}
        />{' '}
        <strong>Only people online</strong>
      </Box>
      <ParticipantTagSorter
        tagList={tagList}
        selectedTag={selectedTag}
        selectTag={selectTag}
      />
      <Box pb={1}>
        <SearchBar
          onFocusChange={onFocusChange}
          onSearch={handleSearch}
          keyword={keyword}
        />
      </Box>

      <Box textAlign="left">
        <Box pb={1} display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Checkbox
              className={classes.checkbox}
              size="small"
              checked={checked.length === participants.length}
              onClick={() =>
                checked.length === participants.length
                  ? setChecked([])
                  : setChecked(participants.map((p) => p.id))
              }
            />{' '}
            <strong>All</strong>
          </Box>
          <Box pr={1}>{checked.length}&nbsp;Selected</Box>
          <Box>
            <Select
              native
              defaultValue=""
              color="secondary"
              value="none"
              label="Move to"
              className={classes.select}
              onChange={moveTo}
              disabled={checked.length === 0 || checked.length > 50}
            >
              <option value="none">Add to room:</option>
              {entities.map((ent) => (
                <option key={ent.id} value={ent.id}>
                  {ent.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            Or&nbsp;
            <Button
              size="small"
              color="secondary"
              onClick={spreadOnRooms}
              startIcon={<Icon>shuffle</Icon>}
              disabled={checked.length === 0 || checked.length > 50}
            >
              Shuffle
            </Button>
          </Box>
        </Box>
        <Divider />
        {participants.map((user) => (
          <Box key={user.id} display="flex">
            <Box>
              <Checkbox
                className={classes.checkbox}
                size="small"
                checked={checked.includes(user.id)}
                onClick={() => toggleChecked(user.id)}
              />
            </Box>
            <Box flexGrow={1}>
              <strong>{user.full_name}</strong>{' '}
              {infoList([user.title, user.company, user.location_city])}
              <br />
              {user.online && <span className={classes.online} />}{' '}
              {user.tags
                .map((tag: any) => (
                  <CoChipSmall
                    size="smaller"
                    key={tag.id}
                    tagtype={tag.type}
                    label={tag.title}
                  />
                ))
                .reduce((r, a) => r.concat(', ', a), [])
                .slice(1)}
            </Box>
          </Box>
        ))}
        {totalCount > participants.length && (
          <Box align="center">
            <Button size="small" color="secondary" onClick={showMore}>
              Load more
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};
