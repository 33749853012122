import { ConditionalRenderer } from '@/components/conditional-renderer';
import { VideoPlayer } from '@/components/video-player';
import { useMuxSrcConstructor } from '@/hooks/useMuxSrcConstructor';
import { useNodeProps } from '@/node-editor/hooks';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { makeStyles, Theme } from '@material-ui/core';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  root: ({ override }) => ({
    width: '100%',
    aspectRatio: '16/9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    ...override.root,
  }),
  none: {
    backgroundColor: '#333',
  },
}));

export const Render = ({
  node: {
    id,
    content: { mediaId },
    override,
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({ override });

  const [videoInfo, setVideoInfo] = useState<any | null>(null);

  useEffect(() => {
    mediaId &&
      axios
        .get(`/api/public/media/${mediaId}/videoinfo`)
        .then((res: any) => {
          setVideoInfo({
            ...useMuxSrcConstructor({
              posterToken: res.data.thumbJWT,
              srcToken: res.data.videoJWT,
              playbackId: res.data.playbackId,
              access: 'public',
            }),
            mediaFile: res.data.mediaFile,
          });
        })
        .catch((err: any) => {
          console.error(err);
          setVideoInfo({
            error: err.response.data.message || 'Error loading video',
            status: err.response.status,
          });
        });
  }, [mediaId]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.none]: !mediaId || (videoInfo && videoInfo.error),
      })}
      {...useNodeProps(id)}
    >
      {!mediaId && <div>No video selected</div>}
      {videoInfo &&
        (videoInfo.error ? (
          <div>
            {videoInfo.status === 401
              ? (window as any).LANG.AUTH_REQUIRED
              : videoInfo.error}
          </div>
        ) : (
          <VideoPlayer
            source={videoInfo}
            trackingdata={{
              programroomId: 'Widget',
              videoTitle: videoInfo.mediaFile.original_filename,
            }}
          ></VideoPlayer>
        ))}
    </div>
  );
};
