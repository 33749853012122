import axios from 'axios';

export interface IProps {
  uploadurl?: string;
  uploadStart?: (value: Blob) => void;
  errorHandler?: (value: string) => void;
  progressHandler?: (value: number) => void;
}

export const useMarkdownImageUpload = ({
  uploadurl = `/api/visitor/conferences/${window.CONFERENCE_ID}/signforummediaupload`,
  uploadStart,
  errorHandler,
  progressHandler,
}: IProps) => {
  const handleProgress = (event) => {
    progressHandler && progressHandler(event);
  };

  const tryAcceptError = (file: File) => {
    if (
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/webp'
    ) {
      return 'Accepts only jpg & png';
    }
    if (file.size > 6192000) {
      return 'No bigger than 6 MB';
    }
    return null;
  };

  const getSignedRequest = (
    file: File,
    onSuccess: (fileData: { generated: string; original: string }) => void,
    onError: (err: string) => void
  ) => {
    const err = tryAcceptError(file);

    if (err) {
      return onError(err);
    }

    uploadStart && uploadStart(file);

    axios
      .get(`${uploadurl}?file-name=${file.name}&file-type=${file.type}`)
      .then((response) => {
        uploadFile(
          file,
          response.data.signedRequest,
          () => {
            const retVal = {
              generated: response.data.generatedName,
              original: file.name,
            };
            onSuccess(retVal);
          },
          (err: Error) => {
            console.log('upload error: ', err);
            onError(err.message);
          }
        );
      })
      .catch((error) => {
        console.log('load error', error.message);
        onError(error.message);
      });
  };

  const uploadFile = (
    file: File,
    signedRequest: string,
    callback: () => void,
    errorHandler: (err: Error) => void
  ) => {
    axios
      .put(signedRequest, file)
      .then(() => {
        callback();
      })
      .catch((error) => {
        console.log('uploadFile error', error);
        errorHandler(new Error('Could not upload file.'));
      });
  };

  return { getSignedRequest };
};
