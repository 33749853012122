import { rgbToCssColor } from '@/js/shared/utils/format';
import { RenderChildren } from '@/node-editor/components';
import { INode, INodeProps } from '@/types/node';
import { Grid, GridSize, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
import { getGridNumber } from '@/node-editor/utilities';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Partial<IContent> & { override: Record<string, any> }
>(() => ({
  gridRoot: ({ override }) => ({
    width: '100%',
    ...override.root,
  }),
  container: ({
    minHeight,
    textColor,
    backgroundColor,
    alignment: { vertical, horizontal },
  }) => ({
    display: 'flex',
    minHeight: minHeight === 0 ? 'auto' : `${minHeight}px`,
    flexDirection: 'column',
    justifyContent: vertical,
    alignItems: horizontal,
    position: 'relative',
    ...(textColor && { color: rgbToCssColor(textColor as any) }),
    ...(backgroundColor && {
      backgroundColor: rgbToCssColor(backgroundColor as any),
    }),
    '&>div': {
      position: 'relative',
    },
    overflow: 'hidden',
  }),
  background: ({ backgroundImage }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  }),
  video: {
    position: 'absolute',
    // top: 0,
    // left: 0,
    objectFit: 'cover',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    backgroundSize: 'cover',
    overflow: 'hidden',
    display: 'block',
  },
}));

export const Render = ({
  node: {
    id,
    override,
    content: {
      alignment,
      backgroundColor,
      backgroundImage,
      maxWidth,
      textColor,
      minHeight = 0,
      videoId,
    },
  },
  children,
}: IProps) => {
  const classes = useStyles({
    alignment,
    backgroundColor,
    backgroundImage: getImage(backgroundImage, ImageSize.Large),
    maxWidth,
    textColor,
    minHeight,
    override,
  });
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const videoUrl =
    videoId && videoId !== '-'
      ? `https://stream.mux.com/${videoId}/${smallScreen ? 'low' : 'high'}.mp4`
      : null;
  return (
    <Grid
      item
      xs={12}
      md={getGridNumber(maxWidth)}
      classes={{ root: classes.gridRoot }}
      {...useNodeProps(id)}
    >
      <div
        className={clsx(classes.container, {
          [classes.background]: Boolean(backgroundImage),
        })}
      >
        {videoUrl && (
          <video
            playsinline="1"
            muted="1"
            loop="1"
            autoplay="1"
            className={classes.video}
          >
            {/* <source src="https://cdn2.hubspot.net/hubfs/4149027/WSAI2018%20-%20AfterMovie.mp4" /> */}
            <source src={videoUrl} />
          </video>
        )}
        <RenderChildren>{children}</RenderChildren>
      </div>
    </Grid>
  );
};
