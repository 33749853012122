import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';

const connectionStyles = makeStyles((theme) => ({
  imageLogo: {
    width: 25,
    height: 25,
    borderRadius: 5,
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: 8,
  },
  eventCircle: {
    marginRight: 8,
    width: 12,
    height: 12,
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}));

export const DiscussionConnection = ({ type, picture, title, url = null }) => {
  const classes = connectionStyles();
  const IconImg = () =>
    type === 'entity' ? (
      <span
        className={classes.imageLogo}
        style={{
          backgroundImage: `url(${getImage(picture, ImageSize.Small)})`,
        }}
      ></span>
    ) : type === 'event' ? (
      <span className={classes.eventCircle} />
    ) : (
      <span></span>
    );
  return (
    <ButtonBase href={url}>
      <IconImg />
      <span className={classes.title}>{title}</span>
    </ButtonBase>
  );
};
DiscussionConnection.displayName = 'DiscussionConnection';
