import { createTheme } from '@material-ui/core/styles';

// Defined using
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFF3E0&secondary.color=FBC02D

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiLink: {
      // Name of the rule
      root: {
        color: '#78AF36',
        fontWeight: 700,
      },
    },
  },
  palette: {
    primary: {
      main: '#cf9400',
      light: '#e8a90b',
      dark: '#c99001',
    },
    secondary: {
      main: '#78AF36',
      light: '#baec86',
      dark: '#548621',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },

  shape: {
    borderRadius: 2,
  },
  shadows: Array(25).fill('none'),
});

export default theme;
