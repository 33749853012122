import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Login/Register',
  icon: 'how_to_reg',
  type: 'navigation',
  tag: 'components',
  content: {
    btnText: 'till live-receptionen',
    startWithLogin: false,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
