import {
  Box,
  Button,
  Container,
  CssBaseline,
  Icon,
  Link,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../shared/context/AuthContext';
import UserAvatar from '../../../shared/components/ui/UserAvatar';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  error: {
    color: '#ff0000',
  },
  userName: {
    fontSize: '1.5rem',
  },
}));

const TokenLogin = (props) => {
  const classes = useStyles();
  const { authState } = useContext(AuthContext); // Hämta Context
  const [errorObject, setError] = useState('');
  const [user, setUser] = useState(authState.user);
  const redirect = useQuery().get('redirect');

  function gotoUrl() {
    // url = url.split('.').join("");
    // var regex = /^([a-zA-Z0-9/\-'])*$/;
    // if (!regex.test(url)) {
    //   console.log("invalid url");
    //   alert('Url is not valid');
    // } else {
    //   var getUrl = window.location;
    //   var baseUrl = getUrl.protocol + "//" + getUrl.host;
    //   window.location.href = baseUrl + url;
    // }
    if (!redirect || redirect.substring(0, 1) !== '/') {
      window.location.href = '/live';
    } else {
      window.location.href = redirect;
    }
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  // const addToHomescreen = () => {
  //   if (window.deferredPrompt) {
  //     window.deferredPrompt.prompt();
  //     window.deferredPrompt.userChoice.then((choiceResult) => {
  //       if (choiceResult.outcome === 'accepted') {
  //         console.log('User accepted the A2HS prompt');
  //         window.deferredPrompt = null;
  //       } else {
  //         console.log('User dismissed the A2HS prompt');
  //       }
  //     });
  //   }
  // };
  // const openShareApi = () => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: 'Share',
  //         text: 'Share',
  //         url: window.location.href,
  //       })
  //       .then(() => {
  //         console.log('Thanks for sharing!');
  //       })
  //       .catch(console.error);
  //   }
  // };

  // useEffect(() => {
  //   let tokenid = props.match.params.tokenid;
  //   axios.get('/api/login/withtoken/?token=' + tokenid, {}).then((response) => {
  //     if (response.data) {
  //       setUser(response.data.user);

  //       // if (!url) {
  //       //   gotoUrl("/");
  //       // }
  //     }
  //   }).catch((error) => {
  //     setError(error.response.data);
  //   }
  //   );
  // }, [authState.stateLoaded, authState.userLoggedIn]);

  // console.log('authState', authState.user);
  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <Box pt={2}>
        {authState.stateLoaded && errorObject ? (
          <Box align="center">
            <Typography variant="h6">Login error</Typography>
            <Box className={classes.error} pt={1}>
              {errorObject.message}
            </Box>
          </Box>
        ) : authState.stateLoaded && user ? (
          <Box id="user" className={classes.user} align="center">
            <Box className={classes.info}>You&apos;ve been logged in as</Box>
            <Box p={1}>
              <UserAvatar size="large" user={user} />
            </Box>
            <Box className={classes.userName}>{user.full_name}</Box>

            <Box p={1}>
              <Button
                onClick={() => gotoUrl()}
                size="small"
                variant="contained"
                color="primary"
              >
                Enter
              </Button>
            </Box>

            {redirect && redirect !== '/live' && !window.INSIDE_PWA && (
              <Box pt={2}>
                or to <Link href="/live">Start page</Link>
              </Box>
            )}
          </Box>
        ) : (
          <Box align="center">
            <CircularProgress disableShrink />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default TokenLogin;
