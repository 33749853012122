import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import VideoChatCall from '../components/interact/VideoChatCall';
import VideoChatSimple from '../components/interact/VideoChatSimple';
import { generateRandomString } from '../utils/StringFormat';
import { AuthContext } from './AuthContext';
import { PusherContext } from './PusherContext';

export const VideoChatContext = createContext();

const VIDEO_RESET = { videoChatId: null, members: [] };

const VideoChatContextProvider = (props) => {
  const { authState, openSnackbar } = useContext(AuthContext);
  const { channels, ROOM_TYPE, actions: pusherActions } = useContext(
    PusherContext
  ); // Hämta Context
  const [videoData, setVideoData] = useState(VIDEO_RESET);
  const [callData, setCallData] = useState(null);

  const pingInviteToVideoChat = (visitorId, videoChatId, participants) => {
    axios.post(
      `/api/visitor/conferences/${window.CONFERENCE_ID}/videoinvite/${visitorId}`,
      {
        videoChatId: videoChatId,
        participants: participants,
        callerVisitorId: authState.user.id,
      }
    );
  };
  const pingAnswerVideoChat = (visitorId, videoChatId, accepted) => {
    axios.post(
      `/api/visitor/conferences/${window.CONFERENCE_ID}/videoanswer/${visitorId}`,
      {
        videoChatId: videoChatId,
        accepted: accepted,
      }
    );
  };
  const pingCancelVideoChat = (visitorId, videoChatId) => {
    axios.post(
      `/api/visitor/conferences/${window.CONFERENCE_ID}/videocancel/${visitorId}`,
      {
        videoChatId: videoChatId,
      }
    );
  };
  const preCreateRoom = (roomId, cb) => {
    axios
      .post(`/api/visitor/webrtc/createroom`, {
        roomId: roomId,
      })
      .then(() => {
        cb();
      })
      .catch(() => {
        openSnackbar('Could not start video room', 3000);
      });
  };

  const isBusyTalking = () => {
    return videoData.videoChatId !== null;
  };

  const isBusyCalling = () => {
    return callData !== null;
  };

  // Start new video chat
  const newVideoChatInvitation = (visitor) => {
    if (authState.user.id !== visitor.id) {
      const participants = [
        {
          id: authState.user.id,
          full_name: authState.user.full_name,
          picture: authState.user.picture,
          company: authState.user.company,
          location_city: authState.user.location_city,
        },
      ];
      setCallData({
        direction: 'outgoing',
        visitorId: visitor.id,
        videoChatId: 'new',
        participants: [visitor],
      });
      pingInviteToVideoChat(visitor.id, null, participants, authState.user.id);
    }
  };
  // Call to Add to current video chat
  const addToVideoChatInvitation = (visitor, participants) => {
    if (
      authState.user.id !== visitor.id &&
      !participants.find((p) => p.id === visitor.id)
    ) {
      setCallData({
        direction: 'outgoing',
        visitorId: visitor.id,
        videoChatId: videoData.videoChatId,
        participants: [visitor],
      });
      pingInviteToVideoChat(visitor.id, videoData.videoChatId, participants);
    }
  };

  const createEmptyRoom = () => {
    setVideoData({ ...VIDEO_RESET, videoChatId: generateRandomString(12) });
  };
  const createEmptyPrivateRoom = () => {
    setVideoData({
      ...VIDEO_RESET,
      videoChatId: `private-${generateRandomString(12)}`,
    });
  };

  const handleSendInvitation = (visitor, hangUpAndStartNew = false) => {
    if (isBusyCalling()) {
      // Already calling someone
    } else if ((!visitor && !isBusyTalking()) || hangUpAndStartNew) {
      // Create empty room
      createEmptyRoom();
    } else if (isBusyTalking()) {
      addToVideoChatInvitation(visitor, videoData.members);
    } else {
      newVideoChatInvitation(visitor);
    }
  };
  // Handle your answer to a call
  const handleAnswerForIncomingCall = (accepted) => {
    let returningVideoChatId = 'new',
      newCreated = false;

    if (callData.videoChatId === 'new' && accepted && callData.addToCurrent) {
      // You are in a meeting and want to add caller to that.
      returningVideoChatId = videoData.videoChatId;
    } else if (callData.videoChatId === 'new' && accepted) {
      // Generate a meeting ID if called accepted a NEW video call.
      returningVideoChatId = `private-${generateRandomString(12)}`;
      newCreated = true;
    } else if (callData.videoChatId !== 'new' && accepted) {
      // Accept group call
      returningVideoChatId = callData.videoChatId;
    }

    if (newCreated) {
      // Create room before (async problem otherwise)
      preCreateRoom(returningVideoChatId, () => {
        if (accepted) {
          // Startup video chat
          setVideoData((currentVideoData) => ({
            ...currentVideoData,
            videoChatId: returningVideoChatId,
          }));
        }
        pingAnswerVideoChat(
          callData.callerVisitorId,
          returningVideoChatId,
          accepted
        );
        setCallData(null);
      });
    } else {
      if (accepted) {
        // Startup video chat
        setVideoData((currentVideoData) => ({
          ...currentVideoData,
          videoChatId: returningVideoChatId,
        }));
      }
      pingAnswerVideoChat(
        callData.callerVisitorId,
        returningVideoChatId,
        accepted
      );
      setCallData(null);
    }
  };

  // Cancel a outgoing call you have made
  const handleIncomingCallCancel = () => {
    pingCancelVideoChat(callData.visitorId, callData.videoChatId);
    setCallData(null);
  };

  const handleVideoChatClose = () => {
    setVideoData(VIDEO_RESET);
    setCallData(null);
  };

  const incomingVideoChatInvitation = (incomingData) => {
    // Trick to get current videoChatId (because of crazy states)
    setVideoData((currentVideoData) => {
      if (
        currentVideoData.videoChatId !== null &&
        incomingData.videoChatId !== 'new' &&
        incomingData.videoChatId !== currentVideoData.videoChatId
      ) {
        // You are in group and the caller is in another group
        // Reply with "busy"
        if (
          currentVideoData.members.length > 1 &&
          incomingData.participants.length < 2
        ) {
          // The caller is alone and you are in a meeting, so add you your
          setCallData({
            ...incomingData,
            videoChatId: 'new',
            addToCurrent: true,
          });
          // } else if (currentVideoData.members.length < 2 && incomingData.participants.length > 1) {
        } else if (currentVideoData.members.length < 2) {
          // Alone in a room, so show call.
          setCallData(incomingData);
        } else {
          pingCancelVideoChat(
            incomingData.callerVisitorId,
            incomingData.videoChatId
          );
        }
      } else if (currentVideoData.videoChatId !== null) {
        // You are in group and someone is calling
        // Show incoming group call
        setCallData({ ...incomingData, addToCurrent: true });
      } else {
        // Show incoming single call
        setCallData(incomingData);
      }
      return currentVideoData;
    });
  };
  const incomingVideoChatAnswer = (incomingData) => {
    if (incomingData.accepted) {
      setVideoData((currentVideoData) => ({
        ...currentVideoData,
        videoChatId: incomingData.videoChatId,
      }));
    } else {
      openSnackbar('Busy, try later', 3000);
    }
    setCallData(null);
  };

  const incomingVideoChatCancel = () => {
    openSnackbar('Busy, try later', 3000);
    setCallData(null);
  };

  const joinPublicVideoChat = (videoChatId) => {
    setVideoData({ ...VIDEO_RESET, videoChatId: videoChatId });
  };

  useEffect(() => {
    if (isBusyTalking() && props.videoChatEnabled) {
      // When connected to call
      const presenceChannel = pusherActions.subscribe(
        ROOM_TYPE.VIDEO_CHAT,
        `${window.CONFERENCE_ID}_${videoData.videoChatId}`,
        'presence'
      );
      pusherActions.subscribe(
        ROOM_TYPE.VIDEO_CHAT,
        `${window.CONFERENCE_ID}_${videoData.videoChatId}_${authState.user.id}`
      ); // Only to display for others
      const membersUpdate = () => {
        setVideoData((currentVideoData) => ({
          ...currentVideoData,
          members: Object.values(presenceChannel.members.members),
        }));
      };
      presenceChannel.bind('pusher:subscription_succeeded', membersUpdate);
      presenceChannel.bind('pusher:member_added', membersUpdate);
      presenceChannel.bind('pusher:member_removed', membersUpdate);
      presenceChannel.bind('pusher:subscription_error', (error) => {
        console.log('presence ERROR', error);
      });
    }
    return () => {
      // When hung up
      if (videoData.videoChatId && props.videoChatEnabled) {
        pusherActions.unSubscribe(
          ROOM_TYPE.VIDEO_CHAT,
          `${window.CONFERENCE_ID}_${videoData.videoChatId}`,
          'presence'
        );
        pusherActions.unSubscribe(
          ROOM_TYPE.VIDEO_CHAT,
          `${window.CONFERENCE_ID}_${videoData.videoChatId}_${authState.user.id}`
        );
      }
    };
  }, [videoData.videoChatId]);

  useEffect(() => {
    if (channels[ROOM_TYPE.USER] && props.videoChatEnabled) {
      // When user is connected to private pusher feed
      channels[ROOM_TYPE.USER].bind(
        'video-chat-invitation',
        incomingVideoChatInvitation
      );
      channels[ROOM_TYPE.USER].bind(
        'video-chat-answer',
        incomingVideoChatAnswer
      );
      channels[ROOM_TYPE.USER].bind(
        'video-chat-cancel',
        incomingVideoChatCancel
      );
    }
  }, [channels[ROOM_TYPE.USER]]);

  return (
    <VideoChatContext.Provider
      value={{
        handleSendInvitation,
        videoChatId: videoData.videoChatId,
        joinPublicVideoChat,
        createEmptyRoom,
        createEmptyPrivateRoom,
        handleVideoChatClose,
        videoData,
      }}
    >
      {/* {videoData.videoChatId && (
        <VideoChatSimple
          videoChatId={videoData.videoChatId}
          members={videoData.members}
          cancelHandler={handleVideoChatClose}
        />
      )} */}
      {callData && (
        <VideoChatCall
          data={callData}
          answerHandler={handleAnswerForIncomingCall}
          cancelHandler={handleIncomingCallCancel}
        />
      )}
      {props.children}
    </VideoChatContext.Provider>
  );
};

export default VideoChatContextProvider;
