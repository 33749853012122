import { FormHelperText, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { TopicData } from '.';
import { Select } from '../select-control';

interface IProps {
  onChange: (topicId: string) => void;
  conferenceId: string;
  value?: string;
}

const useStyles = makeStyles((theme) => ({}));

export const PollSelector: FC<IProps> = ({ onChange, conferenceId, value }) => {
  const classes = useStyles();

  const [polls, setPolls] = useState<TopicData[] | null>(null);
  const [pollId, setPollId] = useState(value);

  useEffect(() => {
    axios
      .get(`/api/conferences/${conferenceId}/polls`)
      .then((response: any) => {
        setPolls(response.data);
      });
  }, []);

  useEffect(() => {
    onChange(pollId);
  }, [pollId]);

  return (
    <div>
      {polls !== null && (
        <Select
          fullWidth
          value={pollId}
          onChange={(val) => setPollId(val.toString())}
          name="slug"
          options={[
            {
              label: 'Select Poll',
              value: '',
            },
            ...polls.map((t: any) => ({
              label: t.title,
              value: t.id,
            })),
          ]}
        />
      )}

      {!pollId && (
        <FormHelperText error>
          ATT: Connecting a poll is mandatory
        </FormHelperText>
      )}
    </div>
  );
};
PollSelector.displayName = 'PollSelector';
