import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Page Banner',
  icon: 'image',
  type: 'header',
  tag: 'basic',
  content: {
    backgroundImage: null,
    displayTitle: true,
    displaySubtitle: true,
    logo: undefined,
    maxHeight: '50vh',
    subTitle: '',
    title: '',
  },
  supports: {
    contexts: ['page'],
    appender: false, //true,
  },
};

export { template, Render, Edit };
