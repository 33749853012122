import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  formLabel: {
    marginRight: 0,
  },
  formCheck: {
    color: 'inherit',
  },
}));

const PollInputVote = React.memo(
  (props) => {
    const classes = useStyles();
    const [answers, setAnswers] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState(null);

    const getSelectedCheckboxes = () => {
      return answers
        .filter((answer) => answer.checked)
        .map((answer) => answer.order);
    };
    const selectionsLeft =
      props.poll.settings_data.max_answers - getSelectedCheckboxes().length;

    const handleCheckboxChange = (index) => (event) => {
      if (
        props.poll.settings_data.max_answers - getSelectedCheckboxes().length >
          0 ||
        !event.target.checked
      ) {
        const answersCopy = [...answers];
        answersCopy[index].checked = event.target.checked;
        setAnswers(answersCopy);
      }
    };
    const handleRadioChange = (e) => {
      setSelectedRadio(parseInt(e.target.value));
    };
    useEffect(() => {
      const selectabeAnswers = [...props.poll.settings_data.answers];
      selectabeAnswers.sort((a, b) => (a.order > b.order ? 1 : -1));
      setAnswers(selectabeAnswers);
    }, []);

    useEffect(() => {
      if (props.poll.settings_data.multiple_choice) {
        props.dataCallback({
          answers: getSelectedCheckboxes(),
        });
      } else {
        props.dataCallback({
          answers: [selectedRadio],
        });
      }
    }, [getSelectedCheckboxes().length, selectedRadio]);

    return (
      <Box>
        <Box>{props.poll.settings_data.description}</Box>

        {props.poll.settings_data.multiple_choice ? (
          <Box>
            {props.poll.settings_data.max_answers < answers.length && (
              <Box pt={1}>
                {selectionsLeft > 0
                  ? `Select up to ${selectionsLeft} more answers`
                  : `All selected`}
              </Box>
            )}
            {answers.map((answer, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Box>
                  <Checkbox
                    className={classes.formCheck}
                    checked={answer.checked || false}
                    onChange={handleCheckboxChange(answer.order)}
                  />
                </Box>
                <Box flexGrow={1}>{answer.text}</Box>
              </Box>
            ))}
          </Box>
        ) : (
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedRadio}
            onChange={handleRadioChange}
          >
            {answers.map((answer, index) => (
              <Box key={index} display="flex" alignItems="center">
                <Box>
                  <FormControlLabel
                    className={classes.formLabel}
                    value={answer.order}
                    control={<Radio className={classes.formCheck} />}
                  />
                </Box>
                <Box flexGrow={1}>{answer.text}</Box>
              </Box>
            ))}
          </RadioGroup>
        )}
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.pollId === nextProps.pollId;
  }
);

PollInputVote.displayName = 'PollInputVote';

export default PollInputVote;
