import EventDescription from '@/js/shared/components/info/EventDescription';
import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';
import { RoomContext } from '@/js/shared/context/RoomContext';
import { VideoChatContext } from '@/js/shared/context/VideoChatContext';
import { useNodeProps } from '@/node-editor/hooks';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  root: ({ override }) => ({
    textAlign: 'left',
    ...override.root,
  }),
}));

export const Render = ({
  node: {
    id,
    content: { defaultText },
    override,
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({ override });

  const { roomState } = useContext(RoomContext); // Hämta Context

  return (
    <WidgetWrapper>
      <div className={classes.root}>
        {roomState.currentEvent ? (
          <EventDescription event={roomState.currentEvent} current={true} />
        ) : (
          defaultText
        )}
      </div>
    </WidgetWrapper>
  );
};