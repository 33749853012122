import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
// import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { MarkdownRender } from './MarkdownRender';
import { useMarkdownImageUpload } from './useMarkdownImageUpload';
import { ManagerMarkdownRender } from './ManagerMarkdownRender';
import { MediaPicker } from '@/components/media-picker';

// const PLUGINS = undefined;
const PLUGINS = [
  'header',
  'font-bold',
  'font-italic',
  // 'font-underline',
  'font-strikethrough',
  'link',
  'image',
  'block-quote',
  'list-ordered',
  'block-wrap',
  // 'block-code-inline',
  'block-code-block',
  'divider',
  'list-unordered',
  'table',
  'tasklist',
  //   'clear',
  // 'logger',
  'mode-toggle',
  // 'full-screen',
  // 'tab-insert'
];

export interface IProps {
  value: string;
  setValue: (value: string) => void;
  conferenceId: string;
  showPreview?: boolean;
  height?: string;
  canView?: any;
  plugins?: typeof PLUGINS;
}

MdEditor.use(remarkGfm);

const usePickerPromise = (conferenceId: string) => {
  const [isOpen, setIsOpen] = useState(false);
  const [promiseMethods, setPromiseMethods] = useState(null);

  const startPromise = (
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => {
    setIsOpen(true);
    setPromiseMethods({
      resolve,
      reject,
    });
  };
  const onSelectImage = (source_id: string) => {
    if (source_id) {
      promiseMethods.resolve(source_id);
    } else {
      promiseMethods.reject(source_id);
    }
    setIsOpen(false);
    setPromiseMethods(null);
  };

  const PickerPromise = () => (
    <MediaPicker
      open={isOpen}
      onClose={onSelectImage}
      conferenceId={conferenceId}
    />
  );
  return { PickerPromise, startPromise };
};

const useStyles = makeStyles((theme: Theme) => ({
  classOverride: {},
}));

export const ManagerMarkdownEditor = ({
  value = '',
  setValue,
  conferenceId,
  showPreview = true,
  height = '500px',
  canView = {
    menu: true,
    md: true,
    html: true,
    fullScreen: false,
    hideMenu: false,
  },
  plugins = PLUGINS,
}: IProps) => {
  const classes = useStyles();

  const { PickerPromise, startPromise } = usePickerPromise(conferenceId);

  const onCustomImageUpload = (event: any) => {
    return new Promise((resolve, reject) => {
      startPromise(
        (source_id: string) => {
          resolve({ url: source_id });
        },
        () => {
          reject('no image selected');
        }
      );
    });
  };

  // Finish!
  const handleEditorChange = ({
    html,
    text,
  }: {
    html: string;
    text: string;
  }) => {
    setValue(text);
  };
  return (
    <>
      <MdEditor
        value={value}
        style={{ height: height }}
        //   renderHTML={(value) => renderHTML(value)}
        onChange={handleEditorChange}
        plugins={plugins}
        view={{ menu: true, md: true, html: showPreview }}
        className={classes.classOverride} // Funkar inte
        linkUrl="http://"
        canView={canView}
        onCustomImageUpload={onCustomImageUpload}
        renderHTML={(text) => (
          <ManagerMarkdownRender text={text} conferenceId={conferenceId} />
        )}
      />
      <PickerPromise />
    </>
  );
};

ManagerMarkdownEditor.displayName = 'ManagerMarkdownEditor';
