import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';

import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { FormControlLabel, Switch } from '@material-ui/core';
import { SwitchControl } from '@/components/switch-control';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { order, reverse, show },
  },
  conferenceId,
}: IProps) => {
  const { control, setValue } = useFormContext();
  return (
    <Fragment>
      Lists files from the Media Library.
      <br />
      <br />
      <SettingsPanel label="Settings" initialOpen>
        <RadioGroupControl
          row
          label="Order"
          name="order"
          defaultValue={order}
          options={[
            {
              label: 'By upload date',
              value: 'date',
            },
            {
              label: 'By file name',
              value: 'filename',
            },
          ]}
        />
        <br />
        <SwitchControl
          name="reverse"
          label="Reverse order"
          defaultValue={reverse}
          options={[true, false]}
        />
        <br />
        <br />
        <InputControl
          name="show"
          type="number"
          label="Max nr of files to show"
          defaultValue={show}
          variant="outlined"
        />{' '}
        0 = Show all files
        <br />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
