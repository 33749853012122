import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Post Image',
  icon: 'photo',
  type: 'forum-reply-image',
  tag: 'forum-inputs',
  content: {
    uploadText: 'Upload image',
    webcamText: 'Use webcam',
    message: '',
    received: 'Question received!',
    discussionId: '',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
