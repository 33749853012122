import { ICurrentAction } from '@/node-editor/store';

export const current = (state: string, action: ICurrentAction) => {

    const { type, payload } = action;
    
    switch( type ) {
        case 'CURRENT_NODE': {
            return payload;
        }
        default:
            return state;
    }
}
