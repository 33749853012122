import { reorder } from '../../../shared/utils/arrays';
import { TNodes } from '@/types/node';
import { INodeAction } from '@/node-editor/store';
import { getChildren } from '@/node-editor/store/selectors';

export const nodes = (state: TNodes, action: INodeAction): TNodes => {
  const { type, payload } = action;

  switch (type) {
    case 'NODES_INIT': {
      return { ...payload };
    }
    case 'NODES_ADD': {
      return { ...state, [payload.id]: payload };
    }
    case 'NODELISTS_ADD': {
      return { ...state, ...payload }; // Payload being object of multiple { [payload.id]: payload }
    }
    case 'NODES_UPDATE': {
      const { [payload.id]: node } = state;

      return { ...state, [node.id]: payload };
    }
    case 'NODES_REMOVE': {
      const { [payload.id]: node, ...rest } = state;

      // Reorder siblings
      getChildren(node.nodeId, rest).forEach((node, index) => {
        Object.assign(rest[node.id], { order: index });
      });

      // Remove children
      const changed = Object.values(rest).reduce((acc, curr) => {
        return curr.nodeId !== node.id ? { ...acc, [curr.id]: curr } : acc;
      }, {});

      return { ...changed };
    }
    case 'NODES_REORDER': {
      const reordered = reorder(
        getChildren(payload.id, state),
        payload.from,
        payload.to
      ).reduce(
        (acc, curr, index) => ({
          ...acc,
          [curr.id]: { ...curr, order: index },
        }),
        {}
      );

      return { ...state, ...reordered };
    }
    case 'NODES_RESET': {
      return { ...payload };
    }
    default:
      return state;
  }
};
