import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Widget Area',
  type: 'widget-area',
  icon: 'widgets',
  tag: 'widgets',
  content: {
    areaLabel: '',
    areaName: '',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    children: [
      'program-widget',
      'embed-widget',
      'forum-post-widget',
      'forum-reply-text-widget',
      'forum-reply-image-widget',
      'widget-button',
      'widget-markdown',
      'widget-actionbutton',
      'widget-sessioninfo',
      'widget-discussion',
      'programtimeline-widget',
    ],
    appender: true,
  },
};

export { template, Render, Edit };
