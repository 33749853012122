import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { FormControlLabel, Link, Switch } from '@material-ui/core';
import axios from 'axios';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { mediaId },
  },
  conferenceId,
}: IProps) => {
  const [mediaList, setMediaList] = useState([]);
  const { watch, setValue } = useFormContext();

  const watchMediaId = watch('mediaId', mediaId);

  const selectedMedia = mediaList.find((m) => m.value === watchMediaId);
  console.log(mediaList, selectedMedia, mediaId, watchMediaId);

  const setPublic = (isPublic: boolean) => {
    console.log('setPublic', isPublic, mediaId, watchMediaId);

    axios
      .put(`/api/media/${watchMediaId}/setpublic`, {
        public: isPublic,
      })
      .then(() => {
        const newList = mediaList.map((m) =>
          m.value === watchMediaId ? { ...m, public: isPublic } : m
        );
        setMediaList(newList);
      })
      .catch(() => {});
  };

  useEffect(() => {
    axios
      .get(`/api/conferences/${conferenceId}/mediafiles?type=videomux`)
      .then((response) => {
        setMediaList(
          response.data.files
            .filter(
              (f: any) => f.status === 2 // Encoded & ready
            )
            .map((f: any) => ({
              value: f.id,
              label: `${f.original_filename}`,
              public: f.public,
            }))
        );
      })
      .catch(() => {});
  }, []);
  return (
    <Fragment>
      <SettingsPanel label="Video source" initialOpen>
        <SelectControl
          label="Select video file"
          name="mediaId"
          defaultValue={mediaId}
          options={mediaList}
        />
        {selectedMedia && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={!selectedMedia.public}
                  onChange={(e) => setPublic(!e.target.checked)}
                  color="secondary"
                />
              }
              label="Protected"
            />
            <em>
              {selectedMedia.public
                ? 'Now playable for all'
                : 'Now playable only for logged in users'}
            </em>
          </div>
        )}
        <br />
        You can upload videos{' '}
        <Link href={`/manager/conference/${conferenceId}/mediafiles/videos`}>
          here
        </Link>
        .
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
