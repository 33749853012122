import axios from 'axios';

const authActions = (dispatch) => {
  return {
    getUser: (conferenceName) => {
      if (window.USER_DATA) {
        dispatch({
          type: 'STATE_FINISHED',
          userLoggedIn: true,
          user: window.USER_DATA,
        });
        window.USER_DATA = null;
      } else {
        dispatch({ type: 'STATE_LOADING' });
        axios
          .get(conferenceName ? `/api/me/${conferenceName}` : '/api/me', {})
          .then((response) => {
            if (response.data && response.data.user) {
              dispatch({
                type: 'STATE_FINISHED',
                userLoggedIn: true,
                user: response.data.user,
              });
            } else {
              dispatch({ type: 'STATE_ERROR', message: 'Wrong json format' });
            }
          })
          .catch((error) => {
            console.log(error.message);
            dispatch({ type: 'STATE_ERROR', message: error.message });
          });
      }
    },

    // refreshState: () => {
    //   axios.get('/api/me').then((response) => {
    //     dispatch({ type: 'LOGIN_SUCCESS', user: response.data.user });
    //   });
    // },
    // login: (data) => {
    //   dispatch({ type: 'LOGIN_START' });
    //   axios
    //     .post(
    //       data.type === 'manager' ? `/api/login/manager` : `/api/login/visitor`,
    //       data
    //     )
    //     .then((response) => {
    //       if (window.location.pathname === '/') {
    //         window.location.href = '/live';
    //       } else {
    //         dispatch({ type: 'LOGIN_SUCCESS', user: response.data.user });
    //       }
    //     })
    //     .catch((error) => {
    //       console.log('login error', error.response);
    //       if (!error.response.data) {
    //         dispatch({ type: 'LOGIN_ERROR', message: 'Login response error' });
    //       } else if (error.response.data.message) {
    //         if (error.response.data.message.message) {
    //           dispatch({ type: 'LOGIN_ERROR', message: 'Login error' });
    //         } else {
    //           dispatch({
    //             type: 'LOGIN_ERROR',
    //             message: error.response.data.message,
    //           });
    //         }
    //       }
    //     });
    // },
    logout: () => {
      dispatch({ type: 'LOGOUT_START' });
      axios
        .get('/api/logout')
        .then(() => {
          dispatch({ type: 'STATE_FINISHED', userLoggedIn: false, user: null });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // forgotPassword: (data) => {
    //   const userType = data.type === 'visitor' ? 'p' : 'm';
    //   dispatch({ type: 'FORGOT_START' });
    //   axios
    //     .post(`/api/forgotpassword/${userType}`, data)
    //     .then(() => {
    //       dispatch({ type: 'FORGOT_SUCCESS' });
    //     })
    //     .catch((error) => {
    //       console.log('login error', error.response);
    //       dispatch({
    //         type: 'FORGOT_ERROR',
    //         message: error.response.data.message,
    //       });
    //     });
    // },
    // loadByRecoveryCode: (type, recoveryCode) => {
    //   dispatch({ type: 'STATE_LOADING' });
    //   axios
    //     .get(`/api/recoverycode/${type}/${recoveryCode}`)
    //     .then((response) => {
    //       dispatch({ type: 'STATE_FINISHED', user: response.data });
    //     })
    //     .catch((error) => {
    //       dispatch({
    //         type: 'STATE_ERROR',
    //         message: error.response.data.message,
    //       });
    //     });
    // },
    updateUser: (data, successCallback) => {
      dispatch({ type: 'UPDATE_START' });
      axios
        .put(`/api/me`, data)
        .then((response) => {
          dispatch({ type: 'UPDATE_SUCCESS', user: response.data.user });
          successCallback && successCallback();
        })
        .catch((error) => {
          dispatch({ type: 'UPDATE_ERROR', message: error.response.data });
        });
    },
    // updatePassword: (type, data) => {
    //   dispatch({ type: 'FORGOT_START' });
    //   axios
    //     .post(`/api/recoverycode/${type}`, data)
    //     .then(() => {
    //       dispatch({ type: 'FORGOT_SUCCESS' });
    //     })
    //     .catch((error) => {
    //       dispatch({
    //         type: 'FORGOT_ERROR',
    //         message: error.response.data.message,
    //       });
    //     });
    // },
  };
};
export default authActions;
