import { infoList } from '@/js/shared/utils/DataFormat';
import axios from 'axios';
import { useEffect, useState } from 'react';

export interface IFilteredParticipants {
  filtered: any[];
  shown: any[];
}

export const useParticipantList = ({
  conferenceId,
  programRoomId,
}: {
  conferenceId: string;
  programRoomId: string | null;
}) => {
  const BATCH_SIZE = 50;
  const [filtered, setFiltered] = useState<IFilteredParticipants>({
    filtered: [],
    shown: [],
  });
  const [online, setOnline] = useState<boolean>(false);
  const [originalList, setOriginalList] = useState<any[]>([]);
  const [fullList, setParticipants] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [keyword, setKeyword] = useState<string>('');
  //   const [batches, setBatches] = useState<number>(1);

  const loadParticipants = () => {
    setLoading(true);
    axios
      .get(
        `/api/visitor/conferences/${conferenceId}/visitors`,
        programRoomId
          ? {
              params: { programroomid: programRoomId },
            }
          : null
      )
      .then((response) => {
        setLoading(false);
        let partisipantssearchdata = addSearchData(response.data);
        setOriginalList(partisipantssearchdata);
      })
      .catch((error) => {
        console.log('Error loading participants', error);
      });
  };
  useEffect(() => {
    if (online) {
      setParticipants(originalList.filter((v) => v.online));
    } else {
      setParticipants(originalList);
    }
    filterByTag(null);
  }, [originalList.length, online]);

  useEffect(() => {
    if (fullList.length) {
      setFiltered({
        filtered: [...fullList],
        shown: fullList.slice(0, BATCH_SIZE),
      });
    }
  }, [fullList.length]);

  //   useEffect(() => {
  //     setFiltered(fullList.slice(0, batches * BATCH_SIZE));
  //   }, [fullList, batches]);

  const addSearchData = (data: any[]) => {
    data.map((user: any) => {
      // //add so we can search "online" i text field to find active users.
      // let active = user.online ? 'online' : 'offline';
      const searchData = infoList([
        user.full_name,
        user.title,
        user.location_city,
        user.company,
        user.location_country,
        // active,
        ...user.tags.map((tag: any) => tag.title),
      ]);
      user.searchData = searchData.toLowerCase();
    });
    return data;
  };
  const filterByTag = (selectedTag?: string) => {
    setKeyword('');
    if (selectedTag === null) {
      setFiltered({
        filtered: [...fullList],
        shown: [...fullList].splice(0, BATCH_SIZE),
      });
    } else {
      const taggedList = fullList.filter((user) =>
        user.tags.map((tag: any) => tag.id).includes(selectedTag)
      );
      setFiltered({
        filtered: [...taggedList],
        shown: [...taggedList].splice(0, BATCH_SIZE),
      });
    }
  };
  const handleSearch = (key: string) => {
    setKeyword(key);
    let matches = [];
    let foundPeople = [...filtered.filtered];
    if (key.length >= 3) {
      var re = new RegExp(key.toLowerCase(), 'gi');
      for (var i = 0; i < fullList.length; i++) {
        if (fullList[i].searchData.match(re) != null) {
          matches.push(fullList[i]);
        }
      }
      //   if (matches.length > 0) {
      foundPeople = matches;
      //   }
    } else if (key.length == 2 || key.length == 0) {
      foundPeople = [...fullList];
    }
    setFiltered({
      filtered: [...foundPeople],
      shown: foundPeople.splice(0, BATCH_SIZE),
    });
  };

  const showMore = () => {
    setFiltered((oldVal: IFilteredParticipants) => {
      const newBatches = oldVal.shown.length + BATCH_SIZE;
      return {
        filtered: [...oldVal.filtered],
        shown: [...oldVal.filtered].splice(0, newBatches),
      };
    });
  };

  return {
    participants: filtered.shown,
    totalCount: filtered.filtered.length,
    fullList,
    loading,
    keyword,
    setKeyword,
    loadParticipants,
    showMore,
    handleSearch,
    filterByTag,
    online,
    setOnline,
  };
};
