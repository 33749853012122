import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Box } from '@material-ui/core';
import React, { FC, Fragment, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { design, state, maxWidth, emptyText },
  },
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <RadioGroupControl
          row
          label="Dates to list"
          name="state"
          defaultValue={state}
          options={[
            {
              label: 'Planned',
              value: 'planned',
            },
            {
              label: 'Archived',
              value: 'archived',
            },
            {
              label: 'Active',
              value: 'active',
            },
          ]}
        />
        <RadioGroupControl
          row
          label="Design"
          name="design"
          defaultValue={design}
          options={[
            {
              label: 'Date & Title',
              value: 'date',
            },
            {
              label: 'Image, Date & Title',
              value: 'dateimage',
            },
            {
              label: 'Only image',
              value: 'image',
            },
          ]}
        />
        <RadioGroupControl
          row
          label="Grid size"
          name="maxWidth"
          defaultValue={maxWidth}
          options={[
            {
              label: 'Full width',
              value: '1',
            },
            {
              label: '2 / row',
              value: '2',
            },
          ]}
        />
        <Box pt={2}>
          <InputControl
            name="emptyText"
            defaultValue={emptyText}
            fullWidth
            variant="outlined"
            label="Text when list is empty"
          />
        </Box>
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
