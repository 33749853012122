import React, { Fragment, ReactElement } from 'react';

interface IProps {
  children: ReactElement;
}

class Container {
  settings: Map<string, Set<ReactElement>>;

  private static Instance: Container;

  private constructor() {
    this.settings = new Map();
  }

  public use(id: string): Set<ReactElement> {
    return Container.Instance.settings.get(id);
  }

  public register(element: ReactElement): void {
    if (!('node' in element.props)) {
      return;
    }

    const elId = element.props.node.id;

    // Removed this test as it cached and "forgot" the latest node form changes
    // if (!Container.Instance.settings.has(elId)) {

    Container.Instance.settings.set(elId, new Set([element]));

    //     return;
    // }

    // if (Container.Instance.settings.get(elId).has(element)) {

    //     Container.Instance.settings.get(elId).add(element);

    //     return;
    // }
  }

  public static getContainer() {
    if (!Container.Instance) {
      Container.Instance = new Container();
    }

    return Container.Instance;
  }
}

export const SettingsContainer = ({ children }: IProps): null => {
  const Settings = Container.getContainer();

  Settings.register(children);

  return null;
};

SettingsContainer.Fill = ({ id }: { id: string }): ReactElement => {
  const Settings = Container.getContainer();

  return (
    <Fragment>
      {[...Settings.use(id)].map((element, index) =>
        React.cloneElement(element, { key: id + '-' + index })
      )}
    </Fragment>
  );
};
