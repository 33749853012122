import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Section',
  type: 'container',
  icon: 'crop_free', //'view_day',
  tag: 'layout',
  content: {
    backgroundColor: undefined,
    textColor: undefined,
    containerWidth: 'xl',
    minHeight: 0,
    alignment: {
      vertical: 'center',
      horizontal: 'center',
    },
    verticalSpacing: {
      top: '0',
      bottom: '0',
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    children: [],
    appender: true,
  },
  depricated: [
    {
      migrate({ flexDirection, ...content }) {
        return {
          ...content,
        };
      },
    },
  ],
};

export { template, Render, Edit };
