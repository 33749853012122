// import googleAnalytics from '@analytics/google-analytics';
import plausiblePlugin from 'analytics-plugin-plausible';
import Analytics from 'analytics';

export const Coeolytics = () => {
  const baseTrackerPlausible = plausiblePlugin({
    domain: 'coeo.events', // By default window.location.hostname
    hashMode: false, // By default 'false'
    trackLocalhost: false, // By default 'false'
  });

  const conf_full_sub_domain =
    window.CONFERENCE_ID + '.stats.' + process.env.COEO_DOMAIN;

  //If we put both base and conf domain we get one request
  const conferenceTrackerPlausible = {
    // initialize the 2nd instance with 'instanceName' field set
    ...plausiblePlugin({
      domain: conf_full_sub_domain + ', coeo.events', // By default window.location.hostname
      hashMode: false, // By default 'false'
      trackLocalhost: false, // By default 'false'
    }),
    // change 'name' plugin to avoid namespace collisions
    ...{
      name: 'plausiblePlugin-conference',
    },
  };

  const useplugins = [conferenceTrackerPlausible];

  //If customer has own google analytics code, enable tracker.
  // if (window.SETTINGS.GA_ANALYTICS_ID !== false) {
  //   const customerTrackerGoogleAnalytics = googleAnalytics({
  //     trackingId: window.SETTINGS.GA_ANALYTICS_ID,
  //     anonymizeIp: true,
  //     enabled: false,
  //   });
  //   //Need to check if user has accepted terms or opt out?
  //   analytics.plugins.enable('google-analytics');
  //   useplugins.push(customerTrackerGoogleAnalytics);
  // }

  const analytics = Analytics({
    app: 'coeo-app',
    version: 100,
    plugins: useplugins,
  });

  return analytics;
};
