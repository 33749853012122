import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface IProps
  extends Omit<SwitchProps, 'onChange' | 'value' | 'checked' | 'defaultValue'> {
  defaultValue: string | number | boolean | readonly string[];
  label: string;
  fullWidth?: boolean;
  options: [string, string] | [number | number] | [boolean, boolean];
  onChange?: (value: string | number | boolean) => void;
}

/**
 * SwitchControl is an abstraction of of MUI FormControlLabel and Switch
 * implemented with react-hook-form Controller
 *
 * @link https://react-hook-form.com/api/usecontroller/controller
 * @link https://v4.mui.com/api/switch
 */
export const SwitchControl = ({
  name,
  defaultValue,
  options,
  label,
  onChange: setState,
  ...props
}: IProps) => {
  const { control } = useFormContext();
  
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <FormControlLabel
          label={label}
          control={
            <Switch
              {...props}
              checked={options[0] === value}
              onChange={(_e, checked) => {
                onChange(checked ? options[0] : options[1]);
                if (typeof setState === 'function') {
                  setState(checked ? options[0] : options[1]);
                }
              }}
            />
          }
        />
      )}
    />
  );
};
