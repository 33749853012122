import { useMessage } from '@/hooks/useMessage';
import { IProvided } from '@/node-editor/context';
import { INodeAction } from '@/node-editor/store';
import { debounce } from '@material-ui/core';
import React, { Fragment, useCallback, useRef } from 'react';

export const ContentWindow: React.FC<IProvided & { slug: string }> = ({
  slug,
  context,
  dispatch,
  children,
}) => {
  const pageSlug = useRef(slug);

  const debounceCurrent = useCallback(debounce(dispatch, 200), []);

  useMessage<INodeAction>('preview_window', (_send, data, origin) => {
    if (context !== 'Render') {
      return;
    }

    if (pageSlug.current !== data.payload.slug) {
      return;
    }

    if (!origin.includes('coeo.events')) {
      return;
    }

    const { slug, ...action } = data.payload;

    switch (action.type) {
      case 'NODES_RESET': {
        return dispatch({ ...action });
      }
      case 'CURRENT_NODE': {
        return debounceCurrent(action);
      }
    }
  });

  return <Fragment>{children}</Fragment>;
};
