import { makeStyles, Paper } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { withDroppable } from '@/components/droppable/withDroppable';

interface IProps {
  classNames?: any;
  children: ReactNode | ReactNode[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: 0,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

const Container = ({ children, ...props }: IProps) => {
  const classes = useStyles();

  return (
    <Paper classes={{root: classes.container}} {...props}>
      {children}
    </Paper>
  );
};

export const NodeContainer = withDroppable(Container)
