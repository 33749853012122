const conferenceReducer = (state, action) => {
  switch (action.type) {
    case 'CONFERENCE_LIST_LOAD':
      return { ...state, conferenceListProgress: true };
    case 'CONFERENCE_LIST_SUCCESS':
      return {
        ...state,
        conferenceListProgress: false,
        conferenceListLoaded: true,
        conferenceList: action.conferenceList,
      };
    case 'CONFERENCE_LIST_ERROR':
      return {
        ...state,
        conferenceListProgress: false,
        conferenceListLoaded: true,
        conferenceListError: action.error,
      };
    case 'CONFERENCE_DETAIL_LOAD':
      return {
        ...state,
        conferenceDetailProgress: true,
        conferenceDetailError: null,
      };
    case 'CONFERENCE_DETAIL_SUCCESS':
      return {
        ...state,
        conferenceDetailProgress: false,
        conferenceDetailLoaded: true,
        conferenceDetail: action.conferenceDetail,
        conferenceDetailError: null,
      };
    case 'PROGRAM_LIVE_LIST_UPDATE': {
      if (!state.conferenceDetail) {
        // Conference data not loaded yet
        return state;
      }
      const updatedDays = [...state.conferenceDetail.programDays];
      // TODO: See if ProgramRooms have been added or removed
      try {
        updatedDays.forEach((day) => {
          day.programRooms.forEach((room, roomIndex) => {
            // Merge old with updated details
            day.programRooms[roomIndex] = {
              ...room,
              ...action.conference.programDays
                .find((d) => d.id === day.id)
                .programRooms.find((r) => r.id === room.id),
            };
          });
        });
      } catch (e) {
        console.log('Live room update error', e);
      }
      return {
        ...state,
        conferenceDetail: {
          ...state.conferenceDetail,
          programDay: updatedDays,
        },
      };
    }
    case 'PROGRAM_LIVE_EVENT_CHANGE': {
      if (!state.conferenceDetail) {
        // Conference data not loaded yet
        return state;
      }
      return {
        ...state,
        conferenceDetail: {
          ...action.conference,
        },
      };
    }

    case 'CONFERENCE_DETAIL_ERROR':
      return {
        ...state,
        conferenceDetailProgress: false,
        conferenceDetailLoaded: true,
        conferenceDetailError: action.error,
      };
    case 'POLL_UPDATE':
      return { ...state, pollProgress: true, pollError: null };
    case 'POLL_UPDATE_SUCCESS':
      if (state.conferenceDetail.polls) {
        const updatedConference = { ...state.conferenceDetail };
        const pollIndex = updatedConference.polls.findIndex(
          (poll) => poll.id === action.poll.id
        );
        updatedConference.polls[pollIndex] = action.poll;
        return {
          ...state,
          progress: false,
          conferenceDetail: updatedConference,
          pollError: null,
        };
      }
      return { ...state, pollProgress: false };
    case 'POLL_UPDATE_ERROR':
      return { ...state, pollProgress: false, pollError: action.error };
    case 'EVENT_FAVORITE_TOGGLE': {
      const updatedState = {
        ...state,
        conferenceDetail: {
          ...state.conferenceDetail,
          programDays: state.conferenceDetail.programDays.map((day) => ({
            ...day,
            programRooms: day.programRooms.map((room) => ({
              ...room,
              events: room.events.map((event) => {
                if (event.id === action.eventId) {
                  console.log('event data', event, action);
                }
                return event.id === action.eventId
                  ? {
                      ...event,
                      favorites: action.data.favorite ? [action.data.data] : [],
                    }
                  : event;
              }),
            })),
          })),
        },
      };
      return updatedState;
    }
    default:
      return state;
  }
};

export default conferenceReducer;
