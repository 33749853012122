import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Icon,
  Link,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
// import { Anchorme } from 'react-anchorme';
import C from '../../../../../shared/constants/general';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';
import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';
import { infoList, sortAsTree } from '../../../shared/utils/DataFormat';
import { PusherContext } from '../../context/PusherContext';
import { MarkdownRender } from '../markdown/MarkdownRender';
import { DiscussionConnection } from './DiscussionConnection';
import { DiscussionMessageEdit } from './DiscussionMessageEdit';
import { DiscussionTagSomeone } from './DiscussionTagSomeone';
import { DiscussionVisitor } from './DiscussionVisitor';

const messageStyles = makeStyles((theme) => ({
  smallReply: {
    marginLeft: -5,
    fontSize: '0.65rem',
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
  },
  timeAgo: {
    paddingLeft: 8,
    fontSize: '0.75rem',
    opacity: 0.5,
    fontStyle: 'italic',
  },
  container: {
    paddingTop: 16,
    position: 'relative',
  },
  user: {
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
  },

  userName: {
    textDecoration: 'underline',
  },
  avatarHolder: {
    padding: '0 8px 2px 0',
  },
  text: {
    // whiteSpace: 'pre-wrap',
    fontSize: '1.1rem',
    backgroundColor: theme.palette.background.shadedown_10,
    borderRadius: 5,
    padding: '1px 8px',
    marginRight: 5,
  },
  userNameStar: {
    fontSize: 12,
    width: 10,
    height: 10,
  },
  pin: {
    position: 'absolute',
    opacity: 0.6,
    top: 14,
    right: 0,
    // backgroundColor: theme.palette.background.default,
    padding: 5,
    '& > *': {
      fontSize: '1rem',
    },
  },
}));

const MessageDetail = React.memo(
  (props) => {
    const classes = messageStyles();
    const { authState } = useContext(AuthContext);
    const { conferenceState, openUserPopup } = useContext(ConferenceContext);
    const LEFT_PADDING = 16;

    const fromNow = moment
      .utc(props.message.updatedAt)
      .utcOffset(-conferenceState.conferenceDetail.utc_offset)
      .fromNow();

    const senderIsContributer =
      props.message.visitor &&
      props.message.visitor.participation_level ===
        C.VISITOR.PARTICIPATION_LEVEL.CONTRIBUTE;

    const isModerator =
      authState.user &&
      authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;

    return (
      <Box
        className={classes.container}
        style={{ paddingLeft: props.message.depth * LEFT_PADDING }}
      >
        <Box>
          {props.message.visitor ? (
            <ButtonBase
              className={classes.user}
              onClick={() => openUserPopup(props.message.visitor)}
            >
              <Box display="flex" alignItems="center">
                {/*<Box className={classes.avatarHolder}>
                    <UserAvatar size="small" user={props.message.visitor} />
            </Box>*/}
                <Box>
                  <span className={classes.userName}>
                    {props.message.visitor.full_name}
                  </span>
                  {senderIsContributer && (
                    <Icon color="primary" className={classes.userNameStar}>
                      star
                    </Icon>
                  )}
                  {', '}
                  {infoList([
                    props.message.visitor.company,
                    props.message.visitor.location_city,
                  ])}
                </Box>
              </Box>
            </ButtonBase>
          ) : props.message.text ? (
            <Box className={classes.user}>Participant no longer exist</Box>
          ) : (
            <Box className={classes.user}>Message removed</Box>
          )}
          <span className={classes.timeAgo}>{fromNow}</span>
          {props.message.edited && (
            <span className={classes.timeAgo}>(edited)</span>
          )}
        </Box>
        <Box className={classes.text}>
          <MarkdownRender text={props.message.text} />
        </Box>
        {props.message.pinned > 0 && (
          <Box className={classes.pin}>
            <Icon>push_pin</Icon>
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end">
          <Box>
            <Button
              onClick={() =>
                props.reply(props.message, {
                  discussionId: props.message.discussionId,
                  parentDiscussionMessageId: props.message.id,
                  text: '',
                  pinned: 0,
                })
              }
              startIcon={<Icon>reply</Icon>}
              size="small"
              className={classes.smallReply}
            >
              Reply
            </Button>
            {(authState.user.id === props.message.visitorId || isModerator) && (
              <Button
                onClick={() => props.reply(null, props.message)}
                startIcon={<Icon>edit</Icon>}
                size="small"
                className={classes.smallReply}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.updatedAt === nextProps.updatedAt
);
MessageDetail.displayName = 'MessageDetail';

const useStyles = makeStyles((theme) => ({
  holder: ({ alwaysSmall }) => ({
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    ...(alwaysSmall
      ? {
          flexDirection: 'column',
        }
      : null),
  }),
  text: {
    // whiteSpace: 'pre-wrap',
    fontSize: '1.1rem',
  },
  discussionHolder: {
    borderLeftColor: theme.palette.background.shadedown_20,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
    width: '100%',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.25rem',
    lineHeight: 1.2,
    paddingBottom: 8,
  },
  tagButton: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  timeAgo: {
    paddingRight: 8,
    fontSize: '0.75rem',
    opacity: 0.5,
    fontStyle: 'italic',
  },
}));

// const sortAsTree = (list, treeList, parentDiscussionMessageId, depth) => {
//   const messagesOnThesDepth = list.filter(
//     (m) => m.parentDiscussionMessageId === parentDiscussionMessageId
//   );
//   messagesOnThesDepth.forEach((m) => {
//     const messageData = {
//       ...m,
//       depth: depth,
//     };
//     treeList.push(messageData);
//     sortAsTree(list, treeList, m.id, depth + 1);
//   });
//   return treeList;
// };

export const Discussion = React.memo(
  ({ discussionId, isEmbedded = false, alwaysSmall = false }) => {
    const editRef = useRef();
    const tagRef = useRef();
    const classes = useStyles({ alwaysSmall });
    const { authState } = useContext(AuthContext); // Hämta Context
    const { conferenceState, openNewDiscussion } = useContext(
      ConferenceContext
    );
    const [discussion, setDiscussion] = useState(null);
    const isModerator =
      authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;
    const { pusher, ROOM_TYPE, actions: pusherActions } = useContext(
      PusherContext
    ); // Hämta Context

    useEffect(() => {
      if (discussionId) {
        axios
          .get(`/api/visitor/discussions/${discussionId}`)
          .then((response) => {
            setAndReSort(response.data);
          });
      }
    }, []);

    const setAndReSort = (discussionData) => {
      setDiscussion({
        ...discussionData,
        messageTree: sortAsTree(
          discussionData.discussion_messages,
          [],
          null,
          0
        ),
      });
    };

    const addMessageAndReSort = (msg) => {
      setDiscussion((currentData) => {
        if (
          msg.visitorId === authState.user.id &&
          msg.parentDiscussionMessageId === null
        ) {
          // If replying to root yourself, you have probably started following the discussion
          currentData.discussion_followers[0] = {
            visitorId: authState.user.id,
            discussionId: currentData.id,
          }; // Just dummy data (never used)
        }
        currentData.discussion_messages.unshift(msg);
        return {
          ...currentData,
          messageTree: sortAsTree(currentData.discussion_messages, [], null, 0),
        };
      });
    };

    const toggleFollow = () => {
      if (discussion.discussion_followers.length === 0) {
        axios
          .post(`/api/visitor/discussions/${discussionId}/follow`)
          .then((response) => {
            const updated = { ...discussion };
            updated.discussion_followers[0] = response.data;
            setDiscussion(updated);
          });
      } else {
        axios
          .delete(`/api/visitor/discussions/${discussionId}/follow`)
          .then(() => {
            const updated = { ...discussion };
            updated.discussion_followers = [];
            setDiscussion(updated);
          });
      }
    };

    const updateMessage = (msg) => {
      setDiscussion((currentData) => {
        const updatedIndex = currentData.discussion_messages.findIndex(
          (dm) => dm.id === msg.id
        );
        if (updatedIndex >= 0) {
          currentData.discussion_messages[updatedIndex] = msg;
          return {
            ...currentData,
            messageTree: sortAsTree(
              currentData.discussion_messages,
              [],
              null,
              0
            ),
          };
        } else {
          return currentData;
        }
      });
    };
    const deleteMessage = (msg) => {
      setDiscussion((currentData) => {
        const deletedIndex = currentData.discussion_messages.findIndex(
          (dm) => dm.id === msg.id
        );
        if (deletedIndex >= 0) {
          currentData.discussion_messages.splice(deletedIndex, 1);
          return {
            ...currentData,
            messageTree: sortAsTree(
              currentData.discussion_messages,
              [],
              null,
              0
            ),
          };
        } else {
          return currentData;
        }
      });
    };

    const tagSomeone = () => {
      tagRef.current.open({
        discussionId,
      });
    };

    const reply = (parentMessage, message) => {
      editRef.current.open({
        discussion,
        parentMessage,
        message,
      });
    };

    const editDiscussion = () => {
      // setEditDiscussions({ title: '', url: '', text: '' });
      openNewDiscussion({
        discussion: discussion,
        callback: (updatedDiscussion) => {
          if (updatedDiscussion) {
            setDiscussion((currentData) => {
              return {
                ...currentData,
                ...updatedDiscussion,
              };
            });
          } else {
            setDiscussion(null); // Deleted
          }
        },
      });
    };

    useEffect(() => {
      if (pusher.current) {
        const entityChannel = pusherActions.subscribe(
          ROOM_TYPE.DISCUSSION,
          discussionId
        );
        entityChannel.bind('new-response', (data) => {
          addMessageAndReSort(data);
        });
        entityChannel.bind('update-response', (data) => {
          updateMessage(data);
        });
        entityChannel.bind('delete-response', (data) => {
          deleteMessage(data);
        });
      }
      return () => {
        if (pusher.current) {
          pusherActions.unSubscribe(ROOM_TYPE.DISCUSSION, discussionId);
        }
      };
    }, [!!pusher.current, discussionId]);

    const fromNow =
      discussion &&
      moment
        .utc(discussion.createdAt)
        .utcOffset(-conferenceState.conferenceDetail.utc_offset)
        .fromNow();

    let connectionData;
    if (discussion && discussion.connection) {
      if (discussion.connection_type === 'entity') {
        connectionData = {
          picture:
            discussion.connection.picture || discussion.connection.bg_picture,
          title: discussion.connection.title,
          url: `/live/item/${discussion.connection.id}`,
        };
      } else if (discussion.connection_type === 'event') {
        connectionData = {
          picture: '', // TODO: Add video img after video is finished
          title: discussion.connection.title,
          url: `/live/session/${discussion.connection.id}`,
        };
      }
    }
    const anonymous =
      (discussion &&
        discussion.discussion_topic &&
        discussion.discussion_topic.ai &&
        window.SETTINGS.AI_ANONYMOUS) ||
      false;

    return discussion ? (
      <Box>
        <Box display="flex" alignItems="flext-start" className={classes.holder}>
          <DiscussionVisitor
            visitor={discussion.visitor}
            alwaysSmall={alwaysSmall}
            anonymous={anonymous}
          />
          <Box flexGrow={1} className={classes.discussionHolder}>
            <Box p={1}>
              {!isEmbedded && connectionData && (
                <Box pb={1}>
                  <DiscussionConnection
                    type={discussion.connection_type}
                    picture={connectionData.picture}
                    title={connectionData.title}
                    url={connectionData.url}
                  />
                </Box>
              )}

              <Typography variant="body2" className={classes.title}>
                {discussion.title}
              </Typography>

              {discussion.url && (
                <Box>
                  <Link href={discussion.url} target="_blank">
                    {discussion.url}
                  </Link>
                </Box>
              )}
              {discussion.text && (
                <Box className={classes.text}>
                  <MarkdownRender text={discussion.text} />
                </Box>
              )}
              <Box className={classes.timeAgo}>
                {fromNow} {discussion.edited && '(edited)'}
              </Box>

              <Box>
                <Button
                  onClick={() =>
                    reply(null, {
                      discussionId: discussion.id,
                      text: '',
                      pinned: 0,
                    })
                  }
                  color="primary"
                  startIcon={<Icon>reply</Icon>}
                  size="small"
                >
                  Comment
                </Button>
                <Button
                  onClick={toggleFollow}
                  color={
                    discussion.discussion_followers.length === 0
                      ? 'primary'
                      : 'secondary'
                  }
                  startIcon={
                    <Icon>
                      {discussion.discussion_followers.length === 0
                        ? 'bookmark_add'
                        : 'bookmark_remove'}
                    </Icon>
                  }
                  size="small"
                >
                  {discussion.discussion_followers.length === 0
                    ? 'Follow'
                    : 'Unfollow'}
                </Button>
                {(discussion.visitorId === authState.user.id ||
                  isModerator) && (
                  <Button
                    onClick={editDiscussion}
                    startIcon={<Icon>edit</Icon>}
                    size="small"
                  >
                    edit
                  </Button>
                )}
                <Button
                  className={classes.tagButton}
                  onClick={tagSomeone}
                  startIcon={<Icon>person_add</Icon>}
                  size="small"
                >
                  Notify someone
                </Button>
              </Box>
            </Box>
            <Box pl={1}>
              {discussion.discussion_messages.length === 0 ? (
                discussion.discussion_topic &&
                discussion.discussion_topic.ai ? (
                  <Box textAlign="center" pt={2}>
                    <CircularProgress size={20} />
                    <br />
                    Waiting for AI{' '}
                  </Box>
                ) : (
                  <Box>Be first to reply</Box>
                )
              ) : (
                <Box>
                  {discussion.messageTree.map((message) => (
                    <MessageDetail
                      message={message}
                      key={message.id}
                      reply={reply}
                      updatedAt={message.updatedAt}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <DiscussionMessageEdit ref={editRef} />
        <DiscussionTagSomeone ref={tagRef} />
      </Box>
    ) : (
      <Box align="center" p={2}>
        ...
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.discussionId === nextProps.discussionId
);

Discussion.displayName = 'Discussion';
