import React, { createContext, useState } from 'react';
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RawDraftContentState,
} from 'draft-js';
import { makeStyles } from '@material-ui/core';
import { blockRenderMap, blockStyleFn, decorators } from './utilities';
import { Toolbar } from './components/toolbar';

interface IProps {
  value: RawDraftContentState;
  onChange: (value: any) => void;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  textarea: {
    borderColor: palette.grey[400],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0px 0px 5px 5px',
    '& .public-DraftEditor-content': {
      padding: spacing(1),
      minHeight: '200px',
    },
    '& .editor-alignment-left': { textAlign: 'left' },
    '& .editor-alignment-center': { textAlign: 'center' },
    '& .editor-alignment-right': { textAlign: 'right' },
  },
}));

export const EditorStateContext = createContext(undefined);

export const RichTextEditor = ({ value, onChange }: IProps) => {
  const classes = useStyles();

  const [editorState, setEditorState] = useState(() =>
    value
      ? EditorState.createWithContent(convertFromRaw(value), decorators)
      : EditorState.createEmpty(decorators)
  );

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onChange(convertToRaw(editorState.getCurrentContent()));
  };

  return (
    <EditorStateContext.Provider value={{ editorState, dispatch: onEditorStateChange }}>
      <Toolbar />
      <div className={classes.textarea}>
        <Editor 
          blockRenderMap={blockRenderMap}
          editorState={editorState} 
          blockStyleFn={blockStyleFn}
          onChange={onEditorStateChange} 
          stripPastedStyles
        />
      </div>
    </EditorStateContext.Provider>
  );
};