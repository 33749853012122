import React, { createContext } from 'react';

interface IProps {
  slug: string;
  contentWindow: HTMLIFrameElement;
}

export const EditorWindowContext = createContext<IProps | undefined>(undefined);

export const EditorWindow: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <EditorWindowContext.Provider value={{ ...props }}>
      {children}
    </EditorWindowContext.Provider>
  );
};
