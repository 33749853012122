import { useIsPreviewWindow } from './use-is-preview-window';
import { useNodeContext } from './use-node-context';

export const useNodeProps = (id: string) => {
  const { current, setCurrentNodeTarget } = useNodeContext();
  const isEditMode = useIsPreviewWindow();

  return {
    style: {
      //   position: 'relative',
      //   zIndex: 1,
      border: current === id && '1px solid #f00',
    },
    ...(isEditMode
      ? {
          onMouseEnter: (e) => {
            setCurrentNodeTarget(e.currentTarget, id);
          },
        }
      : {}),

    id,
  };
};
