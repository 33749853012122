import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';

export const MenuContext = createContext();

const SESSION_STORE_NAME = 'live_menu_open';

export const MenuContextProvider = (props) => {
  // Remember menu open during the session
  const { authState } = useContext(AuthContext);

  const [menuOpen, setMenuOpenValue] = useState(
    window.sessionStorage.getItem(SESSION_STORE_NAME) === 'true'
  );
  const setMenuOpen = (value) => {
    window.sessionStorage.setItem(SESSION_STORE_NAME, value.toString());
    setMenuOpenValue(value);
  };

  useEffect(() => {
    if (
      authState.userLoggedIn &&
      window.sessionStorage.getItem(SESSION_STORE_NAME) === null
    ) {
      // Open menu at first login
      setMenuOpen(window.SETTINGS.MENU_START_OPEN);
    }
  }, [authState.userLoggedIn]);

  return (
    <MenuContext.Provider value={{ menuOpen, setMenuOpen }}>
      {props.children}
    </MenuContext.Provider>
  );
};
