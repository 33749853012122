import { AspectRatio } from '@/components/aspect-ratio';
import { CardContent } from '@/components/card';
import { ConditionalRenderer } from '@/components/conditional-renderer';
import { rgbToCssColor } from '@/js/shared/utils/format';
import { useNodeProps } from '@/node-editor/hooks';
import { userHasTicket } from '@/node-editor/utilities';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { darken, Icon, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { RGBColor } from 'react-color';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import { useLinkAction } from '@/hooks/useLinkAction';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Pick<IContent, 'backgroundImage' | 'backgroundSize' | 'maxWidth'> & {
    backgroundColor: string;
    spacing: number;
    override: Record<string, any>;
  }
>((theme) => ({
  root: ({ override, maxWidth, minWidth, spacing }) => ({
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',

    // marginRight: 0,
    // marginLeft: 0,
    borderRadius: 5,
    filter: `drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.3))`,
    maxWidth: `calc(${100 / parseInt(minWidth)}% - ${
      theme.spacing(spacing) * 2
    }px)`,
    [theme.breakpoints.up('md')]: {
      // flex: '0 1 auto',
      maxWidth: `calc(${100 / parseInt(maxWidth)}% - ${
        theme.spacing(spacing) * 2
      }px)`,
      // margin: theme.spacing(spacing),
    },
    margin: theme.spacing(spacing),
    transition: 'box-shadow .3s linear',
    '&:hover, &:focus-within': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
    },
    ...override.root,
  }),
  aspectContainer: ({
    override,
    backgroundColor,
    backgroundImage,
    backgroundSize,
  }) => ({
    borderRadius: 5,
    backgroundColor: backgroundColor,
    backgroundImage: backgroundImage
      ? `url(${backgroundImage})`
      : `linear-gradient(-45deg, ${backgroundColor}, ${darken(
          backgroundColor,
          0.3
        )})`,
    backgroundSize: backgroundSize || 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    ...override.aspect,
  }),
  content: ({ override }) => ({
    ...override.content,
  }),
}));

/**
 * Card Node description...
 */
export const Render = ({
  node: {
    id,
    content: {
      text = '',
      link,
      icon,
      textColor = '#ffffff',
      backgroundImage,
      backgroundColor = '#000000',
      backgroundSize,
      maxWidth = '4',
      minWidth = '1',
      visibleForTicket = '',
    },
    override,
  },
  conferenceId,
}: IProps) => {
  const userHasOneOfTheTickets = userHasTicket(visibleForTicket);
  const nodeProps = useNodeProps(id);
  const { linkActionCall } = useLinkAction();

  const getColor = (color: string | RGBColor): string =>
    typeof color === 'object' ? rgbToCssColor(color as any) : color;

  const hasLink = (link: ITextLink): boolean =>
    'targetUrl' in link && link.targetUrl !== '';

  const classes = useStyles({
    maxWidth,
    minWidth,
    backgroundImage: getImage(backgroundImage, ImageSize.Medium, conferenceId),
    backgroundColor: getColor(backgroundColor),
    backgroundSize,
    spacing: 0.5,
    override,
  });
  if (!userHasOneOfTheTickets) return null;
  return (
    <div className={classes.root} {...nodeProps}>
      <AspectRatio
        maxHeight="40%"
        styles={{
          aspectContainer: [classes.aspectContainer, classes.aspectContent],
        }}
      >
        <ConditionalRenderer
          condition={hasLink(link)}
          render={(children: ReactNode) => (
            <a
              rel={link.targetRel}
              // target={link.target}
              // href={link.targetUrl}
              {...linkActionCall(
                {
                  target: link.target,
                  url: link.targetUrl,
                },
                'href'
              )}
            >
              {children}
            </a>
          )}
        >
          <CardContent
            gutter={1}
            contentJustification="center"
            color={getColor(textColor)}
            override={override.content}
          >
            {icon && <Icon fontSize="large">{icon}</Icon>}
            <Typography variant="body1" align="center">
              <strong>{text}</strong>
            </Typography>
          </CardContent>
        </ConditionalRenderer>
      </AspectRatio>
    </div>
  );
};

Render.displayName = 'CardRender';
