import axios from 'axios';

const conferenceActions = (dispatch) => {
  return {
    loadConferenceList: () => {
      dispatch({ type: 'CONFERENCE_LIST_LOAD' });
      axios
        .get('/api/visitor/conferences', {})
        .then((response) => {
          if (response.data) {
            dispatch({
              type: 'CONFERENCE_LIST_SUCCESS',
              conferenceList: response.data,
            });
          } else {
            dispatch({
              type: 'CONFERENCE_LIST_ERROR',
              error: 'Wrong list format',
            });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: 'CONFERENCE_LIST_ERROR',
            error: 'Could not load conferences',
          });
        });
    },
    loadPrefetchedDetail: () => {
      if (window.CONFERENCE_DATA) {
        dispatch({
          type: 'CONFERENCE_DETAIL_SUCCESS',
          conferenceDetail: window.CONFERENCE_DATA,
        });
        window.CONFERENCE_DATA = null;
      }
    },
    loadModeratorDetail: (id) => {
      dispatch({ type: 'CONFERENCE_DETAIL_LOAD' });
      axios
        .get(`/api/moderator/conferences/${id}`)
        .then((response) => {
          dispatch({
            type: 'CONFERENCE_DETAIL_SUCCESS',
            conferenceDetail: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'CONFERENCE_DETAIL_ERROR',
            error: 'Could not load conference',
          });
        });
    },
    loadStudioDetail: (name) => {
      dispatch({ type: 'CONFERENCE_DETAIL_LOAD' });
      axios
        .get(`/api/studio/conferences/${name}`)
        .then((response) => {
          dispatch({
            type: 'CONFERENCE_DETAIL_SUCCESS',
            conferenceDetail: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'CONFERENCE_DETAIL_ERROR',
            error: 'Could not load conference',
          });
        });
    },
    loadConferenceDetail: (name, publicData) => {
      if (window.CONFERENCE_DATA) {
        dispatch({
          type: 'CONFERENCE_DETAIL_SUCCESS',
          conferenceDetail: window.CONFERENCE_DATA,
        });
        window.CONFERENCE_DATA = null;
      } else {
        dispatch({ type: 'CONFERENCE_DETAIL_LOAD' });
        const url = publicData
          ? `/api/public/conferences/${name}`
          : `/api/visitor/conferences/${name}`;
        axios
          .get(url, {})
          .then((response) => {
            if (response.data) {
              dispatch({
                type: 'CONFERENCE_DETAIL_SUCCESS',
                conferenceDetail: response.data,
              });
            } else {
              dispatch({
                type: 'CONFERENCE_DETAIL_ERROR',
                error: 'Wrong format',
              });
            }
          })
          .catch(() => {
            dispatch({
              type: 'CONFERENCE_DETAIL_ERROR',
              error: 'Could not load conference',
            });
          });
      }
    },
    reloadConferenceDetail: (name) => {
      axios.get(`/api/visitor/conferences/${name}`, {}).then((response) => {
        if (response.data) {
          dispatch({
            type: 'CONFERENCE_DETAIL_SUCCESS',
            conferenceDetail: response.data,
          });
        }
      });
    },
    inviteToVideoChat: (
      id,
      visitorId,
      videoChatId,
      participants,
      callerVisitorId
    ) => {
      axios
        .post(`/api/visitor/conferences/${id}/videoinvite/${visitorId}`, {
          videoChatId: videoChatId,
          participants: participants,
          callerVisitorId: callerVisitorId,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    answerVideoChat: (id, visitorId, videoChatId, accepted) => {
      axios
        .post(`/api/visitor/conferences/${id}/videoanswer/${visitorId}`, {
          videoChatId: videoChatId,
          accepted: accepted,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelVideoChat: (id, visitorId, videoChatId) => {
      axios
        .post(`/api/visitor/conferences/${id}/videocancel/${visitorId}`, {
          videoChatId: videoChatId,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleFavorite: (eventId, favorite) => {
      return axios
        .put(`/api/visitor/events/${eventId}/favorite`, {
          favorite: favorite,
        })
        .then((response) => {
          console.log('action fav', response.data);
          dispatch({
            type: 'EVENT_FAVORITE_TOGGLE',
            eventId: eventId,
            data: response.data,
          });
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };
};
export default conferenceActions;
