exports.reorder = (array, from, to) => {
    const result = [...array];
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);
  
    return result;
  };
  
  exports.reorderInPlace = (array, from, to) => {
    const [removed] = array.splice(from, 1);
    array.splice(to, 0, removed);
  };