import {
  Badge,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { ConferenceContext } from '../../context/ConferenceContext';
import UserAvatar from '../ui/UserAvatar';

const useStyles = makeStyles((theme) => ({
  followMenu: {
    width: 300,
    maxHeight: 400,
    border: '1px solid #FFFFFF99',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.background.shadedown_20,
    boxShadow: '0 0 15px rgb(0, 0, 0, 0.15)',
    padding: 5,
    borderRadius: 3,
  },
}));

const FollowMenu = (_props, ref) => {
  const classes = useStyles();

  const { authState, dispatch } = useContext(AuthContext);
  const { openUserPopup } = useContext(ConferenceContext);
  const [menuState, setMenu] = useState({
    open: false,
    menuTarget: null,
    list: [],
    new: 0,
    loading: false,
  });

  const handleClose = () => {
    setMenu({
      open: false,
      menuTarget: null,
      list: [],
      new: 0,
      loading: false,
    });
  };

  const loadFollowers = () => {
    axios
      .get(`/api/visitor/followers`)
      .then((response) => {
        setMenu((prevSate) => ({
          ...prevSate,
          list: response.data.followed,
          loading: false,
        }));
        dispatch({ type: 'UPDATE_FOLLOW', followData: { follows_new: 0 } });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useImperativeHandle(ref, () => ({
    open: (menuTarget) => {
      setMenu({
        ...menuState,
        open: true,
        menuTarget: menuTarget,
        list: [],
        new: authState.user.follows_new,
        loading: true,
      });
      // Load list
      loadFollowers();
    },
    close: handleClose,
  }));

  return (
    <Menu
      id="menu-followbar"
      anchorEl={menuState.menuTarget}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={menuState.open}
      onClose={handleClose}
      PaperProps={{ className: classes.followMenu }}
    >
      {menuState.loading ? (
        <MenuItem>Loading...</MenuItem>
      ) : menuState.list.length === 0 ? (
        <MenuItem>No E-Cards exchanged</MenuItem>
      ) : (
        [
          <MenuItem key={0}>E-Cards:</MenuItem>,
          ...menuState.list.map((user, index) => (
            <MenuItem key={user.id} onClick={() => openUserPopup(user)}>
              <ListItemIcon>
                {index < menuState.new ? (
                  <Badge variant="dot" color="primary">
                    <UserAvatar size="medium" user={user} />
                  </Badge>
                ) : (
                  <UserAvatar size="medium" user={user} />
                )}
              </ListItemIcon>
              <ListItemText primary={user.full_name} />
            </MenuItem>
          )),
        ]
      )}
    </Menu>
  );
};

export default forwardRef(FollowMenu);
