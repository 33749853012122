import { IDay } from '@/types/day';
import { IEvent } from '@/types/event';
import { IProgramRoom } from '@/types/room';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useContext, useRef } from 'react';

import C from '../../../../../shared/constants/general';
import { ConferenceContext } from '../../context/ConferenceContext';
import { lightOrDark } from '../../utils/detect';
import { addZeros } from '../../utils/StringFormat';

export interface IAddToCalendar {
  headline: string;
  programRoom: IProgramRoom;
  programDay: IDay;
  override: any;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      backgroundColor: `${theme.palette.primary.main}!important`,
      '&[style]': {
        color: `${theme.palette.primary.contrastText}!important`,
      },
      '& .addeventatc_icon': {
        // Make sure the calendar icon is same color as text (black or white)
        filter:
          lightOrDark(theme.palette.primary.contrastText) === 'light'
            ? 'invert(1)'
            : 'none',
      },

      color: `${theme.palette.primary.contrastText}!important`,
      textTransform: 'uppercase',
      ...override,
    }),
  })
);

export const AddToCalendar = ({
  headline,
  programRoom,
  programDay,
  override = {},
}: IAddToCalendar) => {
  const classes = useStyles({ override });
  const { conferenceState } = useContext(ConferenceContext);
  const showButton =
    programRoom.events.length > 0 &&
    programRoom.video_state === C.PROGRAM_ROOM.VIDEO_STATE.NONE;

  if (!showButton) {
    return null;
  }

  // If there's no specified end time, use start_time and add one hour
  const endEvent: IEvent = programRoom.events[programRoom.events.length - 1];
  const alternativeEnd = new Date(`${programDay.date}T${endEvent.start_time}`);
  alternativeEnd.setTime(alternativeEnd.getTime() + 1 * 60 * 60 * 1000);
  const alternativeEndString = `${addZeros(
    alternativeEnd.getHours()
  )}:${addZeros(alternativeEnd.getMinutes())}:00`;
  const endTime = endEvent.end_time || alternativeEndString;

  return (
    <div title="Add to Calendar" className={clsx(classes.root, 'addeventatc')}>
      {headline}
      <span className="start">
        {programDay.date}T{programRoom.events[0].start_time}
      </span>
      <span className="end">
        {programDay.date}T{endTime}
      </span>
      <span className="timezone">
        {conferenceState.conferenceDetail.timezone}
      </span>
      <span className="title">
        {programRoom.headline || programRoom.title} -{' '}
        {conferenceState.conferenceDetail.title}
      </span>
      <span className="description">{programRoom.description_short}</span>
      <span className="location">{`https://${
        (window as any).location.hostname
      }`}</span>
      <span className="icon"></span>
    </div>
  );
};
AddToCalendar.displayName = 'AddToCalendar';
