import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Icon,
  Popper,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
} from 'react';
import C from '../../../../../shared/constants/general';
import { AuthContext } from '../../../shared/context/AuthContext';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import UserAvatar from '../ui/UserAvatar';
import { infoList } from '../../utils/DataFormat';
import { MarkdownEditor } from '../markdown/MarkdownEditor';

const useStyles = makeStyles((theme) => ({
  paperBg: {
    backgroundColor: theme.palette.background.shadedown_10,
  },
  visitorList: {
    backgroundColor: theme.palette.background.default,
  },
  parentText: {
    maxHeight: 60,
    overflow: 'hidden',
  },
  pinField: {
    width: 80,
  },
}));

export const DiscussionEdit = forwardRef((props, ref) => {
  const classes = useStyles();
  const [visitors, setVisitors] = useState([]);
  const [taggedVisitor, setTaggedVisitor] = useState(null);
  const [taggedVisitorAsSender, setTaggedVisitorAsSender] = useState(false);
  const [open, setOpen] = useState(false);
  const [postError, setPostError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [topicTitle, setTopicTitle] = useState(null);
  const [useUrl, setUseUrl] = useState(false);
  // const [useText, setUseText] = useState(true);
  const [useVisitorTag, setUseVisitorTag] = useState(false);
  const { authState } = useContext(AuthContext);
  const isModerator =
    authState.user &&
    authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR;

  useImperativeHandle(ref, () => ({
    open: ({ discussion, callback, topicTitle = null }) => {
      setData({ discussion, callback });
      setUseUrl(!!discussion.url);
      // setUseText(!!discussion.text);
      setTopicTitle(topicTitle);
      setOpen(true);
    },
  }));

  const cancelHandler = () => {
    setPostError(null);
    setData(null);
    setOpen(false);
    setVisitors([]);
    setUseVisitorTag(false);
    setTaggedVisitorAsSender(false);
    setTopicTitle(null);
    setLoading(false);
  };
  const deleteHandler = () => {
    setLoading(true);
    axios
      .delete(
        `/api/visitor/discussions/${data.discussion.id}${
          isModerator ? '/moderator' : ''
        }`,
        data.discussion
      )
      .then(() => {
        cancelHandler();
        data.callback && data.callback(null);
      })
      .catch((error) => {
        setLoading(false);
        setPostError(error.response.data.message);
      });
  };
  const save = () => {
    const discussionData = {
      ...data.discussion,
      ...(taggedVisitor
        ? {
            taggedVisitorId: taggedVisitor.id,
            taggedVisitorAsSender: taggedVisitorAsSender,
          }
        : null),
    };

    if (!discussionData.title) {
      return setPostError('Title is required');
    }
    setLoading(true);
    if (data.discussion.id) {
      axios
        .put(
          `/api/visitor/discussions/${discussionData.id}${
            isModerator ? '/moderator' : ''
          }`,
          discussionData
        )
        .then((response) => {
          cancelHandler();
          data.callback && data.callback(response.data);
        })
        .catch((error) => {
          setLoading(false);
          setPostError(error.response.data.message);
        });
    } else {
      axios
        .post(
          `/api/visitor/conferences/${window.CONFERENCE_ID}/discussions`,
          discussionData
        )
        .then((response) => {
          data.callback && data.callback(response.data);
          cancelHandler();
        })
        .catch((error) => {
          setLoading(false);
          setPostError(error.response.data.message);
        });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((currentData) => {
      currentData.discussion[name] = value;
      return { ...currentData };
    });
  };
  const toggleUseUrl = (use) => {
    if (use) {
      setUseUrl(true);
    } else {
      setUseUrl(false);
      setData((currentData) => {
        currentData.discussion.url = '';
        return { ...currentData };
      });
    }
  };
  // const toggleUseText = (use) => {
  //   if (use) {
  //     setUseText(true);
  //   } else {
  //     setUseText(false);
  //     setData((currentData) => {
  //       currentData.discussion.text = '';
  //       return { ...currentData };
  //     });
  //   }
  // };

  const loadVisitorsToTag = () => {
    axios
      .get(`/api/visitor/conferences/${window.CONFERENCE_ID}/visitors/minimal`)
      .then((response) => {
        setVisitors(
          response.data.map((v) => ({
            ...v,
            optionLabel: infoList([v.full_name, v.company, v.location_city]),
          }))
        );
      })
      .catch((error) => {
        setPostError(error.response.data.message);
      });

    // searchString: `${v.full_name}|${v.location_city}|${v.company}|${v.email}`,
  };
  const toggleUseVisitorTag = (use) => {
    if (use) {
      setUseVisitorTag(true);
      loadVisitorsToTag();
    } else {
      setUseVisitorTag(false);
      setTaggedVisitor(null);
    }
  };
  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }

        cancelHandler(event);
      }}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="Answer"
      PaperProps={{ className: classes.paperBg }}
    >
      {data && (
        <>
          <DialogTitle>
            {data.discussion.id ? 'Edit ' : 'New '} post{' '}
            {topicTitle && `(# ${topicTitle.toUpperCase()})`}
          </DialogTitle>
          <DialogContent>
            <Box pb={1}>
              <TextField
                autoFocus
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                name="title"
                label="Title (mandatory)"
                placeholder="A clear and informative title"
                variant="outlined"
                onChange={handleInputChange}
                value={data.discussion.title || ''}
              />
            </Box>

            <Box>
              <MarkdownEditor
                value={data.discussion.text}
                setValue={(txt) =>
                  handleInputChange({
                    target: { name: 'text', value: txt },
                  })
                }
                height={'200px'}
              />
            </Box>
            {useUrl && (
              <Box pb={1}>
                <TextField
                  className={classes.field}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="text"
                  name="url"
                  label="URL"
                  placeholder="https://..."
                  variant="outlined"
                  onChange={handleInputChange}
                  value={data.discussion.url}
                />
              </Box>
            )}
            {useVisitorTag && visitors.length && (
              <Box pb={1} display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Autocomplete
                    options={visitors}
                    getOptionLabel={(option) => option.optionLabel || ''}
                    renderOption={(option) => (
                      <>
                        <Chip
                          key={option.id}
                          avatar={<UserAvatar user={option} />}
                          label={infoList([
                            option.full_name,
                            option.company,
                            option.location_city,
                          ])}
                        />
                      </>
                    )}
                    // renderTags={(value) => {
                    //   console.log('render', value);
                    //   return (
                    //     <Chip
                    //       key={value.id}
                    //       avatar={<UserAvatar user={value} />}
                    //       label={infoList([
                    //         value.full_name,
                    //         value.company,
                    //         value.location_city,
                    //       ])}
                    //     />
                    //   );
                    // }}
                    PopperComponent={(props) => {
                      return <Popper {...props} placement="top-start" />;
                    }}
                    classes={{
                      popper: classes.visitorList,
                    }}
                    getOptionSelected={(opt, val) => opt.id === val.id}
                    filterOptions={createFilterOptions({
                      limit: 8,
                    })}
                    fullWidth
                    onChange={(e, v) => setTaggedVisitor(v)}
                    renderInput={(params) => (
                      <TextField
                        autoFocus
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        label="Send notification to:"
                        variant="outlined"
                        placeholder="Find by Name, Org, city."
                      />
                    )}
                  />
                </Box>
                {isModerator && (
                  <Box p={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={taggedVisitorAsSender}
                          onChange={() =>
                            setTaggedVisitorAsSender(!taggedVisitorAsSender)
                          }
                        />
                      }
                      label={data.discussion.id ? 'Change sender' : 'As sender'}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box>
              {/* <Button
                startIcon={
                  <Icon>{useText ? 'remove_circle' : 'add_circle'}</Icon>
                }
                size="small"
                onClick={() => toggleUseText(!useText)}
              >
                {useText ? 'remove' : 'add'} description text
              </Button> */}
              <Button
                startIcon={
                  <Icon>{useUrl ? 'remove_circle' : 'add_circle'}</Icon>
                }
                size="small"
                onClick={() => toggleUseUrl(!useUrl)}
              >
                {useUrl ? 'remove' : 'add'} url
              </Button>
              <Button
                startIcon={
                  <Icon>
                    {useVisitorTag ? 'person_add_disabled' : 'person_add'}
                  </Icon>
                }
                size="small"
                onClick={() => toggleUseVisitorTag(!useVisitorTag)}
              >
                {useVisitorTag ? 'remove notification' : 'notify someone'}
              </Button>
            </Box>
          </DialogContent>

          <DialogActions>
            <Box display="flex" className={classes.buttonList} flexGrow={1}>
              <Box flexGrow={1} display="flex" alignItems="center">
                {isModerator && (
                  <Box>
                    <TextField
                      select
                      SelectProps={{
                        native: true,
                      }}
                      size="small"
                      name="state"
                      label="State"
                      variant="outlined"
                      onChange={handleInputChange}
                      value={data.discussion.state}
                    >
                      <option value="show">show</option>
                      <option value="hidden">hidden</option>
                      <option value="suggested">suggested</option>
                    </TextField>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      className={classes.pinField}
                      size="small"
                      name="pinned"
                      label="Pinned?"
                      type="number"
                      variant="outlined"
                      onChange={handleInputChange}
                      value={data.discussion.pinned}
                    />
                  </Box>
                )}
                {data.discussion &&
                  data.discussion.id &&
                  (data.discussion.nr_messages === 0 || isModerator) && (
                    <Button
                      onClick={() =>
                        window.confirm(
                          'Really delete discussion and all replies?'
                        ) && deleteHandler()
                      }
                      startIcon={<Icon>delete</Icon>}
                    >
                      Delete
                    </Button>
                  )}
              </Box>
              {postError && (
                <Box align="right" color="error.main">
                  {postError}
                </Box>
              )}
              <Box flexGrow={1} align="right">
                <Button onClick={cancelHandler}>Cancel</Button>

                <Button
                  disabled={loading || data.discussion.title === ''}
                  color="primary"
                  onClick={save}
                >
                  {data.discussion.id ? 'Save' : 'Post'}
                </Button>
              </Box>
            </Box>
          </DialogActions>
          {isModerator && (
            <DialogContent>
              <ul>
                <li>
                  Use +Notify Someone with "change sender" to post as other
                  person
                </li>
                <li>Set Pinned over 100 to pin on ALL list</li>
              </ul>
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
});

DiscussionEdit.displayName = 'DiscussionEdit';
