import {
  Box,
  Button,
  Checkbox,
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { max } from 'date-fns';
import React, { Fragment, useState } from 'react';
import EntityListItem from './EntityListItem';
import {
  getHashtags,
  getHashtagData,
  removeHashtags,
  infoList,
} from '@/js/shared/utils/DataFormat';
import { EntityCard } from './EntityCard';

const useStyles = makeStyles((theme) => ({
  cardDescription: {
    maxHeight: 70,
    overflowY: 'auto',
  },
  container: {
    justifyContent: 'center',
  },
  tag: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: 4,
  },
  checkbox: {
    padding: 1,
  },
}));

export const EntityCards = React.memo(
  (props) => {
    const classes = useStyles();

    return (
      <Grid container spacing={1} className={classes.container}>
        {props.entities.map((entity) => (
          <EntityCard
            key={entity.id}
            minGridWith={props.minGridWith}
            entity={entity}
            showParticipants={props.showParticipants}
            nrHosts={entity.visitors && entity.visitors.length}
            refreshEntityList={props.refreshEntityList}
          />
        ))}
      </Grid>
    );
  },
  (prevProps, nextProps) =>
    prevProps.totalVisitors === nextProps.totalVisitors &&
    prevProps.nrEntities === nextProps.nrEntities &&
    prevProps.showParticipants === nextProps.showParticipants &&
    prevProps.entities.length > 0 &&
    nextProps.entities.length > 0 &&
    prevProps.entities[0].id === nextProps.entities[0].id
);
EntityCards.displayName = 'EntityCards';
