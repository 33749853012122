import React from 'react';
import { INode, INodeProps } from '@/types/node';
import { IContent } from '../markdown/contract';
import { Render as NodeRender } from '../markdown/render';
import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Render = (props: IProps) => {
  return (
    <WidgetWrapper>
      <NodeRender {...props} />
    </WidgetWrapper>
  );
};
