import { Box, Container, Link, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useContext, useEffect } from 'react';
import LoginBox from '../../../shared/components/loginbox';
import VisitorContainer from '../../../shared/components/VisitorContainer';
import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';
import { UserContext } from '../../../shared/context/UserContext';

const VerifyEmail = (props) => {
  const { userState, actions } = useContext(UserContext); // Hämta Context
  const { authState } = useContext(AuthContext); // Hämta Context
  const { conferenceState, actions: conferenceActions } = useContext(
    ConferenceContext
  ); // Hämta Context

  useEffect(() => {
    if (authState.userLoggedIn) {
      window.location.href = '/';
    } else {
      conferenceActions.loadConferenceDetail(window.CONFERENCE_NAME, true);
      actions.verifyEmailWithString(
        props.match.params.verificationId,
        window.CONFERENCE_ID
      );
    }
  }, [authState.stateLoaded, authState.userLoggedIn]);

  return conferenceState.conferenceDetailLoaded ? (
    <VisitorContainer {...props} conference={conferenceState.conferenceDetail}>
      <Container maxWidth="md">
        {!userState.userLoaded ? (
          <Box align="center">
            <CircularProgress disableShrink />
          </Box>
        ) : userState.registerSuccess ? (
          <Box align="center" pt={2}>
            <Typography variant="h5" gutterBottom>
              Welcome! You are now ready to login
            </Typography>
            <LoginBox email={userState.user.email} />
          </Box>
        ) : (
          <Box align="center">
            <br />
            <br />
            <Typography variant="body2" gutterBottom>
              Could not load verification code.
              <br />
              Already verified?
              <br />
              <br />
            </Typography>
            <Link href="/">Back home</Link>
          </Box>
        )}
      </Container>
    </VisitorContainer>
  ) : (
    <Box align="center">
      <CircularProgress disableShrink />
    </Box>
  );
};

export default VerifyEmail;
