import { useEffect, useState } from 'react';

interface ITag {
  id: string;
}

export const useParticipantTags = ({
  participants,
  conferenceTags,
}: {
  participants: any[];
  conferenceTags: any[];
}) => {
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    if (participants.length > 0) {
      let mappedTags = participants
        .reduce(function (acc, user) {
          return acc.concat(user.tags.map((tag: ITag) => tag.id));
        }, [])
        .reduce(function (acc: any, curr: string) {
          if (!acc.hasOwnProperty(curr)) {
            acc[curr] = 1;
          } else {
            acc[curr] += 1;
          }
          return acc;
        }, {});

      const tagsCounted = conferenceTags.map((tag) => {
        return { ...tag, count: mappedTags[tag.id] || 0 };
      });

      setTagList([
        ...tagsCounted.filter((t) => t.type === 'skill'),
        ...tagsCounted.filter((t) => t.type === 'scouting'),
      ]);
    }
  }, [participants.length]);

  return {
    tagList,
  };
};
