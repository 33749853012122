import {
  Box,
  Button,
  Checkbox,
  Grid,
  Icon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { max } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import EntityListItem from './EntityListItem';
import {
  getHashtags,
  getHashtagData,
  removeHashtags,
  infoList,
} from '@/js/shared/utils/DataFormat';
import axios from 'axios';
// import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardDescription: {
    maxHeight: 70,
    overflowY: 'auto',
  },
  container: {
    justifyContent: 'center',
  },
  tag: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: 4,
  },
  checkbox: {
    padding: 1,
  },
}));

export const EntityCard = React.memo(
  ({
    minGridWith = 3,
    entity,
    showParticipants,
    nrHosts,
    refreshEntityList,
  }) => {
    const classes = useStyles();
    const [edit, setEdit] = useState(false);
    const [checked, setChecked] = useState([]);
    const [visitors, setVisitors] = useState(entity.visitors || []);
    // const history = useHistory();

    const toggleCheckInRoom = (entityVisitorId) => {
      if (checked.includes(entityVisitorId)) {
        setChecked(checked.filter((c) => c !== entityVisitorId));
      } else {
        setChecked([...checked, entityVisitorId]);
      }
    };
    const tags = getHashtags(entity.description_short).map((tag) =>
      getHashtagData(tag)
    );

    useEffect(() => {
      setVisitors(entity.visitors || []);
      setChecked([]);
    }, [nrHosts]);

    const deleteHosts = () => {
      axios
        .put(`/api/visitor/entities/${entity.id}/unconnectvisitors`, {
          ids: checked,
        })
        .then(() => {
          setVisitors(
            visitors.filter((v) => !checked.includes(v.entityVisitors.id))
          );
          refreshEntityList();
          setChecked([]);
          setEdit(false);
        });
    };

    return (
      <Fragment>
        <Grid
          item
          xs={12}
          sm={showParticipants ? 6 : Math.max(6, minGridWith)}
          md={showParticipants ? 6 : Math.max(4, minGridWith)}
          lg={showParticipants ? 6 : Math.max(3, minGridWith)}
        >
          <EntityListItem url={`/live/item/${entity.id}`} entity={entity} />
          <Box p={1} align="left">
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.cardDescription}
            >
              {tags.map((tagData) => (
                <span
                  className={classes.tag}
                  key={tagData.tag}
                  style={{
                    ...(tagData.color ? { color: `#${tagData.color}` } : {}),
                  }}
                >
                  {tagData.title}
                </span>
              ))}
              {tags.length > 0 && <br />}
              {removeHashtags(entity.description_short)}
            </Typography>
          </Box>
        </Grid>
        {showParticipants && (
          <Grid item xs={12} sm={6}>
            <Box textAlign="left">
              {visitors.length > 0 && (
                <Box display="flex">
                  {edit && (
                    <Box flexGrow={1}>
                      <Button
                        startIcon={<Icon>delete</Icon>}
                        size="small"
                        onClick={deleteHosts}
                        disabled={checked.length === 0}
                        color="secondary"
                      >
                        delete ({checked.length})
                      </Button>
                    </Box>
                  )}
                  <Box>
                    <Button
                      startIcon={<Icon>edit</Icon>}
                      size="small"
                      onClick={() => setEdit(!edit)}
                    >
                      {edit ? 'Stop edit' : 'Edit'}
                    </Button>
                  </Box>
                </Box>
              )}

              {visitors.map((v) => (
                <Box key={v.entityVisitors.id} display="flex">
                  <Box>
                    {/* {edit && (
                        <input
                        className={}
                          type="checkbox"
                          //   name={`check_${entity.id}_${v.id}`}
                          checked={checked.includes(v.entityVisitors.id)}
                          onClick={() => toggleCheckInRoom(v.entityVisitors.id)}
                        />
                      )} */}

                    {edit && (
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        checked={checked.includes(v.entityVisitors.id)}
                        onClick={() => toggleCheckInRoom(v.entityVisitors.id)}
                      />
                    )}
                  </Box>
                  <Box>
                    <strong>{v.full_name}</strong>
                    <br />
                    {infoList([v.title, v.company, v.location_city])}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        )}
      </Fragment>
    );
  },
  (prevProps, nextProps) =>
    prevProps.nrHosts === nextProps.nrHosts &&
    prevProps.showParticipants === nextProps.showParticipants
);
EntityCard.displayName = 'EntityCard';
