import { Box, Button, Icon, Typography } from '@material-ui/core';
import { EventList, EventListContent } from '@/components/event-list';
import { makeStyles, Theme } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IProgramRoom } from '@/types/room';
import { IEvent } from '@/types/event';
import { monthNames } from '@/js/shared/utils/DataFormat';
import { withRouter } from 'react-router-dom';
import { useNodeProps } from '@/node-editor/hooks';
import { getImage } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import { INode, INodeProps } from '@/types/node';
import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import C from '../../../shared/constants/general';
import { AddToCalendar } from '@/js/shared/components/addtocalendar/AddToCalendar';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
  match: {
    params: { programRoomId: string };
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ override }) => ({ ...override.root }),
  content: ({ override }) => ({
    backgroundColor: '#333',
    overflow: 'hidden',
    height: 0,
    width: '100%',
    paddingTop: '56.25%',
    position: 'relative',
    ...override.content,
  }),
  description: ({ override }) => ({ ...override.description }),
  image: {
    position: 'relative',
    minHeight: 150,
    backgroundColor: '#333',
    '& img': {
      width: '100%',
    },
  },
  date: {
    fontSize: '1.1rem',
    textTransform: 'uppercase',
  },
  textHolder: {
    position: 'absolute',
    inset: 0,
    display: 'flex',
    alignItems: 'flex-end',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5))',
  },
  text: {
    textAlign: 'left',
    color: '#fff',
    padding: theme.spacing(1),
  },
}));

export const Render = withRouter(
  ({
    node: {
      id,
      override,
      content: { design, addtocalendar },
    },
    match: {
      params: { programRoomId },
    },
    conferenceId,
  }: IProps) => {
    const classes = useStyles({
      override,
    });
    const [programRoom, setProgramRoom] = useState<IProgramRoom>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [, month, date] = programRoom
      ? (programRoom.programDay.date as string)
          .split('-')
          .map<number>((t: string) => parseInt(t))
      : [0, 0, 0];

    const eventStepper =
      programRoom &&
      [programRoom].reduce(
        (acc, { id, currentEventId, video_state }) => ({
          ...acc,
          current: [...acc.current, currentEventId].filter(Boolean),
          finished: [
            ...acc.finished,
            video_state === 'file' ? id : null,
          ].filter(Boolean),
        }),
        { current: [], finished: [] }
      );

    // Simple version (from program Node) when only one room
    const programReducer = (room: IProgramRoom): IEvent[][] =>
      room.events.map((event) => [event]);

    useEffect(() => {
      axios
        .get(`/api/visitor/publicrooms/${programRoomId}`)
        .then((response) => {
          setProgramRoom(response.data);
        })
        .catch((e) => {
          setError(e.response.data.message);
        })
        .then(() => {
          setLoaded(true);
        });
    }, []);

    return (
      <div className={classes.root} {...useNodeProps(id)}>
        {loaded && (
          <>
            {error ? (
              <Typography color="error">{error}</Typography>
            ) : (
              programRoom && (
                <div>
                  <div className={classes.image}>
                    <img
                      src={getImage(
                        programRoom.bg_picture ||
                          `/coeo/room_bg_${programRoom.order % 5}.jpg`,
                        'large',
                        conferenceId
                      )}
                    />
                    {design === 'dateimage' && (
                      <div className={classes.textHolder}>
                        <div className={classes.text}>
                          <Typography variant="h3">
                            {programRoom.headline || programRoom.title}
                          </Typography>

                          <Typography variant="body2">
                            {monthNames[month - 1]} {date}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                  {programRoom.video_state ===
                    C.PROGRAM_ROOM.VIDEO_STATE.RECORDING &&
                    programRoom.video_access ===
                      C.PROGRAM_ROOM.ACCESS.OPEN_FOR_VISITORS && (
                      <Box textAlign="center" p={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          href={`/live/room/${programRoomId}`}
                          startIcon={<Icon>play_arrow</Icon>}
                        >
                          LIVE
                        </Button>
                      </Box>
                    )}
                  {design !== 'dateimage' && (
                    <Box pt={1}>
                      <Typography variant="h3">
                        {programRoom.headline || programRoom.title}
                      </Typography>
                      <Typography variant="body2">
                        {monthNames[month - 1]} {date}
                      </Typography>
                    </Box>
                  )}
                  {addtocalendar &&
                    programRoom.video_state ===
                      C.PROGRAM_ROOM.VIDEO_STATE.NONE && (
                      <Box p={1} textAlign="center">
                        <AddToCalendar
                          headline={addtocalendar}
                          programRoom={programRoom}
                          programDay={programRoom.programDay}
                          override={override.addtocalendar}
                        />
                      </Box>
                    )}
                  {/* {addtocalendar && programRoom.events.length > 0 && programRoom.video_state === C.PROGRAM_ROOM.VIDEO_STATE.NONE &&
                  <div title="Add to Calendar" className={clsx(classes.root, 'addeventatc')}>
                  {addtocalendar}
                  <span className="start">{programRoom.programDay.date}T{programRoom.events[0].start_time}</span>
                  <span className="end">{programRoom.programDay.date}T{programRoom.events[programRoom.events.length].end_time || programRoom.events[programRoom.events.length].start_time}</span>
                  <span className="timezone">
                    {conferenceState.conferenceDetail.timezone}
                  </span>
                  <span className="title">{programRoom.headline || programRoom.title} - {conferenceState.conferenceDetail.title}</span>
                  <span className="description">{programRoom.description_short}</span>
                  <span className="location">{`https://${
                    (window as any).location.hostname
                  }`}</span>
                </div>
                  } */}
                  <div className={classes.description}>
                    <MarkdownRender text={programRoom.description} />
                  </div>
                  <EventList
                    rooms={[programRoom]}
                    list={programReducer(programRoom)}
                    stepper={eventStepper}
                  >
                    {({ event, ...provided }) => (
                      <EventListContent
                        event={event}
                        nrOfRooms={1}
                        {...provided}
                      ></EventListContent>
                    )}
                  </EventList>
                </div>
              )
            )}
          </>
        )}
      </div>
    );
  }
);
