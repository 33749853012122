import { Box, Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
const UpChunk = require('@mux/upchunk');

const useStyles = makeStyles(() => ({
  button: {},
}));

const DropZone = (props) => {
  const classes = useStyles();
  const [fileQueue, setFileQueue] = useState({
    queue: [],
    finished: [],
  });
  /* eslint-disable-next-line */
  const [uploadInfo, setuploadInfo] = useState({
    generated: null,
    original: null,
  });

  const handleUpload = (info) => {
    setuploadInfo(info);
    props.uploadHandler && props.uploadHandler(info);
  };
  const handleError = (error) => {
    console.log('DropZone handleError', error);
    try {
      props.errorHandler && props.errorHandler(error.response.data.message);
      props.uploadHandler && props.uploadHandler(null);
      setFileQueue({
        queue: [],
        finished: [],
      });
    } catch (error) {
      console.log('DropZone handleError', error);
    }
  };
  const handleProgress = (event) => {
    props.progressHandler && props.progressHandler(event);
  };

  const getSignedRequest = (file, callback) => {
    props.uploadStart && props.uploadStart(file);
    if (props.video) {
      axios
        .get(
          `${props.uploadurl}?file-name=${encodeURIComponent(
            file.name
          )}&file-type=${file.type}`
        )
        .then((response) => {
          props.signingFinished && props.signingFinished(response);
          const upload = UpChunk.createUpload({
            file,
            // Normally this would be retrieved via an API request to an endpoint
            // you control that would return an authenticated URL.
            endpoint: response.data.url,
            chunkSize: 5120, // Uploads the file in ~5mb chunks
          });
          upload.on('error', handleError);
          upload.on('progress', handleProgress);
          upload.on('success', handleUpload);
        })
        .catch(handleError);
    } else {
      axios
        .get(
          `${props.uploadurl}?file-name=${encodeURIComponent(
            file.name
          )}&file-type=${file.type}&file-size=${file.size}`
        )
        .then((response) => {
          uploadFile(
            file,
            response.data.signedRequest,
            () => {
              const retVal = {
                generated: response.data.generatedName,
                original: file.name,
              };
              callback ? callback(retVal) : handleUpload(retVal);
            },
            handleError
          );
        })
        .catch(handleError);
    }
  };

  const uploadFile = (file, signedRequest, callback, errorHandler) => {
    axios
      .put(signedRequest, file)
      .then(() => {
        callback();
      })
      .catch(errorHandler);
  };

  const recursiveUpload = () => {
    props.queueStatus && props.queueStatus(fileQueue);
    if (fileQueue.queue.length > 0) {
      getSignedRequest(fileQueue.queue[0], (result) => {
        const newFileQueue = { ...fileQueue };
        newFileQueue.queue.shift();
        newFileQueue.finished.push(result);
        setFileQueue(newFileQueue);
      });
    } else {
      console.log('Finished');
    }
  };

  useEffect(() => {
    if (fileQueue.queue.length > 0) {
      recursiveUpload();
    } else if (fileQueue.finished.length > 0) {
      // Finished
      console.log('Finished', fileQueue);
      handleUpload(fileQueue.finished);
      setFileQueue({
        queue: [],
        finished: [],
      });
    }
  }, [fileQueue.queue.length]);

  const onDrop = useCallback((acceptedFiles) => {
    console.log('onDrop', acceptedFiles);
    if (props.multiple && acceptedFiles.length > 0) {
      setFileQueue({
        queue: acceptedFiles,
        finished: [],
      });
    } else if (acceptedFiles.length === 1) {
      getSignedRequest(acceptedFiles[0]);
    } else {
      console.log('Drop only one image');
    }
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...(props.acceptedfiletypes ? { accept: props.acceptedfiletypes } : {}),
    multiple: props.multiple || false,
  });

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <Button
          size="small"
          startIcon={props.startIcon || <Icon>photo</Icon>}
          disabled={props.disabled || false}
          color={props.color || 'default'}
          className={classes.button}
          variant={props.variant || 'contained'}
        >
          {props.buttonText || 'Choose image'}
        </Button>
      )}
    </Box>
  );
};

export default DropZone;
