import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
    version: 1,
    name: 'Root',
    type: 'root',
    tag: 'layout',
    icon: '',
    content: {
        backgroundColor: undefined,
        textColor: undefined,
        containerWidth: false,
        alignment: {
            vertical: 'center',
            horizontal: 'center',
        },
        verticalSpacing: {
            top: false,
            bottom: false,
        },
        flexDirection: 'column',
    },
    supports: {
        contexts: ['page', 'room'],
        children: [],
        appender: false,
    }
}

export {
    template,
    Render,
    Edit
};