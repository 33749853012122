import { INodeTemplate } from '../../types/node';
import { WidgetSettings } from '@/widget-editor/components';
import { Settings } from './edit';
import { Render as WidgetRender } from './render';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';

const template: INodeTemplate = {
  version: 1,
  icon: 'info',
  name: 'Session info',
  type: 'widget-sessioninfo',
  tag: 'widgets',
  content: {
    widgetName: 'Session info',
    defaultText: 'No current live stream',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(WidgetRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Edit, Render };
