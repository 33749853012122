import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  icon: 'image',
  name: 'Image',
  tag: 'content',
  type: 'image',
  content: {
    src: undefined,
    altText: undefined,
    link: {
      target: '_self',
      targetUrl: '',
      targetRel: undefined,
    },
  },
  override: {
    link: {},
    image: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Edit, Render };
