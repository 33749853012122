import { NotFound } from '@/pages/404';
import React from 'react';
import { Route } from 'react-router-dom';

// TODO perhaps fetch and cache bundles to be more dynamic..
const bundles = [
    'broadcast',
    'live',
    'manager',
    'moderator',
    'site',
    'studio'
] as const;

interface IProps {
    bundle: typeof bundles[number];
}

export const BundleRoute = ({bundle: currentBundle}: IProps) => {

  return (
    <Route
      path="*"
      render={(props) => {

        const pathParts = props.location.pathname.split('/').filter(Boolean);
        
        const nextBundle = pathParts[0] as IProps['bundle'];

        // If nextBundle is not one of bundles, assume we're still in site bundle.
        if (('site' === currentBundle && !bundles.includes(nextBundle)) || nextBundle === currentBundle) {

            return <NotFound bundle={currentBundle} {...props} />;
        }
        
        window.location.href = props.location.pathname;
        return null;
      }}
    />
  );
};
