import { Button, IconButton, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useSnackbar } from 'notistack';
import { isLocalUrl } from '../../utils/DataFormat';

const useStyles = makeStyles((theme) => ({
  close: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
}));

export const Announcement = forwardRef((props, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const displayAnnouncement = (announcement) => {
    let randkey = Math.random();

    enqueueSnackbar(announcement.message, {
      key: randkey,
      variant: 'default',
      autoHideDuration: 30000,
      resumeHideDuration: 20000,
      disableWindowBlurListener: false,
      action: (
        <React.Fragment>
          {announcement.actionLink && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              startIcon={
                isLocalUrl(announcement.actionLink) ? null : <Icon>launch</Icon>
              }
              target={isLocalUrl(announcement.actionLink) ? '_self' : '_blank'}
              href={announcement.actionLink}
            >
              {announcement.actionText}
            </Button>
          )}

          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={() => closeSnackbar(randkey)}
          >
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </React.Fragment>
      ),
    });
  };

  useImperativeHandle(ref, () => ({
    displayAnnouncement: (message) => {
      displayAnnouncement(message);
    },
  }));
});

Announcement.displayName = 'Announcement';

// export Announcement;
