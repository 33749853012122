import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
// import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import { MarkdownRender } from './MarkdownRender';
import { useMarkdownImageUpload } from './useMarkdownImageUpload';

export interface IProps {
  isModerator: boolean;
  value: string;
  setValue: (value: string) => void;
  height?: string;
}

const renderHTML = (text: string) => {
  return <MarkdownRender text={text} />;
};

// const PLUGINS = undefined;
const PLUGINS = [
  // 'header',
  'font-bold',
  'font-italic',
  // 'font-underline',
  // 'font-strikethrough',
  'link',
  'image',
  'block-quote',
  // 'list-ordered',
  'block-wrap',
  // 'block-code-inline',
  //   'block-code-block',
  'divider',
  'list-unordered',
  'table',
  'tasklist',
  //   'clear',
  // 'logger',
  // 'mode-toggle',
  // 'full-screen',
  // 'tab-insert'
];

MdEditor.use(remarkGfm);

const useStyles = makeStyles((theme: Theme) => ({
  classOverride: {
    '& .rc-md-navigation': {
      backgroundColor: `${theme.palette.background.shadedown_20}!important`,
    },
    '& .navigation-nav, & .button': {
      color: `${theme.palette.text.primary}!important`,
    },
    '& .navigation-nav': {
      color: `${theme.palette.text.primary}!important`,
    },
    '& .button': {
      color: `${theme.palette.text.primary}!important`,
      // '&:hover': {
      //   opacity: 0.6,
      // },
    },
    '& .section-container.input': {
      backgroundColor: `${theme.palette.background.default}!important`,
      color: `${theme.palette.text.primary}!important`,
    },
  },
}));

export const MarkdownEditor = ({
  isModerator = false,
  value = '',
  setValue,
  height = '500px',
}: IProps) => {
  const [preview, showPreview] = useState(false);
  const editorRef = useRef();
  const classes = useStyles();

  //   const [value, setValue] = React.useState('**Hello world!!!**');
  const { getSignedRequest } = useMarkdownImageUpload({});

  const onImageUpload = (file: File) => {
    return new Promise((resolve, reject) => {
      getSignedRequest(
        file,
        (fileData) => {
          console.log('fileData', fileData);
          resolve(fileData.generated);
        },
        (err) => {
          console.log('Reject!', err);
          reject(new Error(err));
        }
      );
      //   const reader = new FileReader();
      //   reader.onload = (data) => {
      //     resolve(data.target.result);
      //   };
      //   reader.readAsDataURL(file);
    });
  };

  // Finish!
  const handleEditorChange = ({
    html,
    text,
  }: {
    html: string;
    text: string;
  }) => {
    setValue(text);
  };

  useEffect(() => {
    console.log('ref', editorRef);
  }, []);

  return (
    <Box>
      <Button
        onClick={() => showPreview(!preview)}
        startIcon={<Icon>{preview ? 'edit' : 'visibility'}</Icon>}
      >
        {preview ? 'edit' : 'preview'}
      </Button>
      {preview ? (
        <MarkdownRender text={value} />
      ) : (
        <MdEditor
          ref={editorRef}
          value={value}
          style={{ height: height }}
          //   renderHTML={(value) => renderHTML(value)}
          onChange={handleEditorChange}
          plugins={PLUGINS}
          view={{ menu: true, md: true, html: false }}
          className={classes.classOverride} // Funkar inte
          canView={{
            menu: true,
            md: false,
            html: false,
            fullScreen: false,
            hideMenu: false,
          }}
          linkUrl="http://"
          imageAccept=".jpg,.jpeg,.png"
          onImageUpload={onImageUpload}
          renderHTML={(text) => <MarkdownRender text={text} />}
        />
      )}
    </Box>
  );
};

MarkdownEditor.displayName = 'MarkdownEditor';
