import React, { Component } from 'react';
import { BoundaryState, FallbackProps } from './contract';

interface IProps {
  fallbackMessage?: string;
  fallbackRender?: (fallbackProps: FallbackProps) => JSX.Element | JSX.Element[];
  FallbackComponent?: React.FunctionComponent<FallbackProps>;
  children: React.ReactNode;
}

class ErrorBoundary extends Component<IProps, BoundaryState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: unknown): BoundaryState {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error: unknown): void {
    console.error(error);
  }

  boundaryReset(): void {
    this.setState({
      error: null,
      hasError: false,
    });
  }

  render() {
    const {
      children,
      fallbackMessage,
      fallbackRender,
      FallbackComponent,
    } = this.props;

    const fallbackProps = {
      error: this.state.error,
      boundaryReset: this.boundaryReset.bind(this),
    };

    if (this.state.hasError) {
      if (typeof fallbackRender === 'function') {
        return fallbackRender({ ...fallbackProps });
      }

      if (FallbackComponent) {
        return <FallbackComponent {...fallbackProps} />;
      }

      return <p>{fallbackMessage}</p>;
    }

    return children;
  }
}

export default ErrorBoundary;
