const rndChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
const shuffledChars = 'x9sgnr65yhwpao2k8btcmjuf74ide1zv3l0q'; // Based on rndChars

const generateRandomString = (len) => {
  return Array(len)
    .join()
    .split(',')
    .map(() => {
      return rndChars.charAt(Math.floor(Math.random() * rndChars.length));
    })
    .join('');
};

const cipherEncode = (str) =>
  (str || '')
    .toLowerCase()
    .split('')
    .map((c) => {
      const cIndex = rndChars.indexOf(c);
      return cIndex === -1 ? c : shuffledChars[cIndex];
    })
    .join('');

const cipherDecode = (str) =>
  (str || '')
    .toLowerCase()
    .split('')
    .map((c) => {
      const cIndex = shuffledChars.indexOf(c);
      return cIndex === -1 ? c : rndChars[cIndex];
    })
    .join('');

const lineBreakToBR = (txt) => {
  return txt.replace(/(?:\r\n|\r|\n)/g, '<br/>\r\n');
};

const addZeros = (num) => num.toString().padStart(2, '0');

const strToURI = (txt) => {
  return txt
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')
    .replace(/å/g, 'a')
    .replace(/ä/g, 'a')
    .replace(/ö/g, 'a');
};

// 02:23:34 => 0.23423435554
const dayTimeToFraction = (dayTimeString) => {
  const split = dayTimeString.split(':');
  return (
    (parseInt(split[0]) * 3600 + parseInt(split[1]) * 60 + parseInt(split[2])) /
    (24 * 3600)
  );
};

// 02:24:44
const getDurationShort = (milliseconds) => {
  if (milliseconds / 3600000 >= 1) {
    return (
      Math.floor(milliseconds / 3600000) +
      ':' +
      String(Math.floor(milliseconds / 60000) % 60).padStart(2, '0') +
      ':' +
      String(Math.floor(milliseconds / 1000) % 60).padStart(2, '0')
    );
  } else {
    return (
      Math.floor(milliseconds / 60000) +
      ':' +
      String(Math.floor(milliseconds / 1000) % 60).padStart(2, '0')
    );
  }
};

// 2h 24min
const getDurationHM = (milliseconds) => {
  if (milliseconds / 60000 < 1) {
    return Math.floor(milliseconds / 1000) + ' sec';
  } else if (milliseconds / 3600000 < 1) {
    return Math.floor(milliseconds / 60000) + ' min';
  } else {
    return (
      Math.floor(milliseconds / 3600000) +
      'h ' +
      (Math.floor(milliseconds / 60000) % 60) +
      ' m'
    );
  }
};

const CSVToMatrix = (csv, delimiter) => {
  let matrix = [];
  csv.split('\n').map((l) => {
    l.trim() == ''
      ? 0
      : matrix.push(
          l
            .trim()
            .split(delimiter)
            .map((v) => v.trim())
        );
  });
  return matrix;
};

const MatrixToJSON = (matrix, from = 0, to) => {
  let jsonResult = [];
  matrix.map((a, i) => {
    let obj = Object.assign(
      {},
      ...matrix[0].map((h, index) => ({ [h]: matrix[i][index] }))
    );
    jsonResult.push(obj);
  });
  return to ? jsonResult.splice(from, to) : jsonResult.splice(from);
};

const CSVToJSON = (inputCSV, delimiter = ';', from = 1, to) => {
  return MatrixToJSON(CSVToMatrix(inputCSV, delimiter), from, to);
};

const slugify = (string) => {
  const a =
    'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b =
    'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  /* eslint-disable */

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  /* eslint-enable */
};

const shortenStr = (str, length, type = 'end') => {
  if (str.length < length) {
    return str;
  } else if (type === 'center') {
    const start = str.substr(0, Math.floor(length / 2));
    const end = str.substr(-Math.floor(length / 2));
    return `${start}...${end}`;
  } else {
    return `${str.substr(0, length)}...`;
  }
};

// inputCSV EXAMPLE:
// var inputCSV =`
// A,B,C
// 1,2,3
// 4,5,6
// `;

// MATRIX = CSVToMatrix(inputCSV, ',')
// MATRIX EXAMPLE:
// [
//   [ "A", "B", "C" ],
//   [ "1", "2", "3" ],
//   [ "4", "5", "6" ]
// ]

// JSON = MatrixToJSON( MATRIX, 1)
// JSON EXAMPLE:
// [
//   { "A": "1", "B": "2", "C": "3" },
//   { "A": "4", "B": "5", "C": "6" }
// ]

module.exports = {
  slugify,
  cipherEncode,
  cipherDecode,
  strToURI,
  lineBreakToBR,
  generateRandomString,
  dayTimeToFraction,
  getDurationHM,
  getDurationShort,
  CSVToJSON,
  shortenStr,
  addZeros,
};
