import {
  Container as MuiContainer,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { RenderChildren } from '@/node-editor/components';
import { getSpacerValue, rgbToCssColor } from '@/js/shared/utils/format';
import { INode, INodeProps } from '@/types/node';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, IContent & {override: Record<string, any>}>((theme) => ({
  container: ({
    backgroundColor,
    textColor,
    containerWidth,
    minHeight,
    verticalSpacing,
    alignment: { vertical, horizontal },
    override
  }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: vertical,
    alignItems: horizontal,
    minHeight: minHeight === 0 ? 'auto' : `${minHeight}px`,
    maxWidth: !containerWidth
      ? 'none'
      : theme.breakpoints.values[containerWidth],
    ...(backgroundColor && {
      backgroundColor: rgbToCssColor(backgroundColor as any),
    }),
    ...(textColor && { color: rgbToCssColor(textColor as any) }),
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    ...override.root
  }),
}));

export const Render = ({
  node: {
    id,
    override,
    content: {
      backgroundColor,
      textColor,
      alignment,
      containerWidth,
      minHeight = 0,
      verticalSpacing,
    },
  },
  children,
}: IProps) => {
  const classes = useStyles({
    backgroundColor,
    textColor,
    alignment,
    containerWidth,
    minHeight,
    verticalSpacing,
    override
  });

  return (
    <MuiContainer
      disableGutters={!containerWidth}
      className={classes.container}
      {...useNodeProps(id)}
    >
      <RenderChildren>{children}</RenderChildren>
    </MuiContainer>
  );
};
