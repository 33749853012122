import { slugify } from '@/js/shared/utils/StringFormat';
import { Box, Typography } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TopicData } from '.';

export const TopicCreator = ({
  topicTitle,
  conferenceId,
  callback,
  cancel,
}: {
  topicTitle: string;
  conferenceId: string;
  callback: (newSlug: TopicData) => void;
  cancel: () => void;
}) => {
  const [error, setError] = useState<string>('');
  const [data, setData] = useState<TopicData>({
    title: topicTitle,
    slug: slugify(topicTitle),
  });

  const create = async () => {
    try {
      const response = await axios.post(
        `/api/conferences/${conferenceId}/discussiontopics`,
        {
          title: data.title,
          slug: data.slug,
          viewstate: 'all',
          poststate: 'open',
          description: '',
          connectPosts: false,
        }
      );
      setError('');
      callback({
        title: response.data.title,
        slug: response.data.slug,
        new: true,
      });
    } catch (e: any) {
      setError(e.response.data.message);
    }
  };

  useEffect(() => {
    create();
  }, [data]);

  return (
    <Box>
      {error && (
        <Box p={1}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
    </Box>
  );
};
TopicCreator.displayName = 'TopicCreator';
