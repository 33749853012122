import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Forum posts',
  icon: 'forum',
  type: 'forum-post',
  tag: 'forum-inputs',
  content: {
    description: '###### Title',
    received: 'Message received!',
    topicSlug: '',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
