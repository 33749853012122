import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  tagChip: {
    marginRight: 5,
    marginBottom: 5,
    textTransform: 'capitalize',
  },
  tagChip_skill_dark: {},
  tagChip_scouting_dark: {
    color: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
  },
}));

const CoChip = (props) => {
  const classes = useStyles();
  const tagChipColor = !props.selected
    ? classes[`tagChip_${props.tagtype}_dark`]
    : '';
  const color = { skill: 'primary', scouting: 'secondary' };

  return (
    <Chip
      {...props}
      variant={props.selected ? 'default' : 'outlined'}
      color={color[props.tagtype]}
      className={`${classes.tagChip} ${tagChipColor}`}
      avatar={props.count === undefined ? null : <Avatar>{props.count}</Avatar>}
    />
  );
};

export default CoChip;
