import { EntityListSelector } from '@/components/entitylist-selector';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Typography } from '@material-ui/core';

import React, { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { entityListId, minGridWith },
  },
  conferenceId,
}: IProps) => {
  const { control, setValue } = useFormContext();
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <SelectControl
          name="minGridWith"
          defaultValue={minGridWith}
          label="Max rooms / row"
          options={[
            {
              label: '4 / row',
              value: 3,
            },
            {
              label: '3 / row',
              value: 4,
            },
            {
              label: '2 / row',
              value: 6,
            },
            {
              label: '1 / row',
              value: 12,
            },
          ]}
        />
        <Typography variant="caption">
          Set maximum nr of rooms in a row to avoid weird design when used in
          small areas.
        </Typography>
        <br />
        <br />
        Select expo list:
        <EntityListSelector
          onChange={(newVal) => setValue('entityListId', newVal)}
          conferenceId={conferenceId}
          value={entityListId}
        />
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
