import { Select } from '@/components/select-control';
import { RichUtils } from 'draft-js';
import React from 'react';
import { BLOCK_TYPES } from '../utilities';

interface IProps {
    editorState: Draft.EditorState,
    dispatch: (state: Draft.EditorState) => void;
}

export const SizeControls = ({editorState, dispatch}: IProps) => {

    const selection = editorState.getSelection();

    const currentType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getFocusKey())
        .getType();

    const currentHeading = Object.values(BLOCK_TYPES).includes(currentType) ? currentType : 'unstyled'

    return(
        <Select 
            value={currentHeading} 
            options={[
                {
                    label: 'Normal',
                    value: BLOCK_TYPES.unstyled
                },
                {
                    label: 'Heading 1',
                    value: BLOCK_TYPES.header_one
                },
                {
                    label: 'Heading 2',
                    value: BLOCK_TYPES.header_two
                },
                {
                    label: 'Heading 3',
                    value: BLOCK_TYPES.header_three
                }
            ]} 
            onChange={(value) => dispatch(RichUtils.toggleBlockType(editorState, value as string))} 
        />
    )
}