const authReducer = (state, action) => {
  switch (action.type) {
    case 'STATE_LOADING':
      return { ...state, stateProgress: true, stateError: null };
    case 'STATE_FINISHED':
      return {
        ...state,
        stateProgress: false,
        stateError: null,
        stateLoaded: true,
        userLoggedIn: action.userLoggedIn,
        user: action.user,
      };
    case 'STATE_ERROR':
      return {
        ...state,
        stateProgress: false,
        stateLoaded: true,
        userLoggedIn: false,
        user: null,
        stateError: action.message,
      };
    case 'LOGIN_START':
      return { ...state, loginProgress: true, loginError: null };
    case 'LOGOUT_START':
      return { ...state, userLoggedIn: false, user: null, loginError: null };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        stateLoaded: true,
        loginProgress: false,
        userLoggedIn: true,
        user: action.user,
        loginError: null,
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        loginProgress: false,
        userLoggedIn: false,
        user: null,
        loginError: action.message,
      };
    case 'UPDATE_START':
      return { ...state, updateProgress: true, updateError: null };
    case 'UPDATE_ERROR':
      return { ...state, updateProgress: false, updateError: action.message };
    case 'UPDATE_SUCCESS':
      return {
        ...state,
        updateProgress: false,
        user: { ...state.user, ...action.user },
        updateError: null,
      };
    case 'FORGOT_START':
      return {
        ...state,
        loginProgress: true,
        forgotSuccess: false,
        forgotError: null,
      };
    // case 'FORGOT_RESET':
    //   return {
    //     ...state,
    //     loginProgress: false,
    //     forgotSuccess: false,
    //     forgotError: null,
    //   };
    case 'FORGOT_SUCCESS':
      return {
        ...state,
        loginProgress: false,
        forgotSuccess: true,
        forgotError: null,
      };
    case 'FORGOT_ERROR':
      return {
        ...state,
        loginProgress: false,
        forgotSuccess: false,
        forgotError: action.message,
      };
    case 'UPDATE_FOLLOW':
      return { ...state, user: { ...state.user, ...action.followData } };
    case 'SET_VISITOR_POPUP':
      return { ...state, user: { ...state.user, userPopup: action.userPopup } };
    default:
      return state;
  }
};

export default authReducer;
