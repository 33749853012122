import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useContext, useMemo } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {},
  },
}: IProps) => {
  return (
    <Fragment>
      <SettingsPanel label="" initialOpen>
        Shows the user's QR Ticket Image for the event. If using Pretix.
        <br />
        <br />
        No settings available.
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
