import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { TopicSelector } from '@/components/topic-selector';
import { slugify } from '@/js/shared/utils/StringFormat';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface TopicData {
  title: string;
  slug: string;
  new?: boolean;
}

const useStyles = makeStyles(() => ({
  topicCreator: {
    marginTop: 12,
    marginBottom: 8,
    padding: 5,
    borderRadius: 5,
    backgroundColor: '#eee',
  },
}));

const TopicCreator = ({
  topicTitle,
  conferenceId,
  callback,
  cancel,
}: {
  topicTitle: string;
  conferenceId: string;
  callback: (newSlug: TopicData) => void;
  cancel: () => void;
}) => {
  const classes = useStyles();
  const [error, setError] = useState<string>('');
  const [data, setData] = useState<TopicData>({
    title: topicTitle,
    slug: slugify(topicTitle),
  });

  // const handleInputChange = (e: any) => {
  //   const { name, value } = e.target;
  //   setData((currentData: any) => {
  //     currentData[name] = value;

  //     if (name === 'title') {
  //       currentData.slug = slugify(value);
  //     }
  //     return { ...currentData };
  //   });
  // };

  const create = async () => {
    try {
      const response = await axios.post(
        `/api/conferences/${conferenceId}/discussiontopics`,
        {
          title: data.title,
          slug: data.slug,
          viewstate: 'all',
          poststate: 'open',
          description: '',
          connectPosts: false,
        }
      );
      setError('');
      callback({
        title: response.data.title,
        slug: response.data.slug,
        new: true,
      });
    } catch (e: any) {
      setError(e.response.data.message);
    }
  };

  useEffect(() => {
    create();
  }, [data]);

  return (
    <Box className={classes.topicCreator}>
      {/* <Box pt={1}>
        <TextField
          InputLabelProps={{ shrink: true }}
          fullWidth
          type="text"
          name="title"
          label="Title (mandatory)"
          placeholder="A clear and informative title"
          variant="outlined"
          onChange={handleInputChange}
          value={data.title || ''}
        />
      </Box>

      <Box>
        <TextField
          InputLabelProps={{ shrink: true }}
          fullWidth
          type="text"
          name="slug"
          label="Slug (mandatory)"
          placeholder="No spaces or weird characters"
          variant="outlined"
          onChange={handleInputChange}
          value={data.slug || ''}
        />
      </Box> */}
      {error && (
        <Box p={1}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
      {/* <Box pb={1} textAlign="right">
        <Button color="default" onClick={cancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={create}>
          Create
        </Button>
      </Box> */}
    </Box>
  );
};

export const Settings = ({
  node: {
    id,
    type: settingType,
    override,
    content: { forumHeadline, topicSlug = '' },
    roomId,
  },
  conferenceId,
}: IProps) => {
  const [roomInfo, setRoomInfo] = useState(null);

  const { setValue } = useFormContext();
  useEffect(() => {
    axios.get(`/api/rooms/${roomId}`).then((response: any) => {
      setRoomInfo(response.data);
    });
  }, []);

  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <InputControl
          fullWidth
          name="forumHeadline"
          label="Forum headline"
          defaultValue={forumHeadline}
          variant="outlined"
        />
        {roomInfo && (
          <TopicSelector
            onChange={(newVal) => setValue('topicSlug', newVal)}
            conferenceId={conferenceId}
            value={topicSlug}
            newTopicTitle={`${roomInfo.title} - ${roomInfo.programDay.title}`}
          />
        )}
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(settingType, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(settingType, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
