import { Icon, IconButton, TextField } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import JSONInput from 'react-json-editor-ajrm';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import { toJson } from 'really-relaxed-json';

interface IProps {
  id: string;
  name: string;
  defaultValue: Record<string, any>;
  height?: string;
  width?: string;
}

type JSONContentValues = {
  jsObject: Record<string, any>;
};

export const JSONControl = ({
  id,
  name,
  defaultValue,
  height = '300px',
  width = '100%',
}: IProps) => {
  const { control, setValue } = useFormContext();

  const paste = (e: any) => {
    // setValue(name, { foo: 'bar' });
    e.preventDefault();
    let obj = null;

    try {
      obj = JSON.parse(toJson(e.target.value));
    } catch (e) {
      console.log('paste error', e);
    }
    if (obj && typeof obj === 'object') {
      setValue(name, obj);
    }
    e.target.value = '';
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <div>
            <TextField
              size="small"
              placeholder="Paste to replace"
              onChange={paste}
            />
            <br />
            <JSONInput
              id={id}
              placeholder={value}
              waitAfterKeyPress={2000}
              onChange={(content: JSONContentValues) => {
                console.log('c', content.jsObject);
                onChange(content.jsObject);
              }}
              locale={locale}
              theme="light_mitsuketa_tribute"
              height={height}
              width={width}
              style={{
                body: {
                  fontSize: '1rem',
                },
              }}
            />
          </div>
        );
      }}
    />
  );
};
