import { createTheme, darken, lighten } from '@material-ui/core/styles';
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
// import { lightOrDark } from '../shared/utils/detect';

// Defined using
// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFF3E0&secondary.color=FBC02D

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 820,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {},
  typography: {
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
  palette: {
    type: 'dark',
    background: {
      shadeup_10: darken('#000000', 0.6),
      paper: lighten('#000000', 0.06),
      shadedown_10: lighten('#000000', 0.06),
      shadedown_20: lighten('#000000', 0.12),
      default: '#000000',
      // Paper is same as Coeo shade light
    },
    primary: {
      main: '#fbc02d',
    },
    secondary: {
      main: '#8f4a76',
    },
  },
  shape: {
    borderRadius: 2,
  },
  shadows: Array(25).fill('none'),
});
export default theme;
