import { ButtonGroupControl } from '@/components/buttongroup-control';
import { ColorControl } from '@/components/color-control';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { MediaPlaceholder } from '@/components/media-placeholder';
import { RadioGroupControl } from '@/components/radiogroup-control';
import { SettingsPanel } from '@/components/settings-panel';
import { useConferencePalette } from '@/hooks/useConferencePalette';
import { EditChildren, SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  Switch,
  Typography,
} from '@material-ui/core';
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';
import axios from 'axios';
import { SelectControl } from '@/components/select-control';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: {
      alignment,
      backgroundColor,
      backgroundImage,
      maxWidth,
      textColor,
      minHeight = 0,
      videoId = '-',
    },
  },
  conferenceId,
}: IProps) => {
  const { getSwatch } = useConferencePalette('live');

  const [isHeightAuto, setIsHeightAuto] = useState<boolean>(minHeight === 0);

  const { ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;

  const { setValue } = useFormContext();

  const [videos, setVideos] = useState<any[]>([]);

  useEffect(() => {
    // fetch videos
    axios
      .get(`/api/conferences/${conferenceId}/mediafiles?type=videomux`)
      .then((res) => {
        setVideos(res.data.files);
      });
  }, []);

  return (
    <Fragment>
      <SettingsPanel label="Size Settings" initialOpen>
        <RadioGroupControl
          row
          name="maxWidth"
          defaultValue={maxWidth}
          options={[
            {
              label: 'Full',
              value: '1',
            },
            {
              label: '1/2',
              value: '2',
            },
            {
              label: '1/3',
              value: '3',
            },
            {
              label: '1/4',
              value: '4',
            },
            {
              label: '2/3',
              value: '2/3',
            },
            {
              label: '3/4',
              value: '3/4',
            },
          ]}
        />
        <Grid container alignItems="center">
          <Grid item sm={3}>
            <FormControlLabel
              label={`${isHeightAuto ? 'Auto' : 'Min'} height`}
              control={
                <Switch
                  checked={isHeightAuto}
                  onChange={(_e, checked) => {
                    setIsHeightAuto(checked);
                    setValue('minHeight', checked ? 0 : 200);
                  }}
                />
              }
            />
          </Grid>
          {!isHeightAuto && (
            <Grid item sm={9}>
              <InputControl
                fullWidth
                name="minHeight"
                label="Min height"
                type="number"
                defaultValue={minHeight}
                variant="outlined"
                style={{ marginBottom: 0 }}
              />
            </Grid>
          )}
        </Grid>
        <Typography paragraph variant="caption">
          Select a minimum pixel height for the container, value 0 gives the
          container an min height of auto.
        </Typography>
      </SettingsPanel>
      <SettingsPanel label="Display Settings" initialOpen>
        <Grid container spacing={4}>
          <Grid item>
            <ButtonGroupControl
              label="Vertical alignment"
              name="alignment.vertical"
              defaultValue={alignment.vertical}
              options={[
                {
                  label: 'Top',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>vertical_align_top</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>vertical_align_center</Icon>,
                },
                {
                  label: 'Bottom',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>vertical_align_bottom</Icon>,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <ButtonGroupControl
              label="Horizontal alignment"
              name="alignment.horizontal"
              defaultValue={alignment.horizontal}
              options={[
                {
                  label: 'Left',
                  value: ALIGNMENT_OPTIONS.start,
                  component: <Icon>format_align_left</Icon>,
                },
                {
                  label: 'Center',
                  value: ALIGNMENT_OPTIONS.center,
                  component: <Icon>format_align_justify</Icon>,
                },
                {
                  label: 'Right',
                  value: ALIGNMENT_OPTIONS.end,
                  component: <Icon>format_align_right</Icon>,
                },
              ]}
            />
          </Grid>
          <Grid item>
            <Controller
              name="backgroundImage"
              defaultValue={backgroundImage}
              render={({ field: { onChange, value } }) => (
                <MediaPlaceholder
                  onChange={onChange}
                  value={value}
                  label="Background image"
                  conferenceId={conferenceId}
                />
              )}
            />
          </Grid>
        </Grid>

        <FormLabel component="legend">Background Video</FormLabel>
        {videos.length === 0 ? (
          <Typography variant="caption"> No videos in Media Library</Typography>
        ) : (
          <SelectControl
            style={{ maxWidth: '100%' }}
            name="videoId"
            defaultValue={videoId}
            label="Video"
            native={true}
            options={[
              {
                label: 'None',
                value: '-',
              },
              ...videos.map((video) => ({
                label: video.original_filename,
                value: video.source_id,
              })),
            ]}
          />
        )}
      </SettingsPanel>
      <SettingsPanel label="Color Settings" initialOpen>
        <ColorControl
          helpText="Select color or specify a custom static color."
          settings={[
            {
              name: 'textColor',
              label: 'Text Color',
              defaultValue: textColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
            {
              name: 'backgroundColor',
              label: 'Background Color',
              defaultValue: backgroundColor,
              palette: [
                ...getSwatch('background'),
                ...getSwatch('primary'),
                ...getSwatch('secondary'),
              ],
            },
          ]}
        />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <Fragment>
      <SettingsContainer>
        <Settings {...props} />
      </SettingsContainer>

      <EditChildren>{children}</EditChildren>
    </Fragment>
  );
};
