import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Icon,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import moment from 'moment';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { fileSizeToHumanReadable, getFileIcon } from '@/js/shared/utils/format';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      fontSize: '1.0rem',
      ...override.root,
    }),
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: { order, reverse, show },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [files, setFiles] = useState<any[]>([]);
  const [showNrOfFiles, setShowNrOfFiles] = useState<number>(
    parseInt(show) || 0
  );
  //
  useEffect(() => {
    axios
      .get(
        `/api/visitor/conferences/${conferenceId}/uploadedfiles?order=${
          order || 'date'
        }&reverse=${reverse ? '1' : '0'}`
      )
      .then((response) => {
        setFiles(response.data);
      });
  }, []);
  const filesToShow =
    showNrOfFiles === 0 ? files : files.slice(0, showNrOfFiles);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user ? (
        <Box display="flex" flexDirection="column">
          {filesToShow.length === 0 && 'No files...'}
          {filesToShow.map((file) => (
            <Box key={file.id} display="flex" alignItems="flex-start" pb={1}>
              <Box mr={1}>
                <Icon>{getFileIcon(file.original_filename)}</Icon>
              </Box>
              <Box flex="1 1 auto">
                <Link href={`/downloadmediafile/${file.id}`}>
                  {file.original_filename}
                </Link>{' '}
                {fileSizeToHumanReadable(file.size)}
              </Box>
            </Box>
          ))}
          {showNrOfFiles !== 0 && files.length > showNrOfFiles && (
            <Button
              color="primary"
              size="small"
              onClick={() => setShowNrOfFiles(0)}
              startIcon={<Icon>expand_more</Icon>}
            >
              {window.LANG.SHOW_ALL}
            </Button>
          )}
        </Box>
      ) : (
        'Need to be logged in'
      )}
    </div>
  );
};
