import { FormHelperText, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { DiscussionData } from '.';
import { Select } from '../select-control';

interface IProps {
  onChange: (discussionId: string) => void;
  conferenceId: string;
  value?: string;
}

const useStyles = makeStyles((theme) => ({}));

export const ForumPostSelector: FC<IProps> = ({
  onChange,
  conferenceId,
  value,
}) => {
  const classes = useStyles();

  const [discussions, setDiscussions] = useState<DiscussionData[] | null>(null);
  const [discussionId, setTopicSlug] = useState(value || '');

  useEffect(() => {
    axios
      .get(`/api/conferences/${conferenceId}/discussions`)
      .then((response: any) => {
        setDiscussions(response.data);
      });
  }, []);

  useEffect(() => {
    onChange(discussionId);
  }, [discussionId]);

  return (
    <div>
      {discussions !== null && (
        <Select
          fullWidth
          value={discussionId}
          onChange={(val) => setTopicSlug(val.toString())}
          name="discussion"
          options={[
            {
              label: 'No post selected',
              value: '',
            },
            ...discussions.map((t: any) => ({
              label: `${t.title} #${t.discussion_topic?.title?.substring(
                0,
                8
              )}`,
              value: t.id,
            })),
          ]}
        />
      )}

      {!discussionId && (
        <FormHelperText error>
          ATT: Connecting a discussion is mandatory
        </FormHelperText>
      )}

      {discussionId &&
        discussions &&
        !discussions.find((t: any) => t.id === discussionId) && (
          <FormHelperText error>
            ERROR: Could not find discussion (deleted? not connected to a
            topic?)
          </FormHelperText>
        )}
    </div>
  );
};
ForumPostSelector.displayName = 'ForumPostSelector';
