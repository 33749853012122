import { ConditionalRenderer } from '@/components/conditional-renderer';
import { SwitchRenderer } from '@/components/switch-renderer';
import { ErrorBoundary } from '@/error-boundary';
import { FallbackProps } from '@/error-boundary/contract';
import { NodeContext } from '@/node-editor';
import {
  Appender,
  NodeContainer,
  NodeControls,
  MigrationWarning,
  NodeWarning,
} from '@/node-editor/components';
import {
  getSelectionWithChildren,
  useSelectors,
} from '@/node-editor/store/selectors';
import { getTemplate } from '@/node-editor/utilities';
import { nodeMap } from '@/nodes';
import { INode, TRenderContext } from '@/types/node';
import React, { useMemo } from 'react';

interface IProps {
  node: INode;
  context: TRenderContext;
  conferenceId: string;
}

interface IRenderFallbackProps extends FallbackProps {
  conferenceId: string;
}

const EditFallback = ({
  boundaryReset,
  conferenceId,
  error,
}: IRenderFallbackProps): JSX.Element | null => {
  const node = error.getNode();

  return (
    <NodeControls node={node} template={getTemplate(node.type)} error>
      <SwitchRenderer
        switchCase={error.name}
        render={{
          NodeContentError: () =>
            error.hasMigration() ? (
              <MigrationWarning reset={boundaryReset} error={error}>
                This component has encountered diverged settings during
                validation.
              </MigrationWarning>
            ) : (
              <NodeWarning reset={boundaryReset} error={error}>
                This component has encountered diverged settings during
                validation.
              </NodeWarning>
            ),
          NodeTypeError: () => (
            <NodeWarning reset={boundaryReset} error={error}>
              This component encountered an error during validation. Please try
              to delete it and add it back againg. If the issue persists please
              file an issue.
            </NodeWarning>
          ),
          NodeNotFoundError: () => (
            <NodeWarning reset={boundaryReset} error={error}>
              This component encountered an error during validation. Please try
              to delete it and add it back againg. If the issue persists please
              file an issue.
            </NodeWarning>
          ),
          default: () => null,
        }}
      />
      {React.cloneElement(
        error.data[error.getContext()]({
          node,
          conferenceId,
          children: node.children || [],
        })
      )}
    </NodeControls>
  );
};

const Node = ({ node, context, conferenceId }: IProps) => {
  const Component = useMemo(() => nodeMap[node.type]({ node, context }), []);

  const _quickhackPassCardOrAreaTitleSlotProp = (): string | undefined => {
    switch (node.type) {
      case 'card': {
        return node.content.text;
      }
      case 'widget-area': {
        return node.content.areaName;
      }
      default:
        return undefined;
    }
  };

  return (
    <NodeControls
      node={node}
      template={getTemplate(node.type)}
      slot={_quickhackPassCardOrAreaTitleSlotProp()}
    >
      {({ supportsChildren, reorderNode }) => (
        <ConditionalRenderer
          condition={supportsChildren}
          render={(component) => (
            <NodeContainer
              id={node.id}
              onDrop={({ source, destination }) =>
                reorderNode(node.id, source.index, destination.index)
              }
            >
              {component}
              <Appender nodeId={node.id} />
            </NodeContainer>
          )}
        >
          <Component key={node.id} node={node} conferenceId={conferenceId}>
            {node.children as INode[]}
          </Component>
        </ConditionalRenderer>
      )}
    </NodeControls>
  );
};

export const EditChildren = ({ children }: { children: INode[] }) => {
  const selectors = useSelectors(() => ({
    getSelectionWithChildren,
  }));

  return (
    <NodeContext.Consumer>
      {({ context, conferenceId }) =>
        selectors.getSelectionWithChildren(children).map((node) => (
          <ErrorBoundary
            key={node.id}
            fallbackRender={(fallbackProps) => (
              <EditFallback conferenceId={conferenceId} {...fallbackProps} />
            )}
          >
            <Node node={node} context={context} conferenceId={conferenceId} />
          </ErrorBoundary>
        ))
      }
    </NodeContext.Consumer>
  );
};
