import { NodeContext } from '@/node-editor';
import { getTemplate } from '@/node-editor/utilities';
import { INode, INodeTemplate, TNodes } from '@/types/node';
import { useContext, useRef } from 'react';
import { IMigration } from '../../types/node';

/**
 * Get the whole node state
 *
 * @param nodes - TNodes
 * @returns TNodes
 */
export const getNodes = (nodes?: TNodes): TNodes => {
  return nodes;
};

/**
 * Get the root node
 *
 * @param nodes - TNodes
 * @returns INode
 */
export const getRootNode = (nodes?: TNodes): INode => {
  return Object.values(nodes).find(({ nodeId }) => !nodeId);
};

/**
 * get node
 *
 * @param nodes - TNodes
 * @param id - string
 * @returns INode
 */
export const getNode = <T extends { [id: string]: INode }>(
  id: string,
  nodes?: T
): INode => {
  return nodes[id];
};

/**
 * Get the content from node
 *
 * @param node - INode
 * @returns - NodeContent
 */
export const getNodeContent = (node: INode) => {
  return node.content;
};

/**
 * Get the depricated migration from node template
 *
 * @param node - INode
 * @returns IMigration[]
 */
export const getMigrationStrategy = (node: INodeTemplate): IMigration[] => {
  return node.depricated || [];
};

/**
 * Get the order of the node
 *
 * @param node - INode
 * @returns number
 */
export const getNodeOrder = (node: INode): number => {
  return node.order;
};

/**
 * Sorts the selection by order in ASC order
 *
 * @param nodes - TNodes
 * @returns INode[]
 */
export const getOrderedSelection = (nodes: INode[]): INode[] => {
  return nodes.sort((a, b) => a.order - b.order);
};

/**
 * get the children related to the node
 *
 * @param nodes - TNodes
 * @param id - string
 * @returns INode[]
 */
export const getChildren = <T = TNodes>(id: string, nodes?: T): INode[] => {
  return getOrderedSelection(
    Object.values(nodes).filter(({ nodeId }) => nodeId === id)
  );
};

/**
 * get the count of children
 *
 * @param nodes - TNodes
 * @param id - string
 * @returns number
 */
export const getChildrenCount = <T = TNodes>(id: string, nodes?: T): number => {
  return getChildren(id, nodes).length;
};

/**
 * Injects related children to each node in the selection
 *
 * @param nodes - TNodes
 * @param selection - INode[]
 * @returns - INode[]
 */
export const getSelectionWithChildren = <T = TNodes>(
  selection: INode[],
  nodes?: T
): INode[] => {
  return getOrderedSelection(
    selection.map((node) => {
      node.children = getChildren(node.id, nodes);

      return node;
    })
  );
};

/**
 * Merges the override with the default override specified in INodeTemplate
 *
 * @param type - node name
 * @param override - Record<string, any>
 * @returns Record<string, any>
 */
export const getNodeOverride = (
  type: string,
  override: Record<string, any>
): Record<string, any> => {
  const defaultOverride = getTemplate(type);

  return Object.keys(override).length === 0
    ? defaultOverride.override
    : { ...defaultOverride.override, ...override };
};

/**
 * Checks if the override is different from the default override
 *
 * @param type - node name
 * @param override - Record<string, any>
 * @returns Record<string, any>
 */
export const hasNodeOverride = (
  type: string,
  override: Record<string, any>
): boolean => {
  const defaultOverride = getTemplate(type);

  if (Object.keys(override).length === 0) {
    return false;
  }

  // Compare the override with the default override
  try {
    return (
      JSON.stringify(defaultOverride.override) !== JSON.stringify(override)
    );
  } catch (e) {
    return false;
  }
};

/**
 * Decorates all returned functions with state from NodeContext reducer
 *
 * @param callback - (nodes: TNodes) => Record<string, unknown>
 * @returns Record<string, Function> - an object with the returned selectors
 *
 * @example
 * const selectors = useSelectors(() => ({
 *     selectorA,
 *     selectorB
 * }))
 */
export const useSelectors = <T extends { [selector: string]: any }>(
  callback: (nodes: TNodes) => T
) => {
  const context = useContext(NodeContext);

  if (context == undefined) {
    throw new Error('useSelectors must be used within NodeContextProvider.');
  }

  const { nodes } = context;

  const inputMap = useRef(callback(nodes));

  const withNodes = (func: (...args: any[]) => any) => (...args: any[]) =>
    func(...args, nodes);

  return Object.keys(inputMap.current).reduce((selectors, selector) => {
    if (typeof inputMap.current[selector] === 'function') {
      return {
        ...selectors,
        [selector]: withNodes(inputMap.current[selector]),
      };
    }

    return selectors;
  }, inputMap.current);
};
