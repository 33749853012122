const threadReducer = (state, action) => {
  switch (action.type) {
    case 'MESSAGE_ADD':
      return { ...state, addingMessage: true };
    case 'THREADS_LOAD':
      return { ...state, threadsLoading: true };
    case 'THREADS_LOADED':
      return { ...state, threads: action.threads, threadsLoading: false };
    case 'THREAD_LOAD':
      return { ...state, loading: true, addingMessage: false };
    case 'THREAD_LOADED':
      if (action.thread) {
        const updatedThreads = [...state.threads];
        const updatedThread = updatedThreads.find(
          (t) => t.id === action.thread.id
        );
        if (updatedThread) {
          updatedThread.threadVisitors.hasUnread = false;
        } else {
          const temporaryThread = {
            id: action.thread.id,
            threadVisitors: { hasUnread: false },
            lastThreadMessage: action.msg,
          };
          updatedThreads.unshift(temporaryThread);
        }
        return {
          ...state,
          currentThread: action.thread,
          threads: updatedThreads,
          loading: false,
          addingMessage: false,
        };
      } else {
        // When setting to null
        return {
          ...state,
          currentThread: action.thread,
          loading: false,
          addingMessage: false,
        };
      }

    case 'THREAD_NEW_MSG':
      if (
        state.currentThread &&
        state.currentThread.id === action.msg.threadId
      ) {
        // Add to open thread
        const withoutTempMessages = state.currentThread.threadMessages.filter(
          (msg) => !msg.hasOwnProperty('tempText')
        );
        const hasDuplicate = state.currentThread.threadMessages.find(
          (msg) => msg.id === action.msg.id
        );
        if (hasDuplicate) {
          // This occures when starting a thread that already exists..
          return { ...state, addingMessage: false };
        } else {
          return {
            ...state,
            currentThread: {
              ...state.currentThread,
              threadMessages: [...withoutTempMessages, action.msg],
            },
            addingMessage: false,
          };
        }
      } else {
        // Set thread as unread (or append)
        const newThreadList = [...state.threads];
        const threadIndex = newThreadList.findIndex(
          (t) => t.id === action.msg.threadId
        );

        if (threadIndex > -1) {
          newThreadList[threadIndex].threadVisitors.hasUnread = true;
          newThreadList[threadIndex].lastThreadMessage = action.msg;
        } else {
          const temporaryThread = {
            id: action.msg.threadId,
            threadVisitors: { hasUnread: true },
            lastThreadMessage: action.msg,
          };
          newThreadList.unshift(temporaryThread);
        }
        return { ...state, threads: newThreadList };
      }
    default:
      return state;
  }
};

export default threadReducer;
