import { darken, lighten, rgbToHex } from "@material-ui/core";

export const getColorSwatch = <T extends {[key: string]: string}>(palette: T, color: keyof T): string[] => {

    if (!palette || !palette[color]) {

        return [];
    }

    const range = [-4, -3, 0, 3, 5, 7];

    return range.reduce((ranges, level) => {

        let hex: string;

        if (0 === level) {

            hex = palette[color];
        }

        if (0 > level) {

            hex = rgbToHex(darken(palette[color], Number('0.' + Math.abs(level))))
        }

        if (0 < level) {

            hex = rgbToHex(lighten(palette[color], Number('0.' + Math.abs(level))))
        }

        if (!ranges.includes(hex)) {

            ranges.push(hex)
        }

        return ranges;
    }, []);
}