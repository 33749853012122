import { INode, INodeElement, TRenderContext } from '@/types/node';
import { isObject } from '../../../shared/utils/objects';
import { getTemplate, getTemplateDepricated } from '@/node-editor/utilities';
import { getNodeContent } from '@/node-editor/store/selectors';

interface IData extends Partial<INodeElement> {
  node: INode;
  context: TRenderContext;
}

class NodeBaseError extends Error {
  data?: IData;

  constructor(message: string, data?: IData) {
    super(message);
    this.data = data;
  }

  getName() {
    return this.name;
  }

  getMessage() {
    return this.message;
  }

  getData() {
    return this.data;
  }

  getNode(): INode {
    return this.data.node;
  }

  getContext(): TRenderContext {
    return this.data.context;
  }
}

export class NodeNotFoundError extends NodeBaseError {
  constructor(message: string, data?: IData) {
    super(message, data);
    this.name = NodeNotFoundError.prototype.constructor.name;
  }
}

export class NodeTypeError extends NodeBaseError {
  constructor(message: string, data?: IData) {
    super(message, data);
    this.name = NodeTypeError.prototype.constructor.name;
  }
}

export class NodeContentError extends NodeBaseError {
  constructor(message: string, data?: IData) {
    super(message, data);
    this.name = NodeContentError.prototype.constructor.name;
  }

  hasMigration() {
    return !!getTemplateDepricated(this.data.node.type);
  }

  getMigrationStrategy() {
    return getTemplateDepricated(this.data.node.type) || [];
  }
}

/**
 * Compares Object properties
 *
 * @param object Record<string, any>
 * @param compare Record<string, any>
 * @returns boolean
 */
export const equalKeys = (
  object: Record<string, any>,
  compare: Record<string, any>
): boolean => {
  return Object.keys(compare).every((key) => {
    if (object[key] && isObject(compare[key])) {
      return equalKeys(object[key], compare[key]);
    }

    return key in object;
  });
};

/**
 *
 * @param context
 * @param node
 * @param component
 */
export const isNodeValid = (
  context: TRenderContext,
  node: INode,
  component?: INodeElement
): boolean => {
  if (!component) {
    throw new NodeNotFoundError('Not found.', {
      node,
      context,
    });
  }

  // Omit validation of rich content
  if (node.type === 'text') {
    const { richContent: tContent, ...template } = getTemplate(
      node.type
    ).content;

    const { richContent: nContent, ...content } = getNodeContent(node);

    if (!equalKeys(template, content)) {
      throw new NodeContentError('Content not valid', {
        node,
        context,
        ...component,
      });
    }

    return true;
  }

  if (!equalKeys(getTemplate(node.type).content, getNodeContent(node))) {
    throw new NodeContentError('Content not valid', {
      node,
      context,
      ...component,
    });
  }

  return true;
};

/**
 *
 * Shallow validation to make sure only allowed keys are saved.
 *
 * @param schema defined override keys in node schema
 * @param input
 * @returns object
 */
export const validateCssOverride = (
  nodeType: string,
  json: Record<string, any>
): Record<string, any> => {
  const schema = getTemplate(nodeType);

  if (!schema.override) {
    throw new Error(
      'This Node does not have a defined schema for css override. Please provide one in node template.'
    );
  }

  return Object.keys(schema.override).reduce(
    (override, key) => ({
      ...override,
      [key]: !json[key] ? {} : { ...json[key] },
    }),
    schema.override
  );
};

/**
 *
 * @param node
 * @param values
 * @returns node
 */
export const validateNodeProperties = (
  node: INode,
  values: Partial<INode>
): INode => {
  const { override, ...content } = values;

  const properties = {
    ...node,
    content: {
      ...node.content,
      ...content,
    },
  };

  if (override != null) {
    properties.override = validateCssOverride(node.type, values.override);
  }

  return properties;
};
