import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const { ALIGNMENT_OPTIONS } = SETTINGS_DEFAULTS;

const template: INodeTemplate = {
  version: 1,
  name: 'Row',
  icon: 'view_column',
  type: 'row',
  tag: 'layout',
  content: {
    horizontalSpacing: '1',
    verticalSpacing: {
      top: '0',
      bottom: '0',
    },
    alignment: {
      vertical: ALIGNMENT_OPTIONS.start,
      horizontal: ALIGNMENT_OPTIONS.center,
    },
  },
  supports: {
    contexts: ['page'],
    children: ['card', 'column'],
    appender: true,
  },
  override: {
    root: {}
  }
}

export {
    template,
    Render,
    Edit,
};

