import {
  AppBar,
  Badge,
  Box,
  Button,
  FormControl,
  Hidden,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  Zoom,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import FollowMenu from '../components/lists/FollowMenu';
import ThreadMenu from '../components/lists/ThreadMenu';
import { AuthContext } from '../context/AuthContext';
import { ConferenceContext } from '../context/ConferenceContext';
import { DiscussionContext } from '../context/DiscussionContext';
import { MenuContext } from '../context/MenuContext';
import { ThreadContext } from '../context/ThreadContext';
import UserAvatar from './ui/UserAvatar';
import { RippleBadge } from '@/components/ripple-badge';
import { WidgetController } from '@/widget-editor/components';
import { ProgramViewGrid } from './info/ProgramViewGrid';
import { findCurrentDayId } from '../utils/DataFormat';
import clsx from 'clsx';
import { favoriteEvents } from '../../../../shared/utils/dataReducers';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: (props) => props.navBg || theme.palette.background.default,
    color: theme.palette.text.primary,
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
  },
  menuBg: {
    backgroundColor: theme.palette.background.default,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.background.shadedown_20,
    boxShadow: '0 0 15px rgb(0, 0, 0, 0.15)',
    padding: 5,
    borderRadius: 3,
  },
  peopleButton: {
    backgroundColor: theme.palette.background.default,
    width: 26,
    height: 22,
    borderRadius: 3,
    fontSize: '1.4rem',
  },
  title: ({ centerHeader }) => ({
    textAlign: 'center',
    paddingLeft: centerHeader ? 170 : 0, // drawerWith + padding
    flexGrow: 1,
  }),
  loginLink: {
    backgroundColor: theme.palette.background.shadedown_20,
    padding: '2px 10px',
    borderRadius: 4,
  },
  loginLinkText: {
    marginRight: 5,
  },
  videoIcon: {
    color: '#FF0000',
    position: 'relative',
  },
  videoIconCount: {
    fontWeight: 600,
    fontSize: 12,
  },
  leftButtonPadding: {
    paddingLeft: 180,
  },
  draft: ({ centerHeader }) => ({
    position: 'absolute',
    top: 0,
    width: 100,
    left: `calc(50% ${centerHeader ? '+ 40px' : '- 50px'})`,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#FF0000',
    color: '#ffffff',
    padding: 4,
    fontSize: '0.6rem',
    zIndex: 2000,
    textAlign: 'center',
    '& span': {
      fontSize: '0.8rem',
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '0 5px 0 0',
    },
  }),
}));

const selectStyles = makeStyles((theme) => ({
  select: {
    padding: 0,
    maxWidth: 130,
  },
  formControl: {
    //width: '100%'
  },
  root: {
    backgroundColor: theme.palette.background.shadedown_20,
  },
  whiteColor: {
    // color: '#ffffff',
  },
  itemRoot: {
    backgroundColor: theme.palette.background.default,
  },
  itemBg: {
    backgroundColor: theme.palette.background.shadedown_20,
  },
  liveDot: {
    '&::after': {
      backgroundColor: theme.palette.error.main,
      content: '""',
      display: 'inline-block',
      width: 10,
      height: 10,
      marginLeft: 5,
      borderRadius: '7.5px',
    },
  },
  liveBadge: {
    backgroundColor: '#f00',
    color: '#fff',
  },
  addToCal: {},
}));

// const DrawLiveSelect = React.memo(
//   (props) => {
//     const classes = selectStyles();
//     const handleChange = (e) => {
//       window.location.href = `/live/${e.target.value}`;
//     };

//     const roomExistsInList = !!props.rooms.find(
//       (room) => room.id === props.programRoomId
//     );

//     return (
//       <FormControl className={classes.formControl}>
//         <Badge badgeContent={props.liverooms} color="error">
//           <Select
//             disableUnderline
//             className={classes.select}
//             classes={{
//               icon: classes.whiteColor,
//             }}
//             MenuProps={{ classes: { paper: classes.itemBg } }}
//             value={props.programRoomId}
//             onChange={handleChange}
//           >
//             {!roomExistsInList && (
//               <MenuItem
//                 classes={{ root: classes.itemRoot }}
//                 key={'hiddenroom'}
//                 value={props.programRoomId}
//               >
//                 Rooms
//               </MenuItem>
//             )}
//             {props.rooms.map((room) => (
//               <MenuItem
//                 className={room.currentEventId ? classes.liveDot : ''}
//                 classes={{ root: classes.itemRoot }}
//                 key={room.id}
//                 value={room.id}
//               >
//                 {room.title}
//               </MenuItem>
//             ))}
//           </Select>
//         </Badge>
//       </FormControl>
//     );
//   },
//   (prevProps, nextProps) => {
//     return prevProps.liverooms === nextProps.liverooms;
//   }
// );
// DrawLiveSelect.displayName = 'DrawLiveSelect';

const VisitorNav = withRouter((props) => {
  const { authState, actions, isModeratorOrSpeaker } = useContext(AuthContext);
  const { threadState } = useContext(ThreadContext);
  const { menuOpen, setMenuOpen } = useContext(MenuContext);
  const { discussionNotifications, viewedByClient } = useContext(
    DiscussionContext
  );
  const {
    conferenceUpdateStepper,
    conferenceState: {
      conferenceDetail,
      conferenceDetail: { programDays: days },
    },
  } = useContext(ConferenceContext);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const welcomeStateForModerators =
    conferenceDetail &&
    conferenceDetail.state === 'welcome' &&
    isModeratorOrSpeaker;

  const classes = useStyles({
    navBg: authState.userLoggedIn ? null : 'transparent',
    centerHeader: menuOpen && !smallScreen,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [programMenuEl, setProgramMenuEl] = useState(null);
  const followMenuRef = useRef();
  const threadMenuRef = useRef();

  const open = Boolean(anchorEl);

  const buttonSize = smallScreen ? 'small' : 'medium';

  const unreadChats = threadState.threads.filter(
    (t) => t.threadVisitors.hasUnread
  ).length;
  const unreadNotifications = viewedByClient
    ? 0
    : discussionNotifications.filter((dn) => !dn.viewed).length;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFollowMenu = (event) => {
    followMenuRef.current.open(event.currentTarget);
  };
  const handleThreadMenu = (event) => {
    threadMenuRef.current.open(event.currentTarget);
  };
  // Used for studio button in navbar, currently disabled
  // const handleContributorMenu = (event) => {
  //   contributorMenuRef.current.open(event.currentTarget);
  // };

  const logout = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    actions.logout();
  };

  const account = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    window.location.href = `/profile/account`;
  };
  const editProfile = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    window.location.href = `/profile/edit`;
  };
  const editProfileNotifications = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    window.location.href = `/profile/notifications`;
  };

  // Count how many rooms are streaming
  const currentLiveCount =
    conferenceDetail.programDays.length > 0
      ? conferenceDetail.programDays[0].programRooms.filter(
          (room) => !!room.currentEventId
        ).length
      : 0;

  // if (!conferenceDetail || days.length === 0) {
  //   console.log('No active days, so dont render the program');
  //   return null; // Don't render with 0 active days
  // }

  // const [currentTab, setCurrentTab] = useState(
  //   findCurrentDayId(conferenceDetail)
  // );
  const currentDayId = findCurrentDayId(conferenceDetail);
  const totalFavorites = favoriteEvents(conferenceDetail, currentDayId);

  const currentDay = useMemo(() => {
    return days.find((d) => d.id === currentDayId);
  }, [currentDayId, conferenceUpdateStepper]);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar variant="dense" disableGutters>
        {!menuOpen && (
          <IconButton
            size="small"
            edge="start"
            color="default"
            aria-label="Menu open"
            onClick={() => setMenuOpen(true)}
          >
            <Icon>menu</Icon>
          </IconButton>
        )}
        {/* {authState.userLoggedIn && ( */}
        <Box
          display="flex"
          className={clsx({
            [classes.leftButtonPadding]: menuOpen && !smallScreen,
          })}
        >
          {welcomeStateForModerators && (
            <div className={classes.draft}>
              <Icon>construction</Icon> Not published
            </div>
          )}

          {currentDay && (
            <>
              <IconButton
                size={'small'}
                aria-label="program menu"
                aria-controls="menu-program"
                aria-haspopup="true"
                onClick={(event) => setProgramMenuEl(event.currentTarget)}
                color="default"
              >
                <Icon>event</Icon>
              </IconButton>
              <Menu
                id="menu-program"
                anchorEl={programMenuEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(programMenuEl)}
                onClose={() => setProgramMenuEl(null)}
                PaperProps={{ className: classes.menuBg }}
              >
                {totalFavorites.length > 0 && (
                  <Box className={classes.addToCal}>
                    <Button
                      size="small"
                      color="primary"
                      startIcon={<Icon>event</Icon>}
                      target="_blank"
                      href={`/api/visitor/conferences/${conferenceDetail.id}/downloadfavorites`}
                    >
                      {window.LANG.ADD_TO_CAL} ({totalFavorites.length})
                    </Button>
                  </Box>
                )}

                <ProgramViewGrid
                  // @ts-expect-error: React.memo type error
                  programDay={currentDay}
                  programDayId={currentDay.id}
                  totalFavorites={totalFavorites}
                  updateStepper={conferenceUpdateStepper}
                />
              </Menu>
            </>
          )}
          {currentLiveCount > 0 && (
            <Hidden smUp>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="Menu open"
                className={classes.videoIcon}
                onClick={() => setMenuOpen(true)}
              >
                <Icon>videocam</Icon>
                <span className={classes.videoIconCount}>
                  {currentLiveCount}
                </span>
              </IconButton>
            </Hidden>
          )}
        </Box>
        {/*  )}*/}
        <Box className={classes.title}>{props.header}</Box>
        {authState.stateLoaded && authState.user ? (
          <Box display="flex" alignItems="center">
            {props.extraTopButtons}
            <Box>
              <IconButton
                size={smallScreen ? 'small' : 'medium'}
                aria-label="Connections"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleFollowMenu}
                color="default"
                variant="contained"
              >
                <Badge
                  color="primary"
                  badgeContent={authState.user.follows_new}
                >
                  <Icon className={classes.peopleButton}>contact_mail</Icon>
                </Badge>
              </IconButton>
              <FollowMenu ref={followMenuRef} />
            </Box>
            <Box>
              <IconButton
                size={buttonSize}
                aria-label="Connections"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleThreadMenu}
                color="default"
                variant="contained"
                disableRipple={true}
              >
                <RippleBadge
                  color="primary"
                  badgeContent={unreadChats + unreadNotifications}
                >
                  <Icon className={classes.peopleButton}>forum</Icon>
                </RippleBadge>
              </IconButton>
              <ThreadMenu ref={threadMenuRef} />
            </Box>
            <Box>
              <IconButton
                size={smallScreen ? 'small' : 'medium'}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="default"
              >
                <UserAvatar size="small" user={authState.user} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                PaperProps={{ className: classes.menuBg }}
              >
                <MenuItem onClick={account}>Profile</MenuItem>
                <MenuItem onClick={editProfile}>Edit Profile</MenuItem>
                <MenuItem onClick={editProfileNotifications}>
                  Edit Notifications
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            {props.match && props.match.path !== '/' && (
              <>
                <Typography
                  className={classes.loginLinkText}
                  variant="body2"
                  noWrap
                >
                  Already registered?
                </Typography>
                <Link className={classes.loginLink} href="/">
                  Login
                </Link>
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
});

export default VisitorNav;
