import { TopMenuItem } from '@/components/topmenuitem';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import C from '../../../shared/constants/general';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import VisitorNav from '../shared/components/VisitorNav';
import { AuthContext } from '../shared/context/AuthContext';
import { ConferenceContext } from '../shared/context/ConferenceContext';
import { MenuContext } from '../shared/context/MenuContext';
import { findCurrentDayId } from '../shared/utils/DataFormat';
import { useLinkAction } from '@/hooks/useLinkAction';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarStyle: {
    height: 64,
    background: 'transparent',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    fontSize: '0.8rem',
    backgroundColor: theme.palette.background.shadeup_10,
    zIndex: 1200,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: drawerWidth,
    flexShrink: 0,
    marginLeft: -drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeaderIcon: {
    color: theme.palette.text.primary,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: 48,
    justifyContent: 'flex-end',
  },
  content: {
    height: 'calc(100vh - 48px)',
    display: 'flex',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  drawerShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  listHeader: {
    lineHeight: '1.4rem',
    opacity: 0.6,
  },
  listItemRoot: {
    opacity: 1,
    '&:hover': {
      // opacity: 1,
      color: theme.palette.primary.main,
    },
  },
  listItemSelected: {
    opacity: 1,
  },
  listItemLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
  },
  listItemIconRoot: {
    fontSize: '.8rem',
    minWidth: 35,
  },
  avatarRoot: {
    maxWidth: 24,
    height: 24,
  },
  badgeRoot: {
    backgroundColor: '#FF0000',
  },
  typography: {
    // fontSize: '.875rem',
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    margin: '4px 0',
    opacity: 0.15,
  },
  topNav: {
    marginTop: 48,
  },
  overlay: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    opacity: 0.4,
    zIndex: 1000,
  },
}));

const useAuthMenus = (menuItems) => {
  const { conferenceState } = useContext(ConferenceContext);
  const { authState } = useContext(AuthContext);
  // const [menuItems, setMenuItems] = useState([]);
  const [programRooms, setProgramRooms] = useState([]);
  // const [hasStudio, setHasStudio] = useState(false);

  useEffect(() => {
    if (authState.userLoggedIn) {
      if (conferenceState.conferenceDetail.programDays) {
        const currentDayId = findCurrentDayId(conferenceState.conferenceDetail);
        const currentDay = conferenceState.conferenceDetail.programDays.find(
          (d) => d.id === currentDayId
        );
        setProgramRooms((currentDay && currentDay.programRooms) || []);
        // setMenuItems(conferenceState.conferenceDetail.menu_items);
      }

      // if (
      //   authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR ||
      //   authState.user.participation_level ===
      //     C.VISITOR.PARTICIPATION_LEVEL.CONTRIBUTE
      // ) {
      //   axios
      //     .get(`/api/visitor/conferences/${window.CONFERENCE_ID}/hasstudio`)
      //     .then((response) => {
      //       setHasStudio(response.data.hasStudio);
      //     });
      // }
    }
  }, [authState.userLoggedIn]);

  if (authState.userLoggedIn) {
    return [
      // Show link to studio if vonage_session_id exists (Which means you are a speaker)
      // ...(hasStudio
      //   ? [
      //       {
      //         title: 'Studio/Backstage',
      //         icon: 'voice_chat',
      //         url: '/live/backstage',
      //       },
      //     ]
      //   : []),
      ...(authState.userLoggedIn &&
      authState.user.access_level === C.VISITOR.ACCESS_LEVEL.MODERATOR
        ? [
            {
              title: 'Moderator',
              icon: 'fact_check',
              url: '/moderator',
            },
          ]
        : []),
      ...menuItems.filter((m) => m.type === 'live' && m.position === 'start'),

      // Loop all program rooms
      ...(window.SETTINGS.HIDE_PROGRAM_ROOMS_IN_MENU
        ? []
        : programRooms.map((room, index) => ({
            title: room.title,
            isLive: room.currentEventId != null,
            avatar: getImage(
              room.bg_picture || `/coeo/room_bg_${index % 5}.jpg`,
              ImageSize.Medium
            ),
            url: `/live/room/${room.id}`,
          }))),
      { divider: true },
      ...menuItems.filter((m) => m.type === 'live' && m.position === 'end'),
    ];
  } else {
    return [];
  }
};

const usePublicMenus = (menuItems) => {
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const theme = useTheme();

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const siteNavs = menuItems.filter(
    (m) =>
      m.type === 'site' &&
      !(m.onlypublic && authState.userLoggedIn) &&
      m.design !== 'elevated'
  );

  const publicNav =
    siteNavs.length > 0 &&
    (location.pathname.toLowerCase().startsWith('/live') || smallScreen)
      ? [
          // ...(authState.userLoggedIn ? [{ divider: true }] : []),
          ...siteNavs.filter((m) => m.position === 'start'),
          ...siteNavs.filter((m) => m.position === 'end'),
        ]
      : [];

  // IF no left menu (home) and is not on home already
  if (siteNavs.length === 0 && location.pathname !== '/') {
    publicNav.push({
      type: 'site',
      position: 'start',
      title: 'Website',
      url: '/',
      design: '',
    });
  }

  return publicNav;
};

// const useLinkAction = (item) => {
//   console.log('item', item);
//   const ACTION_STRING = 'action:';
//   if (item.url.startsWith(ACTION_STRING)) {
//     const [action, param] = item.url.substr(ACTION_STRING).split(':');
//     switch (action) {
//       case 'userdm': {
//         return () => ({
//           onClick: () => {
//             console.log('user dm', param);
//             return false;
//           },
//         });
//       }
//     }
//     return {};
//   } else {
//     return {
//       to: { pathname: item.url },
//       target: item.target == '_blank' ? '_blank' : '_self',
//     };
//   }
// };

export const LiveContainer = (props) => {
  const classes = useStyles();
  const { conferenceState, welcomeState } = useContext(ConferenceContext);

  if (welcomeState) {
    return props.children;
  }

  const { authState, isModeratorOrSpeaker } = useContext(AuthContext);
  const { menuOpen, setMenuOpen } = useContext(MenuContext);
  const { linkActionCall } = useLinkAction();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const handleDrawerClose = () => setMenuOpen(false);
  const menuItems = conferenceState.conferenceDetail.menu_items;

  const authNav = useAuthMenus(menuItems);
  const publicNav = usePublicMenus(menuItems);

  let menu = [...authNav];

  if (authNav.length > 0 && publicNav.length > 0) {
    // Add divider bwtween live and site navs
    menu.push({ divider: true });
  }

  menu = [...menu, ...publicNav];

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        // style={{ marginTop: 48 }}
        className={clsx(classes.content, {
          [classes.contentShift]: menuOpen && menu.length > 0,
          [classes.topNav]: authState.userLoggedIn,
        })}
      >
        {authState.userLoggedIn && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 999,
            }}
          >
            <VisitorNav {...props} />
          </div>
        )}
        {props.children}
        {menuOpen && smallScreen && menu.length > 0 && (
          <div onClick={() => setMenuOpen(false)} className={classes.overlay} />
        )}
      </Box>

      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerShift]: menuOpen && menu.length > 0,
        })}
        variant="persistent"
        anchor="left"
        open={menuOpen && menu.length > 0}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box className={classes.drawerHeader}>
          <IconButton
            size="small"
            onClick={handleDrawerClose}
            className={classes.drawerHeaderIcon}
          >
            <Icon>chevron_left</Icon>
          </IconButton>
        </Box>
        <Box color="text.main">
          <List classes={{ root: classes.listRoot }}>
            {menu.map((item, index) =>
              item.header ? (
                <ListSubheader
                  key={`menuitem-${menu.length}-${menu.title}-${index}`}
                  classes={{ root: classes.listHeader }}
                >
                  {item.header}
                </ListSubheader>
              ) : item.divider ? (
                <Divider
                  className={classes.divider}
                  key={`menuitem-${menu.length}-${index}`}
                />
              ) : (
                <ListItem
                  key={`menuitem-${menu.length}-${index}`}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.listItemSelected,
                  }}
                  selected={props.isActive ? props.isActive(item.url) : false}
                >
                  {item.type === 'site' ? (
                    <TopMenuItem
                      data={item}
                      conferenceId={conferenceState.conferenceDetail.id}
                      leftMenu={true}
                    />
                  ) : (
                    <Link
                      className={classes.listItemLink}
                      {...linkActionCall(item)}
                      // to={{ pathname: item.url }}
                      // target={item.target == '_blank' ? '_blank' : '_self'}
                      // onClick={(e) => {
                      //   console.log('clicked');
                      //   e.preventDefault();
                      //   return false;
                      // }}
                    >
                      <ListItemIcon
                        classes={{ root: classes.listItemIconRoot }}
                      >
                        <Badge
                          classes={{ dot: classes.badgeRoot }}
                          invisible={!item.isLive}
                          variant="dot"
                        >
                          {item.avatar ? (
                            <Avatar
                              src={item.avatar}
                              classes={{ root: classes.avatarRoot }}
                            />
                          ) : (
                            <Icon>{item.icon}</Icon>
                          )}
                        </Badge>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        classes={{ primary: classes.typography }}
                      />
                    </Link>
                  )}
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};
