import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../embed/edit';
import { template as embedTemplate } from '../embed';
import { Render as EmbedRender } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Embed Widget',
  icon: embedTemplate.icon,
  type: 'embed-widget',
  tag: 'widgets',
  content: {
    widgetName: 'Embed Widget',
    ...embedTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(EmbedRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
