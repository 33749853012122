// import { Box, Button, Container, Icon, Typography } from '@material-ui/core';
import { Container, makeStyles, Theme } from '@material-ui/core';
import React, { FC } from 'react';

interface IProps {
  backdropSrc: string;
  maxHeight?: string | number;
  title?: JSX.Element;
  subTitle?: JSX.Element;
  id?: string;
  style?: any;
}

const useStyles = makeStyles<Theme, Pick<IProps, 'maxHeight'>>((theme) => ({
  root: ({ maxHeight }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: maxHeight,
    overflow: 'hidden',
  }),
  backdrop: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'bottom center',
  },
  containerRoot: ({ maxHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: `calc(${maxHeight} - ${theme.spacing(2) * 2})`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
  title: ({ maxHeight }) => ({
    display: 'flex',
    flex: '1 1 100%',
    maxWidth: theme.breakpoints.values.sm,
    marginBottom: theme.spacing(2),
    '& > img': {
      width: 'auto',
      maxWidth: '100%',
      height: '100%',
      maxHeight: `calc(${maxHeight} / 2)`,
    },
  }),
  subTitle: {
    marginBottom: theme.spacing(2),
  },
}));

/**
 *
 * Renders a responsive container with specified image backdrop, maxHeight can be configured through maxHeight prop.
 *
 * @params backdropSrc required string, maxHeight optional string | number, title optional JSX.Element, subTitle optional JSX.Element, children optionsl ReactNode
 * @returns JSX.Element
 * @example
 * <SiteHeader
 *   backdropSrc={'path/to/image'}
 *   maxHeight={400}
 *   title={<Typography>Some title</Typography>}
 *   subTitle={<Typography>Some sub title</Typography>}
 * >
 *   {children}
 * </SiteHeader>
 */
export const SiteHeader: FC<IProps> = ({
  backdropSrc,
  maxHeight = '50vh',
  title,
  subTitle,
  children,
  id,
  style
}) => {
  const classes = useStyles({ maxHeight });

  return (
    <div className={classes.root} id={id} style={style}>
      {backdropSrc && <img className={classes.backdrop} src={backdropSrc} />}
      <Container classes={{ root: classes.containerRoot }} maxWidth="lg">
        {title && window.SETTINGS && window.SETTINGS.TITLE_SHOW && (
          <div className={classes.title}>{title}</div>
        )}
        {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
        {children && <div>{children}</div>}
      </Container>
    </div>
  );
};
