import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Programtimeline',
  icon: 'event',
  type: 'program',
  tag: 'basic',
  content: {
    defaultDisplay: 'list',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
  depricated: [
    {
      migrate({ alignment, verticalSpacing, ...content }) {
        return {
          ...content,
        };
      },
    },
  ],
};

export { template, Render, Edit };
