import { RadioGroup as MUIRadiouGroup, FormControl, FormLabel, Tooltip, FormControlLabel, Radio } from "@material-ui/core";
import React from "react";
import { ConditionalRenderer } from "../conditional-renderer";
import { IProps, defaultClasses } from "./contract";

export const RadioGroup = ({
    row,
    label,
    value,
    classes = defaultClasses,
    onChange,
    options,
    withTooltip
}: IProps) => {
  return (
    <FormControl component="fieldset" style={{justifyContent: 'center'}}>
      <FormLabel classes={classes.formLabel || undefined} component="legend">
        {label || undefined}
      </FormLabel>
      <MUIRadiouGroup
        row={row}
        value={value}
        onChange={(e) => onChange(e.target.value as typeof value)}
        classes={classes.radioGroup || undefined}
      >
        {options.map((option, index) => (
          <ConditionalRenderer
            key={`${option.label}_${index + 1}`}
            condition={withTooltip}
            render={(children) => (
              <Tooltip title={option.label}>
                <span>{children}</span>
              </Tooltip>
            )}
          >
            <FormControlLabel
              label={option.label}
              value={option.value}
              control={<Radio {...option.radioProps} />}
              classes={classes.formControlLabel || undefined}
            />
          </ConditionalRenderer>
        ))}
      </MUIRadiouGroup>
    </FormControl>
  );
};
