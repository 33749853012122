import { INodeTemplate } from '@/types/node';
import { Edit } from '../root/edit';
import { Render } from '../root/render';
import { template as rootTemplate } from '../root';

const template: INodeTemplate = {
  version: 1,
  name: 'Widget Root',
  type: 'widget-root',
  tag: 'layout',
  icon: '',
  content: rootTemplate.content,
  supports: { ...rootTemplate.supports, children: ['widget-area'] },
};

export { template, Render, Edit };
