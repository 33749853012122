import { Box, Link, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeSanitize from 'rehype-sanitize';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';

export interface IProps {
  text: string;
  conferenceId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
  },
  content: {
    '& blockquote': {
      borderWidth: 0,
      borderLeftWidth: 8,
      borderStyle: 'solid',
      backgroundColor: theme.palette.background.shadedown_10,
      borderColor: theme.palette.background.shadedown_20,
      margin: 0,
      padding: '1px 10px',
    },
    '& pre': {
      backgroundColor: theme.palette.background.shadedown_10,
      padding: 4,
    },
    '& table': {
      margin: '8px 0',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.background.shadedown_20,
      borderCollapse: 'collapse',
      //   tableLayout: 'fixed',
    },
    '& th': {
      backgroundColor: theme.palette.background.shadedown_20,
      padding: 3,
    },
    '& td': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.background.shadedown_20,
      padding: 3,
    },
    '& .task-list-item': {
      listStyleType: 'none',
      marginLeft: -20,
    },
  },
}));

// const img_default_width = 600;
// const coeoImageStart = `https://${process.env.STATIC_IMAGES}`;
// Only allow coeo images
const userInputImageUri = (conferenceId: string) => (
  src: string,
  alt: string,
  title: string
) => {
  if (src.indexOf('/') >= 0) {
    return '/images/no_external_images.png';
  } else {
    return uriTransformer(getImage(src, ImageSize.Default, conferenceId));
  }
  //   const isCoeoImage = src.toLowerCase().indexOf(coeoImageStart) === 0;
  //   if (isCoeoImage) {
  //     return `${uriTransformer(
  //       src.split('?').shift()
  //     )}?width=${img_default_width}`; // Add default WIDTH to Coeo image
  //   } else {
  //     return '/images/no_external_images.png';
  //   }
};

export const ManagerMarkdownRender = React.memo(
  ({ text, conferenceId }: IProps) => {
    const classes = useStyles();

    const configs = {
      trusted: {
        linkTarget: '_blank',
      },
      userInput: {
        linkTarget: '_blank',
        components: {
          a: (props: any) => {
            return <Link {...props} />;
          },
          img: (props: any) => <img className={classes.image} {...props} />,
        },
      },
    };

    return (
      <ReactMarkdown
        children={text}
        className={classes.content}
        linkTarget="_blank" // (href, children, title) => string
        style={{ minHeight: '500px' }}
        components={configs['userInput'].components}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeSanitize]}
        transformImageUri={userInputImageUri(conferenceId)}
        imageAccept={'.jpg,.jpeg,.png'}
      />
    );
  },
  (prevProps, nextProps) => prevProps.text === nextProps.text
);

ManagerMarkdownRender.displayName = 'ManagerMarkdownRender';
