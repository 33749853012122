import { FormHelperText, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TopicData } from '.';
import { TopicCreator } from '.';
import { Select, SelectControl } from '../select-control';

interface IProps {
  onChange: (topicId: string) => void;
  conferenceId: string;
  newTopicTitle: string;
  value?: string;
}

const useStyles = makeStyles((theme) => ({}));

export const TopicSelector: FC<IProps> = ({
  onChange,
  conferenceId,
  value,
  newTopicTitle,
}) => {
  const classes = useStyles();

  const [topics, setTopics] = useState<TopicData[] | null>(null);
  const [newTopic, setnewTopic] = useState(false);
  const [topicSlug, setTopicSlug] = useState(value);

  useEffect(() => {
    axios
      .get(`/api/conferences/${conferenceId}/discussiontopics`)
      .then((response: any) => {
        setTopics(response.data);
      });
  }, []);

  const addTopicData = (topicData: TopicData) => {
    setTopics((oldTopics: TopicData[]) => {
      return [...oldTopics, topicData];
    });
  };

  useEffect(() => {
    const newAdded = topics && topics.find((t: TopicData) => t.new);
    if (newAdded) {
      setTopicSlug(newAdded.slug);
      setnewTopic(false);
    }
  }, [topics && topics.length]);

  useEffect(() => {
    if (topicSlug === '__create__') {
      setTopicSlug('');
      setnewTopic(true);
    }
  }, [topicSlug]);

  useEffect(() => {
    onChange(topicSlug);
  }, [topicSlug]);

  return (
    <div>
      {topics !== null && (
        <Select
          fullWidth
          value={topicSlug}
          onChange={(val) => setTopicSlug(val.toString())}
          name="slug"
          options={[
            {
              label: 'No topic',
              value: '',
            },
            ...topics.map((t: any) => ({
              label: t.title,
              value: t.slug,
            })),
            ...(newTopicTitle
              ? [
                  {
                    label: '[Create a topic...]',
                    value: '__create__',
                  },
                ]
              : []),
          ]}
        />
      )}

      {newTopic && topicSlug === '' && (
        <TopicCreator
          cancel={() => setnewTopic(false)}
          callback={(topicData) => addTopicData(topicData)}
          topicTitle={newTopicTitle} // `${roomInfo.title} - ${roomInfo.programDay.title}`
          conferenceId={conferenceId}
        />
      )}
      {!topicSlug && (
        <FormHelperText error>
          ATT: Connecting a topic is mandatory
        </FormHelperText>
      )}

      {topicSlug &&
        topics &&
        !topics.find((t: any) => t.slug === topicSlug) && (
          <FormHelperText error>
            ERROR: No topic with slug "{topicSlug}"
          </FormHelperText>
        )}
    </div>
  );
};
TopicSelector.displayName = 'TopicSelector';
