import { IProvided, useEventGetters } from '@/hooks/useEventGetters';
import theme from '@/js/themes/coeo_manager';
import { IEvent } from '@/types/event';
import { IProgramRoom } from '@/types/room';
import { makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { EventListItem } from '.';

type Provided = IProvided & {
  event: IEvent;
};

interface IProps {
  list: IEvent[][];
  rooms: IProgramRoom[];
  stepper: Record<'current' | 'finished', string[]>;
  children: (provided: Provided) => ReactNode;
}

const useStyles = makeStyles({
  ul: {
    padding: 0,
    margin: 0,
    marginTop: theme.spacing(2),
    listStyle: 'none',
  },
  li: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const EventList = ({
  list = [],
  rooms,
  stepper = { current: [], finished: [] },
  children,
}: IProps) => {
  const classes = useStyles();
  
  return (
    <ul className={classes.ul}>
      {list.map((events, count) => (
        <li key={count} className={classes.li}>
          {events.map((event, index) => (
            <EventListItem
              key={event.id}
              multiple={events.length > 1}
              isLastEvent={count === list.length - 1}
              isFirstEvent={index === 0}
              eventPassed={stepper.finished.includes(event.id)}
              eventLive={stepper.current.includes(event.id)}
              startTime={event.start_time}
            >
              {children({
                event,
                ...useEventGetters(event, rooms, stepper),
              })}
            </EventListItem>
          ))}
        </li>
      ))}
    </ul>
  );
};
