import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../forum-post/edit';
import {
  Render as ForumPostRender,
  template as forumPostTemplate,
} from '../forum-post';

const template: INodeTemplate = {
  version: 1,
  name: 'Post Title',
  icon: forumPostTemplate.icon,
  type: 'forum-post-widget',
  tag: 'forum',
  content: {
    widgetName: 'Forum post',
    ...forumPostTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(ForumPostRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
