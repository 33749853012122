import {
  EditNodeTemplate,
  IEditNodeTemplateOptions,
} from '@/components/edit-node-template';
import {
  Appender,
  EditChildren,
  NodeContainer,
  NodeDialogProvider,
} from '@/node-editor/components';
import {
  getChildren,
  getRootNode,
  useSelectors,
} from '@/node-editor/store/selectors';
import {
  filterSavedTemplateData,
  fromJsonTemplate,
} from '@/node-editor/utilities';
import { addNodeTemplateService } from '@/page-editor/utilities';
import { INodeTemplate } from '@/types/node';
import { WIDGET_AREAS } from '@/widget-editor/utilities';
import { Icon, IconButton, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { reorderNode, useActions } from '../../node-editor/store/actions';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    padding: theme.spacing(1),
    background: theme.palette.grey[300],
  },
  saveTemplate: {
    opacity: 0.3,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export const Edit = ({ model }: { model: 'page' | 'room' }) => {
  const classes = useStyles();

  const actions = useActions(() => ({
    reorderNode,
  }));

  const selectors = useSelectors(() => ({
    getRootNode,
    getChildren,
  }));

  const templateRef = useRef<{
    open: (initOptions: IEditNodeTemplateOptions) => void;
  } | null>(null);

  const root = selectors.getRootNode();

  const handleSaveAsRootTemplate = (
    { title, description, picture, public: showPublic }: FieldValues,
    conferenceId: string
  ) => {
    const nodeTemplate: Partial<INodeTemplate[]> = [
      ({
        ...selectors.getRootNode(),
        children: selectors.getChildren(root.id),
      } as unknown) as INodeTemplate,
    ] as Partial<INodeTemplate[]>;
    const nodeList = filterSavedTemplateData(
      fromJsonTemplate(nodeTemplate),
      conferenceId
    );

    addNodeTemplateService({
      title,
      description,
      picture,
      public: showPublic,
      type: 'root',
      nodes: nodeList,
    });
  };

  const openSaveAsTemplate = () => {
    templateRef.current.open({
      saveAsTemplate: handleSaveAsRootTemplate,
    });
  };

  // Dont add more widget areas than possible
  const hideAddToRoot =
    model === 'room' &&
    selectors.getChildren(root.id).filter((n) => n.type === 'widget-area')
      .length >= WIDGET_AREAS.length;

  return (
    root && (
      <NodeDialogProvider supports={model}>
        <NodeContainer
          id={root.id}
          className={clsx(classes.root, 'node-root')}
          onDrop={({ source, destination }) =>
            actions.reorderNode(root.id, source.index, destination.index)
          }
        >
          <EditChildren>{selectors.getChildren(root.id)}</EditChildren>
          {!hideAddToRoot && <Appender nodeId={root.id} />}
          {!hideAddToRoot && (
            <IconButton
              onClick={openSaveAsTemplate}
              size="small"
              className={classes.saveTemplate}
            >
              <Icon>bookmark</Icon>
            </IconButton>
          )}
          <EditNodeTemplate ref={templateRef} />
        </NodeContainer>
      </NodeDialogProvider>
    )
  );
};
