import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'User Goals',
  icon: 'emoji_events',
  type: 'visitorgoals',
  tag: 'components',
  content: {
    description: 'Complete your profile!',
    completed: 'Nice job!',
    design: 'vertical',
    info: {
      count: 4,
      text: 'Img, org, city, title',
      url: '',
    },
    follow: {
      count: 4,
      text: 'E-Cards',
      url: '',
    },
    discussion: {
      count: 1,
      text: 'Start discussions',
      url: '',
    },
    message: {
      count: 3,
      text: 'Reply in forum',
      url: '',
    },
    chat: {
      count: 1,
      text: 'Live chat',
      url: '',
    },
    thread: {
      count: 2,
      text: 'Private Chats',
      url: '',
    },
    entityvisits: {
      count: 0,
      text: 'Visit sponsors',
      url: '',
    },
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
