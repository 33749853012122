import { INodeTemplate } from '../../types/node';
import { Edit } from './edit';
import { Render } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Calendar list',
  icon: 'date_range',
  type: 'calendarlist',
  tag: 'components',
  content: {
    design: 'date',
    state: 'planned',
    maxWidth: '1',
    emptyText: 'No current events',
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['page'],
    appender: true,
  },
};

export { template, Render, Edit };
