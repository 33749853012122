import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IProps } from './contract';
import { Select } from './select';

/**
 * SelectControl is an abstraction of MUI FormControl, InputLabel and Select combined with react-hook-form Controller
 *
 * @link https://react-hook-form.com/api/usecontroller/controller
 * @link https://v4.mui.com/api/select/
 */
export const SelectControl = ({
  name,
  defaultValue,
  ...props
}: Omit<IProps, 'onChange' | 'value'>) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Select fullWidth value={value} onChange={onChange} {...props} />
      )}
    />
  );
};
