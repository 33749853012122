import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';
import PollInputVote from '@/js/live/components/interact/PollInputVote';
import C from '../../../shared/constants/general';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      ...override.root,
    }),
    results: {
      float: 'left',
    },
  })
);

const pollTypes = {
  [C.POLL.TYPE.VOTE]: PollInputVote,
};

export const Render = ({
  node: {
    id,
    override,
    content: { answered, pollIds },
  },
  conferenceId,
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState, isModerator } = useContext(AuthContext);
  const [error, setError] = useState<string>('');
  const [poll, setPoll] = useState<any>(null);
  // const [hasAnswered, setHasAnswered] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [pollAnswerData, setPollAnswerData] = useState(null);
  const pollId = pollIds.length > 0 ? pollIds[0] : '';
  const hasAnswered = poll && poll.pollAnswers.length > 0;

  useEffect(() => {
    pollId &&
      axios
        .get(`/api/visitor/answerpolls/${pollId}`)
        .then((response) => {
          setPoll(response.data);
        })
        .catch((e) => {
          setError(e.response.data.message);
        })
        .then(() => {
          setSending(false);
        });
  }, [pollId]);

  const post = () => {
    setSending(true);
    axios
      .post(`/api/visitor/polls/${poll.id}/answer`, { data: pollAnswerData })
      .then((response) => {
        // setHasAnswered(true);
        setPoll((currPoll: any) => ({
          ...currPoll,
          pollAnswers: [response.data],
        }));
      })
      .catch((e) => {
        setError(e.response.data.message);
      })
      .then(() => {
        setSending(false);
      });
  };

  const PollInput = poll && pollTypes[poll.type];

  if (!poll) return null;

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {poll.title && <Typography variant="h6">{poll.title}</Typography>}
      {poll.description && (
        <Typography variant="body1">{poll.description}</Typography>
      )}

      {authState.user ? (
        hasAnswered ? (
          <Box p={1}>
            <Typography variant="body1" align="center">
              {answered}
            </Typography>
          </Box>
        ) : (
          <>
            <Box>
              <PollInput
                pollId={poll.id}
                poll={poll}
                dataCallback={setPollAnswerData}
              />
              <Box textAlign="right">
                <Button
                  disabled={sending || !poll}
                  endIcon={<Icon>send</Icon>}
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={post}
                >
                  post
                </Button>
              </Box>
            </Box>
          </>
        )
      ) : (
        'Need to be logged in'
      )}
      {isModerator && (
        <Box display="inline-block" mr={1} className={classes.results}>
          <Button
            href={`/moderator/pollresults/${poll.id}`}
            target="_blank"
            startIcon={<Icon>launch</Icon>}
            color="secondary"
            variant="contained"
            size="small"
          >
            results
          </Button>
        </Box>
      )}
    </div>
  );
};
