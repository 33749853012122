import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  ButtonBase,
  CircularProgress,
  Grid,
  Icon,
  LinearProgress,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent, IVisitorGoal } from './contract';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';

// import { Link } from 'react-router-dom';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface VisitorGoals {
  infoCount: number;
  followCount: number;
  discussionCount: number;
  messageCount: number;
  chatCount: number;
  threadCount: number;
  entityvisitsCount: number;
}
interface IGoalDraw {
  data: IVisitorGoal;
  count: number;
  url: string;
}

interface IContainerStyleProps {
  override: Record<string, any>;
  design: string;
}

const useGoalStyles = makeStyles<Theme>((theme: Theme) => ({
  line: {
    height: 8,
    borderRadius: 4,
  },
  bar: {
    borderRadius: 4,
  },
}));

const Goal = ({ data, count, url }: IGoalDraw) => {
  const classes = useGoalStyles();
  return (
    <Box>
      <Box display="flex">
        <Box flexGrow={1}>
          <Link color="primary" href={data.url || url}>
            {data.text} &gt;
          </Link>
        </Box>
        <Box>
          {count}/{data.count}
        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        value={Math.min(100, (100 * count) / data.count)}
        classes={{ root: classes.line, bar: classes.bar }}
      />
    </Box>
  );
};

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override, design }) => ({
      width: '100%',
      display: 'flex',
      flexDirection: design === 'horizontal' ? 'row' : 'column',
      alignItems: design === 'horizontal' ? 'flex-start' : 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      ...override.root,
    }),
    item: ({ override }) => ({
      ...override.item,
    }),
    empty: ({ override }) => ({
      ...override.empty,
    }),
    head: {
      textAlign: 'center',
      padding: '8px 16px',
    },
    total: {
      position: 'relative',
      width: 100,
      height: 100,
      margin: 'auto',
    },
    avatar: {
      position: 'absolute',
      inset: 0,
      padding: 10,
    },
    totalholder: {
      position: 'absolute',
      inset: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    totaltext: {
      backgroundColor: '#00000099',
      borderRadius: 10,
      color: '#fff',
      padding: '3px 8px',
      fontSize: 14,
      height: 26,
    },
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: {
      description,
      completed,
      design,
      info,
      follow,
      discussion,
      message,
      chat,
      thread,
      entityvisits,
    },
  },
}: IProps) => {
  const classes = useContainerStyles({
    override,
    design,
  });
  const [goals, setGoals] = useState<IGoalDraw[]>([]);
  const { authState } = useContext(AuthContext);
  // const [ItemDesign] = useState<React.FC<DesignProps>>(designs[design]);

  const reloadData = () => {
    axios
      .get(`/api/visitorgoals`)
      .then((response) => {
        const finished = response.data as VisitorGoals;
        const allGoals: IGoalDraw[] = [
          { data: info, url: '/profile/edit', count: finished.infoCount },
          {
            data: follow,
            url: '/live/participants',
            count: finished.followCount,
          },
          {
            data: discussion,
            url: '/live/discussion',
            count: finished.discussionCount,
          },
          {
            data: message,
            url: '/live/discussion',
            count: finished.messageCount,
          },
          { data: chat, url: '/live', count: finished.chatCount },
          {
            data: thread,
            url: '/live/discussion',
            count: finished.threadCount,
          },
          {
            data: entityvisits,
            url: '/live/expo',
            count: finished.entityvisitsCount,
          },
        ].filter((g) => g.data.count > 0);
        setGoals(allGoals);
      })
      .catch((err) => {
        console.log('Could not loaf goals');
      });
  };

  useEffect(() => {
    reloadData();
  }, []);

  const totalGoal = goals.reduce((tot, g) => tot + g.data.count, 0);
  const totalScore = goals.reduce(
    (tot, g) => tot + Math.min(g.count, g.data.count),
    0
  );
  const percent = totalGoal ? Math.round((100 * totalScore) / totalGoal) : 0;

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      <div className={classes.head}>
        <div className={classes.total}>
          <CircularProgress
            variant="determinate"
            value={percent}
            size={100}
            thickness={3}
          />
          <div className={classes.avatar}>
            <UserAvatar
              customSize={{ width: 80, height: 80, fontSize: 20 }}
              user={authState.user}
            />
          </div>
          <div className={classes.totalholder}>
            <div className={classes.totaltext}>{percent}%</div>
          </div>
        </div>
        {percent === 100 ? completed : description}
      </div>
      <Grid container spacing={1}>
        {goals.length > 0 &&
          goals.map((g, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={design === 'horizontal' ? 6 : 12}
            >
              <Goal {...g} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
