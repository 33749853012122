import { useReducer } from 'react';

export interface Action<T = any> {
    type: T
}

export interface AnyAction extends Action {
    [key: string]: any
}

export type Reducer<S = any, A = AnyAction> = (
    state: S | undefined,
    action: A
) => S

export type Dispatch<A> = (action: A) => void;

export const useReducerWithMiddleware = <S, A = AnyAction >(reducer: Reducer<S>, initialState: S, middleware: (((...any: any[]) => any)[])): [S, Dispatch<A>] => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchWithMiddleware = (action: any) => {

        middleware.forEach((middlewareCallback) => middlewareCallback(state, action))
        dispatch(action);
    }
    
    return [state, dispatchWithMiddleware];
}