import { useContext } from 'react';
import { ManagerConferenceContext } from '@/js/shared/context/ManagerConferenceContext';
import { getColorSwatch } from '@/utilities/colors';

type PaletteColors = {
    primary: string;
    secondary: string;
    background: string;
}

export const useConferencePalette = (context: 'site' | 'live') => {

    const conferenceContext = useContext(ManagerConferenceContext);

    if (conferenceContext == null) {
        throw new Error('useConferencePalette must be used within ManagerConferenceContextProvider.');
    }

    const { conferenceData: { conference } } = conferenceContext;

    const palette: PaletteColors = conference[`theme_${context}`]?.palette;

    const getSwatch = (color: keyof PaletteColors) => getColorSwatch<PaletteColors>(palette, color);

    return {
        getSwatch
    }
}