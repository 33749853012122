import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { MediaPlaceholder } from '@/components/media-placeholder';
import { SettingsPanel } from '@/components/settings-panel';
import { SwitchControl } from '@/components/switch-control';
import { LocalLinkSelector } from '@/js/shared/components/ui/LocalLinkSelector';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { src, link, altText },
  },
  conferenceId,
}: IProps) => {
  const { watch, setValue } = useFormContext();

  const watchTargetUrl = watch('link.targetUrl', link.targetUrl);
  const watchTarget = watch('link.target', link.target);

  useEffect(() => {
    setValue(
      'link.targetRel',
      watchTarget === '_blank' ? 'noreferrer' : undefined
    );
  }, [watchTarget]);

  return (
    <Fragment>
      <SettingsPanel label="Image" initialOpen>
        <Controller
          name="src"
          defaultValue={src}
          render={({ field: { onChange, value } }) => (
            <MediaPlaceholder
              label=""
              onChange={onChange}
              value={value}
              conferenceId={conferenceId}
            />
          )}
        />
        <InputControl
          name="link.targetUrl"
          defaultValue={link.targetUrl}
          fullWidth
          variant="outlined"
          label="URL"
        />
        <div>
          <LocalLinkSelector
            userType="manager"
            conferenceId={conferenceId}
            ButtonProps={{ color: 'primary' }}
            dataCallback={(linkUrl) => setValue('link.targetUrl', linkUrl)}
          />
        </div>
        {watchTargetUrl && (
          <SwitchControl
            name="link.target"
            label="Open link in new tab?"
            defaultValue={link.target}
            options={['_blank', '_self']}
          />
        )}
        <InputControl
          disabled
          type="hidden"
          name="link.targetRel"
          defaultValue={link.targetRel}
        />
      </SettingsPanel>
      <SettingsPanel label="Image Attributes" initialOpen>
        <InputControl
          fullWidth
          name="altText"
          label="Alt text"
          defaultValue={altText}
          variant="outlined"
        />
      </SettingsPanel>
      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
