import { ConditionalRenderer } from '@/components/conditional-renderer';
import { useNodeProps } from '@/node-editor/hooks';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  link: ({ override }) => ({
    display: 'inline-block',
    ...override.link,
  }),
  image: ({ override }) => ({
    display: 'block',
    width: '100%',
    ...override.image,
  }),
}));

export const Render = ({
  node: {
    id,
    content: { link, src, altText },
    override,
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({ override });

  const hasLink = (link: ITextLink): boolean =>
    'targetUrl' in link && link.targetUrl !== '';

  return (
    <ConditionalRenderer
      condition={hasLink(link)}
      render={(children) => (
        <a
          className={classes.link}
          rel={link.targetRel}
          target={link.target}
          href={link.targetUrl}
        >
          {children}
        </a>
      )}
    >
      <img
        {...useNodeProps(id)}
        className={classes.image}
        src={getImage(src, ImageSize.Large, conferenceId)}
        alt={altText}
        width={ImageSize.Large.width}
      />
    </ConditionalRenderer>
  );
};
