import { Grid, makeStyles, Theme } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import { RenderChildren } from '@/node-editor/components';
import { getSpacerValue } from '@/js/shared/utils/format';
import { INode, INodeProps } from '@/types/node';
import { IContent } from './contract';
import { GridSpacing } from '@material-ui/core';
import { useNodeProps } from '@/node-editor/hooks';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
  override: {
      root: CSSProperties
  }
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, Partial<IContent> & { override: Record<string, any>}>((theme) => ({
  container: ({ override, verticalSpacing, alignment: { vertical, horizontal } }) => ({
    justifyContent: horizontal,
    alignItems: vertical,
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    ...override.root
  }),
}));

export const Render = ({
  node: {
    id,
    content: { alignment, horizontalSpacing = '1', verticalSpacing },
    override
  },
  children,
}: IProps) => {

  const classes = useStyles({
    alignment,
    verticalSpacing,
    override
  });

  return (
    <Grid
      container
      spacing={parseInt(horizontalSpacing, 10) as GridSpacing}
      className={classes.container}
      {...useNodeProps(id)}
    >
      <RenderChildren>{children}</RenderChildren>
    </Grid>
  );
};
