import { Icon, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { RichUtils } from 'draft-js';
import clsx from 'clsx';

interface IProps {
    editorState: Draft.EditorState,
    dispatch: (state: Draft.EditorState) => void;
}

const formats = [
  {
    type: 'BOLD',
    icon: 'format_bold',
    label: 'Bold'
  },
  {
    type: 'ITALIC',
    icon: 'format_italic',
    label: 'Italic'
  },
  {
    type: 'UNDERLINE',
    icon: 'format_underlined',
    label: 'Underline'
  },
  {
    type: 'STRIKETHROUGH',
    icon: 'format_strikethrough',
    label: 'Strikethrough'
  },
];

const useStyles = makeStyles((theme) => ({
  formatButton: {
    borderRadius: 0,
  },
  formatButtonFocused: {
    color: '#000',
    backgroundColor: theme.palette.grey[100],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  toolTip: {
    fontSize: 12,
  }
}));

export const FormatControls = ({ editorState, dispatch }: IProps) => {
  const classes = useStyles();

  const currentStyle = editorState.getCurrentInlineStyle();

  const handleClick = (type: string) => {

    dispatch(RichUtils.toggleInlineStyle(editorState, type));
  }

  return (
    <Fragment>
      {formats.map((format) => (
        <Tooltip
          key={format.type}
          title={format.label}
          placement="bottom"
        >
          <IconButton
            className={clsx(
              classes.formatButton,
              currentStyle.has(format.type) && classes.formatButtonFocused
            )}
            onMouseDown={() => handleClick(format.type)}
          >
            <Icon>{format.icon}</Icon>
          </IconButton>
        </Tooltip>
      ))}
    </Fragment>
  );
};