import { FormLabel, Slider } from '@material-ui/core';
import React, { Fragment, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface IMark {
    value: number;
    label?: ReactNode;
};

interface IProps {
    color?: 'primary' | 'secondary';
    defaultValue: number | number[]; 
    label: string;
    min: number;
    max: number;
    marks: boolean | IMark[];
    name: string;
    onChange?: (value: number | number[]) => void;
    step?: number;
}

/**
 * SlideControl is an abstraction of MUI Slider using react-hook-form control
 * 
 * @link https://react-hook-form.com/api/useform/control
 * @link https://v4.mui.com/api/slider
 */
export const SlideControl = ({
    label,
    name,
    step,
    defaultValue,
    onChange: emit,
    ...rest
}: IProps) => {

    const { control } = useFormContext();

    return(
        <Fragment>
            <FormLabel component="legend">{label}</FormLabel>
            <Controller 
                control={control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { onChange, value } }) => (
                    <Slider 
                        value={value}
                        onChange={(_e, value) => {
                            onChange(value);
                            if (typeof emit === 'function') {
                                emit(value as typeof defaultValue)
                            }
                        }}
                        step={step || null}
                        valueLabelDisplay="auto"
                        {...rest}
                    />
                )}
            />
        </Fragment>
    )
}