import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
    bundle: string;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh'
    }
});

export const NotFound = (props: IProps) => {

    const classes = useStyles();

    const goBack = () => {

        if (props.history.length >= 1) {

            return props.history.goBack();
        }

        return props.history.replace(`/${props.bundle}`);
    }

    return(
        <div className={classes.root}>
            <h1>404</h1>
            <h3>Ooops nothing here...</h3>
            <Button 
                onClick={goBack}
            >
                Go Back
            </Button>
        </div>
    )
}