import { INodeTemplate } from '@/types/node';
import { WidgetSettings } from '@/widget-editor/components';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';
import { Settings } from '../markdown/edit';
import { template as extendTemplate } from '../markdown';
import { Render as ExtendRender } from './render';

const template: INodeTemplate = {
  version: 1,
  name: 'Styled Text Widget',
  icon: extendTemplate.icon,
  type: 'widget-markdown',
  tag: 'widgets',
  content: {
    widgetName: 'Styled Text Widget',
    ...extendTemplate.content,
  },
  override: {
    root: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
};

const Render = withWidgetDisplay(ExtendRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Render, Edit };
