import { monthNames } from '@/js/shared/utils/DataFormat';
import { useNodeProps } from '@/node-editor/hooks';
import { getGridNumber } from '@/node-editor/utilities';
import { IDay } from '@/types/day';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  ButtonBase,
  Grid,
  Icon,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import C from '../../../shared/constants/general';
import { IProgramRoom } from '@/types/room';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
  backgroundImage?: string;
}
interface IRoomProps {
  design: string;
  item: any;
  override: Record<string, any>;
}

type DesignProps = {
  design: string;
  item: any;
  override: Record<string, any>;
};

const dropShadow: any = {
  filter: 'drop-shadow(0.2rem 0.2rem 0.4rem rgba(0, 0, 0, 0.2))',
  '&:hover': {
    filter: 'drop-shadow(0.2rem 0.2rem 0.8rem rgba(0, 0, 0, 0.3))',
  },
};

const getProgramRoomInfo = (programRoom: IProgramRoom) => {
  const isFile = programRoom.video_state === C.PROGRAM_ROOM.VIDEO_STATE.FILE;
  const isLive =
    programRoom.video_state === C.PROGRAM_ROOM.VIDEO_STATE.RECORDING &&
    programRoom.video_access === C.PROGRAM_ROOM.ACCESS.OPEN_FOR_VISITORS;

  const url = isFile
    ? `/event/${programRoom.id}` // Byt till direktåterspelning efter Roboten är klar. `/live/session/${programRoomId}`
    : isLive
    ? `/live/room/${programRoom.id}`
    : `/event/${programRoom.id}`;
  return {
    url,
    isFile,
    isLive,
  };
};
//design: 'date' | 'dateimage' | 'image';

const useDateStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    content: ({ override }) => ({
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
      backgroundColor: theme.palette.background.default,
      ...dropShadow,
      // margin: theme.spacing(1),
      ...override,
    }),
    date: {
      backgroundColor: theme.palette.background.shadedown_20,
      padding: 10,
      textAlign: 'center',
      flex: '0 0 85px',
      fontSize: '1.1rem',
      lineHeight: '1.5rem',
      textTransform: 'uppercase',
    },
    dateNr: {
      fontSize: '2rem',
    },
    text: {
      textAlign: 'left',
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    liveText: {
      color: theme.palette.error.main,
    },
    play: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const DesignDate: React.FC<DesignProps> = ({
  item,
  override,
}: IRoomProps): JSX.Element => {
  const classes = useDateStyles({
    override,
  });
  const [year, month, date] = (item.programDay.date as string)
    .split('-')
    .map<number>((t: string) => parseInt(t));
  const { url, isFile, isLive } = getProgramRoomInfo(item);

  return (
    <ButtonBase className={classes.content} href={url}>
      <Typography variant="h6" className={classes.date}>
        <div className={classes.dateNr}>{date}</div>
        {monthNames[month - 1]}
      </Typography>
      <div className={classes.text}>
        <Typography variant="h6">
          {isLive && <span className={classes.liveText}>LIVE </span>}
          {item.headline || item.title}
        </Typography>
        <Typography variant="body2">{item.description_short}</Typography>
      </div>
      {isFile && (
        <div className={classes.play}>
          <Icon>play_arrow</Icon>
        </div>
      )}
    </ButtonBase>
  );
};

const useImageDateStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    content: ({ override }) => ({
      backgroundColor: '#333',
      overflow: 'hidden',
      height: 0,
      width: '100%',
      paddingTop: '56.25%',
      position: 'relative',
      filter: 'drop-shadow(0.2rem 0.2rem 0.6rem rgba(0, 0, 0, 0.3))',
      ...dropShadow,
      ...override,
    }),
    image: ({ backgroundImage }) => ({
      position: 'absolute',
      inset: 0,
      display: 'flex',
      alignItems: 'flex-end',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url(${backgroundImage})`,
    }),
    overlay: {
      position: 'absolute',
      inset: 0,
      // backgroundColor: '#00000044',
      // '&:hover': {
      //   display: 'none',
      // },
      backgroundImage:
        'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5))',
    },
    date: {
      fontSize: '1.1rem',
      textTransform: 'uppercase',
    },
    text: {
      position: 'relative',
      textAlign: 'left',
      color: '#fff',
      padding: theme.spacing(1),
      fontSize: '2rem',
    },
    liveText: {
      color: theme.palette.error.main,
    },
    play: {
      position: 'absolute',
      inset: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    playIcon: {
      backgroundColor: '#00000055',
      fontSize: '3rem',
      color: '#fff',
      borderRadius: '100%',
    },
  })
);

const DesignImageDate: React.FC<DesignProps> = ({
  design,
  item,
  override,
}: IRoomProps): JSX.Element => {
  const classes = useImageDateStyles({
    override,
    backgroundImage: getImage(
      item.bg_picture || `/coeo/room_bg_${item.order % 5}.jpg`,
      ImageSize.Medium
    ),
  });
  const [year, month, date] = (item.programDay.date as string)
    .split('-')
    .map<number>((t: string) => parseInt(t));
  const { url, isFile, isLive } = getProgramRoomInfo(item);
  return (
    <ButtonBase className={classes.content} href={url}>
      <div className={classes.image}>
        {design === 'dateimage' && (
          <>
            <div className={classes.overlay} />
            <div className={classes.text}>
              <Typography variant="h6">
                {isLive && <span className={classes.liveText}>LIVE </span>}
                {item.headline || item.title}
              </Typography>

              <Typography variant="body2">
                {monthNames[month - 1]} {date}{' '}
                {item.description_short && `| ${item.description_short}`}
              </Typography>
            </div>
          </>
        )}
        {isFile && (
          <div className={classes.play}>
            <Icon className={classes.playIcon}>play_arrow</Icon>
          </div>
        )}
      </div>
    </ButtonBase>
  );
};

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      ...override.root,
    }),
    item: ({ override }) => ({
      ...override.item,
    }),
    empty: ({ override }) => ({
      ...override.empty,
    }),
  })
);

interface DesignTypes {
  [design: string]: React.FC<DesignProps>;
}
const designs: DesignTypes = {
  date: DesignDate,
  dateimage: DesignImageDate,
  image: DesignImageDate,
};

export const Render = ({
  node: {
    id,
    override,
    content: { design, state, maxWidth, emptyText },
  },
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const [loaded, setLoaded] = useState<boolean>(false);
  const [rooms, setRooms] = useState<any[]>([]);
  const ItemDesign = designs[design];
  // const [ItemDesign] = useState<React.FC<DesignProps>>(designs[design]);

  const reloadRooms = () => {
    axios
      .get(
        `/api/visitor/conferences/${
          (window as any).CONFERENCE_ID
        }/calendarlist?state=${state}`
      )
      .then((response) => {
        setRooms(response.data);
      })
      .catch((err) => {
        console.log('Loading calendar rooms', err);
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  useEffect(() => {
    reloadRooms();
  }, []);

  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-start"
      justifyContent="center"
      className={classes.root}
      {...useNodeProps(id)}
    >
      {!loaded ? (
        <Grid
          xs={12}
          item
          md={getGridNumber(maxWidth)}
          className={classes.item}
        >
          <Skeleton variant="text" width={'100%'} height={118} />
        </Grid>
      ) : loaded && rooms.length === 0 ? (
        <Grid item className={classes.empty}>
          {emptyText}
        </Grid>
      ) : (
        rooms.map((room: any) => (
          <Grid
            item
            key={room.id}
            xs={12}
            md={getGridNumber(maxWidth)}
            className={classes.item}
          >
            <ItemDesign
              design={design}
              item={room}
              override={override.content}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};
