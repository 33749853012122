import { SiteHeader } from '@/components/site-header';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Render = ({
  node: {
    id,
    content: {
      backgroundImage,
      displayTitle,
      displaySubtitle,
      logo,
      maxHeight,
      subTitle,
      title,
    },
  },
  conferenceId,
}: IProps) => {
  const {
    conferenceState: { conferenceDetail },
  } = useContext(ConferenceContext);

  return (
    <SiteHeader
      {...useNodeProps(id)}
      maxHeight={maxHeight}
      backdropSrc={getImage(
        backgroundImage != null ? backgroundImage : conferenceDetail.image,
        ImageSize.Original,
        conferenceId
      )}
      title={
        displayTitle &&
        (logo ? (
          <img
            src={getImage(logo, ImageSize.Medium)}
            alt={Boolean(title) ? title : conferenceDetail.title}
          />
        ) : (
          <Typography align="center" variant="h2">
            {Boolean(title) ? title : conferenceDetail.title}
          </Typography>
        ))
      }
      subTitle={
        displaySubtitle && (
          <Typography align="center" variant="h6">
            {subTitle}
          </Typography>
        )
      }
    />
  );
};
