import { Box, Icon, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  field: {
    borderRadius: 15,
    overflow: 'hidden',
  },
  bar: {
    display: 'flex',
    flexDirection: 'column',
  },
  barinfo: {
    height: 12,
    paddingTop: 3,
    fontSize: '0.7rem',
    opacity: 0.6,
  },
  searchBar: {
    paddingTop: 6,
  },
  iconHolder: {
    marginTop: '0!important',
    opacity: 0.7,
    marginLeft: -4,
  },
}));

const SearchBar = (props) => {
  const classes = useStyles();
  const [focusState, setFocus] = useState(false);
  const setKeyword = (keyword) => {
    //Pass keyword to parent
    props.onSearch(keyword);
  };

  useEffect(() => {
    props.onFocusChange && props.onFocusChange();
  }, [focusState]);

  return (
    <Box className={classes.bar}>
      <TextField
        variant="filled"
        size="small"
        margin="none"
        value={props.keyword}
        className={classes.field}
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        InputProps={{
          classes: { input: classes.searchBar },
          startAdornment: (
            <InputAdornment
              position="start"
              margin="none"
              classes={{ filled: classes.iconHolder }}
            >
              <Icon>search</Icon>
            </InputAdornment>
          ),
        }}
      />
      {focusState == true && (
        <Box className={classes.barinfo}>
          Search by Name, Org, City, Tags, etc
        </Box>
      )}
      {focusState == false && <Box className={classes.barinfo}></Box>}
    </Box>
  );
};

export default SearchBar;
