import { api } from '@/js/manager/utils/api';
import { INode, TNodes, TSavedNodeTamplates } from '@/types/node';
import { IPage, IPageTemplate } from '@/types/page';
import { AxiosResponse } from 'axios';

export type Actions = {
  loading: boolean;
  loadPages: () => Promise<void>;
  createPage: (template: IPageTemplate) => Promise<IPage>;
  updatePage: (page: IPage, nodes: TNodes) => Promise<void>;
  deletePage: (pageId: string) => Promise<void>;
};

export interface IStoredNodeTemplate {
  id?: string;
  title: string;
  description: string;
  picture: string;
  type: string;
  nodes?: { nodes?: TSavedNodeTamplates };
  public: boolean;
}

/**
 *
 * @param conferenceId
 * @returns
 */
export const fetchPagesService = (
  conferenceId: string
): Promise<AxiosResponse<IPage[]>> =>
  api.get(`/conferences/${conferenceId}/pages`);

/**
 *
 * @param conferenceId
 * @returns Record<string, Partial<IPage>>
 */
export const fetchPagesMapService = (
  conferenceId: string
): Promise<
  AxiosResponse<Record<string, Pick<IPage, 'id' | 'slug' | 'locked'>>>
> => api.get(`/conferences/${conferenceId}/pagesmap`);

/**
 *
 * @param conferenceId
 * @param pageId
 * @returns
 */
export const fetchPageByIdService = (
  conferenceId: string,
  pageId: string
): Promise<AxiosResponse<IPage>> =>
  api.get(`/conferences/${conferenceId}/pages/${pageId}`);

/**
 *
 * @param conferenceId
 * @param slug
 * @returns
 */
export const fetchPageBySlugService = (
  conferenceId: string,
  slug: string
): Promise<AxiosResponse<IPage>> =>
  api.get(`/visitor/conferences/${conferenceId}/pages/?slug=${slug}`);

/**
 *
 * @param conferenceId
 * @param data
 * @returns
 */
export const addPageService = (
  conferenceId: string,
  data?: Partial<IPage>
): Promise<AxiosResponse<IPage>> =>
  api.post(`/conferences/${conferenceId}/pages`, data);

/**
 *
 * @param conferenceId
 * @param pageId
 * @param data
 * @returns
 */
export const duplicatePageService = (
  conferenceId: string,
  pageId: string,
  data?: Partial<IPageTemplate>
): Promise<AxiosResponse<IPage>> =>
  api.post(`/conferences/${conferenceId}/pages/${pageId}`, data);

/**
 *
 * @param conferenceId
 * @param page
 * @param nodes
 * @returns
 */
export const updatePageService = (
  conferenceId: string,
  page: IPage,
  nodes: TNodes
): Promise<AxiosResponse<IPage>> =>
  api.put(`conferences/${conferenceId}/pages/${page.id}`, { page, nodes });

/**
 *
 * @param conferenceId
 * @param pageId
 * @returns
 */
export const deletePageService = (conferenceId: string, pageId: string) =>
  api.delete(`/conferences/${conferenceId}/pages/${pageId}`);

/**
 *
 * @param templateData
 * @returns
 */
export const addNodeTemplateService = (
  templateData: IStoredNodeTemplate
): Promise<AxiosResponse<any>> => api.post(`/nodetemplates`, templateData);

/**
 *
 * @param templateData
 * @returns
 */
export const updateNodeTemplateService = (
  templateData: IStoredNodeTemplate
): Promise<AxiosResponse<any>> =>
  api.put(`/nodetemplates/${templateData.id}`, templateData);

/**
 *
 * @param templateId
 * @returns
 */
export const deleteNodeTemplateService = (
  templateId: string
): Promise<AxiosResponse<any>> => api.delete(`/nodetemplates/${templateId}`);

/**
 *
 * @returns
 */
export const fetchNodeTemplatesService = (): Promise<AxiosResponse<any>> =>
  api.get(`/nodetemplates`);
