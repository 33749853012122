import { ForumPostSelector } from '@/components/forumpost-selector';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SettingsPanel } from '@/components/settings-panel';
import { TopicSelector } from '@/components/topic-selector';
import { ManagerMarkdownEditor } from '@/js/shared/components/markdown/ManagerMarkdownEditor';
import { ManagerConferenceContext } from '@/js/shared/context/ManagerConferenceContext';
import { getConferenceUrl } from '@/js/shared/utils/DataFormat';
import { SettingsContainer } from '@/node-editor/components';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Button, Icon } from '@material-ui/core';

import React, { FC, Fragment, useContext, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { message, uploadText, webcamText, received, discussionId = '' },
  },
  conferenceId,
}: IProps) => {
  const { conferenceData } = useContext(ManagerConferenceContext);
  const { watch, setValue } = useFormContext();
  const watchId = watch('discussionId', discussionId);
  return (
    <Fragment>
      <SettingsPanel label="Settings" initialOpen>
        <InputControl
          fullWidth
          name="uploadText"
          label="Button text (empty to hide)"
          defaultValue={uploadText}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="webcamText"
          label="Webcam text (empty to hide)"
          defaultValue={webcamText}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="message"
          label="Message text (empty to hide)"
          defaultValue={message}
          variant="outlined"
        />
        <br />
        <InputControl
          fullWidth
          name="received"
          label="Text when sent"
          defaultValue={received}
          variant="outlined"
        />
        <br />
        Select a discussion post first
        <ForumPostSelector
          onChange={(newVal) => setValue('discussionId', newVal)}
          conferenceId={conferenceId}
          value={discussionId}
        />
        {watchId && (
          <Button
            href={`${getConferenceUrl(
              conferenceData.conference
            )}/live/discussionimages/${watchId}`}
            target="blank"
            startIcon={<Icon>burst_mode</Icon>}
          >
            View image slideshow
          </Button>
        )}
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};
export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
