import { Box, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown, { uriTransformer } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeSanitize from 'rehype-sanitize';
import { getImage, ImageSize } from '../../../../../shared/utils/imageFormat';
import { isLocalUrl } from '../../utils/DataFormat';
import Mustache from 'mustache';

export interface IProps {
  text: string;
  templateData?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    maxWidth: '100%',
  },
  content: {
    '& blockquote': {
      borderWidth: 0,
      borderLeftWidth: 8,
      borderStyle: 'solid',
      backgroundColor: theme.palette.background.shadedown_10,
      borderColor: theme.palette.background.shadedown_20,
      margin: 0,
      padding: '1px 10px',
    },
    '& pre': {
      backgroundColor: theme.palette.background.shadedown_10,
      padding: 4,
    },
    '& table': {
      margin: '8px 0',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.background.shadedown_20,
      borderCollapse: 'collapse',
      //   tableLayout: 'fixed',
    },
    '& th': {
      backgroundColor: theme.palette.background.shadedown_20,
      padding: 3,
    },
    '& td': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.background.shadedown_20,
      padding: 3,
    },
    '& .task-list-item': {
      listStyleType: 'none',
      marginLeft: -20,
    },
  },
}));

// const img_default_width = 600;
// const coeoImageStart = `https://${process.env.STATIC_IMAGES}`;
// Only allow coeo images
const userInputImageUri = (src: string, alt: string, title: string) => {
  if (src.indexOf('/') >= 0) {
    return '/images/no_external_images.png';
  } else {
    return uriTransformer(getImage(src, ImageSize.Default));
  }
};

const headerMap = (props: any) => {
  return <Typography variant={props.node.tagName} {...props} />;
};
export const MarkdownRender = React.memo(
  ({ text, templateData }: IProps) => {
    const classes = useStyles();

    const configs = {
      trusted: {
        linkTarget: '_blank',
      },
      userInput: {
        linkTarget: '_blank',
        components: {
          a: ({ href, target, ...props }) => {
            return (
              <Link
                target={isLocalUrl(href) ? '_self' : '_blank'}
                href={href}
                {...props}
              />
            );
          },
          img: (props: any) => <img className={classes.image} {...props} />,
          h1: headerMap,
          h2: headerMap,
          h3: headerMap,
          h4: headerMap,
          h5: headerMap,
          h6: headerMap,
        },
      },
    };
    let renderText;
    if (templateData) {
      try {
        renderText = Mustache.render(text, templateData);
      } catch (e) {
        renderText = text;
      }
    } else {
      renderText = text;
    }
    return (
      <ReactMarkdown
        children={renderText}
        className={classes.content}
        linkTarget="_blank" // (href, children, title) => string
        // style={{ minHeight: '500px' }}
        components={configs['userInput'].components}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        rehypePlugins={[rehypeSanitize]}
        transformImageUri={userInputImageUri}
        imageAccept={'.jpg,.jpeg,.png'}
        // allowedElements={['p', 'strong', 'em']}
      />
    );
  },
  (prevProps, nextProps) => prevProps.text === nextProps.text
);

MarkdownRender.displayName = 'MarkdownRender';
