import { Button, Icon } from '@material-ui/core';
import React from 'react';
import { NodeDialogContext } from './provider';

interface IProps {
  nodeId: string;
}

export const Appender = ({ nodeId }: IProps) => {
  return (
    <NodeDialogContext.Consumer>
      {({ openAppender }) => (
        <Button
          fullWidth
          disableElevation
          size="small"
          title="open node selection"
          onClick={() => openAppender(nodeId)}
        >
          <Icon>add_circle_outlined</Icon>
        </Button>
      )}
    </NodeDialogContext.Consumer>
  );
};
