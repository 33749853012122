import { getImage, ImageSize } from '../../shared/utils/imageFormat';

interface IProps {
  srcToken: string;
  playbackId: string;
  posterToken?: string;
  posterUrl?: string;
  access?: 'public' | 'signed' | null;
}

/**
 *
 * Constructs mux strem source and poster image url
 * @returns Object video type string, video src string, video poster string
 */
export const useMuxSrcConstructor = ({
  srcToken,
  playbackId,
  posterToken,
  posterUrl,
  access = 'signed',
}: IProps) => {
  console.log('useMuxSrcConstructor', {
    srcToken,
    playbackId,
    posterToken,
    posterUrl,
    access,
  });
  let poster = null;
  if (!playbackId) {
    return;
  }

  if (posterToken) {
    poster = posterToken
      ? access === 'public'
        ? `https://image.mux.com/${playbackId}/thumbnail.jpg`
        : `https://image.mux.com/${playbackId}/thumbnail.jpg?token=${posterToken}`
      : getImage('/misc/black_shade_bg.jpg', ImageSize.Medium);
  } else if (posterUrl) {
    poster = posterUrl
      ? posterUrl
      : getImage('/misc/black_shade_bg.jpg', ImageSize.Medium);
  } else {
    poster = getImage('/misc/black_shade_bg.jpg', ImageSize.Medium);
  }

  return {
    type: 'application/x-mpegURL',
    src: !srcToken
      ? `https://stream.mux.com/${playbackId}.m3u8`
      : `https://stream.mux.com/${playbackId}.m3u8?token=${srcToken}`,
    poster: poster,
  };
};
