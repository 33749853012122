import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { INode, INodeProps } from '@/types/node';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'html'> & { override: Record<string, any> }
>((theme) => ({
  embedContainer: ({ alignment, verticalSpacing, override }) => ({
    width: '100%',
    textAlign:
      alignment.horizontal === 'flex-start'
        ? 'left'
        : alignment.horizontal === 'flex-end'
        ? 'right'
        : alignment.horizontal,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    whiteSpace: 'pre-wrap',
    '& iframe': {
      width: '100%',
    },
    ...override.root,
  }),
}));

/**
 * Embed Node description...
 */
export const Render = ({
  node: {
    id,
    override,
    content: { html, alignment, verticalSpacing },
  },
}: IProps) => {
  const classes = useStyles({ alignment, verticalSpacing, override });

  const getHtml = () => {
    return { __html: html };
  };

  return (
    <div
      className={classes.embedContainer}
      dangerouslySetInnerHTML={getHtml()}
      {...useNodeProps(id)}
    ></div>
  );
};
