import {
  useActions,
  updateNode,
  removeNode,
} from '@/node-editor/store/actions';
import { getTemplate } from '@/node-editor/utilities';
import { NodeContentError } from '@/node-editor/validation';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface IProps {
  error: NodeContentError;
  reset: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  button: {
    marginLeft: 'auto',
  },
}));

export const NodeWarning: FC<IProps> = ({ error, reset, children }) => {
  const classes = useStyles();

  const actions = useActions(() => ({
    removeNode,
    updateNode,
  }));

  const handleDelete = () => {
    actions.removeNode(error.getNode());
    reset();
  };

  const handleReset = () => {
    console.log('error.getNode()', error.getNode());
    console.log('reset', getTemplate(error.getNode().type).content);

    actions.updateNode({
      ...error.getNode(),
      content: {
        ...getTemplate(error.getNode().type).content,
      },
    });

    reset();
  };

  const handleClick = () => {
    if (error.name === 'NodeContentError') {
      return handleReset();
    }

    return handleDelete();
  };

  return (
    <div className={classes.container}>
      <Typography color="error" variant="caption">
        {children}
      </Typography>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        {error.name === 'NodeContentError' ? 'Reset Content' : 'Remove Node'}
      </Button>
    </div>
  );
};
