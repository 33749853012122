// @ts-nocheck
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  InputLabel,
  Link,
  NativeSelect,
  TextField,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import clsx from 'clsx';
import queryString from 'query-string';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import COUNTRIES from '../../../shared/constants/countries';
import C from '../../../shared/constants/general';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import LoginBox from '@/js/shared/components/loginbox';
import CoChip from '@/js/shared/components/ui/CoChip';
import DropZone from '@/js/shared/components/ui/DropZone';
import { RegisterCustomFields } from '@/js/shared/components/ui/RegisterCustomFields';
import VisitorContainer from '@/js/shared/components/VisitorContainer';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { UserContext } from '@/js/shared/context/UserContext';
import { lineBreakToBR } from '@/js/shared/utils/StringFormat';
import { useNodeProps } from '@/node-editor/hooks';
import { useLanguage } from '@/js/shared/utils/useLanguage';
import { UserAgreement } from '@/js/shared/components/useragreement/UserAgreement';

// const userTermsText = `Alla personuppgifter, interaktioner och individkopplad data som skapas inom tjänsten kommer att totalt raderas två veckor efter eventet.
//
// Vi använder Google Analitics för att lära oss mer om vilka typer av mobiler och datorer som används, för att kunna förbättra tjänsten, men har ingen koppling till personuppgifter.
//
// All inspelad video samt hantering av andra uppgifter i samband med biljetthanteringen går under arrangörens privacy agreement.`;

const useStyles = makeStyles((theme) => ({
  root: ({ root }) => ({
    ...root,
  }),
  field: {
    // backgroundColor: '#222',
    width: '100%',
    '& input::placeholder': {
      opacity: 0.1,
    },
  },
  selectField: {
    padding: 10,
  },
  fieldVerifyHolder: {
    // backgroundColor: '#222',
    width: 200,
  },
  fieldVerify: {
    textAlign: 'center',
    fontSize: '3.5rem',
  },
  inputFieldVerify: {
    textAlign: 'center',
  },
  mainIcon: {
    verticalAlign: 'bottom',
  },
  uploadprogress: {
    position: 'absolute',
    left: 33,
    top: 33,
  },

  businessCardBg: {
    opacity: 0.3,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url(/images/ecard-bg.jpg)',
  },
  businessCard: {
    position: 'relative',
    borderTopColor: '#ffffff22',
    borderLeftColor: '#ffffff22',
    borderRightColor: '#00000022',
    borderBottomColor: '#00000022',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 5,
    width: '100%',
    maxWidth: 340,
  },
  eCardIcon: {
    verticalAlign: 'bottom',
  },
  businessCardText: {},
  businessCardTextSmall: {
    fontSize: 10,
    overflow: 'hidden',
    overflowWrap: 'anywhere',
  },
  cardTitle: {
    opacity: 0.7,
    textTransform: 'uppercase',
    paddingRight: 10,
  },
  cardLocation: {
    opacity: 0.5,
  },
  avatar: {
    width: 110,
    height: 110,
    backgroundColor: '#000',
    opacity: 0.2,
    marginRight: 10,
  },
  fullOpacity: {
    opacity: 1,
  },
  avatarHolder: {
    width: 120,
    height: 140,
    position: 'relative',
  },
  avatarDrop: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  chooseAvatarLink: {
    color: theme.palette.text.primary,
  },
  avatarLink: {
    width: '100%',
    maxWidth: 340,
  },
  listAvatarLink: {
    marginRight: 16,
  },
  listAvatar: {
    width: 60,
    height: 60,
  },
  listAvatarHolder: {
    overflowX: 'auto',
  },
  checkbox: {
    color: theme.palette.text.primary,
  },
  emailCheckbox: {
    fontSize: 12,
    height: '100%',
  },
  // userAgreementDialog: {
  //   margin: '0 -16px',
  // },
  // userAgreementBg: {
  //   backgroundColor: theme.palette.background.default,
  // },
}));

const DrawTags = React.memo(
  (props) => {
    return (
      <Grid item xs={12}>
        <Box pt={1} align="center">
          <Typography variant="body1" gutterBottom>
            {props.textTagSelect}
          </Typography>
          {props.conferenceTags.map((tag) => (
            <CoChip
              theme="dark"
              onClick={() => props.toggleTag(tag.id)}
              variant="default"
              selected={tag.selected}
              key={tag.id}
              tagtype={tag.type}
              label={tag.title}
            />
          ))}
        </Box>
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.conferenceTags.map((tag) => tag.selected).join(',') ===
      nextProps.conferenceTags.map((tag) => tag.selected).join(',')
    );
  }
);

const CountrySelect = React.memo(
  (props) => {
    return (
      <FormControl className={props.classes.field} variant="outlined">
        <InputLabel htmlFor="location_countrycode">
          {props.lang({
            EN: 'Country',
            SE: 'Land',
          })}
        </InputLabel>
        <NativeSelect
          name="location_countrycode"
          onChange={props.handleSelectChange}
          defaultValue={props.value}
          className={props.classes.field}
          inputProps={{
            className: props.classes.selectField,
            name: 'location_countrycode',
            id: 'location_countrycode',
          }}
        >
          {Object.keys(COUNTRIES.COUNTRIES).map((code) => (
            <option key={code} value={code}>
              {COUNTRIES.COUNTRIES[code]}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.redrawState === nextProps.redrawState
    );
  }
);

const langCodesMap = {
  SV: 'SE',
  EN: 'EN',
};
const mapLanguageCodes = (langCode) => langCodesMap[langCode];

export const Render = (props) => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const classes = useStyles({ ...props.override });
  const { userState, actions } = useContext(UserContext); // Hämta Context
  const { authState } = useContext(AuthContext); // Hämta Context
  const { conferenceState, actions: conferenceActions } = useContext(
    ConferenceContext
  ); // Hämta Context

  const lang = useLanguage(
    props.node.content.countryCode ||
      mapLanguageCodes(conferenceState.conferenceDetail.lang_iso || 'SV')
  );

  const [invitationCode, setInvitationCode] = useState(query.invite);
  const [picture, setPicture] = useState(null);
  const [redrawState, setRedrawState] = useState(0); // Used to force redraw
  const [ticketError, setTicketError] = useState(null);
  const [tags, setTags] = useState([]);
  const [conferenceTags, setConferenceTags] = useState([]);
  // const [userAgreementDialog, setUserAgreementDialog] = useState({
  //   open: false,
  //   agreement: '',
  // });
  const [showAvatars, setShowAvatars] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [showEmailAndPassword, setShowEmailAndPassword] = useState(
    conferenceState.conferenceDetail.registration_type !==
      C.CONFERENCE.REG_TYPE.ANONYMOUS
  );

  const hidden = props.node.content.hiddenFields || {}; // Hidden fields (default no hidden fields)

  const loaded = invitationCode
    ? !userState.invitationError && userState.invitationLoaded
    : conferenceState.conferenceDetailLoaded;
  const conferenceData =
    loaded &&
    (invitationCode
      ? userState.invitation.conference
      : conferenceState.conferenceDetail);

  const anonymous =
    conferenceState.conferenceDetail.registration_type ===
      C.CONFERENCE.REG_TYPE.ANONYMOUS && !invitationCode;
  const anonymousButWantToCreateAccount = anonymous && showEmailAndPassword;

  const schema = useMemo(() => {
    return yup
      .object()
      .shape({
        first_name: yup.string().required(),
        last_name: yup.string().required(),
        ...(anonymous
          ? {}
          : conferenceData.allow_auto_login
          ? {
              email: yup.string().email().required(),
            }
          : {
              email: yup.string().email().required(),
              password: yup.string().min(7).max(25).required(),
            }),
        agreed: yup.boolean().oneOf([true]).required(),
      })
      .required();
  }, [conferenceData && conferenceData.allow_auto_login]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(schema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        agreed: false,
        first_name: '',
        picture: null,
        location_countrycode: 'SE',
        settings_show_email: true,
        ...(props.participantAt && {
          ticket_type:
            props.participantAt === 'irl'
              ? C.VISITOR.TICKET.IRL
              : C.VISITOR.TICKET.ONLINE,
        }),
      },
    },
    [conferenceData && conferenceData.allow_auto_login]
  );
  const values = getValues();

  register('picture', {
    type: 'text',
  });
  register('location_countrycode', {
    type: 'text',
  });
  register('settings_show_email', {});

  if (!invitationCode) {
    register('ticket_type', {
      type: 'text',
    });
  }

  const onSubmit = () => {
    if (invitationCode) {
      actions.register(invitationCode, { form: getValues(), tags: tags });
    } else if (
      conferenceState.conferenceDetail.registration_type ===
      C.CONFERENCE.REG_TYPE.VERIFY
    ) {
      actions.registerVerify(window.CONFERENCE_ID, {
        form: getValues(),
        tags: tags,
      });
    } else if (
      conferenceState.conferenceDetail.registration_type ===
        C.CONFERENCE.REG_TYPE.OPEN ||
      conferenceState.conferenceDetail.registration_type ===
        C.CONFERENCE.REG_TYPE.ANONYMOUS
    ) {
      actions.registerOpen(window.CONFERENCE_ID, {
        form: getValues(),
        tags: tags,
      });
    }
  };
  // const toggleUserAgreement = (open) => {
  //   setUserAgreementDialog({
  //     open: open,
  //     agreement: lineBreakToBR(
  //       conferenceData.privacy_agreement + userTermsText
  //     ),
  //   });
  // };

  const uploadStart = () => {
    setUploading(true);
  };
  const uploadHandler = (fileInfo) => {
    setPicture(fileInfo.generated);
    setValue('picture', fileInfo.generated);
  };
  const avatarImageloaded = () => {
    setUploading(false);
  };
  const toggleTag = (tagId) => {
    const tagIndex = tags.findIndex((tag) => tag === tagId);

    if (tagIndex === -1) {
      setTags([...tags, tagId]);
    } else {
      const newTags = [...tags];
      newTags.splice(tagIndex, 1);
      setTags(newTags);
    }
  };

  const loadTicket = () => {
    if (
      getValues().email.length === 0 ||
      getValues().invitation_code.length === 0
    ) {
      setTicketError({
        type: 'emptyfileds',
        message: 'Both fields are required',
      });
      return;
    }

    axios
      .post(`/api/visitor/findcode`, {
        email: getValues().email,
        invitation_code: getValues().invitation_code,
      })
      .then(() => {
        setInvitationCode(getValues().invitation_code);
      })
      .catch((error) => {
        setTicketError(error.response.data);
      });
  };

  const acceptInvitation = () => {
    actions.acceptInvitation(invitationCode);
  };

  const handleSelectChange = (ev) => {
    setValue('location_countrycode', ev.target.value + '');
  };

  const forceReRender = () => {
    setRedrawState((prevRedrawState) => prevRedrawState + 1);
  };

  // Sometimes "onchange" doesn't fire, so also trigger on ENTER
  const handleCodeChangeEnter = (event) => {
    if (
      (event.charCode === 13 || event.keyCode === 13) &&
      event.target.value.length === 5
    ) {
      actions.verifyEmailWithCode(
        userState.user.id,
        event.target.value,
        window.CONFERENCE_ID
      );
    }
  };

  const handleCodeChange = (event) => {
    if (event.target.value.length === 5) {
      actions.verifyEmailWithCode(
        userState.user.id,
        event.target.value,
        window.CONFERENCE_ID
      );
    }
  };

  const chooseAvatar = (e, avatar) => {
    e.preventDefault();
    setPicture(avatar);
    setValue('picture', avatar);
    setShowAvatars(false);
  };
  useEffect(() => {
    if (invitationCode) {
      actions.loadInvitation(invitationCode);
    } else {
      conferenceActions.loadConferenceDetail(window.CONFERENCE_NAME, true);
    }
  }, [invitationCode]);

  useEffect(() => {
    if (userState.invitationLoaded && !userState.invitationError) {
      reset({
        first_name: userState.invitation.first_name,
        last_name: userState.invitation.last_name,
        location_countrycode:
          userState.invitation.location_countrycode &&
          userState.invitation.location_countrycode.length == 2
            ? userState.invitation.location_countrycode
            : 'SE',
        location_city: userState.invitation.location_city,
        email: userState.invitation.email,
        info_description: userState.invitation.info_description,
        phone: userState.invitation.phone,
        title: userState.invitation.title,
        company: userState.invitation.company,
        settings_show_email: true,
        picture: userState.invitation.picture,
        ...(userState.invitation.registration_data
          ? { custom: userState.invitation.registration_data }
          : {}),
      });
      setRedrawState((prevRedrawState) => prevRedrawState + 1);
      if (userState.invitation.picture) {
        setPicture(userState.invitation.picture);
      }
    }
  }, [userState.invitationLoaded]);

  if (
    loaded &&
    userState.registerSuccess &&
    userState.user.status === C.USER.STATUS.VERIFIED
  ) {
    window.location.href = '/';
  }

  useEffect(() => {
    if (loaded) {
      const selectedTags = conferenceData.tags.map((tag) => {
        return { ...tag, selected: tags.includes(tag.id) };
      });
      // selectedTags.co((a, b) => a.type.localeCompare(b.type)).reverse();
      setConferenceTags([
        ...selectedTags.filter((t) => t.type === 'skill'),
        ...selectedTags.filter((t) => t.type === 'scouting'),
      ]);
    }
  }, [tags.length, loaded]);

  const get_UserExist_NotLoggedIn_NotRegistered = () => {
    return (
      <Box align="center" py={2}>
        You have a user registered for the invited email, please login before
        accepting.
        <br />
        <LoginBox email={userState.invitation.visitor.email} />
      </Box>
    );
  };

  const get_UserExist_NotLoggedIn_OpenRegistration = () => {
    return (
      <Box align="center" py={2}>
        <Box color="error.main">
          {lang({
            EN:
              'You seem to already be registered for this event, please login.',
            SE: 'Du verkar redan registrerad till detta, testa logga in.',
          })}
        </Box>
        <br />
        <LoginBox email={values.email} />
      </Box>
    );
  };
  const get_LoggedIn_Registered = () => {
    return (
      <Box align="center" py={2}>
        {lang({
          EN: 'You are registered',
          SE: 'Du är registrerad',
        })}
        <br />
        <Link href="/">
          {lang({
            EN: 'Back to start',
            SE: 'Tillbaks',
          })}
        </Link>
      </Box>
    );
  };
  const get_Invitation_Not_Found = () => {
    return (
      <Box align="center" py={2}>
        {lang({
          EN: 'Could not find any registration information.',
          SE: 'Kunde inte hitta information om den här registreringen.',
        })}
      </Box>
    );
  };
  const registration_Complete_LoginLink = () => {
    return (
      <Box align="center" py={2}>
        {lang({
          EN: 'Registration complete, please check your email for login link.',
          SE: 'Registreringen klar, kolla din email för inloggningslänk.',
        })}
      </Box>
    );
  };

  const get_Registered_NotVerified = () => {
    return userState.user &&
      userState.user.status === C.USER.STATUS.VERIFIED ? (
      <Box align="center" py={2}>
        <Typography variant="body1" gutterBottom>
          {lang({
            EN: 'Welcome!',
            SE: 'Välkommen!',
          })}
        </Typography>
      </Box>
    ) : (
      <Box align="center" py={2}>
        {lang({
          EN: ' Almost there!',
          SE: 'Nästan framme!',
        })}
        <br />
        {lang({
          EN: 'Now check your',
          SE: 'Kolla din ',
        })}{' '}
        <Icon className={classes.mainIcon}>mail</Icon> <em>{values.email}</em>{' '}
        {lang({
          EN: ' for a code.',
          SE: ' efter en kod.',
        })}
        <br />
        <br />
        <TextField
          label={lang({
            EN: 'Code *',
            SE: 'Kod *',
          })}
          onChange={handleCodeChange}
          onKeyDown={handleCodeChangeEnter}
          placeholder="12345"
          name="verification_code"
          className={classes.fieldVerifyHolder}
          variant="outlined"
          inputProps={{ className: classes.fieldVerify }}
          InputLabelProps={{ shrink: true }}
        />
        <br />
        {userState.userProgress && <CircularProgress />}
        {userState.userError && (
          <Box align="center">
            <FormHelperText error>{userState.userError}</FormHelperText>
          </Box>
        )}
        <br />
        <br />
        <em>
          {lang({
            EN: 'Might end up in the spam folder',
            SE: 'Kan hamna i skräpkorgen',
          })}
        </em>
      </Box>
    );
  };
  const get_LoggedIn_Invited_NotRegistered = () => {
    return (
      <Box align="center" py={2}>
        Hello {authState.user.full_name}!
        <br />
        <br />
        You have been invitation to {userState.invitation.conference.title}.
        <br />
        <br />
      </Box>
    );
  };

  const get_LoggedIn_WithOtherUser = () => {
    return (
      <Box align="center" py={2}>
        NOTICE!
        <br />
        <br />
        You are logged in as
        <br />
        {authState.user.full_name} ({authState.user.email})
        <br />
        and this invitation is for
        <br />
        {userState.invitation.full_name} ({userState.invitation.email}).
        <br />
        <br />
        Something wrong? Contact us at
        <br />
        <Link href="mailto:support@coeo.events">support@coeo.events</Link>
        <br />
      </Box>
    );
  };

  let registrationState;

  if (invitationCode && userState.invitationLoaded && userState.invitation) {
    if (userState.invitationError) {
      registrationState = get_Invitation_Not_Found;
    } else if (userState.invitation.status === C.USER.STATUS.VERIFIED) {
      registrationState = get_LoggedIn_Registered;
    } else if (userState.invitation.status === C.USER.STATUS.UNREGISTERED) {
      if (
        !authState.userLoggedIn &&
        userState.invitation.status === C.USER.STATUS.VERIFIED
      ) {
        registrationState = get_UserExist_NotLoggedIn_NotRegistered;
      } else if (
        authState.userLoggedIn &&
        authState.user.status === C.USER.STATUS.VERIFIED
      ) {
        if (authState.user.id === userState.invitation.id) {
          registrationState = get_LoggedIn_Invited_NotRegistered;
        } else {
          registrationState = get_LoggedIn_WithOtherUser;
        }
      }
    }
  } else if (
    userState.registerSuccess &&
    userState.user.status === C.USER.STATUS.REGISTERED
  ) {
    registrationState = conferenceData.allow_auto_login
      ? registration_Complete_LoginLink
      : get_Registered_NotVerified;
  } else if (
    userState.registerSuccess &&
    userState.user.status === C.USER.STATUS.VERIFIED
  ) {
    // After verification (is logged in and will be redirected to /live)
    registrationState = get_Registered_NotVerified;
  } else if (!authState.userLoggedIn && userState.errorType === 'userexist') {
    // Trying to register same email (Register without invitation)
    registrationState = get_UserExist_NotLoggedIn_OpenRegistration;
  } else if (authState.stateLoaded && authState.userLoggedIn) {
    // Loggen in, but not registered for this conference
    registrationState = get_LoggedIn_Registered;
  }
  errors && Object.keys(errors).length && console.log('errors', errors);
  return (
    <div {...useNodeProps(props.node.id)} className={classes.root}>
      {!loaded ? (
        userState.invitationError ? (
          <Box align="center" py={2}>
            {userState.invitationError}
          </Box>
        ) : (
          <Box align="center">
            <CircularProgress disableShrink />
          </Box>
        )
      ) : conferenceData.registration_type ===
          C.CONFERENCE.REG_TYPE.INVITATION && !invitationCode ? (
        <Box align="center" py={2}>
          {lang({
            EN: 'Not open for registration',
            SE: 'Inte öppen för registrering',
          })}
        </Box>
      ) : (
        <Fragment>
          <Container maxWidth="md">
            {registrationState ? (
              registrationState()
            ) : (
              <Box py={2} pb={4}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1}>
                    {(!anonymous || anonymousButWantToCreateAccount) && (
                      <Grid
                        item
                        xs={12}
                        sm={conferenceData.allow_auto_login ? 12 : 6}
                      >
                        <TextField
                          // autoComplete="nope"
                          error={errors.hasOwnProperty('email')}
                          {...register('email')}
                          onBlur={forceReRender}
                          label="Email *"
                          placeholder="name@example.com"
                          type="email"
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                        {errors.email && (
                          <FormHelperText error>
                            {errors.email.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                    {(!anonymous || anonymousButWantToCreateAccount) &&
                      !conferenceData.allow_auto_login && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            // autoComplete="nosir"
                            error={errors.hasOwnProperty('password')}
                            {...register('password')}
                            label={lang({
                              EN: 'Create Password *',
                              SE: 'Skapa lösenord *',
                            })}
                            placeholder=""
                            type="password"
                            className={classes.field}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                          {errors.password && (
                            <FormHelperText error>
                              {errors.password.message}
                            </FormHelperText>
                          )}
                          <br />
                          &nbsp;
                        </Grid>
                      )}
                    <Grid item xs={6}>
                      <TextField
                        // autoComplete="nada"
                        error={errors.hasOwnProperty('first_name')}
                        {...register('first_name')}
                        label={lang({
                          EN: 'First Name *',
                          SE: 'Förnamn *',
                        })}
                        onBlur={forceReRender}
                        placeholder="Jane"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.first_name && (
                        <FormHelperText error>
                          {errors.first_name.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={errors.hasOwnProperty('last_name')}
                        {...register('last_name')}
                        label={lang({
                          EN: 'Last Name *',
                          SE: 'Efternamn *',
                        })}
                        onBlur={forceReRender}
                        placeholder="Doe"
                        className={classes.field}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors.last_name && (
                        <FormHelperText error>
                          {errors.last_name.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    {!hidden.title && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...register('title')}
                          label={lang({
                            EN: 'Title',
                            SE: 'Title',
                          })}
                          onBlur={forceReRender}
                          placeholder={lang({
                            EN: 'CEO, Student, ..',
                            SE: 'VD, Student, ..',
                          })}
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )}
                    {!hidden.company && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          {...register('company')}
                          onBlur={forceReRender}
                          label={lang({
                            EN: 'Organization',
                            SE: 'Organisation',
                          })}
                          placeholder="Acme AB"
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )}
                    {!hidden.city && (
                      <Grid item xs={7} sm={6}>
                        <TextField
                          {...register('location_city')}
                          onBlur={forceReRender}
                          label={lang({
                            EN: 'City',
                            SE: 'Stad',
                          })}
                          placeholder={lang({
                            EN: 'New York',
                            SE: 'Stockholm',
                          })}
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )}
                    {!hidden.country && (
                      <Grid item xs={5} sm={6}>
                        <CountrySelect
                          lang={lang}
                          classes={classes}
                          redrawState={redrawState}
                          handleSelectChange={handleSelectChange}
                          value={values.location_countrycode}
                        />
                      </Grid>
                    )}
                    {!hidden.phone && (
                      <Grid item xs={12}>
                        <TextField
                          {...register('phone')}
                          onBlur={forceReRender}
                          label={lang({
                            EN: 'Phone',
                            SE: 'Telefon',
                          })}
                          placeholder="+4670123456"
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )}
                    {!hidden.ecard && (
                      <Grid item xs={12}>
                        <Box align="center" pt={1}>
                          <Typography variant="body2">
                            <Icon className={classes.eCardIcon}>
                              contact_mail
                            </Icon>{' '}
                            {lang({
                              EN: 'E-Card to exchange with others',
                              SE: 'Digitalt Visitkort',
                            })}
                          </Typography>
                          <Box
                            className={classes.businessCard}
                            align="left"
                            p={1}
                            display="flex"
                          >
                            <Box className={classes.businessCardBg} />
                            <Box className={classes.avatarHolder}>
                              {uploading && (
                                <CircularProgress
                                  className={classes.uploadprogress}
                                  disableShrink
                                />
                              )}
                              <Avatar
                                className={clsx(
                                  classes.avatar,
                                  picture && !uploading && classes.fullOpacity
                                )}
                                onLoad={avatarImageloaded}
                                src={getImage(
                                  picture || '/avatars/co_avatars_14.png',
                                  ImageSize.Small
                                )}
                              />
                            </Box>
                            <Box
                              flexGrow={1}
                              className={classes.businessCardText}
                              display="block"
                            >
                              <Box>
                                {values.first_name} {values.last_name}
                              </Box>
                              {values.title && (
                                <Box className={classes.cardTitle}>
                                  {values.title}
                                </Box>
                              )}
                              {values.company && <Box>{values.company}</Box>}
                              <Box className={classes.cardLocation}>
                                {values.location_city && (
                                  <span>{values.location_city}, </span>
                                )}
                                {values.first_name && values.last_name && (
                                  <span>
                                    {
                                      COUNTRIES.COUNTRIES[
                                        values.location_countrycode
                                      ]
                                    }
                                  </span>
                                )}
                              </Box>

                              <Box
                                pt={1}
                                className={classes.businessCardTextSmall}
                              >
                                {values.email}
                              </Box>
                              {values.phone && <Box>{values.phone}</Box>}
                            </Box>
                          </Box>
                          <Box
                            pb={1}
                            className={classes.avatarLink}
                            display="flex"
                            align="left"
                            alignItems="center"
                          >
                            <Box>
                              <DropZone
                                acceptedfiletypes="image/jpeg, image/png, image/webp"
                                uploadStart={uploadStart}
                                color="primary"
                                variant="text"
                                uploadHandler={uploadHandler}
                                buttonText={
                                  picture
                                    ? lang({
                                        EN: 'Change photo',
                                        SE: 'Byt bild',
                                      })
                                    : lang({
                                        EN: 'Upload photo',
                                        SE: 'Ladda bild',
                                      })
                                }
                                uploadurl={`/api/visitor/conferences/${window.CONFERENCE_ID}/signmediaupload`}
                              />
                            </Box>
                            <Box flexGrow={1}>
                              {lang({
                                EN: 'or ',
                                SE: 'eller ',
                              })}
                              <Link
                                className={classes.chooseAvatarLink}
                                component="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setShowAvatars(!showAvatars);
                                }}
                              >
                                {showAvatars
                                  ? lang({
                                      EN: 'Hide avatars',
                                      SE: 'Göm avatarer',
                                    })
                                  : lang({
                                      EN: 'Choose avatar',
                                      SE: 'Välj avatar',
                                    })}
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {!hidden.ecard && showAvatars && (
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          pb={1}
                          className={classes.listAvatarHolder}
                        >
                          {[...Array(52).keys()].map((i) => {
                            const avatarName = `/avatars/co_avatars_${String(
                              i + 1
                            ).padStart(2, '0')}.png`;
                            return (
                              <Link
                                key={i}
                                component="button"
                                className={classes.listAvatarLink}
                                onClick={(e) => chooseAvatar(e, avatarName)}
                              >
                                <Avatar
                                  className={classes.listAvatar}
                                  src={getImage(avatarName, ImageSize.Small)}
                                />
                              </Link>
                            );
                          })}
                        </Box>
                      </Grid>
                    )}
                    {!hidden.description && (
                      <Grid item xs={12}>
                        <TextField
                          {...register('info_description')}
                          label={lang({
                            EN: 'Description',
                            SE: 'Beskrivning',
                          })}
                          placeholder={lang({
                            EN: 'Introduce yourself',
                            SE: 'Introducera dig',
                          })}
                          multiline
                          minRows={3}
                          maxRows={30}
                          className={classes.field}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                    )}
                    {conferenceData.registration_fields &&
                      conferenceData.registration_fields.fields && (
                        <RegisterCustomFields
                          fields={conferenceData.registration_fields.fields}
                          register={register}
                          fieldClass={classes.field}
                          setValue={setValue}
                          control={control}
                        />
                      )}

                    {conferenceTags.length > 0 && (
                      <DrawTags
                        conferenceTags={conferenceTags}
                        toggleTag={toggleTag}
                        textTagSelect={
                          props.node.content.textTagSelect ||
                          lang({
                            EN: 'Please pick relevant tags',
                            SE: 'Välj taggar som passar dig',
                          })
                        }
                      />
                    )}
                    {values.email && !query.invite && (
                      <Grid item xs={12}>
                        <Box p={1} align="right">
                          {lang({
                            EN: 'Please double check your email',
                            SE: 'Dubbelkolla gärna din email',
                          })}
                          <br />
                          <strong>{values.email}</strong>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <UserAgreement
                            errors={errors}
                            values={values}
                            onChange={(val) => setValue('agreed', val)}
                          />
                          {/* {errors.agreed && (
                            <FormHelperText error>
                              {lang({
                                EN: 'You need to check the terms',
                                SE: 'Du måste godkänna användarvillkoren',
                              })}
                            </FormHelperText>
                          )}
                          <Checkbox
                            {...register('agreed')}
                            className={classes.checkbox}
                            color="primary"
                          />
                          {lang({
                            EN: 'I agree to ',
                            SE: 'Jag godkänner ',
                          })}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleUserAgreement(true);
                            }}
                          >
                            {lang({
                              EN: 'User Terms',
                              SE: 'Användarvillkoren',
                            })}
                          </Link> */}
                        </Box>
                        <Box textAlign="right">
                          {userState.userProgress ? (
                            <CircularProgress disableShrink />
                          ) : userState.user ? (
                            <Box>
                              <Typography variant="body1" gutterBottom>
                                {lang({
                                  EN: 'Welcome!',
                                  SE: 'Välkommen',
                                })}
                              </Typography>
                            </Box>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              {lang({
                                EN: 'Done!',
                                SE: 'Klar',
                              })}
                            </Button>
                          )}
                          {userState.userError && (
                            <>
                              <FormHelperText error>
                                {userState.userError}
                              </FormHelperText>
                              {userState.errorType === 'userexist' && (
                                <Box></Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
                <br />
                <br />
                <br />
              </Box>
            )}
          </Container>
        </Fragment>
      )}
    </div>
  );
};
