/* eslint-disable no-undef */

// From https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
const isMobileDevice = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

const colorStrToRGB = (color) => {
  let r, g, b;

  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  return { r, g, b };
};

const lightOrDark = (color) => {
  let hsp;
  // Check the format of the color, HEX or RGB?

  const { r, g, b } = colorStrToRGB(color);

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const browser = {
  isAndroid: /Android/.test(navigator.userAgent),
  isCordova: !!window.cordova,
  isEdge: /Edge/.test(navigator.userAgent),
  isFirefox: /Firefox/.test(navigator.userAgent),
  isChrome: /Google Inc/.test(navigator.vendor),
  isChromeIOS: /CriOS/.test(navigator.userAgent),
  isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
  isIE: /Trident/.test(navigator.userAgent),
  isIOS: /(iPhone|iPad|iPod)/.test(navigator.userAgent),
  isOpera: /OPR/.test(navigator.userAgent),
  isSafari:
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
  isTouchScreen:
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch),
  isWebComponentsSupported:
    'registerElement' in document &&
    'import' in document.createElement('link') &&
    'content' in document.createElement('template'),
};

module.exports = {
  lightOrDark,
  isMobileDevice,
  browser,
};
