import React, { createContext, useReducer } from 'react';
import userActions from '../actions/userActions';
import userReducer from '../reducers/userReducer';

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [userState, dispatch] = useReducer(userReducer, {
    userProgress: false,
    userLoaded: false,
    userError: null,
    errorType: null,
    user: null,
    email: null, // For verification
    invitationProgress: false,
    invitationLoaded: false,
    invitationError: null,
    registerSuccess: false,
    invitation: null,
    listError: null,
    listLoaded: false,
    list: [],
    updatingFollowing: false,
  });
  const actions = userActions(dispatch);

  return (
    <UserContext.Provider value={{ userState, dispatch, actions }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
