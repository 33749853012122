import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import UserAvatar from '@/js/shared/components/ui/UserAvatar';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import {
  Box,
  Button,
  ButtonBase,
  Icon,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

interface IContainerStyleProps {
  override: Record<string, any>;
}

const useContainerStyles = makeStyles<Theme, IContainerStyleProps>(
  (theme: Theme) => ({
    root: ({ override }) => ({
      width: '100%',
      padding: theme.spacing(1),
      textAlign: 'center',
      ...override.root,
    }),
    rqimg: {
      width: '100%',
      maxWidth: '592px',
      height: 'auto',
      borderRadius: 5,
      overflow: 'hidden',
    },
  })
);

export const Render = ({
  node: {
    id,
    override,
    content: {},
  },
}: IProps) => {
  const classes = useContainerStyles({
    override,
  });
  const { authState } = useContext(AuthContext);
  const [pretixData, setPretixData] = useState<any>({});
  const [error, setError] = useState<string>('');

  const fetchPretixData = () => {
    axios
      .get(`/api/mypretixdata`)
      .then((response) => {
        const data = response.data;
        setPretixData(data);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  useEffect(() => {
    authState.userLoggedIn && fetchPretixData();
  }, [authState.userLoggedIn]);

  return (
    <div className={classes.root} {...useNodeProps(id)}>
      {authState.user
        ? pretixData?.qr && (
            <Box>
              <img className={classes.rqimg} src={pretixData.qr} />

              {/* <Button
                startIcon={<Icon>cloud_download</Icon>}
                href={pretixData.pdf}
                target="_blank"
              >
                PDF
              </Button> */}
            </Box>
          )
        : 'Need to be logged in, and have a ticket to see this information.'}

      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};
