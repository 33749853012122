import { INodeTemplate } from '../../types/node';
import { WidgetSettings } from '@/widget-editor/components';
import { Settings } from './edit';
import { Render as WidgetRender } from './render';
import {
  withWidgetDisplay,
  withWidgetSettings,
} from '@/widget-editor/utilities';

const template: INodeTemplate = {
  version: 1,
  icon: 'smart_button',
  name: 'Link Button',
  type: 'widget-button',
  tag: 'widgets',
  content: {
    widgetName: 'Link Button',
    label: '',
    description: '',
    link: {
      type: 'link',
      target: '_self',
      targetUrl: '',
      targetRel: 'noreferrer',
    },
  },
  override: {
    root: {},
    button: {},
  },
  supports: {
    contexts: ['room'],
    appender: true,
  },
  //   depricated: [
  //     {
  //       migrate({ link, ...content }) {
  //         const { targeturl, ...otherParams } = link;
  //         return {
  //           ...content,
  //           link: { ...otherParams, targetUrl: targeturl },
  //         };
  //       },
  //     },
  //   ],
};

const Render = withWidgetDisplay(WidgetRender);

const Edit = withWidgetSettings(WidgetSettings)(Settings);

export { template, Edit, Render };
