import DiscussionList from '@/js/shared/components/discussion/DiscussionList';
import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';
import { RoomContext } from '@/js/shared/context/RoomContext';
import { INode, INodeProps } from '@/types/node';
import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IContent } from './contract';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  root: ({ override }) => ({
    ...override.root,
  }),
}));

export const Render = ({
  node: {
    id,
    content: { forumHeadline, topicSlug },
    override,
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({ override });
  const location = useLocation();
  const history = useHistory();
  const [topic, setTopic] = useState(null);

  const { roomState } = useContext(RoomContext); // Hämta Context

  useEffect(() => {
    topicSlug &&
      axios
        .get(
          `/api/visitor/conferences/${
            (window as any).CONFERENCE_ID
          }/topic/${topicSlug}`
        )
        .then((response: any) => {
          setTopic(response.data);
        });
  }, [topicSlug]);
  return (
    <WidgetWrapper>
      <div className={classes.root}>
        {/* {(roomState.currentEvent || !connectSession) && ( */}
        {!topicSlug && (
          <Box>No topic specified (please select in widget admin)</Box>
        )}
        {topic && (
          <DiscussionList
            listType="topic"
            location={location}
            history={history}
            isEmbedded={true}
            connectionType={roomState.currentEvent ? 'event' : null}
            connectionId={
              roomState.currentEvent ? roomState.currentEvent.id : null
            }
            hideMenu={true}
            title={forumHeadline}
            alwaysSmall={true}
            topicSlug={topicSlug || null}
            topic={topic}
          />
        )}
      </div>
    </WidgetWrapper>
  );
};
