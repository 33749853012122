import { ErrorBoundary } from '@/error-boundary';
import { FallbackProps } from '@/error-boundary/contract';
import { NodeContext } from '@/node-editor/context';
import {
  getNodeContent,
  getSelectionWithChildren,
  useSelectors,
} from '@/node-editor/store/selectors';
import { migrateContent } from '@/node-editor/utilities';
import { NodeContentError } from '@/node-editor/validation';
import { nodeMap } from '@/nodes';
import { INode, TRenderContext } from '@/types/node';
import React, { useMemo } from 'react';

interface IProps {
  node: INode;
  context: TRenderContext;
  conferenceId: string;
}

interface IRenderFallbackProps extends FallbackProps {
  conferenceId: string;
}

const RenderFallback = ({
  conferenceId,
  error,
}: IRenderFallbackProps): JSX.Element | null => {
  console.log('RenderFallback', error);

  if (error instanceof NodeContentError && error.hasMigration()) {
    console.log('Has migration!');

    const node = {
      ...error.getNode(),
      content: {
        ...migrateContent(
          error.getMigrationStrategy(),
          getNodeContent(error.getNode())
        ),
      },
    };

    return React.cloneElement(
      error.data[error.getContext()]({
        node,
        conferenceId,
        children: (node.children as INode[]) || [],
      })
    );
  }

  return null;
};

const Node = ({ node, context, conferenceId }: IProps) => {
  const Component = useMemo(() => nodeMap[node.type]({ node, context }), []);

  return (
    <Component key={node.id} node={node} conferenceId={conferenceId}>
      {node.children as INode[]}
    </Component>
  );
};

export const RenderChildren = ({ children }: { children: INode[] }) => {
  const selectors = useSelectors(() => ({
    getSelectionWithChildren,
  }));

  return (
    <NodeContext.Consumer>
      {({ context, conferenceId }) =>
        selectors.getSelectionWithChildren(children).map((node) => (
          <ErrorBoundary
            key={node.id}
            fallbackRender={(fallbackProps) => (
              <RenderFallback conferenceId={conferenceId} {...fallbackProps} />
            )}
          >
            <Node node={node} context={context} conferenceId={conferenceId} />
          </ErrorBoundary>
        ))
      }
    </NodeContext.Consumer>
  );
};
