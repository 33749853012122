import { registerNode } from '@/node-editor';
import { nodeResolver } from '@/node-editor/utilities';
import { NodeComponentMap } from '@/types/node';

import { template as addtocalendar } from './addtocalendar';
import { template as addtohomescreen } from './addtohomescreen';
import { template as calendarlist } from './calendarlist';
import { template as card } from './card';
import { template as column } from './column';
import { template as container } from './container';
import { template as customFields } from './custom-fields';
import { template as embed } from './embed';
import { template as expoList } from './expo-list';
import { template as fileList } from './file-list';
import { template as forumList } from './forum-list';
import { template as forumPost } from './forum-post';
import { template as forumReplyText } from './forum-reply-text';
import { template as forumReplyImage } from './forum-reply-image';
import { template as grid } from './grid';
import { template as header } from './header';
import { template as image } from './image';
import { template as navigation } from './navigation';
import { template as pretix } from './pretix';
import { template as pretixTicket } from './pretix-ticket';
import { template as program } from './program';
import { template as poll } from './poll';
import { template as rooms } from './rooms';
import { template as root } from './root';
import { template as row } from './row';
import { template as text } from './text';
import { template as markdown } from './markdown';
import { template as topmenu } from './topmenu';
import { template as visitorgoals } from './visitorgoals';
import { template as videomux } from './videomux';

// Static
import { template as registration } from './registration';
import { template as staticEventinfo } from './static-eventinfo';

// Live Session Widgets
import { template as embedWidget } from './embed-widget';
import { template as forumPostWidget } from './forum-post-widget';
import { template as forumReplyTextWidget } from './forum-reply-text-widget';
import { template as forumReplyImageWidget } from './forum-reply-image-widget';
import { template as programWidget } from './program-widget';
import { template as widgetMarkdown } from './widget-markdown';
import { template as widgetarea } from './widget-area';
import { template as widgetButton } from './widget-button';
import { template as widgetActionbutton } from './widget-actionbutton';
import { template as widgetSessioninfo } from './widget-sessioninfo';
import { template as widgetDiscussion } from './widget-discussion';
import { template as widgetRoot } from './widget-root';
import { template as programTimelineWidget } from './programtimeline-widget';

[
  container,
  row,
  column,

  markdown,
  image,
  card,
  embed,
  grid,

  // Special
  customFields,
  calendarlist,
  navigation,
  program,
  pretix,
  pretixTicket,
  poll,
  rooms,
  expoList,
  addtocalendar,
  addtohomescreen,
  topmenu,
  videomux,
  visitorgoals,
  fileList,

  //Forum inputs
  forumList,
  forumPost,
  forumReplyText,
  forumReplyImage,

  // Static (only available in one page)
  registration,
  staticEventinfo,

  // OLD (depricated?)
  header,
  text,

  // Root (cant be selected)
  root,

  // live widget specific
  embedWidget,
  forumPostWidget,
  forumReplyTextWidget,
  forumReplyImageWidget,
  programWidget,
  widgetMarkdown,
  widgetarea,
  widgetButton,
  widgetActionbutton,
  widgetSessioninfo,
  widgetDiscussion,
  widgetRoot,
  programTimelineWidget,
].forEach((template) => registerNode(template.type, template));

export const nodeMap: NodeComponentMap = {
  addtocalendar: (provided) =>
    nodeResolver(() => import('@/nodes/addtocalendar'), provided),
  addtohomescreen: (provided) =>
    nodeResolver(() => import('@/nodes/addtohomescreen'), provided),

  calendarlist: (provided) =>
    nodeResolver(() => import('@/nodes/calendarlist'), provided),
  card: (provided) => nodeResolver(() => import('@/nodes/card'), provided),
  column: (provided) => nodeResolver(() => import('@/nodes/column'), provided),
  container: (provided) =>
    nodeResolver(() => import('@/nodes/container'), provided),
  'custom-fields': (provided) =>
    nodeResolver(() => import('@/nodes/custom-fields'), provided),
  embed: (provided) => nodeResolver(() => import('@/nodes/embed'), provided),
  'expo-list': (provided) =>
    nodeResolver(() => import('@/nodes/expo-list'), provided),
  'file-list': (provided) =>
    nodeResolver(() => import('@/nodes/file-list'), provided),
  'forum-list': (provided) =>
    nodeResolver(() => import('@/nodes/forum-list'), provided),
  'forum-post': (provided) =>
    nodeResolver(() => import('@/nodes/forum-post'), provided),
  'forum-reply-text': (provided) =>
    nodeResolver(() => import('@/nodes/forum-reply-text'), provided),
  'forum-reply-image': (provided) =>
    nodeResolver(() => import('@/nodes/forum-reply-image'), provided),
  grid: (provided) => nodeResolver(() => import('@/nodes/grid'), provided),
  header: (provided) => nodeResolver(() => import('@/nodes/header'), provided),
  image: (provided) => nodeResolver(() => import('@/nodes/image'), provided),
  markdown: (provided) =>
    nodeResolver(() => import('@/nodes/markdown'), provided),
  navigation: (provided) =>
    nodeResolver(() => import('@/nodes/navigation'), provided),
  pretix: (provided) => nodeResolver(() => import('@/nodes/pretix'), provided),
  'pretix-ticket': (provided) =>
    nodeResolver(() => import('@/nodes/pretix-ticket'), provided),
  program: (provided) =>
    nodeResolver(() => import('@/nodes/program'), provided),
  poll: (provided) => nodeResolver(() => import('@/nodes/poll'), provided),
  registration: (provided) =>
    nodeResolver(() => import('@/nodes/registration'), provided),
  'static-eventinfo': (provided) =>
    nodeResolver(() => import('@/nodes/static-eventinfo'), provided),

  rooms: (provided) => nodeResolver(() => import('@/nodes/rooms'), provided),
  root: (provided) => nodeResolver(() => import('@/nodes/root'), provided),
  row: (provided) => nodeResolver(() => import('@/nodes/row'), provided),
  text: (provided) => nodeResolver(() => import('@/nodes/text'), provided),
  topmenu: (provided) =>
    nodeResolver(() => import('@/nodes/topmenu'), provided),

  videomux: (provided) =>
    nodeResolver(() => import('@/nodes/videomux'), provided),
  visitorgoals: (provided) =>
    nodeResolver(() => import('@/nodes/visitorgoals'), provided),

  // live widget specific
  'embed-widget': (provided) =>
    nodeResolver(() => import('@/nodes/embed-widget'), provided),
  'forum-post-widget': (provided) =>
    nodeResolver(() => import('@/nodes/forum-post-widget'), provided),
  'forum-reply-text-widget': (provided) =>
    nodeResolver(() => import('@/nodes/forum-reply-text-widget'), provided),
  'forum-reply-image-widget': (provided) =>
    nodeResolver(() => import('@/nodes/forum-reply-image-widget'), provided),
  'program-widget': (provided) =>
    nodeResolver(() => import('@/nodes/program-widget'), provided),
  'widget-markdown': (provided) =>
    nodeResolver(() => import('@/nodes/widget-markdown'), provided),
  'widget-area': (provided) =>
    nodeResolver(() => import('@/nodes/widget-area'), provided),
  'widget-button': (provided) =>
    nodeResolver(() => import('@/nodes/widget-button'), provided),
  'widget-actionbutton': (provided) =>
    nodeResolver(() => import('@/nodes/widget-actionbutton'), provided),
  'widget-sessioninfo': (provided) =>
    nodeResolver(() => import('@/nodes/widget-sessioninfo'), provided),
  'widget-discussion': (provided) =>
    nodeResolver(() => import('@/nodes/widget-discussion'), provided),
  'widget-root': (provided) =>
    nodeResolver(() => import('@/nodes/widget-root'), provided),
  'programtimeline-widget': (provided) =>
    nodeResolver(() => import('@/nodes/programtimeline-widget'), provided),
};
