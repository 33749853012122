import { useContext } from 'react';
import { NodeDialogContext } from '@/node-editor/components';

export const useNodeDialog = () => {

    const context = useContext(NodeDialogContext);

    if (context === undefined) {
        throw new Error('useNodeDialog must be used within NodeDialogProvider.');
    }

    return context;
}