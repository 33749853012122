import { SiteHeader } from '@/components/site-header';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { useNodeProps } from '@/node-editor/hooks';
import { INode, INodeProps } from '@/types/node';
import { IMenuItem } from '@/types/menu';
import {
  Box,
  Container,
  Icon,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { getImage, ImageSize } from '../../../shared/utils/imageFormat';
import { IContent } from './contract';
import { AuthContext } from '@/js/shared/context/AuthContext';
import { TopMenuItem } from '@/components/topmenuitem';
import { MenuContext } from '@/js/shared/context/MenuContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  IContent & { override: Record<string, any> }
>((theme) => ({
  container: ({ containerWidth, override }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 5,
    paddingBottom: 5,
    minHeight: 54,
    maxWidth: !containerWidth
      ? 'none'
      : theme.breakpoints.values[containerWidth],
    ...override.root,
  }),
  left: {
    flexGrow: 1,
  },
  right: {
    textAlign: 'right',
  },
}));

export const Render = ({
  node: {
    id,
    override,
    content: { containerWidth },
  },
  conferenceId,
}: IProps) => {
  const classes = useStyles({
    containerWidth,
    override,
  });
  const { menuOpen, setMenuOpen } = useContext(MenuContext);
  const {
    conferenceState: { conferenceDetail },
  } = useContext(ConferenceContext);
  const { authState } = useContext(AuthContext); // Hämta Context
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  const items: IMenuItem[] = conferenceDetail.menu_items.filter(
    (m: IMenuItem) =>
      m.type === 'site' && !(m.onlypublic && authState.userLoggedIn)
  );

  const left = items.filter((m) => m.position === 'start');
  const right = items.filter((m) => m.position === 'end');
  const elevated = items.filter((m) => m.design === 'elevated');
  const leftElevated = elevated.filter((m) => m.position === 'start');
  const rightElevated = elevated.filter((m) => m.position === 'end');
  const unElevated = items.filter((m) => m.design !== 'elevated');

  /**
   * Menu rendering Logic:
   * - IF no items OR (mobile AND logged in), hide everything
   * - IF large, show full menu
   * - IF mobile AND not logged in AND 1 left menu AND 1 unElevated, show left menu item (Eg. Home AND Register)
   * - IF mobile AND not logged, show Elevated
   * - IF mobile AND not logged AND 2+ unElevated, show hamburger
   */

  return items.length === 0 ||
    (smallScreen && authState.userLoggedIn) ? null : (
    <Container className={classes.container} {...useNodeProps(id)}>
      <Box className={classes.left}>
        {smallScreen && unElevated.length > 1 && (
          <IconButton onClick={() => setMenuOpen(true)} size="small">
            <Icon>menu</Icon>
          </IconButton>
        )}

        {!smallScreen ? (
          left.map((m) => (
            <TopMenuItem data={m} key={m.id} conferenceId={conferenceId} />
          ))
        ) : left.length === 1 && unElevated.length === 1 ? (
          <TopMenuItem data={left[0]} conferenceId={conferenceId} />
        ) : (
          leftElevated.map((m) => (
            <TopMenuItem data={m} key={m.id} conferenceId={conferenceId} />
          ))
        )}
      </Box>
      <Box className={classes.right}>
        {!smallScreen
          ? right.map((m) => (
              <TopMenuItem data={m} key={m.id} conferenceId={conferenceId} />
            ))
          : rightElevated.map((m) => (
              <TopMenuItem data={m} key={m.id} conferenceId={conferenceId} />
            ))}
      </Box>
    </Container>
  );
};
