import { ButtonGroupControl } from '@/components/buttongroup-control';
import { InputControl } from '@/components/input-control';
import { JSONControl } from '@/components/json-control';
import { SelectControl } from '@/components/select-control';
import { SettingsPanel } from '@/components/settings-panel';
import { SettingsContainer } from '@/node-editor/components';
import { SETTINGS_DEFAULTS } from '@/node-editor/store';
import {
  getNodeOverride,
  hasNodeOverride,
} from '@/node-editor/store/selectors';
import { INode, INodeProps } from '@/types/node';
import { Grid, Icon, Typography } from '@material-ui/core';
import React, { FC, Fragment, useContext, useMemo } from 'react';
import { IContent } from './contract';
import { ManagerConferenceContext } from '@/js/shared/context/ManagerConferenceContext';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

export const Settings = ({
  node: {
    id,
    type,
    override,
    content: { eventUrl },
  },
}: IProps) => {
  const { conferenceData } = useContext(ManagerConferenceContext);

  // if (
  //   !eventUrl &&
  //   conferenceData.conference.pretix_id &&
  //   conferenceData.conference.organization &&
  //   conferenceData.conference.organization.pretix_id
  // ) {
  //   // eventUrl = conferenceData.conference.pretix_id;
  //   eventUrl = `https://pretix.eu/${conferenceData.conference.organization.pretix_id}/${conferenceData.conference.pretix_id}/`;
  // }

  return (
    <Fragment>
      <SettingsPanel label="" initialOpen>
        <InputControl
          name="eventUrl"
          //defaultValue={eventUrl}
          fullWidth
          variant="outlined"
          label="OVERRIDE Pretix full URL"
        />
        Override when using multiple Pretix shops or not using integration,
        otherwise leave empty.
        <br />
        <Typography variant="caption">
          <em>https://pretix.eu/demo/democon/</em>
        </Typography>
      </SettingsPanel>

      <SettingsPanel
        label="Css override"
        initialOpen={useMemo(() => hasNodeOverride(type, override), [])}
      >
        <JSONControl
          id={id}
          name="override"
          defaultValue={getNodeOverride(type, override)}
        />
      </SettingsPanel>
    </Fragment>
  );
};

export const Edit: FC<IProps> = ({ children, ...props }) => {
  return (
    <SettingsContainer>
      <Settings {...props} />
    </SettingsContainer>
  );
};
