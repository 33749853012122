const C = require('../constants/general');

const CONFERENCE_ID =
  // eslint-disable-next-line no-undef
  typeof window === 'undefined' ? undefined : window.CONFERENCE_ID;

module.exports.ImageSize = {
  Icon: { w: 64 },
  Small: { w: 300 },
  Medium: { w: 600 },
  Default: { w: 940 },
  Large: { w: 1920 },
  Original: {},
  Email: { w: 570 },
};

const getQueryParams = (params) =>
  Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

const imageUrl = (path, params) =>
  `https://${C.URLS.IMAGES}/${path}${
    params ? '?' + getQueryParams({ fit: 'max', ...params }) : ''
  }`;

const getSharedImage = (path, imageSize) =>
  imageUrl(`shared${path}`, imageSize);

const getConfImage = (path, id, imageSize) =>
  path && imageUrl(`conferences/${id}/${path}`, imageSize);

const getExactPath = (path, imageSize) =>
  path && imageUrl(path.substring(1), imageSize);

const getFolderImage = (folder, path, imageSize) =>
  path && imageUrl(`${folder}/${path}`, imageSize);

const getOrgImage = (path, id, imageSize) =>
  imageUrl(`organizations/${id}/${path}`, imageSize);

module.exports.imageUrl = imageUrl;
module.exports.getSharedImage = getSharedImage;
module.exports.getConfImage = getConfImage;
module.exports.getOrgImage = getOrgImage;
module.exports.getImage = (
  path,
  imageSize,
  conferenceId = CONFERENCE_ID,
  folder = null
) =>
  path &&
  (folder // For eg. manager images
    ? getFolderImage(folder, path, imageSize)
    : path.startsWith('/conferences/') // Trick for templates (between different conferences)
    ? getExactPath(path, imageSize)
    : path[0] === '/' // Shared images
    ? getSharedImage(path, imageSize)
    : getConfImage(path, conferenceId, imageSize));

module.exports.findImage = () =>
  'https://via.placeholder.com/600/FF0000/FFFFFF/?text=Testbild';
