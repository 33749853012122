import { ErrorBoundary } from '@/error-boundary';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';

interface IProps {
    switchCase: string;
    render: Record<string, () => JSX.Element>;
}

/**
 * Just like a Switch statement this component takes a switchCase of some state, and renders the component matching the case.
 * 
 * @return JSX.Element
 * @example
 *  <SwitchRenderer 
 *    switchCase={type}
 *    render={{
 *      event_case_1: () => <AComponent/>,
 *      event_case_2: () => <AnotherComponent />,
 *      default: () => <DefaultComponent />
 *    }}
 *  />
 */
export const SwitchRenderer = ({switchCase, render}: IProps): JSX.Element => {

    const ChildComponent = () => {

        if ( ! render[switchCase] && render['default'] ) {

            return render['default']();
        }

        if ( render[switchCase] ) {

            return render[switchCase]();
        }

        throw new Error( 'No component to render in SwitchRenderer. Maybee you forgot to provide render prop with default case?' );
    }

    return switchCase ? useMemo(() => (
        <ErrorBoundary
          fallbackRender={() => (
            <Typography variant="subtitle2" align="center" color="error">
              Something went wrong.
              <br />
              Please refresh your browser.
            </Typography>
          )}
        >
          <ChildComponent />
        </ErrorBoundary>
    ), [switchCase]) : null;
}