import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';

export const DiscussionContext = createContext();

const DiscussionContextProvider = (props) => {
  const { authState } = useContext(AuthContext);
  const [viewedByClient, setViewedByClient] = useState(false);
  const [discussionNotifications, setDiscussionNotifications] = useState([]);

  const dispatch = (action) => {
    switch (action.type) {
      case 'DISCUSSION_NEW': {
        setDiscussionNotifications((current) => {
          const other = current.filter((dn) => dn.id !== action.msg.id); // Remove updated ids
          return [action.msg, ...other];
        });

        setViewedByClient(false);
        break;
      }
    }
  };

  const updateReadState = () => {
    setViewedByClient(true);
    axios
      .put(`/api/visitor/discussionnotificationsviewed`)
      .then(() => {})
      .catch(() => {});
  };

  const loadNotifications = () => {
    axios
      .get(`/api/visitor/listdiscussionnotifications`)
      .then((response) => {
        setDiscussionNotifications(response.data);
      })
      .catch(() => {});
  };

  const gotoDiscussion = (discussionId) => {
    window.location.href = `/live/discussion?discussion=${discussionId}`;
  };

  useEffect(() => {
    if (authState.userLoggedIn) {
      loadNotifications();
    }
  }, [authState.userLoggedIn]);

  return (
    <DiscussionContext.Provider
      value={{
        dispatch,
        discussionNotifications,
        viewedByClient,
        updateReadState,
        gotoDiscussion,
        loadNotifications,
      }}
    >
      {props.children}
    </DiscussionContext.Provider>
  );
};

export default DiscussionContextProvider;
