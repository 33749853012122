import { InputControl } from '@/components/input-control';
import { SettingsPanel } from '@/components/settings-panel';
import { INodeProps } from '@/types/node';
import React, { Fragment } from 'react';

export const WidgetSettings = ({
  node: {
    content: { widgetName },
  },
  children,
}: INodeProps) => {
  return (
    <Fragment>
      {children}
      <SettingsPanel label="Widget Name">
        <InputControl
          name="widgetName"
          defaultValue={widgetName}
          fullWidth
          variant="outlined"
          label="Widget name"
        />
      </SettingsPanel>
    </Fragment>
  );
};
