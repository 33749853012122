export const SETTINGS_DEFAULTS = {
    VERTICAL_SPACE_OPTIONS: [
        {
            label: 'None',
            value: '0'
        },
        {
            label: 'Small',
            value: 'sm'
        },
        {
            label: 'Medium',
            value: 'md'
        },
        {
            label: 'Large',
            value: 'lg'
        },
        {
            label: 'X-Large',
            value: 'xl'
        }
    ],
    ALIGNMENT_OPTIONS: {
        start: 'flex-start',
        center: 'center',
        end: 'flex-end'
    }
}