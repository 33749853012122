import WidgetWrapper from '@/js/shared/components/widgets/WidgetWrapper';
import { VideoChatContext } from '@/js/shared/context/VideoChatContext';
import { useNodeProps } from '@/node-editor/hooks';
import { ITextLink } from '@/types/link';
import { INode, INodeProps } from '@/types/node';
import { Box, Button, Icon, makeStyles, Theme } from '@material-ui/core';
import React, { useContext } from 'react';
import { IContent } from './contract';
import { useLinkAction } from '@/hooks/useLinkAction';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<Theme, { override: Record<string, any> }>(() => ({
  root: ({ override }) => ({
    textAlign: 'center',
    ...override.root,
  }),
  button: ({ override }) => ({
    ...override.button,
  }),
}));

export const Render = ({
  node: {
    id,
    content: { link, label, description },
    override,
  },
  conferenceId,
}: IProps) => {
  const { linkActionCall } = useLinkAction();

  const classes = useStyles({ override });

  return (
    <WidgetWrapper>
      <Box className={classes.root}>
        <Button
          {...useNodeProps(id)}
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          // href={link.targetUrl}
          // target={link.target}
          {...linkActionCall(
            {
              target: link.target,
              url: link.targetUrl,
            },
            'href'
          )}
          startIcon={link.target === '_blank' ? <Icon>launch</Icon> : null}
        >
          {label}
        </Button>
        {description && <div>{description}</div>}
      </Box>
    </WidgetWrapper>
  );
};
